import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  IconEdit,
  IconTick,
  Inline,
  TextField,
  useToast,
} from 'braid-design-system';
import { useState } from 'react';

import { useApi } from 'src/api';

export const Name = ({ initialUsername }: { initialUsername?: string }) => {
  const api = useApi();
  const { getAccessTokenSilently } = useAuth0();

  const showToast = useToast();

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(initialUsername ?? '');

  const updateName = async () => {
    try {
      await api.self.changeName(name);

      await getAccessTokenSilently({ cacheMode: 'off' });

      setIsEditing(false);
    } catch (err) {
      showToast({
        key: 'changeName',
        tone: 'critical',
        message: 'We couldn’t save your name.',
        description: err instanceof Error ? err.message : String(err),
      });
    }
  };

  return (
    <Inline space="small">
      <TextField
        aria-label="Name"
        disabled={!isEditing}
        id="change-username-field"
        onChange={(e) => setName(e.currentTarget.value)}
        value={name}
      />
      {isEditing ? (
        <Button type="submit" variant="transparent" onClick={updateName}>
          <IconTick />
        </Button>
      ) : (
        <Button
          onClick={() => setIsEditing(true)}
          type="button"
          variant="transparent"
        >
          <IconEdit />
        </Button>
      )}
    </Inline>
  );
};
