
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "replay-events-by-id"
    }}>{`Replay events by ID`}</h4>
    <p>{`We’ve extended the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/replayWebhookSubscription"
      }}><inlineCode parentName="a">{`replayWebhookSubscription`}</inlineCode></a>{` mutation with a new `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ReplayWebhookSubscriptionInput/field/eventIds"
      }}><inlineCode parentName="a">{`eventIds`}</inlineCode></a>{` argument that can be used to replay events by their IDs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation (\\n  $webhookSubscription: ReplayWebhookSubscription_SubscriptionInput!\\n  $eventIds: [String!]\\n) {\\n  replayWebhookSubscription(\\n    input: { webhookSubscription: $webhookSubscription, eventIds: $eventIds }\\n  ) {\\n    webhookSubscription {\\n      id {\\n        value\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":5,\"column\":1,\"offset\":166},\"end\":{\"line\":20,\"column\":4,\"offset\":471},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"webhookSubscription\\\": {\\n    \\\"id\\\": \\\"seekAnzPublicTest:webhookSubscription:events:BoJiJ9ZWFVgejLXLJxUnvL\\\"\\n  },\\n  \\\"eventIds\\\": [\\n    \\\"seekAnzPublicTest:event:events:ntLKio4NjhScF7TemEsWq\\\",\\n    \\\"seekAnzPublicTest:event:events:CexxKWfccy26wxbe4hDrjD\\\"\\n  ]\\n}\",\"position\":{\"start\":{\"line\":22,\"column\":1,\"offset\":473},\"end\":{\"line\":32,\"column\":4,\"offset\":740},\"indent\":[1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation": true,
        "(\\n": true,
        "": true,
        "$webhookSubscription:": true,
        "ReplayWebhookSubscription_SubscriptionInput!\\n": true,
        "$eventIds:": true,
        "[String!]\\n)": true,
        "{\\n": true,
        "replayWebhookSubscription(\\n": true,
        "input:": true,
        "{": true,
        "webhookSubscription:": true,
        "$webhookSubscription,": true,
        "eventIds:": true,
        "$eventIds": true,
        "}\\n": true,
        ")": true,
        "webhookSubscription": true,
        "id": true,
        "value\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":5,\"column\":1,\"offset\":166},\"end\":{\"line\":20,\"column\":4,\"offset\":471},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"webhookSubscription\\\":": true,
        "\\\"id\\\":": true,
        "\\\"seekAnzPublicTest:webhookSubscription:events:BoJiJ9ZWFVgejLXLJxUnvL\\\"\\n": true,
        "},\\n": true,
        "\\\"eventIds\\\":": true,
        "[\\n": true,
        "\\\"seekAnzPublicTest:event:events:ntLKio4NjhScF7TemEsWq\\\",\\n": true,
        "\\\"seekAnzPublicTest:event:events:CexxKWfccy26wxbe4hDrjD\\\"\\n": true,
        "]\\n}\",\"position\":{\"start\":{\"line\":22,\"column\":1,\"offset\":473},\"end\":{\"line\":32,\"column\":4,\"offset\":740},\"indent\":[1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`mutation (
  $webhookSubscription: ReplayWebhookSubscription_SubscriptionInput!
  $eventIds: [String!]
) {
  replayWebhookSubscription(
    input: { webhookSubscription: $webhookSubscription, eventIds: $eventIds }
  ) {
    webhookSubscription {
      id {
        value
      }
    }
  }
}
`}</code></pre>
    <p>{`Furthermore, this functionality has been packaged into the `}<a parentName="p" {...{
        "href": "https://manage.developer.seek.com/webhooks"
      }}>{`Developer Dashboard’s webhooks page`}</a>{` where users can now select individual events to replay under the `}<strong parentName="p">{`Events`}</strong>{` tab of each subscription.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;