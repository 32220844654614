import { Box, ContentBlock, Hidden, Stack } from 'braid-design-system';
import type { ComponentProps, ReactNode } from 'react';

import { PageGutter } from './PageGutter';

import * as styles from './PageWrapper.css';

interface Props {
  children: ComponentProps<typeof Stack>['children'];
  heading: ComponentProps<typeof Stack>['children'];
  explainer?: ReactNode;
  width?: 'full' | 'contained';
}

export const PageWrapper = ({ width = 'contained', ...props }: Props) => (
  <Box background="body" className={styles.fullHeight} paddingY="xlarge">
    {width === 'contained' ? (
      <ContentBlock width="large">
        <Content {...props} />
      </ContentBlock>
    ) : (
      <Content {...props} />
    )}
  </Box>
);

const Content = ({ heading, explainer, children }: Omit<Props, 'width'>) => (
  <Box paddingX={['none', 'none', 'gutter']}>
    <Stack space="xlarge">
      <PageGutter>
        <Stack space="large">
          {heading}
          {explainer !== undefined && (
            <Hidden below="desktop">{explainer}</Hidden>
          )}
        </Stack>
      </PageGutter>

      {children}
    </Stack>
  </Box>
);
