import { useMemo } from 'react';
import { v4 as uuidV4 } from 'uuid';

import build from 'src/build.json';
import { useSessionStorage } from 'src/hooks/storage';

/**
 * https://github.com/SEEK-Jobs/indie-api-guidelines/blob/master/topics/frontend-experiences.md#client-initiated-requests
 */
export const useTracingHeaders = () => {
  const [sessionId] = useSessionStorage('x-session-id', uuidV4());

  const agent = `seek-developer-dashboard/${
    build.number || build.branch || 'unknown'
  }`;

  return useMemo(
    () => ({
      'Seek-Source-Agent': agent,
      'X-Session-Id': sessionId,
    }),
    [sessionId, agent],
  );
};
