import type { SeekApiPayload } from '@seek/indie-api-types';
import {
  Actions,
  Button,
  ButtonLink,
  Dropdown,
  Hidden,
  Stack,
  TextField,
} from 'braid-design-system';
import { type FormEvent, useEffect } from 'react';

import { FieldRow } from 'src/components/FieldRow/FieldRow';
import { useEnvironmentConfig } from 'src/hooks/environment';
import type { CreateNewWebhookMutationVariables } from 'src/types/graphql';

import { DeliveryForm } from '../../../SubscriptionForm/DeliveryForm';
import { SigningForm } from '../../../SubscriptionForm/SigningForm';
import { HirerPicker, useHirerState } from '../HirerPicker/HirerPicker';

import { type FormData, FormProvider, useField } from './form';
import { useRequiredField } from './useRequiredField';

const eventTypeCodes: SeekApiPayload.EventType[] = [
  'CandidateApplicationCreated',
  'CandidateProfilePurchased',
  'HirerRelationshipChanged',
  'PositionProfileClosed',
  'PositionProfilePosted',
];

interface FormProps {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  loading: boolean;
}

const Form = ({ onSubmit, loading }: FormProps) => {
  const eventTypeCode = useRequiredField('eventTypeCode');
  const schemeId = useRequiredField('schemeId');
  const hirerId = useRequiredField('hirerId', false);

  const hirerState = useHirerState();

  useEffect(() => {
    hirerId.onChange(hirerState.hirer?.id.value);
  }, [hirerId, hirerState.hirer?.id.value]);

  const commonProps = {
    disabled: loading,
    label: undefined,
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack space="large">
        <FieldRow
          label={eventTypeCode.label}
          htmlFor={eventTypeCode.id}
          description="Subscriptions are scoped to a specific event type. Use this field to nominate which event type this subscription receives."
        >
          <Dropdown
            placeholder="Select event type..."
            {...eventTypeCode}
            {...commonProps}
          >
            {eventTypeCodes.map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </Dropdown>
        </FieldRow>

        <DeliveryForm<FormData>
          disabled={loading}
          mode="create"
          rowWidths="2/5"
          useField={useField}
        />

        {eventTypeCode.value !== 'HirerRelationshipChanged' && (
          <FieldRow
            label="Hirer ID"
            htmlFor={hirerId.id}
            description="You can use this field to scope the subscription to only send events that relate to a specific hirer. Leave it blank to receive events from all hirers."
          >
            <HirerPicker id={hirerId.id} {...hirerState} />
          </FieldRow>
        )}

        <SigningForm
          disabled={loading}
          mode="create"
          rowWidths="2/5"
          useField={useField}
        />

        {/* There's only one valid scheme ID at the moment, so no need to show the field */}
        <Hidden screen>
          <TextField {...schemeId} />
        </Hidden>

        <Actions>
          <Button loading={loading} tone="brandAccent" type="submit">
            {loading ? 'Creating' : 'Create'}
          </Button>
          <ButtonLink href="/manage/webhooks" variant="transparent">
            Cancel
          </ButtonLink>
        </Actions>
      </Stack>
    </form>
  );
};

interface NewWebhookFormProps {
  onSubmit: (details: CreateNewWebhookMutationVariables) => void;
  loading: boolean;
}

const initialValues: Omit<FormData, 'schemeId'> = {
  // schemeId is dynamic and pulled from useEnvironmentConfig hook
  eventTypeCode: '',
  signingAlgorithmCode: '',
  url: '',
  hirerId: undefined,
  maxEventsPerAttempt: undefined,
  secret: undefined,
};

const parseSubmittedData = (
  inputValues: FormData,
): CreateNewWebhookMutationVariables => ({
  ...inputValues,
  secret: inputValues.secret ? inputValues.secret : undefined,
  maxEventsPerAttempt: inputValues.maxEventsPerAttempt
    ? parseInt(inputValues.maxEventsPerAttempt, 10)
    : undefined,
});

export const NewWebhookForm = ({ onSubmit, loading }: NewWebhookFormProps) => {
  const { seekAnzSchemeId } = useEnvironmentConfig();

  return (
    <FormProvider
      initialValues={{ ...initialValues, schemeId: seekAnzSchemeId }}
    >
      {({ handleSubmit }) => (
        <Form
          loading={loading}
          onSubmit={handleSubmit((data) => {
            const parsedData = parseSubmittedData(data);
            onSubmit({
              ...parsedData,
              hirerId:
                parsedData.eventTypeCode === 'HirerRelationshipChanged'
                  ? null
                  : parsedData.hirerId,
            });
          })}
        />
      )}
    </FormProvider>
  );
};
