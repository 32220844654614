
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "home-location-in-optimised-apply"
    }}>{`Home location in Optimised Apply`}</h4>
    <p>{`A candidate can add a home location to their SEEK Profile.
We have added support to retrieve this attribute from a job application in `}<a parentName="p" {...{
        "href": "/use-cases/application-export"
      }}>{`Optimised Apply`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "5723": true,
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($id: String!) {\\n  candidateProfile(id: $id) {\\n    candidate {\\n      person {\\n        communication {\\n          address {\\n            # Select other address fields as necessary\\n            formattedAddress\\n            countryCode\\n          }\\n        }\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":6,\"column\":1,\"offset\":224},\"end\":{\"line\":22,\"column\":4,\"offset\":516},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"id\\\": \\\"seekAnzPublicTest:candidateProfile:apply:5G5NaFDmy7UigvGy6aT6VT\\\"\\n}\",\"position\":{\"start\":{\"line\":24,\"column\":1,\"offset\":518},\"end\":{\"line\":28,\"column\":4,\"offset\":607},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"candidateProfile\\\": {\\n    \\\"candidate\\\": {\\n      \\\"person\\\": {\\n        \\\"communication\\\": {\\n          \\\"address\\\": [\\n            {\\n              \\\"formattedAddress\\\": \\\"Coober Pedy SA 5723 AU\\\",\\n              \\\"countryCode\\\": \\\"AU\\\"\\n            }\\n          ]\\n        }\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":30,\"column\":1,\"offset\":609},\"end\":{\"line\":47,\"column\":4,\"offset\":897},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($id:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "candidateProfile(id:": true,
        "$id)": true,
        "candidate": true,
        "person": true,
        "communication": true,
        "address": true,
        "#": true,
        "Select": true,
        "other": true,
        "fields": true,
        "as": true,
        "necessary\\n": true,
        "formattedAddress\\n": true,
        "countryCode\\n": true,
        "}\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":6,\"column\":1,\"offset\":224},\"end\":{\"line\":22,\"column\":4,\"offset\":516},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"id\\\":": true,
        "\\\"seekAnzPublicTest:candidateProfile:apply:5G5NaFDmy7UigvGy6aT6VT\\\"\\n}\",\"position\":{\"start\":{\"line\":24,\"column\":1,\"offset\":518},\"end\":{\"line\":28,\"column\":4,\"offset\":607},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"candidateProfile\\\":": true,
        "\\\"candidate\\\":": true,
        "\\\"person\\\":": true,
        "\\\"communication\\\":": true,
        "\\\"address\\\":": true,
        "[\\n": true,
        "\\\"formattedAddress\\\":": true,
        "\\\"Coober": true,
        "Pedy": true,
        "SA": true,
        "AU\\\",\\n": true,
        "\\\"countryCode\\\":": true,
        "\\\"AU\\\"\\n": true,
        "]\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":30,\"column\":1,\"offset\":609},\"end\":{\"line\":47,\"column\":4,\"offset\":897},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query ($id: String!) {
  candidateProfile(id: $id) {
    candidate {
      person {
        communication {
          address {
            # Select other address fields as necessary
            formattedAddress
            countryCode
          }
        }
      }
    }
  }
}
`}</code></pre>
    <p>{`A home location may be as specific as a suburb (`}<inlineCode parentName="p">{`Cremorne VIC 3121 AU`}</inlineCode>{`),
or as broad as a country (`}<inlineCode parentName="p">{`Poland`}</inlineCode>{`).
If you wish to use this attribute,
your software will need to account for these differences in granularity.
You may retrieve other `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/Address"
      }}>{`address`}</a>{` fields as appropriate;
note that street-level detail is not currently available.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;