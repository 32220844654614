import { Alert, Stack, Text } from 'braid-design-system';

interface Props {
  subject: string;
}

export const NoPermissionAlert = ({ subject }: Props) => (
  <Alert tone="caution">
    <Stack space="medium">
      <Text>You don’t have permission to {subject}.</Text>
      <Text size="small">
        Please contact one of your administrators if you need access.
      </Text>
    </Stack>
  </Alert>
);
