import { Private } from '@seek/indie-api-types';
import type { Validator } from '@seek/validators-js';

import type { FormData } from './form';

export const validVariation: Validator<
  string,
  {
    eventTypeCode: FormData['eventTypeCode'];
  }
> = {
  name: 'validVariation',
  test: ({
    value,
    validatorProps,
  }: {
    value: string;
    validatorProps: { eventTypeCode: FormData['eventTypeCode'] };
  }) =>
    Private.TestCallInput.fields.input.guard({
      eventTypeCode: validatorProps.eventTypeCode,
      variation: value,
    }),
  getErrorMessage: ({ validatorProps }) =>
    Promise.resolve(
      `A variation is required for ${validatorProps.eventTypeCode} events.`,
    ),
  getErrorMessageSync: ({ validatorProps }) =>
    `A variation is required for ${validatorProps.eventTypeCode} events.`,
};
