
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "webhook-playground"
    }}>{`Webhook Playground`}</h4>
    <p>{`We’ve added a `}<a parentName="p" {...{
        "href": "https://manage.developer.seek.com/webhook-playground"
      }}>{`Webhook Playground`}</a>{` to the Developer Dashboard that allows you to send a variety of mock events to your webhook endpoint and review the response received by the SEEK API.`}</p>
    <p>{`The mock events sent using this tool contain references to `}<a parentName="p" {...{
        "href": "/graphql/playground/mock-objects"
      }}>{`mock objects`}</a>{` that can be queried with `}<a parentName="p" {...{
        "href": "/graphql/playground#authentication"
      }}>{`Playground credentials`}</a>{`.`}</p>
    <p>{`This will allow you to develop your software with a much faster feedback loop, since you can easily send a mock event at the click of a button, rather than having to perform any real actions on SEEK.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;