import {
  Accordion,
  AccordionItem,
  Heading,
  Stack,
  Strong,
  Text,
  TextLink,
} from 'braid-design-system';
import { CodeBlock, InlineCode } from 'scoobie';

export const MainContent = () => (
  <Stack space="large">
    <Text>
      This page offers an easy and quick way to test your webhook integration
      with the SEEK API. You can seamlessly send a variety of webhook events and
      view the response you provided to SEEK when events are posted to your
      software.
    </Text>

    <Text>
      The events contain references to
      <TextLink href="https://developer.seek.com/graphql/playground/mock-objects">
        {' '}
        mock objects{' '}
      </TextLink>
      that can be queried with{' '}
      <TextLink href="https://developer.seek.com/graphql/playground#authentication">
        playground credentials
      </TextLink>
      .
    </Text>

    <Text>
      To get started we recommend reading{' '}
      <TextLink href="https://developer.seek.com/events/webhooks">
        webhooks
      </TextLink>{' '}
      to get an understanding of how to process incoming webhook events.
    </Text>
    <Heading level="3">Troubleshooting</Heading>
    <Accordion dividers={false}>
      <AccordionItem label="Authentication" id="authenticating">
        <Stack space="medium">
          <Text>
            {
              'The data in playground events is queryable via the SEEK API when authenticated using '
            }
            <TextLink href="https://developer.seek.com/graphql/playground">
              Playground
            </TextLink>
            {' credentials.'}
          </Text>
          <Text>
            A partner token can be requested from the command line on macOS,
            Linux or Windows Subsystem for Linux:
          </Text>
          <CodeBlock language="bash" size="standard">
            {`
        curl --request POST\n --url https://auth.seek.com/oauth/token\n --header 'content-type: application/json'\n --data '{"client_id":"Contact SEEK","client_secret":"Contact SEEK","audience":"https://test.graphql.seek.com","grant_type":"client_credentials"}
    `}
          </CodeBlock>
          <Text>
            The provided token will expire in 24 hours at which point it will
            need to be re-requested.
          </Text>
          <Text>
            <TextLink href="https://developer.seek.com/graphql/playground#authentication">
              Read more about authentication...
            </TextLink>
          </Text>
        </Stack>
      </AccordionItem>

      <AccordionItem label="Using localhost" id="Receiving events locally">
        <Stack space="medium">
          <Text>
            To receive these events on your local machine, you can use a tool
            like <TextLink href="https://ngrok.com/">ngrok</TextLink> to create
            a tunnel to the port your software is running on.
          </Text>
          <CodeBlock
            label="Installing ngrok locally and creating a tunnel to port 8080"
            language="bash"
          >
            {
              'npm install ngrok -g\nngrok http 8080\n# https://757c1652.ngrok.io -> http://localhost:8080'
            }
          </CodeBlock>
          <Text>
            The above command tunnels{' '}
            <InlineCode>http://localhost:8080</InlineCode>
            to the endpoint <InlineCode>https://757c1652.ngrok.io</InlineCode>,
            which will be publicly accessible over the internet. You would then
            be able to input <InlineCode>https://757c1652.ngrok.io</InlineCode>{' '}
            into the <Strong>URL</Strong> field of the webhook request form so
            that you can receive requests on your software running on{' '}
            <InlineCode>http://localhost:8080</InlineCode>.
          </Text>
        </Stack>
      </AccordionItem>
    </Accordion>
  </Stack>
);
