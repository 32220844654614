import releaseNote0 from 'src/notis/release-notes/2020-07-01.profile-apply.md';
import releaseNote1 from 'src/notis/release-notes/2020-08-01.documentation-features.md';
import releaseNote2 from 'src/notis/release-notes/2020-08-02.job-posting-features.md';
import releaseNote3 from 'src/notis/release-notes/2020-09-01.mutation-conflicts.md';
import releaseNote4 from 'src/notis/release-notes/2020-11-01.profile-apply-extensions.md';
import releaseNote5 from 'src/notis/release-notes/2020-12-24.replay-webhook-subscriptions.md';
import releaseNote6 from 'src/notis/release-notes/2021-03-29.ad-selection-features.md';
import releaseNote7 from 'src/notis/release-notes/2021-04-12.ad-selection-message-visibility.md';
import releaseNote8 from 'src/notis/release-notes/2021-04-13.mutation-split.md';
import releaseNote9 from 'src/notis/release-notes/2021-04-20.position-profile-closed-event.md';
import releaseNote10 from 'src/notis/release-notes/2021-04-27.mutation-input-field-limits.md';
import releaseNote11 from 'src/notis/release-notes/2021-05-13.hirer-configuration-queries.md';
import releaseNote12 from 'src/notis/release-notes/2021-05-19.update-webhook-conflicts.md';
import releaseNote13 from 'src/notis/release-notes/2021-05-31.payment-due-excluding-tax.md';
import releaseNote14 from 'src/notis/release-notes/2021-06-07.self-query.md';
import releaseNote15 from 'src/notis/release-notes/2021-06-24.questionnaire-input-limits.md';
import releaseNote16 from 'src/notis/release-notes/2021-07-08.ad-selection-theme.md';
import releaseNote17 from 'src/notis/release-notes/2021-08-13.hirer-relationship-changed-events.md';
import releaseNote18 from 'src/notis/release-notes/2021-08-31.candidate-profile-position-profile.md';
import releaseNote19 from 'src/notis/release-notes/2021-09-06.position-profile-created-by-self.md';
import releaseNote20 from 'src/notis/release-notes/2021-10-20.ad-selection-panel-responsive.md';
import releaseNote21 from 'src/notis/release-notes/2021-10-31.content-discoverability.md';
import releaseNote22 from 'src/notis/release-notes/2021-10-31.self-service-credential-management.md';
import releaseNote23 from 'src/notis/release-notes/2021-11-04.downloading-hirer-list-csvs.md';
import releaseNote24 from 'src/notis/release-notes/2021-11-24.advertisement-details-limit-lowered.md';
import releaseNote25 from 'src/notis/release-notes/2021-12-14.replay-events-prior-subscription.md';
import releaseNote26 from 'src/notis/release-notes/2021-12-22.new-graphql-explorer.md';
import releaseNote27 from 'src/notis/release-notes/2022-02-11.ad-selection-panel-pricing-tooltip-update.md';
import releaseNote28 from 'src/notis/release-notes/2022-02-18.ad-selection-graphql-message-update.md';
import releaseNote29 from 'src/notis/release-notes/2022-02-22.question-html-limit.md';
import releaseNote30 from 'src/notis/release-notes/2022-03-31.previewing-a-job-ad-feature.md';
import releaseNote31 from 'src/notis/release-notes/2022-05-04.webhook-payload-hirer-id.md';
import releaseNote32 from 'src/notis/release-notes/2022-09-15.ad-selection-panel-feature-removal.md';
import releaseNote33 from 'src/notis/release-notes/2022-10-06.unified-locations.md';
import releaseNote34 from 'src/notis/release-notes/2022-10-18.email-parsing-retirement.md';
import releaseNote35 from 'src/notis/release-notes/2022-11-17.webhook-playground.md';
import releaseNote36 from 'src/notis/release-notes/2022-12-02.relaxed-string-length-limits.md';
import releaseNote37 from 'src/notis/release-notes/2023-01-04.new-webhook-replays.md';
import releaseNote38 from 'src/notis/release-notes/2023-05-05.cache-partner-token.md';
import releaseNote39 from 'src/notis/release-notes/2023-10-06.job-ad-expiry-date-validation.md';
import releaseNote40 from 'src/notis/release-notes/2023-11-23.commission-only.md';
import releaseNote41 from 'src/notis/release-notes/2024-02-23.graphql-spec-compliance.md';
import releaseNote42 from 'src/notis/release-notes/2024-06-19.inferlocation-countrycode.md';
import releaseNote43 from 'src/notis/release-notes/2024-06-28.home-location.md';

export const releaseNotes: Record<string, MDX.Document[]> = {
  '2020-07': [releaseNote0],
  '2020-08': [releaseNote2, releaseNote1],
  '2020-09': [releaseNote3],
  '2020-11': [releaseNote4],
  '2020-12': [releaseNote5],
  '2021-03': [releaseNote6],
  '2021-04': [releaseNote10, releaseNote9, releaseNote8, releaseNote7],
  '2021-05': [releaseNote13, releaseNote12, releaseNote11],
  '2021-06': [releaseNote15, releaseNote14],
  '2021-07': [releaseNote16],
  '2021-08': [releaseNote18, releaseNote17],
  '2021-09': [releaseNote19],
  '2021-10': [releaseNote22, releaseNote21, releaseNote20],
  '2021-11': [releaseNote24, releaseNote23],
  '2021-12': [releaseNote26, releaseNote25],
  '2022-02': [releaseNote29, releaseNote28, releaseNote27],
  '2022-03': [releaseNote30],
  '2022-05': [releaseNote31],
  '2022-09': [releaseNote32],
  '2022-10': [releaseNote34, releaseNote33],
  '2022-11': [releaseNote35],
  '2022-12': [releaseNote36],
  '2023-01': [releaseNote37],
  '2023-05': [releaseNote38],
  '2023-10': [releaseNote39],
  '2023-11': [releaseNote40],
  '2024-02': [releaseNote41],
  '2024-06': [releaseNote43, releaseNote42],
};
