import React, { type ReactNode } from 'react';

import { ClientErrorPage } from 'src/pages/ClientErrorPage';

interface Props {
  errorPage: ReactNode;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

/**
 * Catches errors thrown by a page and renders an error page in its place
 *
 * This protects `Sidebar` from the error so the user can navigate to another
 * page.
 */
export class PageErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ClientErrorPage />;
    }

    return this.props.children;
  }
}
