import type { PartnerNoti } from '@seek/indie-api-types';
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useApi } from 'src/api';

type NotiConfig = PartnerNoti.PartnerNotiConfig;
type NotiConfigInput = PartnerNoti.PartnerNotiConfigInput;

const blankConfig: NotiConfig = {
  disabledSourceCodes: [],
  emailAddress: [],
  feedUrlSecret: [],
  integrationPartnerUuid: '',
  phoneNumber: [],
  slack: [],
};
interface ActionOptions {
  showLoading: boolean;
}

interface ConfigContext {
  notiConfig: NotiConfig;
  loading: boolean;
  refresh: (opts?: ActionOptions) => Promise<void>;
  update: (
    newDetails: NotiConfigInput,
    opts?: ActionOptions,
  ) => Promise<Error | null>;
  rotateFeedSecrets: () => Promise<void>;
  error: {
    failed: boolean;
    message: string;
  };
}

const ConfigContext = createContext<ConfigContext>({
  notiConfig: blankConfig,
  loading: false,
  refresh: async () => {},
  update: async () => null,
  rotateFeedSecrets: async () => {},
  error: { failed: false, message: '' },
});

export const useNotiConfig = () => {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error('useNotiConfig must be used within a NotiConfigProvider');
  }

  return context;
};

interface NotiConfigProviderProps {
  children: ReactNode;
}

export const NotiConfigProvider = ({ children }: NotiConfigProviderProps) => {
  const api = useApi();
  const [notiConfig, setNotiConfig] = useState<NotiConfig>(blankConfig);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const refresh = async (
    { showLoading }: ActionOptions = { showLoading: false },
  ) => {
    if (showLoading) {
      setLoading(true);
    }

    try {
      const newConfig = await api.noti.get();
      setNotiConfig(newConfig ?? blankConfig);
      setErrorMessage('');
    } catch (err) {
      setErrorMessage(err instanceof Error ? err.message : String(err));
    }

    if (showLoading) {
      setLoading(false);
    }
  };

  const update = async (
    newDetails: NotiConfigInput,
    { showLoading }: ActionOptions = { showLoading: false },
  ): Promise<Error | null> => {
    if (showLoading) {
      setLoading(true);
    }

    try {
      const newConfig = await api.noti.update(newDetails);
      setNotiConfig(newConfig);

      return null;
    } catch (err) {
      if (err instanceof Error) {
        return err;
      }

      throw err;
    } finally {
      if (showLoading) {
        setLoading(false);
      }
    }
  };

  const rotateFeedSecrets = async () => {
    await api.noti.update({ ...notiConfig, enableSyndicationFeeds: false });
    const newConfig = await api.noti.update({
      ...notiConfig,
      enableSyndicationFeeds: true,
    });
    setNotiConfig(newConfig);
  };

  const value = useMemo(
    () => ({
      notiConfig,
      loading,
      refresh,
      update,
      rotateFeedSecrets,
      error: {
        failed: errorMessage.length > 0,
        message: errorMessage,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notiConfig, loading],
  );

  // Load on initial render
  useEffect(() => {
    refresh({ showLoading: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};
