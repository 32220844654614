import { gql } from '@apollo/client';

export const REPLAY_WEBHOOK = gql`
  mutation replayWebhook(
    $subscriptionId: String!
    $createdAfterDateTime: DateTime!
    $createdBeforeDateTime: DateTime!
    $replayDeliveredEventsIndicator: Boolean!
    $hirerId: String
  ) {
    replayWebhookSubscription(
      input: {
        webhookSubscription: { id: $subscriptionId }
        filter: {
          createdAfterDateTime: $createdAfterDateTime
          createdBeforeDateTime: $createdBeforeDateTime
          replayDeliveredEventsIndicator: $replayDeliveredEventsIndicator
          hirerId: $hirerId
        }
      }
    ) {
      webhookSubscription {
        id {
          value
        }
      }
    }
  }
`;
