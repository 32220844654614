
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "webhook-subscription-mutation-split"
    }}>{`Webhook subscription mutation split`}</h4>
    <p>{`It’s currently possible to update some config options of a webhook subscription, using the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/updateWebhookSubscriptionDeliveryConfiguration"
      }}><inlineCode parentName="a">{`updateWebhookSubscriptionDeliveryConfiguration`}</inlineCode></a>{` mutation.
Previously, this mutation required the `}<inlineCode parentName="p">{`signingAlgorithmCode`}</inlineCode>{` as a mandatory string, which in turn required that `}<inlineCode parentName="p">{`secret`}</inlineCode>{` was also provided, which meant that the secret string for webhook signing had to be included in every request to update a webhook subscription.
This made simple updates like changing the `}<inlineCode parentName="p">{`maxAttemptsPerEvent`}</inlineCode>{` unnecessarily difficult.`}</p>
    <p>{`To allow easier update actions, this mutation has been split in two.
To update `}<inlineCode parentName="p">{`maxEventsPerAttempt`}</inlineCode>{` or `}<inlineCode parentName="p">{`url`}</inlineCode>{`, continue to use the `}<inlineCode parentName="p">{`updateWebhookSubscriptionDeliveryConfiguration`}</inlineCode>{` mutation as before.
To make changes to the signing config options, use the new `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/updateWebhookSubscriptionSigningConfiguration"
      }}><inlineCode parentName="a">{`updateWebhookSubscriptionSigningConfiguration`}</inlineCode></a>{` mutation, which takes the `}<inlineCode parentName="p">{`signingAlgorithmCode`}</inlineCode>{` and `}<inlineCode parentName="p">{`secret`}</inlineCode>{` options.
Both mutations still return the full `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/WebhookSubscription"
      }}><inlineCode parentName="a">{`WebhookSubscription`}</inlineCode></a>{`.`}</p>
    <h5 {...{
      "id": "new-mutation-examples"
    }}>{`New mutation examples`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":\"label=\\\"Update delivery config\\\"\",\"value\":\"mutation updateWebhookDeliveryConfig(\\n  $webhookSubscriptionId: String!\\n  $url: String!\\n  $maxEventsPerAttempt: Int\\n) {\\n  updateWebhookSubscriptionDeliveryConfiguration(\\n    input: {\\n      webhookSubscription: {\\n        id: $webhookSubscriptionId\\n        url: $url\\n        maxEventsPerAttempt: $maxEventsPerAttempt\\n      }\\n    }\\n  ) {\\n    ... on UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success {\\n      webhookSubscription {\\n        id {\\n          value\\n        }\\n        url\\n        maxEventsPerAttempt\\n      }\\n    }\\n    ... on UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict {\\n      conflictingWebhookSubscription {\\n        id {\\n          value\\n        }\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":14,\"column\":1,\"offset\":1023},\"end\":{\"line\":47,\"column\":4,\"offset\":1773},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":\"label=\\\"Update signing config\\\"\",\"value\":\"mutation updateWebhookSigningConfig(\\n  $webhookSubscriptionId: String!\\n  $signingAlgorithmCode: String!\\n  $secret: String\\n) {\\n  updateWebhookSubscriptionSigningConfiguration(\\n    input: {\\n      webhookSubscription: {\\n        id: $webhookSubscriptionId\\n        signingAlgorithmCode: $signingAlgorithmCode\\n        secret: $secret\\n      }\\n    }\\n  ) {\\n    webhookSubscription {\\n      id {\\n        value\\n      }\\n      signingAlgorithmCode\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":49,\"column\":1,\"offset\":1775},\"end\":{\"line\":72,\"column\":4,\"offset\":2265},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":\"label": "\\\"Update",
        "delivery": true,
        "config\\\"\",\"value\":\"mutation": true,
        "updateWebhookDeliveryConfig(\\n": true,
        "": true,
        "$webhookSubscriptionId:": true,
        "String!\\n": true,
        "$url:": true,
        "$maxEventsPerAttempt:": true,
        "Int\\n)": true,
        "{\\n": true,
        "updateWebhookSubscriptionDeliveryConfiguration(\\n": true,
        "input:": true,
        "webhookSubscription:": true,
        "id:": true,
        "$webhookSubscriptionId\\n": true,
        "url:": true,
        "$url\\n": true,
        "maxEventsPerAttempt:": true,
        "$maxEventsPerAttempt\\n": true,
        "}\\n": true,
        ")": true,
        "...": true,
        "on": true,
        "UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success": true,
        "webhookSubscription": true,
        "id": true,
        "value\\n": true,
        "url\\n": true,
        "maxEventsPerAttempt\\n": true,
        "UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict": true,
        "conflictingWebhookSubscription": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":14,\"column\":1,\"offset\":1023},\"end\":{\"line\":47,\"column\":4,\"offset\":1773},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":\"label": "\\\"Update",
        "signing": true,
        "updateWebhookSigningConfig(\\n": true,
        "$signingAlgorithmCode:": true,
        "$secret:": true,
        "String\\n)": true,
        "updateWebhookSubscriptionSigningConfiguration(\\n": true,
        "signingAlgorithmCode:": true,
        "$signingAlgorithmCode\\n": true,
        "secret:": true,
        "$secret\\n": true,
        "signingAlgorithmCode\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":49,\"column\":1,\"offset\":1775},\"end\":{\"line\":72,\"column\":4,\"offset\":2265},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`mutation updateWebhookDeliveryConfig(
  $webhookSubscriptionId: String!
  $url: String!
  $maxEventsPerAttempt: Int
) {
  updateWebhookSubscriptionDeliveryConfiguration(
    input: {
      webhookSubscription: {
        id: $webhookSubscriptionId
        url: $url
        maxEventsPerAttempt: $maxEventsPerAttempt
      }
    }
  ) {
    ... on UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success {
      webhookSubscription {
        id {
          value
        }
        url
        maxEventsPerAttempt
      }
    }
    ... on UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict {
      conflictingWebhookSubscription {
        id {
          value
        }
      }
    }
  }
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;