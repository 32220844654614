import type { EventPayload, PartnerNoti } from '@seek/indie-api-types';
import {
  Box,
  Column,
  Columns,
  Heading,
  IconCaution,
  IconChevron,
  IconInfo,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';
import { useState } from 'react';

import { GutterBox } from 'src/components/GutterBox/GutterBox';

import { NotiBodyMarkdown } from '../NotiBodyMarkdown/NotiBodyMarkdown';

import * as styles from './styles.css';

type PartnerNoti = EventPayload.PartnerNotiSubmission;

const PartnerNotiClassIcon = ({
  partnerNotiClass,
}: {
  partnerNotiClass: PartnerNoti['class'];
}) => {
  switch (partnerNotiClass) {
    case 'alert':
      return <IconCaution tone="caution" />;

    case 'informational':
      return <IconInfo tone="info" />;
  }
};

interface HistoricNotiRowProps {
  noti: PartnerNoti.HistoricNoti;
}

export const HistoricNotiRow = ({ noti }: HistoricNotiRowProps) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <GutterBox>
      <Stack space="medium">
        <Box
          className={styles.hover}
          onClick={() => setExpanded((prev) => !prev)}
        >
          <Columns space="medium" alignY="center" collapseBelow="tablet">
            <Column>
              <Heading
                icon={
                  <PartnerNotiClassIcon partnerNotiClass={noti.noti.class} />
                }
                level="4"
              >
                {noti.noti.title}
              </Heading>
            </Column>

            <Column width="content">
              <Inline space="small" alignY="center">
                <Text tone="secondary">
                  {new Date(noti.firstSeen).toLocaleString()}
                </Text>
                <IconChevron direction={expanded ? 'up' : 'down'} />
              </Inline>
            </Column>
          </Columns>
        </Box>
        {expanded && (
          <Text>
            <NotiBodyMarkdown>{noti.noti.body}</NotiBodyMarkdown>
          </Text>
        )}
      </Stack>
    </GutterBox>
  );
};
