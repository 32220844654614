import { CheckboxStandalone, Column, Columns, Text } from 'braid-design-system';
import { InlineCode } from 'scoobie';

import { AutoLinkedInlineCode } from 'src/components/AutoLinkedInlineCode/AutoLinkedInlineCode';
import { NoWrapText } from 'src/components/NoWrapText/NoWrapText';
import { TwoColumnGrid } from 'src/components/TwoColumnGrid/TwoColumnGrid';
import { usePermissions } from 'src/hooks/auth';
import type { SubscriptionEventsQuery } from 'src/types/graphql';

import { DateTimeColumn } from '../DateTimeColumn/DateTimeColumn';

type QueriedEvent = NonNullable<
  SubscriptionEventsQuery['events']
>['edges'][0]['node'];

interface Props {
  event: QueriedEvent;
  selectable: boolean;
  checked: boolean;
  setCheckedCallback: (eventOid: string) => void;
  context?: string;
}

export const Event = ({
  event,
  selectable,
  checked,
  setCheckedCallback,
  context,
}: Props) => {
  const { permissions } = usePermissions();

  return (
    <Columns space="gutter">
      {permissions.includes('mutate:webhooks') ? (
        <Column width="content">
          <CheckboxStandalone
            checked={checked}
            aria-label={`Select ${event.id.value} ${context}`}
            id={`checkbox-${event.id.value}`}
            onChange={() => {
              if (selectable) {
                setCheckedCallback(event.id.value);
              }
            }}
          />
        </Column>
      ) : null}
      <DateTimeColumn>{new Date(event.createDateTime)}</DateTimeColumn>
      <Column>
        <TwoColumnGrid space="small">
          <Text align="right" size="small" weight="strong">
            ID
          </Text>
          <NoWrapText size="small">
            <InlineCode>{event.id.value}</InlineCode>
          </NoWrapText>

          <Text align="right" size="small" weight="strong">
            Subject
          </Text>
          <NoWrapText size="small">
            <AutoLinkedInlineCode>{event.oid}</AutoLinkedInlineCode>
          </NoWrapText>
        </TwoColumnGrid>
      </Column>
    </Columns>
  );
};
