import { Actions, ButtonLink, IconNewWindow } from 'braid-design-system';

interface Props {
  href: string;
  label: string;
}

export const JumpTo = ({ href, label }: Props) => (
  <Actions>
    <ButtonLink
      href={href}
      icon={<IconNewWindow />}
      target="_blank"
      variant="soft"
    >
      {label} documentation
    </ButtonLink>
  </Actions>
);
