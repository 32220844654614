
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "positionprofileclosed-event"
    }}><inlineCode parentName="h4">{`PositionProfileClosed`}</inlineCode>{` event`}</h4>
    <p>{`We’ve added a new `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/closing-a-job-ad#positionprofileclosed"
      }}><inlineCode parentName="a">{`PositionProfileClosed`}</inlineCode>{` event`}</a>{` that’s emitted whenever a job ad has been closed.`}</p>
    <p>{`It’s recommended that you update your software’s internal state based on `}<inlineCode parentName="p">{`PositionProfileClosed`}</inlineCode>{` events instead of scheduled end dates.
This ensures your software remains synchronised with SEEK, particularly when a hirer contacts our Customer Service team to close a job ad early.`}</p>
    <p>{`This is an example of a `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhook`}</a>{` body containing a `}<inlineCode parentName="p">{`PositionProfileClosed`}</inlineCode>{` event:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsonc",
        "metastring": "sample-webhook-body",
        "sample-webhook-body": true
      }}>{`{
  "events": [
    {
      "id": "seekAnzPublicTest:event:events:5dEQGJWnR6mSyUwnL14VGA",
      "type": "PositionProfileClosed",
      // The date the job ad was closed
      "createDateTime": "2019-09-20T21:02:27.101Z",

      // This can be passed to the \`positionProfile\` query to retrieve the job ad's final state
      "positionProfileId": "seekAnzPublicTest:positionProfile:jobAd:26d9DVAzs"
    }
  ],
  "subscriptionId": "seekAnzPublicTest:webhookSubscription:events:9H6GD9v2o8hQw7aBBG2sAU",
  "url": "https://example.com/webhook"
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;