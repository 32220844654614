
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "replay-webhook-subscriptions"
    }}>{`Replay webhook subscriptions`}</h4>
    <p>{`We’ve extended `}<a parentName="p" {...{
        "href": "/events/webhooks#replaying-a-subscriptions-events"
      }}>{`webhook subscriptions`}</a>{` with a new mutation `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/replayWebhookSubscription"
      }}><inlineCode parentName="a">{`replayWebhookSubscription`}</inlineCode></a>{`,
which resends undelivered events from the past 90 days to your subscription endpoint.`}</p>
    <p>{`This makes it easy for you to recover from downtime without building a bespoke replay mechanism on top of the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/events"
      }}><inlineCode parentName="a">{`events`}</inlineCode></a>{` query.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation ($input: ReplayWebhookSubscriptionInput!) {\\n  replayWebhookSubscription(input: $input) {\\n    webhookSubscription {\\n      id {\\n        value\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":8,\"column\":1,\"offset\":391},\"end\":{\"line\":18,\"column\":4,\"offset\":574},\"indent\":[1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"input\\\": {\\n    \\\"webhookSubscription\\\": {\\n      \\\"id\\\": \\\"seekAnzPublicTest:webhookSubscription:events:BoJiJ9ZWFVgejLXLJxUnvL\\\"\\n    },\\n    \\\"filter\\\": {\\n      \\\"createdAfterDateTime\\\": \\\"2021-01-02T00:00:00.000Z\\\",\\n      \\\"createdBeforeDateTime\\\": \\\"2021-01-05T00:00:00.000Z\\\",\\n      \\\"replayDeliveredEventsIndicator\\\": false\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":20,\"column\":1,\"offset\":576},\"end\":{\"line\":33,\"column\":4,\"offset\":911},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation": true,
        "($input:": true,
        "ReplayWebhookSubscriptionInput!)": true,
        "{\\n": true,
        "": true,
        "replayWebhookSubscription(input:": true,
        "$input)": true,
        "webhookSubscription": true,
        "id": true,
        "value\\n": true,
        "}\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":8,\"column\":1,\"offset\":391},\"end\":{\"line\":18,\"column\":4,\"offset\":574},\"indent\":[1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"input\\\":": true,
        "\\\"webhookSubscription\\\":": true,
        "\\\"id\\\":": true,
        "\\\"seekAnzPublicTest:webhookSubscription:events:BoJiJ9ZWFVgejLXLJxUnvL\\\"\\n": true,
        "},\\n": true,
        "\\\"filter\\\":": true,
        "\\\"createdAfterDateTime\\\":": true,
        "\\\"2021-01-02T00:00:00.000Z\\\",\\n": true,
        "\\\"createdBeforeDateTime\\\":": true,
        "\\\"2021-01-05T00:00:00.000Z\\\",\\n": true,
        "\\\"replayDeliveredEventsIndicator\\\":": true,
        "false\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":20,\"column\":1,\"offset\":576},\"end\":{\"line\":33,\"column\":4,\"offset\":911},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`mutation ($input: ReplayWebhookSubscriptionInput!) {
  replayWebhookSubscription(input: $input) {
    webhookSubscription {
      id {
        value
      }
    }
  }
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;