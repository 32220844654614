import type { Private } from '@seek/indie-api-types';
import { Alert, Box, Loader, Stack, Text } from 'braid-design-system';
import { useEffect, useState } from 'react';

import { useApi } from 'src/api';

import { ResponseGraph } from './components/ResponseGraph/ResponseGraph';
import { StatisticsColumns } from './components/StatisticsColumns/StatisticsColumns';

interface SubscriptionHealthMetricsProps {
  timePeriod: Private.WebhookSubscriptionRequestMetricTimeRange;
  timePeriodLabel: string;
  subscriptionId: string;
}

export const SubscriptionHealthMetrics = ({
  timePeriod,
  timePeriodLabel,
  subscriptionId,
}: SubscriptionHealthMetricsProps) => {
  const api = useApi();
  const [metrics, setMetrics] =
    useState<Private.WebhookSubscriptionRequestMetric>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.subscriptions.webhookHealth({
          subscriptionId,
          timeRange: timePeriod,
        });
        setMetrics(response);
      } catch (err) {
        setShowErrorAlert(true);
      }
      setLoading(false);
    })();
  }, [api.subscriptions, timePeriod, subscriptionId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <Loader />
      </Box>
    );
  }

  if (showErrorAlert) {
    return (
      <Alert tone="critical">
        <Text>
          We were unable to fetch metrics for the last {timePeriodLabel}. Please
          try again later.
        </Text>
      </Alert>
    );
  }

  if (!metrics) {
    return (
      <Alert tone="info">
        <Text>No metrics found for the last {timePeriodLabel}.</Text>
      </Alert>
    );
  }

  const { statistics } = metrics.data;

  return (
    <Stack space="gutter">
      <StatisticsColumns
        totalRequests={statistics.totalRequests}
        successPercentage={statistics.percentSuccessful}
        p95Latency={statistics.p95Latency}
      />
      <ResponseGraph
        datasets={metrics.data.values}
        timeRange={metrics.timeRange}
      />
    </Stack>
  );
};
