import type { Page } from '../UserGuide';

import { MainContent as CredentialsMainContent } from './credentials';
import { MainContent as DefaultMainContent } from './default';
import { MainContent as UsersMainContent } from './users';
import { MainContent as WebhookPlaygroundMainContent } from './webhookPlayground';
import { MainContent as WebhooksMainContent } from './webhooks';

interface Content {
  MainContent: () => JSX.Element;
}

export const getContent = (page?: Page): Content => {
  switch (page) {
    case 'credentials':
      return { MainContent: CredentialsMainContent };
    case 'users':
      return { MainContent: UsersMainContent };
    case 'webhooks':
      return { MainContent: WebhooksMainContent };
    case 'webhook-playground':
      return { MainContent: WebhookPlaygroundMainContent };
    default:
      // This is purely for wide views where the User Guide is rendered off-screen
      return { MainContent: DefaultMainContent };
  }
};
