import { Hidden, Stack } from 'braid-design-system';

import { PageGutter } from 'src/components/PageGutter';
import { useStatuspage } from 'src/hooks/statuspage';

import { ComponentTiles } from './components/ComponentTiles';
import { ErrorBoundary } from './components/ErrorBoundary';
import { TopLevelStatus } from './components/TopLevelStatus';
import { UpcomingMaintenance } from './components/UpcomingMaintenance/UpcomingMaintenance';

/**
 * Renders a view of the SEEK API's status using the Atlassian Statuspage API
 */
export const SystemStatus = () => {
  const summary = useStatuspage();

  if (!summary) {
    // Not loaded yet
    return null;
  }

  const upcomingMaintenances = summary.scheduled_maintenances.filter(
    // This sneakily includes `completed` so we can test locally using
    // `includeScheduledMaintenances="all"`. Completed maintenances aren't
    // returned by the Statuspage summary API normally.
    ({ status }) => status === 'scheduled' || status === 'completed',
  );

  return (
    <ErrorBoundary>
      <Stack space="large" dividers>
        {upcomingMaintenances.map((maintenance) => (
          <UpcomingMaintenance
            key={maintenance.id}
            page={summary.page}
            maintenance={maintenance}
          />
        ))}

        <PageGutter>
          <Stack space="small">
            <TopLevelStatus
              status={summary.status}
              statusPageUrl={summary.page.url}
              pageName={summary.page.name}
            />

            <Hidden below="desktop">
              <ComponentTiles
                components={summary.components}
                statusPageUrl={summary.page.url}
              />
            </Hidden>
          </Stack>
        </PageGutter>
      </Stack>
    </ErrorBoundary>
  );
};
