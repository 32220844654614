
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "ad-selection"
    }}>{`Ad selection`}</h1>
    <p>{`Going forward, the SEEK ad products available for selection will vary between hirers, markets, and over time.
For example, an Australian hirer may choose between `}<inlineCode parentName="p">{`Classic`}</inlineCode>{`, `}<inlineCode parentName="p">{`StandOut`}</inlineCode>{` and `}<inlineCode parentName="p">{`Premium`}</inlineCode>{` or just `}<inlineCode parentName="p">{`StandOut`}</inlineCode>{` and `}<inlineCode parentName="p">{`Premium`}</inlineCode>{`,
while a Singaporean hirer may have access to `}<inlineCode parentName="p">{`Basic`}</inlineCode>{`, `}<inlineCode parentName="p">{`Branded`}</inlineCode>{` and `}<inlineCode parentName="p">{`Premium`}</inlineCode>{`.`}</p>
    <p>{`We have built two options to accommodate this new variable model for ad selection.
Your software must adopt one of these dynamic options and must not hardcode any logic based on specific names or identifiers as SEEK ad products may continue to evolve.`}</p>

    <Alert tone="promote" mdxType="Alert">
  <Text mdxType="Text">
    SEEK strongly recommends using the Ad Selection Panel for your integration.
    It will significantly reduce development time and ongoing maintenance, as
    well as provide an up-to-date user experience for SEEK hirers.
  </Text>
    </Alert>
    <h2 {...{
      "id": "option-1-ad-selection-panel"
    }}>{`Option 1: Ad Selection Panel`}</h2>
    <p>{`SEEK has created an updated Ad Selection Panel that you can embed into your posting flow.`}</p>
    <p>{`Refer to the `}<a parentName="p" {...{
        "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/panel"
      }}>{`panel uplift section`}</a>{` to view more details.`}</p>
    <h2 {...{
      "id": "option-2-graphql"
    }}>{`Option 2: GraphQL`}</h2>
    <p>{`If you’re unable to use the Ad Selection Panel,
your custom user interface must be rebuilt on our new `}<inlineCode parentName="p">{`advertisementProducts`}</inlineCode>{` query.`}</p>
    <p>{`Refer to the `}<a parentName="p" {...{
        "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/graphql"
      }}>{`GraphQL uplift section`}</a>{` to view more details.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;