import parseHighlights from 'autosuggest-highlight/parse';
import { Box, Text } from 'braid-design-system';
import { Fragment } from 'react';

import type { SearchResultItem } from './mapSearchResult';

import * as styles from './SearchResult.css';

export const SearchResult = ({ result }: { result: SearchResultItem }) => (
  <Box
    component="span"
    display="flex"
    justifyContent="spaceBetween"
    paddingX="small"
    paddingY="xsmall"
    flexDirection="column"
  >
    <Text baseline={false} size="small" weight="medium">
      {parseHighlights(result.title, result.titleHighlights ?? []).map(
        ({ highlight, text }, partIndex) =>
          highlight ? (
            <Box component="span" key={partIndex} className={styles.highlight}>
              {text}
            </Box>
          ) : (
            <Fragment key={partIndex}>{text}</Fragment>
          ),
      )}
    </Text>
    {result.description ? (
      <Text size="small" tone="secondary" baseline={false}>
        {parseHighlights(
          result.description ?? '',
          result.descriptionHighlights ?? [],
        ).map(({ highlight, text }, partIndex) =>
          highlight ? (
            <Box component="span" key={partIndex} className={styles.highlight}>
              {text}
            </Box>
          ) : (
            <Fragment key={partIndex}>{text}</Fragment>
          ),
        )}
      </Text>
    ) : null}
  </Box>
);
