import { IconSearch } from 'braid-design-system';
import { MockLocationSuggest } from 'wingman-fe';

import { BorderCard } from 'src/components/BorderCard';

export const SeekLocationSuggest = () => (
  <BorderCard>
    <MockLocationSuggest
      icon={<IconSearch />}
      id="seek-location-suggest"
      label="Location"
      schemeId="seekAnz"
      showStorybookAction
    />
  </BorderCard>
);
