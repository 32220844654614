
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "advertisementdetails-limit-lowered"
    }}><inlineCode parentName="h4">{`AdvertisementDetails`}</inlineCode>{` limit lowered`}</h4>
    <p>{`The maximum size of the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PositionFormattedDescriptionInput"
      }}><inlineCode parentName="a">{`AdvertisementDetails`}</inlineCode>{` position description`}</a>{` has been lowered from 20,000 bytes to 15,000 bytes in UTF-8 encoding.
This aligns the SEEK API with the corresponding limit on the `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au"
      }}>{`SEEK Employer website`}</a>{`.`}</p>
    <p>{`Our analysis of job posting behaviour over the past 6 months indicates that fewer than 0.005% of job ads would be affected.
If your software includes validations for the previous limit you’re encouraged to update to the new limit.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;