import { Book } from '@phosphor-icons/react';
import {
  Actions,
  ButtonLink,
  IconArrow,
  IconRenderer,
  Tiles,
} from 'braid-design-system';

import { TileLink } from 'src/components/TileLink';

const GuideIcon = () => (
  <IconRenderer>
    {({ className }) => <Book className={className} />}
  </IconRenderer>
);

export const CurrentGuides = () => (
  <Tiles columns={[1, 1, 2]} space="small">
    <TileLink
      description="Latest revision of baseline requirements for the Job Posting use case and our Asia-Pacific expansion"
      href="/migration-guides/jobstreet-and-jobsdb-uplift"
      icon={<GuideIcon />}
      title="2023 Job Posting revision"
    />

    <TileLink
      description="Long-lived reference for converting from SEEK advertiser IDs and working with hirer IDs in the SEEK API"
      href="/migration-guides/seek-advertiser-ids"
      icon={<GuideIcon />}
      title="Hirer identifiers"
    />
  </Tiles>
);

export const ArchivedGuides = () => (
  <Actions>
    <ButtonLink
      href="/migration-guides/archived"
      icon={<IconArrow direction="right" />}
      variant="soft"
    >
      Jump to archive
    </ButtonLink>
  </Actions>
);
