
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { PdfFile } from './PdfFile';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "option-2-graphql-user-experience-guide"
    }}>{`Option 2: GraphQL user experience guide`}</h1>
    <p>{`As part of this revision,
GraphQL implementations of ad selection must adhere to new user experience requirements.`}</p>
    <p>{`The file below contains an example of a compliant user experience as well as examples that no longer meet the requirements.`}</p>

    <PdfFile mdxType="PdfFile" />
    <p>{`Once you are familiar with these requirements,
continue to the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/v2/graphql"
      }}>{`GraphQL implementation guide`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;