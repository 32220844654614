
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "ad-selection-panel-tooltip"
    }}>{`Ad Selection Panel tooltip`}</h4>
    <p>{`We’ve added a new caveat under the ad products in `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/panel"
      }}>{`Ad Selection Panel`}</a>{` to accommodate the case where your software can schedule job ads to post on SEEK on a future date.`}</p>
    <p>{`The caveat states that ”Ad prices shown represent today’s prices only.
If you schedule your job ad for a future date, you acknowledge and agree you’ll be charged the price of that ad at the date
it’s published on SEEK’s website.”`}</p>
    <p>{`The ”How are prices set?” link has also been replaced by the new info icon in the caveat.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;