
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "mock-objects"
    }}>{`Mock objects`}</h1>
    <p>{`There are a number of manually curated mock objects available in the `}<a parentName="p" {...{
        "href": "/graphql/playground"
      }}>{`Playground`}</a>{` environment.
Each object is uniquely identified by an `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#object-identifiers"
      }}>{`object identifier`}</a>{` string.`}</p>
    <h2 {...{
      "id": "application-questionnaires"
    }}>{`Application questionnaires`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:applicationQuestionnaire:rrv2:SqFmkV8S2dMipyqbHjD9Mr`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:applicationQuestionnaire:rrv2:Jx1Gnnkf8uxhR3TeCkNsSK`}</inlineCode></p></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "advertisement-brandings"
    }}>{`Advertisement brandings`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:advertisementBranding:hirerBranding:37EWfWxhwVEGTT5BcjfE2L`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:advertisementBranding:hirerBranding:5DU2L2vQsyTXuu9NEUKT3f`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:advertisementBranding:hirerBranding:9Rw3f4qpkwv4poHjTwdu6K`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:advertisementBranding:hirerBranding:BYAZKaoXhS9LHFMv5gJ87e`}</inlineCode></p></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "advertisement-products"
    }}>{`Advertisement products`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Ad type`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Job update`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:adProduct:seekApi:EWHKTY9D6mJFiA5EH6P7MFbCR3cKV9WdNSMC6e1vqiUJ`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`Classic`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Create only`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:adProduct:seekApi:EWHKTY9D6mJFiA5EH6P7MFbByJ1JVteAQQkfa3uMJLPJ`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`StandOut`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Create only`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:adProduct:seekApi:EWHKTY9D6mJFiA5EH6P7MFaUj6LchctVWMg2zsCHxbpQ`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`Premium`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Create only`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:adProduct:seekApi:GxWYyP4HtLsXHEVMJFcPoRw29chXnRTDdvbZz1sQZQ1Q`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`Classic`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:positionProfile:jobAd:2782PZfXV`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:adProduct:seekApi:GxWYyP4HtLsXHEVMJFcPoRw1hs6WoAakfu13TRkq21vQ`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`StandOut`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:positionProfile:jobAd:2782PZfXV`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:adProduct:seekApi:GxWfPGp6WAZMZMndU84euf7d5DHk9duMyFCPoe8nvYkk`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`Premium`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:positionProfile:jobAd::28cfuo97M`}</inlineCode></p></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "candidates"
    }}>{`Candidates`}</h2>
    <h3 {...{
      "id": "seek-candidates"
    }}>{`SEEK candidates`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Name`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:candidate:feed:5PGXAHysiXhtA9JUqhyM8hhzMuWMPA`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Riley Doherty`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:candidate:feed:5PGXAHysjZdkQYwZghfL4bRCqvZ7ZM`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Amelia Collins`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:candidate:feed:5PGXAHysRLRreMSV17fuC5b8qmBg6C`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Ella Greenholt`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:candidate:feed:5f1Yht6N8e9nZ42UygrWn2m6sSQ95s`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Hamish Alexander`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:candidate:feed:5f1Yht6NVesExMZaYgL49mvwkD7tUs`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Hayden`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:candidate:feed:5f1Yht6NJyHSumX6xNxQm73PdWEuUi`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Owen Schultz`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:candidate:feed:5PGXAHysr9oiPkWWUb4pt9msjLFZCm`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Alice Waelchi`}</p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "uploaded-candidates"
    }}>{`Uploaded candidates`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Name`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:candidate:uploaded:12uNaG7vqgW6G1jG7qp7ha`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Marcus Damore`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "candidate-profiles"
    }}>{`Candidate profiles`}</h2>
    <h3 {...{
      "id": "application-profiles"
    }}>{`Application profiles`}</h3>
    <p>{`The identifier suffixes should be prepended with `}<inlineCode parentName="p">{`seekAnzPublicTest:candidateProfile:apply:`}</inlineCode>{` to create a candidate profile ID.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier suffix`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Attachments`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Has questionnaire`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Most recent role`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`7DtW6Q68gk2R4okNGhvg1Y`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`None`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Yes`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`New to workforce`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`4QM5fWQbdekL9gPtPZrzex`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Resume, cover letter`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Yes`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`In past`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`5G5NaFDmy7UigvGy6aT6VT`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Cover letter`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`No`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Current`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`8LKX1QrkJk5JuU2AfW6tsc`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Resume, cover letter`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`No`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`New to workforce`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`UHeZTe27g1ZJZoHMiwzmPJ`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Selection criteria`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`No`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`In past`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`T87M4jzTT8or3qVt8DuBP9`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Resume, selection criteria`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Yes`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Current`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`DRwKishTjgDrgoJV8sCHR3`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Resume`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Yes`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`In past`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`HmvRHVfJ7FBJMz6jPKuCkQ`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Resume, cover letter`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`No`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`In past`}</p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "purchased-profiles"
    }}>{`Purchased profiles`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Name`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:candidateProfile:purchased:73nRti9c7jBm`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Alexander Watsford`}</p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "uploaded-profiles"
    }}>{`Uploaded profiles`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Name`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:candidateProfile:uploaded:7m7JgSEM2rkAwrxoBrfPyV`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Marcus Damore`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "hirers"
    }}>{`Hirers`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Relationships with Playground partner`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:organization:seek:93WyyF1h`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`All`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:organization:seek:9YQA3jYK`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`ApplicationExport`}</inlineCode>{` only`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:organization:seek:E2LikAHu`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`None`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "job-categories"
    }}>{`Job categories`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Job category`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:jobCategory:seek:27HXTkNXh`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Design & Architecture — Web & Interaction Design`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:jobCategory:seek:2PiXemmou`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Retail & Consumer Products - Retail Assistants`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "locations"
    }}>{`Locations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Location`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:location:seek:W7NggeKH`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Auckland — Auckland Central`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:location:seek:2m81wybwV`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Melbourne — CBD & Inner Suburbs`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:location:seek:8WVUnymq`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Russia & Eastern Europe`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:location:seek:2vArzkyio`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Sydney — CBD, Inner West & Eastern Suburbs`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:location:seek:2beMJFcmV`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`UK & Ireland`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "position-openings"
    }}>{`Position openings`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Position profiles`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:positionOpening:positions:E3Lz6HkSrbZCawh4gPGAtM`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`None`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:positionOpening:positions:F65aT5rAv3ZSusuunFWT8i`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Posted only`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:positionOpening:positions:876y9hibtZZvv6vgtQx4zd`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Unposted only`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:positionOpening:positions:X1KiHhcuHWggkb6ydd9wyM`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Posted & unposted`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "position-profiles"
    }}>{`Position profiles`}</h2>
    <p>{`These position profiles are owned by `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}>{`hirers with different relationships`}</a>{` to the Playground partner.
You can only run position profile mutations for hirers you have a `}<inlineCode parentName="p">{`JobPosting`}</inlineCode>{` relationship with.`}</p>
    <h3 {...{
      "id": "posted-position-profiles"
    }}>{`Posted position profiles`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Relationships`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:positionProfile:jobAd:2782PZfXV`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`All`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:positionProfile:jobAd:27cuZeA47`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`All`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:positionProfile:jobAd:297Z5sddy`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`All`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:positionProfile:jobAd:287njieaj`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`ApplicationExport`}</inlineCode>{` only`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:positionProfile:jobAd:28cfuo97M`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`ApplicationExport`}</inlineCode>{` only`}</p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "unposted-position-profiles"
    }}>{`Unposted position profiles`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Relationships`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:positionProfile:unposted:GSg8dZtux2FD8hP4Bmju3B`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`All`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`globalPublicTest:positionProfile:unposted:QnwpqZfzXu1NRmZw5tjswn`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`All`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "webhook-subscriptions"
    }}>{`Webhook subscriptions`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Identifier`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Event type`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:webhookSubscription:events:BoJiJ9ZWFVgejLXLJxUnvL`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`CandidateApplicationCreated`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:webhookSubscription:events:CNmTxcSwNLgH8ZkssMCusC`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`CandidateApplicationCreated`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:webhookSubscription:events:RNzsabxEX56cuRepCD9A8j`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`HirerRelationshipChanged`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:webhookSubscription:events:9H6GD9v2o8hQw7aBBG2sAU`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionProfileClosed`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:webhookSubscription:events:T4HEUGJRpD3cpUSZ7xXWYL`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionProfilePosted`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnzPublicTest:webhookSubscription:events:V5aXuWdqmh2mzERRn44W22`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionProfilePosted`}</inlineCode></p></td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;