import { useAuth0 } from '@auth0/auth0-react';
import type { PartnerNoti, Private } from '@seek/indie-api-types';
import { useMemo } from 'react';

import {
  type EnvironmentConfig,
  useEnvironmentConfig,
} from 'src/hooks/environment';
import { useTracingHeaders } from 'src/hooks/tracingHeaders';

type NotiConfig = PartnerNoti.PartnerNotiConfig;
type NotiInput = PartnerNoti.PartnerNotiConfigInput;
type NotiHistory = PartnerNoti.HistoricNoti;

interface Client {
  name: string;
  clientId: string;
  hasClientGrant: boolean;
  createdAt: string;
}

export type CreateClientPayload = Client & {
  clientSecret: string;
};

export type LiveClientPayload = Client & {
  type: 'live';
  clientSecret: string | null;
  lastUsedAt?: string | null;
};

export type PublicTestClientPayload = Client & {
  type: 'publicTest';
  clientSecret: string;
  lastUsedAt?: string | null;
};

export type GetClientsPayload = Array<
  LiveClientPayload | PublicTestClientPayload
>;

export interface GetPartnerTokenPayload {
  accessToken: string;
  tokenType: string;
  expiresIn: number;
}

const createApiClient = (
  environment: EnvironmentConfig,
  getToken: () => Promise<string>,
  tracingHeaders: Record<string, string>,
) => {
  const { baseUrls } = environment;

  const REQUEST_TIMEOUT_MS = 5000;

  interface RequestOptions {
    baseUrl: string;
    body?: string;
    method: string;
    path: string;
  }

  const request = async ({ baseUrl, body, method, path }: RequestOptions) => {
    const token = await getToken();
    const headers: Record<string, string> = {
      ...tracingHeaders,
      Authorization: `Bearer ${token}`,
    };

    const controller = new AbortController();

    const id = setTimeout(() => controller.abort(), REQUEST_TIMEOUT_MS);
    const uri = `${baseUrl}${path}`;

    const response = await fetch(uri, {
      body,
      method,
      headers: {
        ...(body && { 'Content-Type': 'application/json' }),
        ...headers,
      },
      signal: controller.signal,
    });

    clearTimeout(id);

    if (
      !response.ok &&
      response.status === 400 &&
      response.headers.get('content-type')?.indexOf('application/json') !== -1
    ) {
      const responseData = await response.json();

      if (!responseData.message) {
        throw new Error(
          `${path} responded with ${response.status}: ${response.statusText}`,
        );
      }

      throw new Error(responseData.message);
    }

    if (!response.ok) {
      throw new Error(
        `${path} responded with ${response.status}: ${response.statusText}`,
      );
    }

    return response;
  };

  const requestJson = async (opts: RequestOptions) => {
    const response = await request(opts);

    return response.json();
  };

  return {
    credentials: {
      list: (): Promise<GetClientsPayload> =>
        requestJson({
          baseUrl: baseUrls.users,
          path: '/user-api/credentials/getClients',
          method: 'POST',
        }),
      create: (
        name: string,
        dataType: 'live' | 'publicTest',
      ): Promise<CreateClientPayload> =>
        requestJson({
          baseUrl: baseUrls.users,
          path: '/user-api/credentials/createCredentials',
          method: 'POST',
          body: JSON.stringify({ name, dataType }),
        }),
      updateClient: (clientId: string, name: string) =>
        request({
          baseUrl: baseUrls.users,
          path: '/user-api/credentials/updateClient',
          method: 'POST',
          body: JSON.stringify({ clientId, name }),
        }),
      delete: (clientId: string) =>
        request({
          baseUrl: baseUrls.users,
          path: '/user-api/credentials/deleteClient',
          method: 'POST',
          body: JSON.stringify({ clientId }),
        }),
      createClientGrant: (clientId: string) =>
        request({
          baseUrl: baseUrls.users,
          path: '/user-api/credentials/createClientGrant',
          method: 'POST',
          body: JSON.stringify({ clientId }),
        }),
      getPartnerToken: (clientId: string): Promise<GetPartnerTokenPayload> =>
        requestJson({
          baseUrl: baseUrls.users,
          path: '/user-api/credentials/getPartnerToken',
          method: 'POST',
          body: JSON.stringify({ clientId }),
        }),
    },
    noti: {
      get: async (): Promise<NotiConfig | undefined> => {
        const response = await request({
          baseUrl: baseUrls.noti,
          path: '/noti/config',
          method: 'GET',
        });

        return response.status === 204 ? undefined : response.json();
      },
      update: (newDetails: NotiInput): Promise<NotiConfig> =>
        requestJson({
          baseUrl: baseUrls.noti,
          path: '/noti/config',
          method: 'POST',
          body: JSON.stringify(newDetails),
        }),
    },
    notiHistory: {
      get: (): Promise<NotiHistory[]> =>
        requestJson({
          baseUrl: baseUrls.noti,
          path: '/noti/history',
          method: 'GET',
        }),
    },
    users: {
      list: (): Promise<Private.Auth0UserPayload[]> =>
        requestJson({
          baseUrl: baseUrls.users,
          path: '/user-api/users',
          method: 'GET',
        }),
      create: (newUserDetails: Private.Auth0UserInput) =>
        request({
          baseUrl: baseUrls.users,
          path: '/user-api/users',
          method: 'POST',
          body: JSON.stringify(newUserDetails),
        }),
      changeRole: (newRoleDetails: Private.Auth0UserRoleChangeInput) =>
        request({
          baseUrl: baseUrls.users,
          path: '/user-api/users/changeUserRole',
          method: 'POST',
          body: JSON.stringify(newRoleDetails),
        }),
      delete: (userId: string) =>
        request({
          baseUrl: baseUrls.users,
          path: '/user-api/users/deleteUser',
          method: 'POST',
          body: JSON.stringify({ id: userId }),
        }),
      resetMfa: (userId: string) =>
        request({
          baseUrl: baseUrls.users,
          path: '/user-api/users/resetMfa',
          method: 'POST',
          body: JSON.stringify({ id: userId }),
        }),
    },
    self: {
      resetMfa: () =>
        request({
          baseUrl: baseUrls.users,
          path: '/user-api/users/resetSelfMfa',
          method: 'POST',
        }),
      requestChangePassword: () =>
        request({
          baseUrl: baseUrls.users,
          path: '/user-api/users/changePassword',
          method: 'POST',
        }),
      changeName: (name: string) =>
        request({
          baseUrl: baseUrls.users,
          path: '/user-api/users/changeName',
          method: 'POST',
          body: JSON.stringify({ name }),
        }),
    },
    testEvents: {
      sendTestEvent: (
        input: Private.TestCallInput,
      ): Promise<Private.TestCallOutput> =>
        requestJson({
          baseUrl: baseUrls.users,
          path: '/user-api/events/test',
          method: 'POST',
          body: JSON.stringify(input),
        }),
    },
    subscriptions: {
      webhookHealth: async (
        input: Private.WebhookSubscriptionRequestMetricInput,
      ): Promise<Private.WebhookSubscriptionRequestMetric | undefined> => {
        const response = await request({
          baseUrl: baseUrls.users,
          path: '/user-api/subscriptions/health',
          method: 'POST',
          body: JSON.stringify(input),
        });
        return response.status === 204 ? undefined : response.json();
      },
    },
  };
};

export const useApi = () => {
  const { getAccessTokenSilently } = useAuth0();
  const env = useEnvironmentConfig();
  const tracingHeaders = useTracingHeaders();

  return useMemo(
    () => createApiClient(env, getAccessTokenSilently, tracingHeaders),
    [env, getAccessTokenSilently, tracingHeaders],
  );
};
