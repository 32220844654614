import { gql } from '@apollo/client';

export const JOB_AD_PREVIEW = gql`
  query jobAdPreview(
    $positionProfile: PostedPositionProfilePreview_PositionProfileInput!
  ) {
    postedPositionProfilePreview(positionProfile: $positionProfile) {
      previewUri {
        url
      }
    }
  }
`;

export const JOB_DETAILS = gql`
  query jobDetails($id: String!) {
    positionProfile(id: $id) {
      jobCategories {
        id {
          value
        }
      }
      offeredRemunerationPackage {
        basisCode
        descriptions
        ranges {
          intervalCode
          maximumAmount {
            currency
            value
          }
          minimumAmount {
            currency
            value
          }
        }
      }
      positionFormattedDescriptions {
        content
        descriptionId {
          value
        }
      }
      positionLocation {
        id {
          value
        }
      }
      positionOrganizations {
        id {
          value
        }
        name
      }
      positionTitle
      positionUri
      postingInstructions {
        applicationMethods {
          applicationUri {
            url
          }
        }
        brandingId
        end
        seekAnzAdvertisementType
        start
      }
      profileId {
        value
      }
      seekAnzWorkTypeCode
      seekBillingReference
      seekCreatedBySelfIndicator
      seekHirerJobReference
      seekPartnerMetadata
      seekVideo {
        seekAnzPositionCode
        url
      }
    }
  }
`;
