import { createForm } from '@seek/forms-ui';

export interface FormData {
  name: string;
}

const { FormProvider, useField } = createForm<
  FormData,
  Record<string, never>,
  'en'
>();

export { FormProvider, useField };
