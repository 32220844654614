import { Box, Text } from 'braid-design-system';

import * as styles from './FlowchartHeading.css';

interface Props {
  children: string;
}

export const FlowchartHeading = ({ children }: Props) => (
  <Box
    borderRadius="large"
    className={styles.headingNode}
    paddingX="small"
    paddingY="medium"
    textAlign="center"
  >
    <Text size="small" weight="strong">
      {children}
    </Text>
  </Box>
);
