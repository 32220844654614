import { Heading, Stack, Text } from 'braid-design-system';
import { SmartTextLink } from 'scoobie';
import { BrandSelect } from 'wingman-fe';

import type { HiringOrganizationIdFieldsFragment } from 'src/types/graphql';

interface Props {
  hirer?: HiringOrganizationIdFieldsFragment;
}

export const BrandingSection = ({ hirer }: Props) => (
  <Stack space="large">
    <Heading level="4">
      <SmartTextLink href="https://developer.seek.com/use-cases/job-posting/branding">
        Brand select
      </SmartTextLink>
    </Heading>

    {hirer ? (
      <BrandSelect hideLabel hirerId={hirer.id.value} showCopyableOid />
    ) : (
      <Text tone="secondary">Select a hirer to view their brands.</Text>
    )}
  </Stack>
);
