import { Box } from 'braid-design-system';
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Menu } from './Menu';
import { Nav } from './Nav';
import {
  PreviewDeploymentBanner,
  useShowPreview,
} from './PreviewDeploymentBanner';

import { navHeight } from 'src/styles.css';

const LayoutSlot = createContext<HTMLElement | null>(null);

export const useLayoutSlot = () => useContext(LayoutSlot);

export const Layout = ({ children }: { children: ReactNode }) => {
  const [stickyHeight, setStickyHeight] = useState<string>(navHeight);
  const stickyRef = useRef<HTMLElement>(null);
  const [layoutSlot, setLayoutSlot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const recalculate = () => {
      if (stickyRef.current) {
        const measuredHeight = `${stickyRef.current.clientHeight}px`;
        if (measuredHeight !== stickyHeight) {
          setStickyHeight(measuredHeight);
        }
      }
    };

    recalculate();

    const observer = new ResizeObserver(recalculate);
    const element = stickyRef.current;

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [stickyHeight]);

  const [showPreview, dismiss] = useShowPreview();

  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);

  const setLayoutSlotCallback = useCallback(setLayoutSlot, [setLayoutSlot]);

  return (
    <LayoutSlot.Provider value={layoutSlot}>
      <Box display="flex" flexDirection="column">
        <Box
          style={{ position: 'sticky' }}
          width="full"
          zIndex="sticky"
          top={0}
          ref={stickyRef}
        >
          {showPreview ? <PreviewDeploymentBanner dismiss={dismiss} /> : null}
          <Nav setMenuOpened={setOpen} />
        </Box>

        <Box display="flex">
          <Menu usedHeight={stickyHeight} isOpen={open} close={close} />

          <Box width="full" overflow="hidden">
            {children}
          </Box>

          <Box ref={setLayoutSlotCallback} />
        </Box>
      </Box>
    </LayoutSlot.Provider>
  );
};
