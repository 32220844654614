
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';
import { InlineCode } from 'scoobie';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "questionnaire-panel"
    }}>{`Questionnaire Panel`}</h1>
    <p>{`SEEK provides a Questionnaire Panel you can embed within your job posting flow.
The Questionnaire Panel allows hirers to interactively create questionnaires for candidates to complete as part of their job application.
It lets hirers:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Select relevant question suggestions from SEEK’s library of commonly used questions to ask candidates as a part of their job application.`}</p>
        <p parentName="li">{`The relevancy of the questions to the hirers’ role will depend on the position’s title, `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/job-categories"
          }}>{`job category`}</a>{`, `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/locations"
          }}>{`location`}</a>{`, advertisement details, search summary, and search bullet points that are inputted.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add custom questions if they do not find the suggestions from SEEK’s library to be relevant.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Select preferred answers for each question.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Attach a company privacy policy if applicable.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "browser-support"
    }}>{`Browser support`}</h2>
    <p>{`The Questionnaire Panel tracks our standard `}<a parentName="p" {...{
        "href": "/introduction/browser-support"
      }}>{`browser support policy`}</a>{`.`}</p>
    <h2 {...{
      "id": "step-1-include-the-panel"
    }}>{`Step 1: Include the panel`}</h2>
    <p>{`Add the following script tag to the page where the Questionnaire Panel will be displayed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script
  type="text/javascript"
  src="https://integration.seek.com/panels/SeekApi.js"
></script>
`}</code></pre>
    <h2 {...{
      "id": "step-2-render-the-panel"
    }}>{`Step 2: Render the panel`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`SeekApi.render`}</inlineCode>{` function renders an instance of the panel in the specified DOM element.`}</p>
    <p>{`The render function must be called on page load and whenever the properties of the `}<a parentName="p" {...{
        "href": "#props"
      }}><inlineCode parentName="a">{`positionProfile`}</inlineCode>{` object`}</a>{` change.
For example, if the hirer updates their position title you must re-render the panel to refresh question suggestions.`}</p>
    <p>{`The render function may throw errors if:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`containerNode`}</inlineCode>{` provided is not a valid `}<inlineCode parentName="p">{`HTMLElement`}</inlineCode></p>
      </li>
    </ul>

    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const { dispatchEvent } = SeekApi.render(containerNode, 'questionnaire', props);
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`containerNode`}</inlineCode>{` –
(DOM Node) The DOM element to render the panel into.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`'questionnaire'`}</inlineCode>{` -
The name of the panel to render.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`props`}</inlineCode>{` –
`}<a parentName="p" {...{
            "href": "#props"
          }}>{`Props`}</a>{` for rendering the panel.`}</p>
      </li>
    </ul>
    <Alert tone="caution" mdxType="Alert">
  <Text mdxType="Text">
    Modifying the <InlineCode mdxType="InlineCode">containerNode</InlineCode> or its children after
    the <InlineCode mdxType="InlineCode">render</InlineCode> function has been called may lead to
    unexpected behaviour.
  </Text>
    </Alert>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Prop`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Type`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`getAuthToken`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`() => Promise<string>`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Function to call to retrieve the `}<a parentName="p" {...{
                "href": "#step-3-handle-browser-token-requests"
              }}>{`browser token`}</a>{` to use when calling the SEEK API.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`schemeId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`string`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The `}<a parentName="p" {...{
                "href": "/graphql/seek-api-conventions#scheme-identifiers"
              }}>{`scheme ID`}</a>{` to use when suggesting questions or creating questionnaires. Either `}<inlineCode parentName="p">{`seekAnz`}</inlineCode>{` or `}<inlineCode parentName="p">{`seekAnzPublicTest`}</inlineCode>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`mode`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`string`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The mode of the panel. Set to `}<inlineCode parentName="p">{`Create`}</inlineCode>{` when posting a new job ad, and `}<inlineCode parentName="p">{`Update`}</inlineCode>{` when updating an existing job ad. The widget will show a non-editable summary of the questionnaire in update mode.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`questionnaireId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The ID of an existing questionnaire to load into the panel. Questionnaires are `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/questionnaires#immutability"
              }}>{`immutable`}</a>{`; once saved they cannot be edited.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`locale`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Specifies the locale to display content in, e.g. `}<inlineCode parentName="p">{`en-AU`}</inlineCode>{`. Set this prop to override the default localisation behaviour based on users browser preferences. Supported locales are outlined in the `}<a parentName="p" {...{
                "href": "/graphql/in-practice#content-localisation"
              }}>{`content localisation`}</a>{` documentation.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`privacyPolicy`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`object`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The configuration options for the hirer’s privacy policy. The option to include a privacy policy question will only be presented to the hirer if this is present.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`privacyPolicy.url`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`string`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The website where the hirer’s privacy policy is hosted. This should start with `}<inlineCode parentName="p">{`http://`}</inlineCode>{` or `}<inlineCode parentName="p">{`https://`}</inlineCode>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`privacyPolicy.descriptionHtml`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`A short phrase to present to candidates to prompt them to accept the privacy policy. Defaults to “Do you agree to the privacy policy?”`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`positionProfile`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`object`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The configuration options for providing information about the position being posted. This information is used to suggest questions relevant to the position.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`positionProfile.jobCategories`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string[]`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The array of identifiers for the position’s `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/job-categories"
              }}>{`job category`}</a>{`. Only a single value is currently accepted.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`positionProfile.positionLocation`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string[]`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The array of identifiers for the position’s `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/locations"
              }}>{`location`}</a>{`. Only a single value is currently accepted.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`positionProfile.positionOrganizations`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`string[]`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The array of identifiers of the hirer posting the job. Only a single value is currently accepted.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`positionProfile.positionTitle`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`string`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The title of the position.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`positionProfile.positionFormattedDescriptions`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p><inlineCode parentName="td">{`object[]`}</inlineCode></p><p><small>{`optional`}</small></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`An array of formatted position profile descriptions.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`positionProfile.positionFormattedDescriptions[].content`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`string`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The HTML content of the description.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`positionProfile.positionFormattedDescriptions[].descriptionId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`string`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The description type. One of `}<inlineCode parentName="p">{`AdvertisementDetails`}</inlineCode>{`, `}<inlineCode parentName="p">{`SearchBulletPoint`}</inlineCode>{`, or `}<inlineCode parentName="p">{`SearchSummary`}</inlineCode>{`.`}</p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "sample-code"
    }}>{`Sample code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`try {
  const { dispatchEvent } = SeekApi.render(
    document.getElementById('seekQuestionnaireContainerDiv'),
    'questionnaire',
    {
      // Function to call to retrieve the browser token to use when calling the
      // SEEK API, see "Step 3" for more details.
      getAuthToken: () => {
        return Promise.resolve('browser token');
      },
      // The schemeId controls whether the panel talks to the live production
      // environment or the Playground environment.
      schemeId: 'seekAnzPublicTest',
      // The mode controls whether the panel allows edits to the questionnaire.
      // Questionnaires are immutable, and cannot be added to or removed from a
      // posted job ad.
      mode: 'Create',
      // Providing a questionnaire ID will make the panel load the data from that
      // questionnaire. If the questionnaire was saved as part of a draft job
      // ad and the mode is still \`Create\`, the widget will allow the hirer to
      // add and remove questions.
      questionnaireId:
        'seekAnzPublicTest:applicationQuestionnaire:rrv2:SqFmkV8S2dMipyqbHjD9Mr',
      // The privacy policy allows you to configure how a hirer‘s privacy policy
      // may be attached.
      privacyPolicy: {
        url: 'https://example.com/privacy-policy.html',
        descriptionHtml: 'Do you accept the terms in the privacy policy?'
      },
      // The positionProfile allows you to add the details of the position being
      // posted to improve question suggestions.
      positionProfile: {
        jobCategories: 'seekAnzPublicTest:jobCategory:seek:27HXTkNXh',
        positionLocation: 'seekAnzPublicTest:location:seek:W7NggeKH',
        positionOrganizations: 'seekAnzPublicTest:organization:seek:E2LikAHu',
        positionTitle: 'Office Manager',
        positionFormattedDescriptions: [
          {
            content:
              '<strong>Amazing opportunity!</strong><p>Do you want to join a fast-paced company, managing the office and staff?</p>',
            descriptionId: 'AdvertisementDetails'
          },
          {
            content: 'Great culture',
            descriptionId: 'SearchBulletPoint'
          },
          {
            content: 'Exciting role fronting a dynamic office',
            descriptionId: 'SearchSummary'
          }
        ]
      }
    }
  );
} catch (error) {
  // Errors thrown here might be caused by not providing a valid HTMLElement as
  // the containerNode, a configuration error in your options, or by the
  // Questionnaire Panel script not successfully loading.
}
`}</code></pre>
    <h2 {...{
      "id": "step-3-handle-browser-token-requests"
    }}>{`Step 3: Handle browser token requests`}</h2>
    <img alt="" data-scoobie-style="none" src={require('../../../../../../mermaid/.14b0509d5aa2ed1f29ac6fd19ea2ed0fbfd33266.mmd.svg')} title="Panel authentication" />
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The panel loads and invokes the `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function passed to it.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend requests a browser token from your backend.`}</p>
        <p parentName="li">{`The `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function should request a new token for the hirer ID in `}<inlineCode parentName="p">{`positionProfile.positionOrganizations`}</inlineCode>{`.
If a user switches to a different SEEK hirer account in your posting form,
your software should re-render the panel with the new hirer ID in `}<inlineCode parentName="p">{`positionProfile.positionOrganizations`}</inlineCode>{`,
and ensure that subsequent invocations of `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` will request a token for the new hirer ID.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend authenticates and authorises the user.`}</p>
        <p parentName="li">{`Your software is responsible for verifying that the user is authorised to access a given hirer ID.
A user must not be able to request a browser token for an arbitrary organization that they do not belong to.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend `}<a parentName="p" {...{
            "href": "/auth/browser-tokens"
          }}>{`requests a browser token from the SEEK API`}</a>{` for the appropriate hirer ID and `}<inlineCode parentName="p">{`mutate:application-questionnaires query:application-library-question-suggestions query:application-questionnaires query:organizations`}</inlineCode>{` scope.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend responds with the browser token.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend returns the browser token from the `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The panel can now make requests to the GraphQL endpoint.`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`POST https://graphql.seek.com/auth/token HTTP/1.1
Authorization: Bearer PARTNER_TOKEN_HERE
Content-Type: application/json
User-Agent: YourPartnerService/1.2.3

{
  "hirerId": "seekAnzPublicTest:organization:seek:93WyyF1h",
  "scope": "mutate:application-questionnaires query:application-library-question-suggestions query:application-questionnaires query:organizations",
  "userId": "317665"
}
`}</code></pre>
    <h2 {...{
      "id": "step-4-post-the-job-ad"
    }}>{`Step 4: Post the job ad`}</h2>
    <p>{`When the hirer is ready to post their job ad, your software should call `}<inlineCode parentName="p">{`dispatchEvent('seek:questionnaire:save')`}</inlineCode>{`.
It returns a Promise to a result object.
`}<inlineCode parentName="p">{`dispatchEvent('seek:questionnaire:save')`}</inlineCode>{` does not throw exceptions.`}</p>
    <h3 {...{
      "id": "result-object"
    }}>{`Result object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Result property`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Type`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`isError`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`boolean`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Whether an error has occurred.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`data`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`object`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="p">{`null`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p>{`An object that contains information about the saved questionnaire.`}</p><p>{`Only present when questions were configured in the panel and `}<inlineCode parentName="td">{`isError`}</inlineCode>{` is `}<inlineCode parentName="td">{`false`}</inlineCode>{`.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`data.id`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`string`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The questionnaire ID of the saved questionnaire. For example, `}<inlineCode parentName="p">{`seekAnzPublicTest:applicationQuestionnaire:rrv2:SqFmkV8S2dMipyqbHjD9Mr`}</inlineCode>{`.`}</p></td>
        </tr>
      </tbody>
    </table>
    <p>{`If the save succeeded, then `}<inlineCode parentName="p">{`isError`}</inlineCode>{` will be `}<inlineCode parentName="p">{`false`}</inlineCode>{`, and `}<inlineCode parentName="p">{`data.id`}</inlineCode>{` will contain the saved questionnaire’s ID.
You may provide the new questionnaire ID in the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PostPosition_PositionProfileInput/field/seekApplicationQuestionnaireId"
      }}><inlineCode parentName="a">{`PostPosition_PositionProfileInput.seekApplicationQuestionnaireId`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PostPositionProfileForOpeningPositionProfileInput/field/seekApplicationQuestionnaireId"
      }}><inlineCode parentName="a">{`PostPositionProfileForOpeningPositionProfileInput.seekApplicationQuestionnaireId`}</inlineCode></a>{` mutation inputs, or persist it as part of a draft job ad.`}</p>
    <p>{`If the hirer did not add any questions via the panel, then `}<inlineCode parentName="p">{`isError`}</inlineCode>{` will be `}<inlineCode parentName="p">{`false`}</inlineCode>{`, and `}<inlineCode parentName="p">{`data`}</inlineCode>{` will be `}<inlineCode parentName="p">{`null`}</inlineCode>{`.
You should continue to post the job ad without a questionnaire ID.`}</p>
    <p>{`If there was some problem with the questions the hirer added—for example, adding a custom question, but not configuring any answers—then `}<inlineCode parentName="p">{`isError`}</inlineCode>{` will be `}<inlineCode parentName="p">{`true`}</inlineCode>{`, and `}<inlineCode parentName="p">{`data`}</inlineCode>{` will be `}<inlineCode parentName="p">{`null`}</inlineCode>{`.
You should not continue to post the job ad, as the hirer intends to add a questionnaire but an error has occurred.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const postSeekJobAd = async (input) => {
  // Call a posting mutation to post or update a SEEK job ad.
};

const handleSubmitButton = async () => {
  const input = {
    // Compose from form data.
  };

  const result = await dispatchEvent('seek:questionnaire:save');

  if (result.isError) {
    // Do not post the job ad if an error occurs while saving the questionnaire.
    // The panel will display an actionable error message to the hirer.
    return;
  }

  // Data will be nullish if the hirer did not add any questions via the panel.
  if (result.data?.id) {
    input.positionProfile.seekApplicationQuestionnaireId = result.data.id;
  }

  await postSeekJobAd(input);
};
`}</code></pre>
    <h2 {...{
      "id": "draft-job-ads"
    }}>{`Draft job ads`}</h2>
    <p>{`If your software supports draft job ads, you should also save the questionnaire state as part of the draft.
Saving a questionnaire for a draft works the same way as saving for posting a job ad.
Call `}<inlineCode parentName="p">{`dispatchEvent('seek:questionnaire:save')`}</inlineCode>{`, and if there is a returned questionnaire ID and `}<inlineCode parentName="p">{`isError`}</inlineCode>{` is `}<inlineCode parentName="p">{`false`}</inlineCode>{`,
continue to save the draft and persist the ID.
To load the draft into the Questionnaire Panel, you must provide the saved `}<inlineCode parentName="p">{`questionnaireId`}</inlineCode>{` and set the mode to `}<inlineCode parentName="p">{`Create`}</inlineCode>{` in the panel options.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;