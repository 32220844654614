
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "relaxed-string-length-limits"
    }}>{`Relaxed string length limits`}</h4>
    <p>{`We have relaxed our length validation on `}<inlineCode parentName="p">{`String`}</inlineCode>{` input fields.
Previously, string lengths were measured and specified in terms of bytes in UTF-8 encoding.
This was stricter than common validation approaches on the web,
including the HTML `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/maxlength"
      }}><inlineCode parentName="a">{`maxlength`}</inlineCode>{` attribute`}</a>{` and JavaScript `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/length"
      }}><inlineCode parentName="a">{`String.length`}</inlineCode>{` property`}</a>{`.`}</p>
    <p>{`Now, the SEEK API measures and specifies string lengths in terms of Unicode code points or colloquially `}<em parentName="p">{`characters`}</em>{`.
This is a closer match to the graphemes visible to an end user,
and should provide more equitable support for other scripts and special characters.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#string-lengths"
      }}>{`string lengths`}</a>{` for more information.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;