import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';

import { AdPerformanceLoader } from './AdPerformanceLoader';
import type { AdPerformanceProps } from './types';

type Props = AdPerformanceProps;

export const AdPerformancePanel = ({ positionProfileId }: Props) => {
  const { getAccessTokenSilently } = useAuth0();

  const options = useMemo(
    () => ({
      getAuthToken: () => getAccessTokenSilently(),
      positionProfileId,
    }),
    [positionProfileId, getAccessTokenSilently],
  );

  return <AdPerformanceLoader props={options} />;
};
