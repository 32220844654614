import { Box, Text, TooltipRenderer } from 'braid-design-system';
import type { ReactNode } from 'react';

import * as styles from './TooltipField.css';

interface TooltipFieldProps {
  children: ReactNode;
  name: string;
}

export const TooltipField = ({ children, name }: TooltipFieldProps) => (
  <TooltipRenderer
    id={`${name}Tooltip`}
    tooltip={<Text size="small">{name}</Text>}
  >
    {({ triggerProps }) => (
      <Box
        {...triggerProps}
        className={styles.hoverTooltipText}
        component="span"
        cursor="pointer"
      >
        {children}
      </Box>
    )}
  </TooltipRenderer>
);
