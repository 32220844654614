import { gql } from '@apollo/client';

export const POLL_EVENTS = gql`
  query pollEvents(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: EventsFilterInput
    $schemeId: String!
  ) {
    events(
      after: $after
      before: $before
      first: $first
      last: $last
      filter: $filter
      schemeId: $schemeId
    ) {
      edges {
        node {
          ... on CandidateApplicationCreatedEvent {
            candidateId
            candidateApplicationProfileId
          }
          ... on CandidateProfilePurchasedEvent {
            candidateProfileId
          }
          ... on HirerRelationshipChangedEvent {
            hirerId
          }
          ... on PositionProfileClosedEvent {
            positionProfileId
          }
          ... on PositionProfilePostedEvent {
            positionProfileId
          }
          id {
            value
          }
          createDateTime
          typeCode
          schemeId
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
