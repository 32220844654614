import type { Private } from '@seek/indie-api-types';

export type Role = Private.Auth0UserRoleChangeInput['role'];

export const roleDisplayNames: Record<Role, string> = {
  'read-only': 'Read only user',
  edit: 'User',
  admin: 'Administrator',
};

export type UserType = 'impersonating' | 'unverified' | 'verified';
