import {
  Badge,
  Box,
  Divider,
  IconChevron,
  IconSearch,
  Text,
} from 'braid-design-system';
import { compute } from 'compute-scroll-into-view';
import { useCombobox } from 'downshift';
import {
  type ComponentProps,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { PageIcon } from '../PageIcon';

import { SearchResult } from './SearchResult';
import type { SearchResultItem } from './mapSearchResult';
import { type SearchSource, searchSources } from './searchIndex';

import * as styles from './SiteSearch.css';

const useIsMac = () => {
  const [isMac, setIsMac] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setIsMac(Boolean(global.navigator?.platform?.match('Mac')));
  }, []);

  return isMac;
};

export const SearchField = ({
  autoFocus,
  onBlur,
  onChooseResult,
  onSearchTermChange,
  searchTerm,
  results,
}: {
  autoFocus: boolean | undefined;
  onBlur: (() => void) | undefined;
  onChooseResult: (result: SearchResultItem) => void;
  onSearchTermChange: (term: string) => void;
  searchTerm: string;
  results: Record<SearchSource, SearchResultItem[]>;
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState<
    Partial<Record<SearchSource, boolean>>
  >({});
  const isMac = useIsMac();

  useEffect(() => {
    if (isMac === undefined) return;

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'k' && (isMac ? e.metaKey : e.ctrlKey)) {
        ref.current?.focus();
        e.preventDefault();
        e.stopPropagation();
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [isMac]);

  useEffect(() => {
    if (!isOpen || !searchTerm) {
      setShowMore({});
    }
  }, [isOpen, searchTerm]);

  const items = searchSources.flatMap(({ value, icon, heading }) => [
    ...(showMore[value] ? results[value] : results[value].slice(0, 3)).map(
      (result, index) =>
        ({
          heading:
            index === 0
              ? { text: heading, icon: <PageIcon icon={icon} /> }
              : undefined,

          kind: 'result',
          value: result,
        } as const),
    ),
    ...(results[value].length > 3
      ? ([
          {
            kind: 'expand-collapse',
            label: showMore[value]
              ? `Show less in ${value}`
              : `Show more in ${value}`,
            expanded: showMore[value],
            onSelect: () =>
              setShowMore((prev) => ({ ...prev, [value]: !prev[value] })),
          },
        ] as const)
      : []),
  ]);

  const { getMenuProps, getInputProps, highlightedIndex, getItemProps } =
    useCombobox({
      id: 'site-search',
      isOpen,
      onInputValueChange: ({ inputValue }) => {
        onSearchTermChange(inputValue);
      },
      items,
      stateReducer: (state, { changes, type }) => {
        if (type === useCombobox.stateChangeTypes.InputKeyDownEscape) {
          if (state.inputValue === '') {
            removeFocus(ref);
          }

          onSearchTermChange('');

          return {
            ...changes,
            inputValue: '',
          };
        }

        if (changes.selectedItem?.kind === 'result') {
          onChooseResult(changes.selectedItem.value);
          removeFocus(ref);

          return {
            ...changes,
            inputValue: '',
            selectedItem: null,
          };
        }

        if (changes.selectedItem?.kind === 'expand-collapse') {
          changes.selectedItem.onSelect();

          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            inputValue: state.inputValue,
            selectedItem: null,
          };
        }

        return changes;
      },
      scrollIntoView: (node, menuNode) => {
        if (!node) {
          return;
        }

        const actions = compute(node, {
          boundary: menuNode,
          block: 'center',
          scrollMode: 'if-needed',
        });

        actions.forEach(({ el, top, left }) => {
          el.scrollTop = top;
          el.scrollLeft = left;
        });
      },
    });

  const inputProps = getInputProps<ComponentProps<typeof Box>>({
    ref,
  });

  return (
    <Box width="full" position="relative">
      <Box width="full">
        <Box
          // We can't use a braid <TextField /> because it doesn't accept all props downshift wants, notably onKeyDown
          component="input"
          placeholder="Search site"
          className={styles.input}
          autoFocus={autoFocus}
          {...inputProps}
          onFocus={(e) => {
            setIsOpen(true);
            inputProps.onFocus?.(e);
          }}
          onBlur={(e) => {
            setIsOpen(false);
            onBlur?.();
            inputProps.onBlur?.(e);
          }}
        />
        <Box className={[styles.icon, styles.iconLeft]} background="surface">
          <Text>
            <IconSearch />
          </Text>
        </Box>
        {isMac !== undefined && !isOpen && (
          <Box className={[styles.icon, styles.iconRight]} background="surface">
            <Badge tone="neutral">{isMac ? '⌘' : 'Ctrl + '}K</Badge>
          </Box>
        )}
      </Box>

      <RemoveScroll enabled={isOpen && items.length > 0}>
        <Box
          component="ul"
          display={isOpen && items.length > 0 ? 'block' : 'none'}
          background="surface"
          className={styles.menu}
          {...getMenuProps()}
        >
          {items.map((result, index) => (
            <Fragment
              key={result.kind === 'result' ? result.value.path : result.label}
            >
              {result.kind === 'result' && result.heading && index !== 0 && (
                <Box paddingX="small" paddingY="small" component="li">
                  <Divider />
                </Box>
              )}
              {result.kind === 'result' && result.heading && (
                <Box padding="small" component="li">
                  <Text
                    weight="medium"
                    tone="secondary"
                    icon={result.heading.icon}
                  >
                    {result.heading.text}
                  </Text>
                </Box>
              )}
              <Box
                component="li"
                background={
                  highlightedIndex === index ? 'formAccentSoft' : undefined
                }
                className={styles.menuItem}
                {...getItemProps({ item: result, index })}
              >
                {result.kind === 'result' ? (
                  <SearchResult result={result.value} />
                ) : (
                  <Box paddingY="xxsmall" paddingX="small">
                    <Text tone="info" weight="strong" size="small">
                      {result.label}{' '}
                      <IconChevron
                        direction={result.expanded ? 'up' : 'down'}
                      />
                    </Text>
                  </Box>
                )}
              </Box>
            </Fragment>
          ))}
        </Box>
      </RemoveScroll>
    </Box>
  );
};

const removeFocus = (ref: React.RefObject<HTMLInputElement | null>) => {
  ref.current?.blur();

  /**
   * Chrome seems to have a bug where it focuses the body on blur ⬆
   * But then command up/down doesn't scroll the page, despite option up/down, spacebar, etc, all scrolling.
   * This seems to work. I hate it.
   */
  const hiddenFocus = document.createElement('input');
  hiddenFocus.style.position = 'absolute';
  hiddenFocus.style.left = '-9999px';
  hiddenFocus.setAttribute('tabindex', '-1');

  document.body.appendChild(hiddenFocus);

  hiddenFocus.focus({ preventScroll: true });

  document.body.removeChild(hiddenFocus);
  document.body.focus({ preventScroll: true });
};
