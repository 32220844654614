import { Heading, Stack, Text } from 'braid-design-system';
import { InlineCode, SmartTextLink } from 'scoobie';

import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';
import { AuthVerifier } from 'src/components/auth/AuthVerifier';

import { HirerList } from './components/HirerList/HirerList';
import { HirerLookup } from './components/HirerLookup/HirerLookup';

export const HirersPage = () => (
  <AuthVerifier>
    <PageWrapper
      heading={
        <Heading level="2" weight="weak">
          Hirer relationships
        </Heading>
      }
      explainer={
        <Stack space="medium">
          <Text tone="secondary">
            View the SEEK hirers you have an{' '}
            <SmartTextLink href="/auth/hirer-relationships">
              active relationship
            </SmartTextLink>{' '}
            with.
          </Text>

          <Text tone="secondary">
            You can use the{' '}
            <SmartTextLink href="https://developer.seek.com/schema/#/query/hiringOrganization">
              <InlineCode>hiringOrganization</InlineCode> query
            </SmartTextLink>{' '}
            to programmatically query a hirer from the SEEK API.
          </Text>
        </Stack>
      }
    >
      <HirerLookup />

      <HirerList />
    </PageWrapper>
  </AuthVerifier>
);
