
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';
import {
  IconCaution,
  IconDelete,
  IconInfo,
  IconSubCategory
} from 'braid-design-system';
import { BoardSelect } from './BoardSelect.tsx';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "phase-4-transitioning-hirers"
    }}>{`Phase 4: Transitioning hirers`}</h1>

    <Alert tone="info" mdxType="Alert">
  <Text mdxType="Text">
    This phase is only applicable to existing integrations with our legacy
    Jobstreet & Jobsdb APIs. You may skip this section if you are only
    integrated with the SEEK API.
  </Text>
    </Alert>
    <p>{`Once your integration is feature complete,
firm up a release plan with SEEK’s support team in accordance with the timeline:`}</p>
    <img alt="" data-scoobie-style="none" src={require('../../../../mermaid/.e2ef8689fe8cbac3f022085c2119d558e48de135.mmd.svg')} title="" />
    <h2 {...{
      "id": "release-to-australia--new-zealand-hirers"
    }}>{`Release to Australia & New Zealand hirers`}</h2>
    <p>{`Hirers that use your SEEK API integration in Australia & New Zealand can be seamlessly upgraded to an APAC feature set.
They will be able to post across `}<a parentName="p" {...{
        "href": "/introduction#multi-market-support"
      }}>{`SEEK’s APAC markets`}</a>{` and have their job ads surfaced on local Jobstreet & Jobsdb boards for greater reach.`}</p>
    <p>{`If you have a continuous delivery practice and prefer to minimise big bang deployments,
you may incrementally release revisions to these hirers starting from August 2023.
Keep in mind that job ads posted via the SEEK API will not be syndicated to Jobstreet & Jobsdb markets until their respective consolidation dates.`}</p>
    <p>{`Alternatively, you may bundle release activities for Australia & New Zealand together with the Jobstreet & Jobsdb hirer transition in January 2024.`}</p>
    <h2 {...{
      "id": "understand-the-jobstreet-bridge"
    }}>{`Understand the Jobstreet bridge`}</h2>
    <p>{`SEEK will consolidate the Jobstreet platform in Q4 2023, before hirers are transitioned to the SEEK API.
We will operate a bridge system in the interim months to ensure service continuity for legacy APOS and Adloader integrations.
Jobstreet hirers will continue to post job ads from your software during this period,
but may observe degradations where feature sets differ between the platforms:`}</p>

    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p"><IconSubCategory mdxType="IconSubCategory" /></strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Feature`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Summary`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconInfo tone="info" mdxType="IconInfo" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Ad budgets`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Job ads are posted even if they are insufficiently covered by an ad budget. Hirers will be notified when their budgets run out, will be invoiced the remaining balance, and can see inline payment summaries once transitioned to the SEEK API.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconInfo tone="info" mdxType="IconInfo" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Ad selection`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Pricing is variable. Hirers can see inline pricing once transitioned to the SEEK API.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconInfo tone="info" mdxType="IconInfo" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Employment type`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is mandatory. Jobs ads without an employment type will default to full time.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconInfo tone="info" mdxType="IconInfo" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Job description`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is truncated if it exceeds the character limit.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconInfo tone="info" mdxType="IconInfo" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Job title`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is truncated if it exceeds the character limit.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconInfo tone="info" mdxType="IconInfo" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Search summary`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is new to Jobstreet and will be inferred from the job description.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconInfo tone="info" mdxType="IconInfo" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Video URL`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is inferred from `}<inlineCode parentName="p">{`templateId`}</inlineCode>{`. Hirers can embed YouTube videos once transitioned to the SEEK API, and can contact SEEK to update Jobstreet templates in the meantime.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconCaution tone="caution" mdxType="IconCaution" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Job categories`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Category hierarchy is different. Job ads will use a best-effort match from SEEK’s AI-driven prediction service. Hirers will select from the new hierarchy once transitioned to the SEEK API.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconCaution tone="caution" mdxType="IconCaution" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Job description links`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Links are not clickable by default. Hirers can request account-level configuration to restore the feature or link out to display rich content on their external careers website.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconCaution tone="caution" mdxType="IconCaution" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Locations`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Multi-location is not supported. Job ads will use the first and most granular location. Hirers can post multiple job ads to reach multiple locations.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconCaution tone="caution" mdxType="IconCaution" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Private ads`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Option is not honoured on a per-job ad basis. Hirers can request account-level configuration to restore the feature.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconDelete tone="critical" mdxType="IconDelete" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Application emails`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is not supported. Hirers will be instructed to view applicants in the SEEK employer website or link out to an external careers website.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconDelete tone="critical" mdxType="IconDelete" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Recruitment Centre edits`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`SEEK employer site does not allow hirers to edit job ads posted via integration. Hirers will be instructed to contact SEEK or use your integration to edit their job ads.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconDelete tone="critical" mdxType="IconDelete" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Scheduled posting`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is not honoured. Job ads will be posted immediately.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconDelete tone="critical" mdxType="IconDelete" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Education level`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is not displayed on the job ad.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconDelete tone="critical" mdxType="IconDelete" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field of study`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is not displayed on the job ad.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconDelete tone="critical" mdxType="IconDelete" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Language`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is not displayed on the job ad.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconDelete tone="critical" mdxType="IconDelete" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Position level`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is not displayed on the job ad.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconDelete tone="critical" mdxType="IconDelete" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Skills`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is not displayed on the job ad.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconDelete tone="critical" mdxType="IconDelete" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Years of experience`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Field is not displayed on the job ad.`}</p></td>
        </tr>
      </tbody>
    </table>
    <p>{`SEEK will notify our mutual hirers of relevant experiential changes in advance.
Please work with us on a unified plan to avoid doubling up on communications.
We may recommend that certain minor and nuanced impacts are not proactively shared to avoid confusing hirers.`}</p>
    <h2 {...{
      "id": "transition-jobstreet--jobsdb-hirers"
    }}>{`Transition Jobstreet & Jobsdb hirers`}</h2>
    <p>{`SEEK will consolidate the Jobsdb platform in January 2024,
at which point you must transition your Jobstreet & Jobsdb hirers across to the SEEK API.
While the features `}<IconDelete tone="critical" mdxType="IconDelete" />{` retired above will remain unavailable,
your revised SEEK API integration can offer a superior experience in key areas:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection"
          }}>{`Ad selection`}</a>{` can be displayed with in-flow pricing, payment summaries and a variable product ladder that includes Premium-tier ads`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-3-apply-and-proactive-sourcing#apply"
          }}>{`Apply`}</a>{` products can be offered to streamline the candidate application process`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/branding"
          }}>{`Branding`}</a>{` options can be visually presented to the hirer`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/job-categories"
          }}>{`Job categories`}</a>{` can be interactively suggested and even preselected`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/locations"
          }}>{`Locations`}</a>{` can be interactively suggested and updated as our hierarchy evolves`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/previewing-a-job-ad"
          }}>{`Previews`}</a>{` can be made available at the click of a button`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/questionnaires"
          }}>{`Questionnaires`}</a>{` can be presented on SEEK’s Apply Form`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "on-seek"
    }}>{`On SEEK`}</h3>
    <p>{`SEEK’s support team will work with you to consolidate records of our mutual hirers and their `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}>{`relationships`}</a>{`.
SEEK API relationships can be configured ahead of time without affecting existing permissions through our legacy integrations,
ensuring service continuity until hirers transition to your SEEK API integration.
We will provide you with their `}<a parentName="p" {...{
        "href": "/migration-guides/seek-advertiser-ids"
      }}>{`hirer IDs`}</a>{` to configure in your software.`}</p>
    <p>{`This consolidation process may involve onboarding a hirer to a new product.
For example, a hirer that was screening their applicants in Jobstreet’s SiVA Recruitment Centre may request to be transitioned to `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au/partners/integrations/seek-optimised-apply/"
      }}>{`SEEK Optimised Apply`}</a>{`,
streamlining their recruitment process by allowing them to screen their applicants within your software.
They may be contacted by SEEK to consent to product terms of use before proceeding.`}</p>
    <p>{`After release, new hirers will follow our standard `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships#hirer-onboarding"
      }}>{`hirer onboarding`}</a>{` process.`}</p>
    <h3 {...{
      "id": "in-your-software"
    }}>{`In your software`}</h3>
    <p>{`When we consolidate the Jobsdb platform in January 2024,
retire your legacy integrations and transition Jobstreet & Jobsdb hirers to the SEEK API.
You should direct them towards SEEK on your job board selection page,
and you may use the following mockup as a reference:`}</p>

    <BoardSelect mdxType="BoardSelect" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;