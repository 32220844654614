import { Actions, ButtonLink, IconDownload } from 'braid-design-system';

import figma from './seek-profile-ui.fig';
import pdf from './seek-profile-ui.pdf';

export const UxFiles = () => (
  <Actions>
    <ButtonLink
      download="seek-profile-ui-v1.fig"
      href={figma}
      icon={<IconDownload />}
      variant="soft"
    >
      Figma file
    </ButtonLink>

    <ButtonLink
      download="seek-profile-ui-v1.pdf"
      href={pdf}
      icon={<IconDownload />}
      variant="soft"
    >
      PDF file
    </ButtonLink>
  </Actions>
);
