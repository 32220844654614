
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "phase-5-event-delivery"
    }}>{`Phase 5: Event delivery`}</h1>
    <p>{`The SEEK API’s `}<a parentName="p" {...{
        "href": "/events"
      }}>{`event`}</a>{` streams and webhook subscriptions replace `}<a parentName="p" {...{
        "href": "/migration-guides/job-posting-api/phase-4-managing-job-ads#get-all-advertisements"
      }}>{`polling for advertisements`}</a>{` in the Job Posting API.
This enables your software to be notified of important events in a job ad’s lifecycle:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad#positionprofileposted"
          }}><inlineCode parentName="a">{`PositionProfilePosted`}</inlineCode>{` event`}</a>{` is emitted when a job ad goes live.
In response you can `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/querying-a-job-ad#positionprofile"
          }}>{`request fields from the position profile`}</a>{` such as the URL of the job ad.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/closing-a-job-ad#positionprofileclosed"
          }}><inlineCode parentName="a">{`PositionProfileClosed`}</inlineCode>{` event`}</a>{` is emitted when a job ad is closed.
The Job Posting API refers to this as “expiry”.`}</p>
      </li>
    </ul>
    <p>{`For more information on setting up webhooks, see the `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhooks documentation`}</a>{`.`}</p>
    <h2 {...{
      "id": "exit-criteria"
    }}>{`Exit criteria`}</h2>
    <p>{`In order to finalise your job posting migration, you need to complete the following:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Criteria`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Events received`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Your software successfully receives `}<inlineCode parentName="p">{`PositionProfilePosted`}</inlineCode>{` events when SEEK hirers post job ads via the SEEK API.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`SEEK job ad link displayed`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Your software provides hirers with a link to view their posted job ad on the SEEK website.`}</p></td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;