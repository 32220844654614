import { maxLength, required } from '@seek/validators-js';
import { TextField } from 'braid-design-system';

import { useField } from './form';

interface Props {
  disabled: boolean;
  id: string;
  showLabel: boolean;
}

export const NameField = ({ disabled, id, showLabel }: Props) => {
  const name = useField<'name', { max: number; requiredLabel: string }>({
    id: 'name',
    validators: [required, maxLength],
    validatorProps: { max: 32, requiredLabel: 'a name' },
  });

  return (
    <TextField
      {...(showLabel ? { label: 'Name' } : { 'aria-label': 'Name' })}
      characterLimit={32}
      disabled={disabled}
      id={id}
      message={name.errorMessage}
      onBlur={name.onBlur}
      onChange={name.onChange}
      tone={name.valid ? undefined : 'critical'}
      value={name.value}
    />
  );
};
