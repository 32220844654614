
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "update-webhook-subscription-conflicts"
    }}>{`Update webhook subscription conflicts`}</h4>
    <p>{`We’ve introduced a new result format for `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/updateWebhookSubscriptionDeliveryConfiguration"
      }}><inlineCode parentName="a">{`updateWebhookSubscriptionDeliveryConfiguration`}</inlineCode></a>{`.`}</p>
    <p>{`Webhook subscriptions must be unique based on their `}<inlineCode parentName="p">{`schemeId`}</inlineCode>{`, `}<inlineCode parentName="p">{`eventTypeCode`}</inlineCode>{`, `}<inlineCode parentName="p">{`hirerId`}</inlineCode>{` & `}<inlineCode parentName="p">{`url`}</inlineCode>{`.
Attempting to create a duplicate webhook subscription with `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/createWebhookSubscription"
      }}><inlineCode parentName="a">{`createWebhookSubscription`}</inlineCode></a>{` would correctly return a `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#mutation-conflicts"
      }}>{`mutation conflict`}</a>{`.
However, we didn’t properly handle updating a webhook subscription’s `}<inlineCode parentName="p">{`url`}</inlineCode>{` to conflict with an existing subscription.`}</p>
    <p>{`💥 If your software uses `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/updateWebhookSubscriptionDeliveryConfiguration"
      }}><inlineCode parentName="a">{`updateWebhookSubscriptionDeliveryConfiguration`}</inlineCode></a>{`,
update the selection set to account for this change:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff",
        "metastring": "label=\"GraphQL\"",
        "label": "\"GraphQL\""
      }}>{`mutation($input: UpdateWebhookSubscriptionDeliveryConfigurationInput!) {
  updateWebhookSubscriptionDeliveryConfiguration(input: $input) {
-     webhookSubscription {
-       url
-       maxEventsPerAttempt
-     }
+     __typename
+     ... on UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success {
+       webhookSubscription {
+         url
+         maxEventsPerAttempt
+       }
+     }
+     ... on UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict {
+       conflictingWebhookSubscription {
+         id {
+           value
+         }
+       }
    }
  }
}
`}</code></pre>
    <p>{`Then, update your code to check for conflicts in one of two new ways:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (
   // Option 1: test for existence of conflict field
   response.data?.conflictingWebhookSubscription
   // Option 2: test name of the response type
   response.data?.__typename === 'CreateWebhookSubscriptionDeliveryConfigurationPayload_Conflict'
) {
  // Handle conflict here
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;