import gql from 'graphql-tag';

export const HIRING_ORGANIZATION_FRAGMENT = gql`
  fragment hiringOrganizationFields on HiringOrganization {
    id {
      value
    }
    name
    seekApiCapabilities {
      relationshipTypeCodes
      applicationMethodCodes
    }
    seekAnzAdvertiserId
  }
`;

export const HIRING_ORGANIZATION_ID_FRAGMENT = gql`
  fragment hiringOrganizationIdFields on HiringOrganization {
    id {
      value
    }
    name
    seekAnzAdvertiserId
  }
`;
