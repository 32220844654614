
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "migrating-from-graphql-playground-to-graphql-explorer"
    }}>{`Migrating from GraphQL Playground to GraphQL Explorer`}</h4>
    <p>{`We have added the ability to instantly run queries and mutations against the Playground environment from within the Developer Dashboard.
Previously, you would’ve had to manually `}<a parentName="p" {...{
        "href": "/graphql/playground#authentication"
      }}>{`request a partner token`}</a>{` and enter it on the GraphQL Playground.`}</p>
    <p>{`This new feature replaces `}<a parentName="p" {...{
        "href": "https://graphql.seek.com/graphql"
      }}>{`https://graphql.seek.com/graphql`}</a>{` with `}<a parentName="p" {...{
        "href": "https://manage.developer.seek.com/graphql-explorer"
      }}>{`https://manage.developer.seek.com/graphql-explorer`}</a>{` for querying the Playground environment.`}</p>
    <p>{`If you were using `}<a parentName="p" {...{
        "href": "https://graphql.seek.com/graphql"
      }}>{`https://graphql.seek.com/graphql`}</a>{` with live credentials, we recommend switching to Postman or another similar tool.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;