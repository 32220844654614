import { Box, Heading } from 'braid-design-system';
import type { ComponentProps, ReactNode } from 'react';

export const ContentHeader = ({
  children,
  icon,
}: {
  children?: ReactNode;
  icon?: ComponentProps<typeof Heading>['icon'];
}) => (
  <Box paddingY="large">
    {children === undefined ? null : (
      <Heading icon={icon} level="2">
        {children}
      </Heading>
    )}
  </Box>
);
