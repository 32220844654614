import {
  Box,
  Button,
  Hidden,
  HiddenVisually,
  IconSearch,
  Link,
  Text,
} from 'braid-design-system';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { LogoLink } from 'src/components/Logo';
import { SiteSearch } from 'src/components/SiteSearch/SiteSearch';

import * as styles from './Nav.css';

const ExpandingSiteSearch = ({
  expanded,
  setExpanded,
}: {
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}) =>
  expanded ? (
    <Box className={styles.absoluteFullWidthInput}>
      <Box flexGrow={1} paddingX="small">
        <SiteSearch autoFocus onBlur={() => setExpanded(false)} />
      </Box>
    </Box>
  ) : (
    <Button onClick={() => setExpanded(true)} tone="neutral">
      <IconSearch />
    </Button>
  );

export const Nav = ({
  setMenuOpened,
}: {
  setMenuOpened: Dispatch<SetStateAction<boolean>>;
}) => {
  const [searchExpanded, setSearchExpanded] = useState(false);

  const location = useLocation();

  return (
    <Box
      className={styles.nav}
      component="nav"
      background="brand"
      paddingX={['xxsmall', 'small']}
    >
      <Hidden below="wide">
        <Link href="/" className={styles.navLink}>
          <LogoLink />
        </Link>
      </Hidden>

      <Hidden above="desktop">
        <div
          className={styles.navLink}
          onClick={() => setMenuOpened((o) => !o)}
        >
          {searchExpanded ? (
            <HiddenVisually>
              <LogoLink showMenu />
            </HiddenVisually>
          ) : (
            <LogoLink showMenu />
          )}
        </div>
      </Hidden>

      <Hidden below="wide">
        <Box className={styles.navSection}>
          <Link href="/" className={styles.navLink}>
            <Text>
              <Box
                component="span"
                className={{
                  [styles.navLinkInner]: true,
                  [styles.navLinkActive]:
                    // TODO: Update this once the schema site is added in
                    !location.pathname.startsWith('/manage'),
                }}
              >
                Docs
              </Box>
            </Text>
          </Link>

          <Link
            href="https://manage.developer.seek.com" // TODO: Switch to /manage when we cutover
            className={styles.navLink}
          >
            <Text>
              <Box
                component="span"
                className={{
                  [styles.navLinkInner]: true,
                  [styles.navLinkActive]:
                    // TODO: Fix this so that the /authorize page is not considered active or we have a way to determine the page being redirected to
                    location.pathname.startsWith('/manage') ||
                    location.pathname === '/authorize',
                }}
              >
                Dashboard
              </Box>
            </Text>
          </Link>

          <Link
            href="https://developer.seek.com/schema"
            className={styles.navLink}
          >
            <Text>
              <span className={styles.navLinkInner}>Schema</span>
            </Text>
          </Link>

          <Link href="https://status.seek.com" className={styles.navLink}>
            <Text>
              <span className={styles.navLinkInner}>Status</span>
            </Text>
          </Link>
        </Box>
      </Hidden>

      <Box flexGrow={1} className={styles.desktopOnlySearch}>
        <SiteSearch />
      </Box>

      <Hidden above="tablet">
        <ExpandingSiteSearch
          expanded={searchExpanded}
          setExpanded={setSearchExpanded}
        />
      </Hidden>
    </Box>
  );
};
