
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "phase-1-developer-dashboard"
    }}>{`Phase 1: Developer Dashboard`}</h1>
    <p>{`It is important you first familiarise yourself with the `}<a parentName="p" {...{
        "href": "https://manage.developer.seek.com/"
      }}>{`Developer Dashboard`}</a>{`. The Dashboard is an administrative user interface that lets you observe and configure your SEEK API integration.`}</p>
    <p>{`It’s now a key component of our `}<a parentName="p" {...{
        "href": "/introduction#integration-process"
      }}>{`integration process`}</a>{` and can be used to:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Interact with `}<a parentName="p" {...{
            "href": "/use-cases/job-posting"
          }}>{`Job Posting`}</a>{` feature references`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Manage your `}<a parentName="p" {...{
            "href": "/events/webhooks"
          }}>{`webhook subscriptions`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Configure notification channels for release notes and system health`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Create and rotate your `}<a parentName="p" {...{
            "href": "/auth#client-credentials"
          }}>{`client credentials`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Run `}<a parentName="p" {...{
            "href": "/graphql/playground"
          }}>{`Playground`}</a>{` operations in the GraphQL Explorer`}</p>
      </li>
    </ul>
    <p>{`If you haven’t been onboarded to the Developer Dashboard, please submit a request to our `}<a parentName="p" {...{
        "href": "https://forms.office.com/pages/responsepage.aspx?id=Jg4UYrc30kKN-6TXzxvEJ0zmau3wnqRNvo4Y8qw-c8pUMkVISzZXR1hRQThDTVNYM09FVENNSkdHMS4u"
      }}>{`Integration Service Desk`}</a>{` to gain access.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;