import {
  Column,
  Columns,
  IconDelete,
  IconEdit,
  IconSecurity,
  MenuItem,
  OverflowMenu,
  Secondary,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';
import { useState } from 'react';
import { InlineCode } from 'scoobie';

import { GutterBox } from 'src/components/GutterBox/GutterBox';
import { NoWrapText } from 'src/components/NoWrapText/NoWrapText';
import { ScrollingXBox } from 'src/components/ScrollingBox/ScrollingBox';
import { TwoColumnGrid } from 'src/components/TwoColumnGrid/TwoColumnGrid';
import { usePermissions } from 'src/hooks/auth';
import type { WebhookDetailsQuery } from 'src/types/graphql';

import { DeleteDialog } from './components/DeleteDialog/DeleteDialog';
import { UpdateDeliveryDialog } from './components/UpdateDelivery/UpdateDeliveryDialog';
import { UpdateSigningDialog } from './components/UpdateSigning/UpdateSigningDialog';

const signingAlgorithmLabel = (code: string) => {
  if (code === 'None') {
    return (
      <>
        <IconSecurity tone="critical" /> Unsigned
      </>
    );
  }

  if (code === 'SeekHmacSha512') {
    return (
      <>
        <IconSecurity tone="positive" /> HMAC-SHA512
      </>
    );
  }

  return code;
};

const HirerCell = ({
  hirerId,
  hirer,
}: {
  hirerId: string;
  hirer: NonNullable<WebhookDetailsQuery['webhookSubscription']>['hirer'];
}) => (
  <NoWrapText size="small">
    {hirer ? (
      <TextLink href={`/hirers?id=${encodeURIComponent(hirerId)}`}>
        {hirer.name}
      </TextLink>
    ) : (
      <>
        <InlineCode>{hirerId}</InlineCode>
        <Secondary> (no active relationship)</Secondary>
      </>
    )}
  </NoWrapText>
);

interface SubscriptionDetailProps {
  subscription: NonNullable<WebhookDetailsQuery['webhookSubscription']>;
}

export const SubscriptionDetail = (props: SubscriptionDetailProps) => {
  const { subscription } = props;

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showUpdateDeliveryDialog, setShowUpdateDeliveryDialog] =
    useState(false);
  const [showUpdateSigningDialog, setShowUpdateSigningDialog] = useState(false);

  const { permissions } = usePermissions();

  const {
    id,
    createDateTime,
    eventTypeCode,
    maxEventsPerAttempt,
    schemeId,
    signingAlgorithmCode,
    updateDateTime,
    url,
    hirerId,
    hirer,
  } = subscription;

  return (
    <>
      <Stack dividers space="none">
        <GutterBox>
          <Columns alignY="center" space="gutter">
            <Column>
              {/* Icon slot is not required here as we're • inline • text */}
              <Text size="small">
                {signingAlgorithmLabel(signingAlgorithmCode)} •{' '}
                {maxEventsPerAttempt} event
                {maxEventsPerAttempt === 1 ? '' : 's'} per request •{' '}
                <InlineCode>{schemeId}</InlineCode>
              </Text>
            </Column>
            <Column width="content">
              {permissions.includes('mutate:webhooks') && (
                <OverflowMenu label="Modify subscription">
                  <MenuItem
                    icon={<IconEdit />}
                    onClick={() => setShowUpdateDeliveryDialog(true)}
                  >
                    Update delivery
                  </MenuItem>
                  <MenuItem
                    icon={<IconSecurity />}
                    onClick={() => setShowUpdateSigningDialog(true)}
                  >
                    Update signing
                  </MenuItem>
                  <MenuItem
                    icon={<IconDelete />}
                    onClick={() => setShowDeleteDialog(true)}
                    tone="critical"
                  >
                    Delete
                  </MenuItem>
                </OverflowMenu>
              )}
            </Column>
          </Columns>
        </GutterBox>

        <ScrollingXBox>
          <GutterBox>
            <TwoColumnGrid space="small">
              <Text align="right" size="small" weight="strong">
                ID
              </Text>
              <NoWrapText size="small">
                <InlineCode>{id.value}</InlineCode>
              </NoWrapText>

              <Text align="right" size="small" weight="strong">
                URL
              </Text>
              <NoWrapText size="small">{url}</NoWrapText>

              <Text align="right" size="small" weight="strong">
                Scope
              </Text>
              <NoWrapText size="small">
                {hirerId ? 'Hirer-specific ' : 'Partner-wide '}
                <InlineCode>{eventTypeCode}</InlineCode>
                {' events'}
              </NoWrapText>

              {hirerId && (
                <>
                  <Text align="right" size="small" weight="strong">
                    Hirer
                  </Text>
                  <HirerCell hirerId={hirerId.value} hirer={hirer} />
                </>
              )}

              <Text align="right" size="small" weight="strong">
                Created
              </Text>
              <NoWrapText size="small">
                {new Date(createDateTime).toLocaleString()}
              </NoWrapText>

              <Text align="right" size="small" weight="strong">
                Updated
              </Text>
              <NoWrapText size="small">
                {new Date(updateDateTime).toLocaleString()}
              </NoWrapText>
            </TwoColumnGrid>
          </GutterBox>
        </ScrollingXBox>
      </Stack>
      <UpdateDeliveryDialog
        subscription={subscription}
        show={showUpdateDeliveryDialog}
        closeDialog={() => setShowUpdateDeliveryDialog(false)}
      />

      <UpdateSigningDialog
        subscription={subscription}
        show={showUpdateSigningDialog}
        closeDialog={() => setShowUpdateSigningDialog(false)}
      />

      <DeleteDialog
        subscriptionId={subscription.id.value}
        show={showDeleteDialog}
        closeDialog={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
