import type { PartnerNoti } from '@seek/indie-api-types';
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useApi } from 'src/api';

type HistoricNoti = PartnerNoti.HistoricNoti;
interface ActionOptions {
  showLoading: boolean;
}

interface HistoryContext {
  notifications: HistoricNoti[];
  loading: boolean;
  refresh: (opts: ActionOptions) => Promise<void>;
  error: {
    failed: boolean;
    message: string;
  };
}

const HistoryContext = createContext<HistoryContext>({
  notifications: [],
  loading: false,
  refresh: async () => {},
  error: { failed: false, message: '' },
});

export const useNotiHistory = () => {
  const context = useContext(HistoryContext);

  if (!context) {
    throw new Error('useNotiHistory must be used within a NotiHistoryProvider');
  }

  return context;
};

interface NotiHistoryProviderProps {
  children: ReactNode;
}

export const NotiHistoryProvider = ({ children }: NotiHistoryProviderProps) => {
  const api = useApi();
  const [notifications, setNotifications] = useState<HistoricNoti[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const refresh = async ({ showLoading }: ActionOptions) => {
    if (showLoading) {
      setLoading(true);
    }

    try {
      const newNotifications = await api.notiHistory.get();
      setNotifications(newNotifications);
      setErrorMessage('');
    } catch (err) {
      setErrorMessage(String(err));
    }

    if (showLoading) {
      setLoading(false);
    }
  };

  const value = useMemo(
    () => ({
      notifications,
      loading,
      refresh,
      error: {
        failed: errorMessage.length > 0,
        message: errorMessage,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications, loading],
  );

  // Load on initial render
  useEffect(() => {
    refresh({ showLoading: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
};
