export type DescriptionCodeFilter = Record<DescriptionCode, boolean>;

export const DESCRIPTION_CODES = [
  'BadResponse',
  'InvalidUrl',
  'RequestTimeout',
  'Success',
] as const;

export type DescriptionCode = (typeof DESCRIPTION_CODES)[number];

const descriptionCodeSet = new Set<string>(DESCRIPTION_CODES);

export const isValidDescriptionCode = (
  input: string,
): input is DescriptionCode => descriptionCodeSet.has(input);

export const DISPLAY_LABEL_FOR_DESCRIPTION: Record<DescriptionCode, string> = {
  BadResponse: 'Bad Response',
  InvalidUrl: 'Invalid URL',
  RequestTimeout: 'Request Timeout',
  Success: 'Success',
};
