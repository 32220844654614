import {
  Alert,
  Loader,
  Stack,
  Text,
  useResponsiveValue,
} from 'braid-design-system';

import { GutterBox } from 'src/components/GutterBox/GutterBox';
import { PageGutter } from 'src/components/PageGutter';
import { SectionCard } from 'src/components/SectionCard/SectionCard';

import { useUsers } from '../../UsersContext';

import { UserRow } from './components/UserRow/UserRow';
import { UserRowSection } from './components/UserRowSection';

interface UsersListWrapperProps {
  children: JSX.Element;
  isMobile: boolean;
}

const UsersListWrapper = ({ children, isMobile }: UsersListWrapperProps) =>
  isMobile ? (
    <Stack dividers space="none">
      {children}
    </Stack>
  ) : (
    <SectionCard>
      <Stack dividers space="none">
        {children}
      </Stack>
    </SectionCard>
  );

export const UsersList = () => {
  const { users, loading, error } = useUsers();
  const responsiveValue = useResponsiveValue();
  const isMobile = responsiveValue({
    mobile: true,
    tablet: false,
  });

  if (loading) {
    return (
      <SectionCard>
        <GutterBox>
          <Loader />
        </GutterBox>
      </SectionCard>
    );
  }

  if (error) {
    return (
      <PageGutter>
        <Alert tone="critical">
          <Stack space="small">
            <Text>We couldn’t load the user list.</Text>
            <Text size="small">{error}</Text>
          </Stack>
        </Alert>
      </PageGutter>
    );
  }

  const verifiedUsers = users.filter(
    (user) => !user.impersonating && user.emailVerified,
  );

  const unverifiedUsers = users.filter(
    (user) => !user.impersonating && !user.emailVerified,
  );

  const impersonatingUsers = users.filter((user) => user.impersonating);

  const showVerifiedHeaderRow =
    verifiedUsers.length &&
    (unverifiedUsers.length || impersonatingUsers.length);

  const showUnverifiedHeaderRow = unverifiedUsers.length;

  const showImpersonationHeaderRow = impersonatingUsers.length;

  return (
    <UsersListWrapper isMobile={isMobile ?? false}>
      <>
        {showVerifiedHeaderRow ? (
          <UserRowSection
            background="positiveLight"
            count={`${verifiedUsers.length}/${users.length}`}
          >
            Verified
          </UserRowSection>
        ) : null}

        {verifiedUsers.map((user) => (
          <UserRow
            key={user.emailAddress}
            user={user}
            type="verified"
            isMobile={isMobile ?? false}
          />
        ))}

        {showUnverifiedHeaderRow ? (
          <UserRowSection
            background="cautionLight"
            count={`${unverifiedUsers.length}/${users.length}`}
          >
            Unverified
          </UserRowSection>
        ) : null}

        {unverifiedUsers.map((user) => (
          <UserRow
            key={user.emailAddress}
            user={user}
            type="unverified"
            isMobile={isMobile ?? false}
          />
        ))}

        {showImpersonationHeaderRow ? (
          <UserRowSection
            background="promoteLight"
            count={`${impersonatingUsers.length}/${users.length}`}
          >
            Impersonating
          </UserRowSection>
        ) : null}

        {impersonatingUsers.map((user) => (
          <UserRow
            key={user.emailAddress}
            user={user}
            type="impersonating"
            isMobile={isMobile ?? false}
          />
        ))}
      </>
    </UsersListWrapper>
  );
};
