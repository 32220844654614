
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "exporting-attachments"
    }}>{`Exporting attachments`}</h1>
    <p>{`When a candidate applies using SEEK’s Apply Form they’ll be prompted to attach a cover letter and a resume.
They may choose an existing document, upload a new one, or decide to omit the attachment entirely.`}</p>
    <p>{`A SEEK hirer can be configured to prompt for a third attachment referred to as “selection criteria”.
This allows the hirer to request an additional document relevant to the job’s industry.`}</p>
    <p>{`Partners can download attachment files associated with a particular candidate application.`}</p>
    <h2 {...{
      "id": "before-you-begin"
    }}>{`Before you begin`}</h2>
    <p>{`Before you start with attachment queries, you will need to `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`request a partner token`}</a>{`.`}</p>
    <h2 {...{
      "id": "operations"
    }}>{`Operations`}</h2>
    <p>{`Use the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/candidateProfile"
      }}>{`GraphQL `}<inlineCode parentName="a">{`candidateProfile`}</inlineCode>{` query`}</a>{` to list candidate application attachments.
You should combine this with the query you use to retrieve `}<a parentName="p" {...{
        "href": "/use-cases/application-export/candidate-profiles"
      }}>{`candidate profiles`}</a>{` and `}<a parentName="p" {...{
        "href": "/use-cases/application-export/questionnaire-submissions"
      }}>{`questionnaire submissions`}</a>{`.`}</p>
    <h3 {...{
      "id": "candidateprofile"
    }}>{`candidateProfile`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($id: String!) {\\n  candidateProfile(id: $id) {\\n    attachments {\\n      descriptions\\n      seekRoleCode\\n      url\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":22,\"column\":1,\"offset\":942},\"end\":{\"line\":32,\"column\":4,\"offset\":1087},\"indent\":[1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"id\\\": \\\"seekAnzPublicTest:candidateProfile:apply:4QM5fWQbdekL9gPtPZrzex\\\"\\n}\",\"position\":{\"start\":{\"line\":34,\"column\":1,\"offset\":1089},\"end\":{\"line\":38,\"column\":4,\"offset\":1178},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"jsonc\",\"meta\":null,\"value\":\"{\\n  \\\"candidateProfile\\\": {\\n    \\\"attachments\\\": [\\n      {\\n        // Human readable description of the attachment type\\n        \\\"descriptions\\\": [\\\"Candidate cover letter\\\"],\\n\\n        // `CoverLetter`, `Resume`, `SelectionCriteria`, or null for miscellaneous documents\\n        \\\"seekRoleCode\\\": \\\"CoverLetter\\\",\\n\\n        // Download URL of the attachment (see below)\\n        \\\"url\\\": \\\"https://graphql.seek.com/anzPublicTest/applications/4QM5fWQbdekL9gPtPZrzex/attachments/7xF8zuiaFdnFo84DBSZVEjiu9EoWZasQRAr5YSYeHvGj\\\"\\n      },\\n      {\\n        \\\"descriptions\\\": [\\\"Candidate resume\\\"],\\n        \\\"seekRoleCode\\\": \\\"Resume\\\",\\n        \\\"url\\\": \\\"https://graphql.seek.com/anzPublicTest/applications/4QM5fWQbdekL9gPtPZrzex/attachments/7wS9uaWkwNrz4L5EUjNoBpVHCo61gaTSTdqiEeqxPVAk\\\"\\n      }\\n    ]\\n  }\\n}\",\"position\":{\"start\":{\"line\":40,\"column\":1,\"offset\":1180},\"end\":{\"line\":62,\"column\":4,\"offset\":1963},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($id:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "candidateProfile(id:": true,
        "$id)": true,
        "attachments": true,
        "descriptions\\n": true,
        "seekRoleCode\\n": true,
        "url\\n": true,
        "}\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":22,\"column\":1,\"offset\":942},\"end\":{\"line\":32,\"column\":4,\"offset\":1087},\"indent\":[1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"id\\\":": true,
        "\\\"seekAnzPublicTest:candidateProfile:apply:4QM5fWQbdekL9gPtPZrzex\\\"\\n}\",\"position\":{\"start\":{\"line\":34,\"column\":1,\"offset\":1089},\"end\":{\"line\":38,\"column\":4,\"offset\":1178},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"jsonc\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"candidateProfile\\\":": true,
        "\\\"attachments\\\":": true,
        "[\\n": true,
        "//": true,
        "Human": true,
        "readable": true,
        "description": true,
        "of": true,
        "the": true,
        "attachment": true,
        "type\\n": true,
        "\\\"descriptions\\\":": true,
        "[\\\"Candidate": true,
        "cover": true,
        "letter\\\"],\\n\\n": true,
        "`CoverLetter`,": true,
        "`Resume`,": true,
        "`SelectionCriteria`,": true,
        "or": true,
        "null": true,
        "for": true,
        "miscellaneous": true,
        "documents\\n": true,
        "\\\"seekRoleCode\\\":": true,
        "\\\"CoverLetter\\\",\\n\\n": true,
        "Download": true,
        "URL": true,
        "(see": true,
        "below)\\n": true,
        "\\\"url\\\":": true,
        "\\\"https://graphql.seek.com/anzPublicTest/applications/4QM5fWQbdekL9gPtPZrzex/attachments/7xF8zuiaFdnFo84DBSZVEjiu9EoWZasQRAr5YSYeHvGj\\\"\\n": true,
        "},\\n": true,
        "resume\\\"],\\n": true,
        "\\\"Resume\\\",\\n": true,
        "\\\"https://graphql.seek.com/anzPublicTest/applications/4QM5fWQbdekL9gPtPZrzex/attachments/7wS9uaWkwNrz4L5EUjNoBpVHCo61gaTSTdqiEeqxPVAk\\\"\\n": true,
        "]\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":40,\"column\":1,\"offset\":1180},\"end\":{\"line\":62,\"column\":4,\"offset\":1963},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query ($id: String!) {
  candidateProfile(id: $id) {
    attachments {
      descriptions
      seekRoleCode
      url
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "downloading-attachment-files"
    }}>{`Downloading attachment files`}</h2>
    <p>{`Because GraphQL does not efficiently support binary data, the attachment itself is provided over HTTPS.
Each attachment has a unique download URL provided in the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/Attachment/field/url"
      }}><inlineCode parentName="a">{`url`}</inlineCode>{` field`}</a>{` of the GraphQL response.`}</p>
    <p>{`Your software can download attachments `}<a parentName="p" {...{
        "href": "#on-demand"
      }}>{`on demand`}</a>{` or `}<a parentName="p" {...{
        "href": "#at-time-of-candidate-application"
      }}>{`at time of candidate application`}</a>{`.`}</p>
    <h3 {...{
      "id": "on-demand"
    }}>{`On demand`}</h3>
    <p>{`You can download each attachment on demand when requested by a hirer reviewing the candidate application.
This avoids having to store large and potentially sensitive documents within your software.`}</p>
    <p>{`The attachment can be downloaded directly from the hirer’s browser using a `}<a parentName="p" {...{
        "href": "/auth/browser-tokens"
      }}>{`browser token`}</a>{` with the `}<inlineCode parentName="p">{`download:candidate-profile-attachments`}</inlineCode>{` scope.
The SEEK API will automatically validate that the hirer is allowed to access the requested attachment.`}</p>
    <p>{`Otherwise, you can proxy the download through your server using a `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`partner token`}</a>{`.
Your software will need to validate that the user is allowed to access requested attachment.`}</p>
    <p>{`An attachment will remain for 180 days after the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads#job-ad-lifecycle"
      }}>{`close date`}</a>{` of its associated job ad.
If your software needs to access attachments for longer than 180 days you should download them `}<a parentName="p" {...{
        "href": "#at-time-of-candidate-application"
      }}>{`at the time of candidate application`}</a>{`.`}</p>
    <h3 {...{
      "id": "at-time-of-candidate-application"
    }}>{`At time of candidate application`}</h3>
    <p>{`If on-demand downloads aren’t feasible, you can download attachments when you receive a `}<inlineCode parentName="p">{`CandidateApplicationCreated`}</inlineCode>{` event.
This would be needed if your software performed resume parsing on incoming candidate applications, for example.`}</p>
    <p>{`This can reuse the same partner token you used to query the candidate application from GraphQL.`}</p>
    <h2 {...{
      "id": "attachment-file-metadata"
    }}>{`Attachment file metadata`}</h2>
    <p>{`Attachment files have additional metadata returned in standard HTTP headers.
These headers can be parsed by your software or passed to the hirer’s browser when they download the attachment.`}</p>
    <p>{`You can use the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/HEAD"
      }}>{`HTTP `}<inlineCode parentName="a">{`HEAD`}</inlineCode>{` method`}</a>{` to retrieve an attachment’s metadata without downloading its contents.
This is useful to display the attachment’s metadata before downloading it `}<a parentName="p" {...{
        "href": "#on-demand"
      }}>{`on demand`}</a>{`.`}</p>
    <h3 {...{
      "id": "content-disposition"
    }}><inlineCode parentName="h3">{`Content-Disposition`}</inlineCode></h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition"
      }}><inlineCode parentName="a">{`Content-Disposition`}</inlineCode></a>{` header contains the attachment’s original filename encoded using `}<a parentName="p" {...{
        "href": "https://datatracker.ietf.org/doc/html/rfc6266"
      }}>{`RFC 6266`}</a>{`.`}</p>
    <p>{`We recommend parsing the filename using a library or framework that supports `}<a parentName="p" {...{
        "href": "https://datatracker.ietf.org/doc/html/rfc6266"
      }}>{`RFC 6266`}</a>{`.
This ensures your software is robust against filenames containing spaces, quotes or other special characters.`}</p>
    <h3 {...{
      "id": "content-length"
    }}><inlineCode parentName="h3">{`Content-Length`}</inlineCode></h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Length"
      }}><inlineCode parentName="a">{`Content-Length`}</inlineCode></a>{` header indicates the size of the attachment in bytes.
This can be retrieved using the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/HEAD"
      }}>{`HTTP `}<inlineCode parentName="a">{`HEAD`}</inlineCode>{` method`}</a>{` to display the attachment’s size before downloading it `}<a parentName="p" {...{
        "href": "#on-demand"
      }}>{`on demand`}</a>{`.`}</p>
    <h3 {...{
      "id": "content-type"
    }}><inlineCode parentName="h3">{`Content-Type`}</inlineCode></h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Type"
      }}><inlineCode parentName="a">{`Content-Type`}</inlineCode></a>{` header indicates the file format of the attachment.
Alternatively, you can extract the attachment’s file extension from its `}<a parentName="p" {...{
        "href": "#content-disposition"
      }}><inlineCode parentName="a">{`Content-Disposition`}</inlineCode></a>{`.`}</p>
    <p>{`The SEEK API does not guarantee a fixed set of file formats; they are subject to change without notice.
Avoid attempts to manually parse the `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` or `}<inlineCode parentName="p">{`Content-Disposition`}</inlineCode>{` headers.
Instead, propagate these headers for the end user client to handle.`}</p>
    <p>{`Common file formats for attachments are:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Example `}<inlineCode parentName="strong">{`Content-Type`}</inlineCode></strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`File format`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Example extension`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`application/msword`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Microsoft Word 97-2004`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`.doc`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`application/vnd.openxmlformats-officedocument.wordprocessingml.document`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Microsoft Word (OpenXML)`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`.docx`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`application/rtf`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Rich Text Format (RTF)`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`.rtf`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`application/pdf`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Portable Document Format (PDF)`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`.pdf`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`text/plain`}</inlineCode>{` or `}<inlineCode parentName="p">{`text/plain; charset=utf8`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Plain text`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`.txt`}</inlineCode></p></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "last-modified"
    }}><inlineCode parentName="h3">{`Last-Modified`}</inlineCode></h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Last-Modified"
      }}><inlineCode parentName="a">{`Last-Modified`}</inlineCode></a>{` header contains the date the attachment was uploaded or generated.`}</p>
    <h2 {...{
      "id": "security"
    }}>{`Security`}</h2>
    <p>{`SEEK processes attachments to reduce the risk to hirers reviewing candidate applications:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Attachments are scanned for viruses before the candidate application is made visible to partners.
Any files containing viruses are removed from the candidate application.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Attachments may be modified to remove dangerous content such as macros or phishing links.`}</p>
      </li>
    </ul>
    <p>{`The precise details of SEEK’s attachment processing are proprietary and subject to change.
SEEK continuously improves its processes in response to new threats.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;