import { datadogRum } from '@datadog/browser-rum';
import React, { useCallback, useContext, useEffect } from 'react';

import type { Environment } from 'src/hooks/environment';

interface DatadogContext {
  setIntegrationPartner: (uuid: unknown) => void;
  setUser: (id: string) => void;
}

const ctx = React.createContext<DatadogContext>({
  setIntegrationPartner: () => {},
  setUser: () => {},
});

interface DatadogProps {
  children: JSX.Element;
  env: Environment;
  version: string;
}

export const DatadogProvider = ({ children, env, version }: DatadogProps) => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const branchVersion = searchParams.get('v');
    if (env !== 'local') {
      // https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
      datadogRum.init({
        applicationId: 'e4ea3219-c84e-4e7b-9c5a-4d98bbe3d62f',
        clientToken: 'pub9a1d346b97a807f0d51051bede392c4c',
        site: 'datadoghq.com',
        service: 'developer-site',
        env,
        version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        silentMultipleInit: true,
        defaultPrivacyLevel: 'mask',
        workerUrl: `/static/datadog-rum-worker.js${
          branchVersion ? `?v=${branchVersion}` : ''
        }`,
      });
    }
  }, [env, version]);

  const setIntegrationPartner = useCallback(
    (uuid: unknown) => {
      if (env !== 'local') {
        datadogRum.setGlobalContextProperty('integrationPartnerUuid', uuid);
      }
    },
    [env],
  );

  const setUser = useCallback(
    (id: string) => {
      if (env !== 'local') {
        datadogRum.setUser({ id });
      }
    },
    [env],
  );

  return (
    <ctx.Provider value={{ setIntegrationPartner, setUser }}>
      {children}
    </ctx.Provider>
  );
};

export const useDatadog = () => useContext(ctx);
