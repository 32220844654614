import { Heading, Stack, TextField } from 'braid-design-system';
import { useState } from 'react';
import { SmartTextLink } from 'scoobie';
import { useDebounce } from 'use-debounce';
import { JobCategorySelect } from 'wingman-fe';

import { CopyableText } from 'src/components/CopyableText/CopyableText';
import { useCriticalErrorsOnlyTrackingApolloClient } from 'src/hooks/apollo';
import { useEnvironmentConfig } from 'src/hooks/environment';

export const JobCategorySelectSection = () => {
  const { seekAnzSchemeId } = useEnvironmentConfig();

  const [oid, setOid] = useState<string>();
  const [rawLocation, setLocation] = useState('');
  const [positionLocation] = useDebounce(rawLocation.trim(), 1000);
  const client = useCriticalErrorsOnlyTrackingApolloClient();

  return (
    <Stack space="large">
      <Heading level="4">
        <SmartTextLink href="https://developer.seek.com/use-cases/job-posting/job-categories#option-2-provide-dropdown-fields">
          Job category select
        </SmartTextLink>
      </Heading>

      <TextField
        onChange={(event) => setLocation(event.currentTarget.value)}
        id="seek-job-category-select-position-location"
        label="Location ID"
        value={rawLocation}
      />
      <JobCategorySelect
        id="seek-job-category-select"
        onSelect={(category) => setOid(category.id.value)}
        schemeId={seekAnzSchemeId}
        positionProfile={
          positionLocation
            ? {
                positionLocation: [positionLocation],
              }
            : undefined
        }
        client={client}
      />

      {oid ? <CopyableText inlineCode>{oid}</CopyableText> : null}
    </Stack>
  );
};
