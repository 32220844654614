import { Strong } from 'braid-design-system';
import ReactMarkdown from 'react-markdown';

import { AutoLinkedInlineCode } from 'src/components/AutoLinkedInlineCode/AutoLinkedInlineCode';

interface Props {
  children: string;
}

/**
 * Renders the subset of Markdown supported in noti bodies
 */
export const NotiBodyMarkdown = ({ children }: Props) => (
  // TODO: Unify this with what Indoctrinate does once `remark@6` &
  // `remark-mdx@2` have settled down. Until then we'd need to depend on a bunch
  // of outdated packages to glue everything together.
  <ReactMarkdown
    skipHtml={true}
    components={{
      code: AutoLinkedInlineCode,
      strong: Strong,
    }}
  >
    {children}
  </ReactMarkdown>
);
