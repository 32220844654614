import { Box, Column, Columns, Inline, Text } from 'braid-design-system';
import type { ComponentProps } from 'react';
import { SmartTextLink } from 'scoobie';

import * as styles from './Footer.css';

interface Props {
  paddingX?: ComponentProps<typeof Box>['paddingX'];
}

export const Footer = ({ paddingX }: Props) => (
  <Box
    className={styles.borderTop}
    component="footer"
    paddingX={paddingX}
    paddingY="xlarge"
  >
    <Text weight="medium">
      <Columns alignY="center" collapseBelow="tablet" space="medium">
        <Column width="content">© SEEK Group</Column>

        <Column>
          <Inline align={['left', 'right']} space="medium">
            <SmartTextLink href="https://talent.seek.com.au/partners/partner-with-seek/">
              Partner Hub
            </SmartTextLink>

            <SmartTextLink href="https://talent.seek.com.au/partners/terms-of-use/">
              Terms of Use
            </SmartTextLink>
          </Inline>
        </Column>
      </Columns>
    </Text>
  </Box>
);
