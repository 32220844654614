import base58 from 'bs58';
import { parse, print, stripIgnoredCharacters } from 'graphql';

export const base58Decode = (input: string) =>
  new TextDecoder().decode(base58.decode(input));

export const base58Encode = (input: string) =>
  base58.encode(new TextEncoder().encode(input));

export const decodeGraphQLQuery = (input: string | null | undefined) => {
  let query = input;

  if (!query) {
    return query;
  }

  // The query may not be encoded.
  // Pass it through if decoding fails.
  try {
    query = base58Decode(query);
  } catch {}

  // GraphQL's formatting functions remove comments.
  // Only prettify if the query has no comments.
  if (!query.includes('#')) {
    try {
      query = prettifyGraphQLQuery(query);
    } catch {}
  }

  return query;
};

export const encodeGraphQLQuery = (input: string | undefined) => {
  let query = input;

  if (!query) {
    return query;
  }

  // GraphQL's formatting functions remove comments.
  // Only minify if the query has no comments.
  if (!query.includes('#')) {
    try {
      // This can e.g. strip a redundant `query` prefix.
      query = prettifyGraphQLQuery(query);
      query = stripIgnoredCharacters(query);
    } catch {}
  }

  return base58Encode(query);
};

const prettifyGraphQLQuery = (input: string) => print(parse(input));
