import { Box } from 'braid-design-system';
import type { ReactNode } from 'react';

import * as styles from './Fade.css';

interface Props {
  children: ReactNode;
}

export const Fade = ({ children }: Props) => (
  <Box className={styles.fade} transition="touchable">
    {children}
  </Box>
);
