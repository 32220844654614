import { Badge } from 'braid-design-system';

import {
  DISPLAY_LABEL_FOR_DESCRIPTION,
  isValidDescriptionCode,
} from './DescriptionCode';

const statusBadgeTones = {
  BadResponse: 'critical',
  InvalidUrl: 'critical',
  RequestTimeout: 'caution',
  Success: 'positive',
} as const;

interface RequestStatusBadgeProps {
  description: string;
  code: number;
}

export const RequestStatusBadge = ({
  description,
  code,
}: RequestStatusBadgeProps) => {
  if (!isValidDescriptionCode(description)) {
    return (
      <Badge bleedY tone="neutral">
        Unknown status
      </Badge>
    );
  }

  return (
    <Badge
      bleedY
      tone={statusBadgeTones[description]}
    >{`${code} ${DISPLAY_LABEL_FOR_DESCRIPTION[description]}`}</Badge>
  );
};
