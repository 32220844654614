import { Heading, Stack } from 'braid-design-system';
import { SmartTextLink } from 'scoobie';
import { LocationLookup } from 'wingman-fe';

import { useEnvironmentConfig } from 'src/hooks/environment';

export const LocationLookupSection = () => {
  const { seekAnzSchemeId } = useEnvironmentConfig();

  return (
    <Stack space="large">
      <Heading level="4">
        <SmartTextLink href="https://developer.seek.com/use-cases/job-posting/locations#location">
          Location lookup
        </SmartTextLink>
      </Heading>

      <LocationLookup schemeId={seekAnzSchemeId} />
    </Stack>
  );
};
