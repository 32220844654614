
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "content-localisation"
    }}>{`Content localisation`}</h1>
    <p>{`The SEEK API now supports language localisation in select GraphQL operations and SEEK-hosted panels.
Propagate the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Language"
      }}><inlineCode parentName="a">{`Accept-Language`}</inlineCode></a>{` header in a `}<a parentName="p" {...{
        "href": "/graphql#requests-and-responses"
      }}>{`GraphQL request`}</a>{` to retrieve translated content and error messages for display to the end user.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/graphql/in-practice#content-localisation"
      }}>{`content localisation`}</a>{` section in our GraphQL documentation for more information.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;