import { Stack, TextField } from 'braid-design-system';
import { useState } from 'react';
import { CodeBlock, InlineCode } from 'scoobie';

import { BorderCard } from 'src/components/BorderCard';

const DEFAULT_INPUT = '👩‍👩‍👧‍👧';
const DEFAULT_INPUT_UTF_8_BYTE_LENGTH = 25;

const pluralise = (count: number, subject: string) =>
  `${count} ${subject}${count === 1 ? '' : 's'}`;

const utf8ByteLength = (input: string) =>
  global.TextEncoder
    ? new TextEncoder().encode(input).length
    : DEFAULT_INPUT_UTF_8_BYTE_LENGTH;

export const StringLengths = () => {
  const [input, setInput] = useState(DEFAULT_INPUT);

  const [utf8, utf16, unicode] = [
    utf8ByteLength(input),
    input.length,
    Array.from(input).length,
  ];

  return (
    <BorderCard>
      <Stack space="medium">
        <TextField
          id="string-lengths-input"
          label={
            <>
              <InlineCode>String</InlineCode> input field
            </>
          }
          onChange={(event) => setInput(event.currentTarget.value)}
          type="text"
          value={input}
        />

        <CodeBlock language="javascript">
          {`
const input = ${JSON.stringify(input)};

new TextEncoder().encode(input).length;
// ${pluralise(utf8, 'byte')} in UTF-8 encoding

input.length;
// ${pluralise(utf16, 'UTF-16 code unit')}

Array.from(input).length;
// ${pluralise(unicode, 'character')}
`.trim()}
        </CodeBlock>
      </Stack>
    </BorderCard>
  );
};
