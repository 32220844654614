import { TextLink } from 'braid-design-system';
import type { ReactNode } from 'react';
import { InlineCode } from 'scoobie';

import {
  type EnvironmentConfig,
  useEnvironmentConfig,
} from 'src/hooks/environment';

/**
 * Tries to match an inline code string with a useful internal page
 */
const hrefForInlineCodeString = (
  code: string,
  seekAnzSchemeId: EnvironmentConfig['seekAnzSchemeId'],
): string | undefined => {
  if (code.startsWith(`${seekAnzSchemeId}:organization:seek:`)) {
    // We're a hirer
    return `/manage/hirers?id=${encodeURIComponent(code)}`;
  }

  if (code.startsWith(`${seekAnzSchemeId}:positionProfile:jobAd:`)) {
    // We're a job ad
    return `/manage/job-ads?id=${encodeURIComponent(code)}`;
  }

  if (code.startsWith(`${seekAnzSchemeId}:webhookSubscription:events:`)) {
    // We're a webhook subscription
    return `/manage/webhooks/${encodeURIComponent(code)}`;
  }

  return;
};

/**
 * Tries to convert a `ReactNode` to a string
 *
 * This doesn't try very hard but it's sufficient for `NotiBodyMarkdown`.
 */
const reactNodeToString = (node: ReactNode): string | undefined => {
  if (typeof node === 'string') {
    return node;
  }

  if (Array.isArray(node) && node.length === 1 && typeof node[0] === 'string') {
    return node[0];
  }

  return;
};

interface Props {
  children: ReactNode;
}

/**
 * Wrapper around `InlineCode` that will automatically link certain types of
 * OIDs to their relevant pages
 */
export const AutoLinkedInlineCode = ({ children }: Props) => {
  const { seekAnzSchemeId } = useEnvironmentConfig();

  const code = reactNodeToString(children);
  const href = code && hrefForInlineCodeString(code, seekAnzSchemeId);

  if (!href) {
    // Nothing to link
    return <InlineCode>{children}</InlineCode>;
  }

  return (
    <TextLink href={href}>
      <InlineCode>{children}</InlineCode>
    </TextLink>
  );
};
