import { IconNewWindow, Text, TextLink } from 'braid-design-system';

interface SlackConfigProps {
  id: string;
  name: string;
}

export const SlackConfig = ({ id, name }: SlackConfigProps) => (
  <Text>
    <TextLink
      href={`https://slack.com/app_redirect?channel=${encodeURIComponent(id)}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      #{name} <IconNewWindow />
    </TextLink>
  </Text>
);
