import "src/styles.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles.css.ts.vanilla.css\",\"source\":\"KiB7CiAgc2Nyb2xsLW1hcmdpbi10b3A6IGNhbGMoKHZhcigtLWg0eWhteGEpICogMTUpICsgdmFyKC0taDR5aG14MykpOwp9CmJvZHkgewogIG1pbi13aWR0aDogMzIwcHg7Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/pages/manage/userGuide/styles.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/pages/manage/userGuide/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VU246bMBB9z1eMVqoEWzkFkvTivPRXDEyCu8ZGxgTYKv9eYcjuQKLdbNU+enzmzJzjGa/bX4htG8HvFUBlaumk0RwOssN8vwIoUB4LxyETKgviKDoVwCA4CRswVmz7ouxECI8Q78JwgDtTTdhbkAFhCV8wQ8VxCJ9hEUt8bAl7BBaHIXyBJJwOS5QvybZjV63MXXGjr7+s6IVaoS9meUkQrTc1oKiRmcbtV+fVerQ29taWUrOLl95Ggth4BGUshT1KzW4Sw+yWQ1R1hGvruXJZV0r0HLTRSG53/jYV2dPRmkbnHKjOpAkplhfmhHaRwTKjjF3klTTvG0/xYCz6xMxoh9pxeHjY075SZbKnIaLwcNHwMhvT6WIXLdV781Njc7TMilw29RyQjYD3+6XTLtLaqMbhEH1mUufYcYiJpu9ezGDHQZmW9RxE48yoyGI2jYFTNIXaMKqMd7On+jE91QuBcpZeU4J3BDXhrPVkoPlZYi4F1JlF1CB0DsEwhNMuxEkUVV3oyWeDevMX+PA/cM9P8Pre4+k/bOkdewpwfrUgecuCexSRFf80U0VTnicw3UM15B0t9ouOtlNH15vzkR9j+Wf8S49Js1+v7LOohJMnnL33VfXdUIgGNhfq8x97mOU6oAYAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var CloseUserGuideButtonStyling = 'wjeeww6';
export var CloseUserGuideIconStyling = 'wjeeww5';
export var ClosedUserGuideSidebar = 'wjeeww0';
export var ContentInnerContainer = 'wjeeww9 wjeeww7';
export var ContentOuterContainer = 'wjeeww8 wjeeww7';
export var MainConstricted = 'wjeeww4';
export var MainRelaxed = 'wjeeww3';
export var OpenUserGuideSidebar = 'wjeeww1';
export var UserGuideVerticalDivider = 'wjeeww2';