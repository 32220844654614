import { gql } from '@apollo/client';

export const GET_WEBHOOK_SUBSCRIPTION_REPLAYS = gql`
  query webhookSubscriptionReplays(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $statusCode: String
    $subscriptionId: String!
  ) {
    webhookSubscription(id: $subscriptionId) {
      id {
        value
      }
      webhookSubscriptionReplays(
        after: $after
        before: $before
        first: $first
        last: $last
        filter: { statusCode: $statusCode }
      ) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          cursor
          node {
            id {
              value
            }
            createDateTime
            updateDateTime
            statusCode
            request {
              typeCode
              ... on WebhookSubscriptionReplayByRangeRequest {
                hirer {
                  id {
                    value
                  }
                  name
                }
                afterDateTime
                beforeDateTime
                replayDeliveredEventsIndicator
              }
              ... on WebhookSubscriptionReplayByIdRequest {
                eventIds
              }
            }
          }
        }
      }
    }
  }
`;
