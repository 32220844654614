import { Alert, Box, PageBlock, Strong, Text } from 'braid-design-system';
import { useEffect } from 'react';

import * as styles from './ExternalRedirect.css';

export const ExternalRedirect = ({ to }: { to: string }) => {
  useEffect(() => {
    setTimeout(() => window.location.replace(to), 2000);
  }, [to]);

  return (
    <PageBlock width="large">
      <Box className={styles.logoHeight} />

      <Alert tone="info">
        <Text>
          Redirecting to <Strong>{to}</Strong>...
        </Text>
      </Alert>
    </PageBlock>
  );
};
