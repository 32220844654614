import { Heading } from 'braid-design-system';

import { CardSection } from 'src/components/CardSection/CardSection';

import { NotiHistoryProvider } from './HistoryContext';
import { HistoryList } from './components/HistoryList/HistoryList';

export const HistorySection = () => (
  <NotiHistoryProvider>
    <CardSection header={<Heading level="3">Recent notifications</Heading>}>
      <HistoryList />
    </CardSection>
  </NotiHistoryProvider>
);
