import { gql } from '@apollo/client';

export const UPDATE_WEBHOOK_DELIVERY_CONFIGURATION = gql`
  mutation updateWebhookDelivery(
    $subscriptionId: String!
    $url: String!
    $maxEventsPerAttempt: Int
  ) {
    updateWebhookSubscriptionDeliveryConfiguration(
      input: {
        webhookSubscription: {
          id: $subscriptionId
          url: $url
          maxEventsPerAttempt: $maxEventsPerAttempt
        }
      }
    ) {
      ... on UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success {
        webhookSubscription {
          id {
            value
          }
          url
          maxEventsPerAttempt
        }
      }

      ... on UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict {
        conflictingWebhookSubscription {
          id {
            value
          }
        }
      }
    }
  }
`;

export const UPDATE_WEBHOOK_SIGNING_CONFIGURATION = gql`
  mutation updateWebhookSigning(
    $subscriptionId: String!
    $signingAlgorithmCode: String!
    $secret: String
  ) {
    updateWebhookSubscriptionSigningConfiguration(
      input: {
        webhookSubscription: {
          id: $subscriptionId
          signingAlgorithmCode: $signingAlgorithmCode
          secret: $secret
        }
      }
    ) {
      webhookSubscription {
        id {
          value
        }
        signingAlgorithmCode
      }
    }
  }
`;
