import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Actions,
  Alert,
  Button,
  Heading,
  IconNewWindow,
  Loader,
  Notice,
  Stack,
  Text,
  TextField,
  useToast,
} from 'braid-design-system';
import { useCallback, useEffect, useState } from 'react';
import { SmartTextLink } from 'scoobie';
import { useDebounce } from 'use-debounce';

import { CopyableText } from 'src/components/CopyableText/CopyableText';
import { TwoColumnGrid } from 'src/components/TwoColumnGrid/TwoColumnGrid';
import { useQueryParam } from 'src/hooks/queryParams';
import type {
  JobAdPreviewQuery,
  JobAdPreviewQueryVariables,
  JobDetailsQuery,
  JobDetailsQueryVariables,
} from 'src/types/graphql';

import { SeekCreatedBySelfIndicator } from './SeekCreatedBySelfIndicator';
import {
  formatRemunerationRange,
  mapPositionProfileToPreviewInput,
} from './mapping';
import { JOB_AD_PREVIEW, JOB_DETAILS } from './queries';

interface JobAdLookupProps {
  id: string;
  setIdParam: (id: string) => void;
}

const JobAdLookup = ({ id, setIdParam }: JobAdLookupProps) => {
  const [previewLoading, setPreviewLoading] = useState(false);

  const showToast = useToast();

  const { data, error, loading } = useQuery<
    JobDetailsQuery,
    JobDetailsQueryVariables
  >(JOB_DETAILS, {
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (data?.positionProfile?.profileId.value) {
      setIdParam(data.positionProfile.profileId.value);
    }
  }, [data?.positionProfile?.profileId.value, setIdParam]);

  const [previewJobAd] = useLazyQuery<
    JobAdPreviewQuery,
    JobAdPreviewQueryVariables
  >(JOB_AD_PREVIEW, {
    errorPolicy: 'all',
  });

  const onPreviewClick = useCallback(async () => {
    if (!data?.positionProfile?.seekAnzWorkTypeCode) {
      // This is an extra safety check; the callback should not be invoked if
      // the `seekAnzWorkTypeCode` field is missing.
      return;
    }

    setPreviewLoading(true);

    try {
      const response = await previewJobAd({
        variables: mapPositionProfileToPreviewInput(
          data.positionProfile,
          data.positionProfile.seekAnzWorkTypeCode,
        ),
      });

      const url = response.data?.postedPositionProfilePreview.previewUri.url;

      if (response.error || !url) {
        return showToast({
          message: 'We couldn’t preview this job ad. Please try again later.',
          description: response.error?.message,
          key: 'previewJobAd.error',
          tone: 'critical',
        });
      }

      window.open(url);
    } finally {
      setPreviewLoading(false);
    }
  }, [data?.positionProfile, previewJobAd, showToast]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Alert tone="critical">
        <Stack space="medium">
          <Text>We couldn’t look up this job ad.</Text>

          <Text>{error.message}</Text>
        </Stack>
      </Alert>
    );
  }

  if (!data?.positionProfile) {
    return (
      <Alert tone="info">
        <Text>We couldn’t find this job ad.</Text>
      </Alert>
    );
  }

  const { positionProfile } = data;

  if (
    // `seekAnz` scheme requires one salary range.
    positionProfile.offeredRemunerationPackage.ranges.length !== 1 ||
    // `seekAnz` scheme requires one organization.
    positionProfile.positionOrganizations.length !== 1 ||
    // `seekAnz` scheme requires one instruction.
    positionProfile.postingInstructions.length !== 1 ||
    // `seekAnz` scheme requires `seekAnzWorkTypeCode`.
    // This may be omitted for other schemes and unposted position profiles.
    !positionProfile.seekAnzWorkTypeCode
  ) {
    return (
      <Alert tone="info">
        <Text>We couldn’t load this job ad.</Text>
      </Alert>
    );
  }

  const {
    offeredRemunerationPackage: {
      basisCode,
      ranges: [remunerationRange],
    },
    positionOrganizations: [positionOrganization],
    postingInstructions: [postingInstruction],
  } = positionProfile;

  return (
    <Stack dividers space="large">
      <Stack space="large">
        <Heading level="4">{positionProfile.positionTitle}</Heading>

        <TwoColumnGrid space="medium">
          <Text>Hirer</Text>
          <Text>
            <SmartTextLink
              href={`/hirers?id=${encodeURIComponent(
                positionOrganization.id.value,
              )}`}
            >
              {positionOrganization.name}
            </SmartTextLink>
          </Text>

          <Text>Ad type</Text>
          <Text>{postingInstruction.seekAnzAdvertisementType}</Text>

          <Text>Ad URL</Text>
          <Text>
            <SmartTextLink href={positionProfile.positionUri}>
              {new URL(positionProfile.positionUri).hostname}
            </SmartTextLink>
          </Text>

          <Text>Application method</Text>
          <Text>
            {postingInstruction.applicationMethods[0]?.applicationUri ? (
              <>
                <SmartTextLink
                  href={
                    postingInstruction.applicationMethods[0].applicationUri.url
                  }
                >
                  {
                    new URL(
                      postingInstruction.applicationMethods[0].applicationUri.url,
                    ).hostname
                  }
                </SmartTextLink>{' '}
                (Link Out)
              </>
            ) : (
              'Native Apply'
            )}
          </Text>

          <Text>Start date</Text>
          <Text>{new Date(postingInstruction.start).toLocaleString()}</Text>

          <Text>End date</Text>
          <Text>{new Date(postingInstruction.end).toLocaleString()}</Text>

          <Text>Remuneration basis</Text>
          <Text>{basisCode}</Text>

          <Text>Remuneration range</Text>
          <Text>{formatRemunerationRange(remunerationRange)}</Text>
        </TwoColumnGrid>
      </Stack>

      <Stack space="large">
        <Heading level="4">
          <SmartTextLink href="/use-cases/job-posting/identifiers-and-references">
            Identifiers &amp; references
          </SmartTextLink>
        </Heading>

        <TwoColumnGrid space="medium">
          <Text tone="info">
            <SmartTextLink href="https://developer.seek.com/schema/#/named-type/PostedPositionProfile/field/profileId">
              profileId
            </SmartTextLink>
          </Text>
          <CopyableText>{positionProfile.profileId.value}</CopyableText>

          <Text tone="info">
            <SmartTextLink href="https://developer.seek.com/schema/#/named-type/PostedPositionProfile/field/seekBillingReference">
              seekBillingReference
            </SmartTextLink>
          </Text>
          {typeof positionProfile.seekBillingReference === 'string' ? (
            <CopyableText>{positionProfile.seekBillingReference}</CopyableText>
          ) : (
            <Text />
          )}

          <Text tone="info">
            <SmartTextLink href="https://developer.seek.com/schema/#/named-type/PostedPositionProfile/field/seekHirerJobReference">
              seekHirerJobReference
            </SmartTextLink>
          </Text>
          {typeof positionProfile.seekHirerJobReference === 'string' ? (
            <CopyableText>{positionProfile.seekHirerJobReference}</CopyableText>
          ) : (
            <Text />
          )}

          <Text tone="info">
            <SmartTextLink href="https://developer.seek.com/schema/#/named-type/PostedPositionProfile/field/seekPartnerMetadata">
              seekPartnerMetadata
            </SmartTextLink>
          </Text>
          {typeof positionProfile.seekPartnerMetadata === 'string' ? (
            <CopyableText>{positionProfile.seekPartnerMetadata}</CopyableText>
          ) : (
            <Text />
          )}

          <Text tone="info">
            <SmartTextLink href="https://developer.seek.com/schema/#/named-type/PostedPositionProfile/field/seekCreatedBySelfIndicator">
              seekCreatedBySelfIndicator
            </SmartTextLink>
          </Text>
          <SeekCreatedBySelfIndicator
            indicator={positionProfile.seekCreatedBySelfIndicator}
          />
        </TwoColumnGrid>

        <Notice tone="info">
          <Text>
            Do not include any{' '}
            <SmartTextLink href="https://www.oaic.gov.au/privacy/guidance-and-advice/what-is-personal-information/">
              personal information
            </SmartTextLink>{' '}
            such as a legal name or email address in these reference fields.
          </Text>
        </Notice>
      </Stack>

      <Actions>
        <Button
          icon={<IconNewWindow />}
          loading={previewLoading}
          onClick={onPreviewClick}
          tone="brandAccent"
        >
          Preview
        </Button>
      </Actions>
    </Stack>
  );
};

export const JobAdLookupSection = () => {
  const [idParam, setIdParam] = useQueryParam('id');

  const [rawId, setId] = useState(idParam ?? '');

  const [id] = useDebounce(rawId.trim(), 1000);

  return (
    <Stack dividers space="large">
      <TextField
        id="job-ad-lookup-id"
        label="ID"
        onChange={(event) => setId(event.currentTarget.value)}
        placeholder="seekAnzPublicTest:positionProfile:jobAd:27cuZeA47"
        value={rawId}
      />

      {id ? <JobAdLookup id={id} setIdParam={setIdParam} /> : null}
    </Stack>
  );
};
