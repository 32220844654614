import { useAuth0 } from '@auth0/auth0-react';
import { Box } from 'braid-design-system';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const LogoutWrapper = ({ children }: Props) => {
  const { logout } = useAuth0();
  return (
    <Box
      id="logout-wrapper"
      cursor="pointer"
      onClick={async (e) => {
        e.stopPropagation();
        await logout({ logoutParams: { returnTo: window.location.origin } });
      }}
    >
      {children}
    </Box>
  );
};
