
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "locations"
    }}>{`Locations`}</h1>
    <p>{`SEEK’s location hierarchy has been extended with increased granularity in core Asia-Pacific locations.
If you have already built a supported `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/locations#implementation-options"
      }}>{`implementation option`}</a>{` in your software,
these changes have been automatically rolled out to your hirers and you can `}<a parentName="p" {...{
        "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/salary"
      }}>{`skip to the next section`}</a>{`.`}</p>
    <p>{`Legacy integrations that use static location mappings must move to a supported option as part of this revision.
SEEK can no longer justify the effort of producing mapping files tailored to each partner and the administrative overhead of managing manual updates,
particularly as our ontologies have expanded in scope and are now evolving at a quicker pace.
For reference, our location hierarchy has been revised six times over the past six months.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`If your software can interactively prompt the hirer to select a location from a SEEK-specific autosuggest field,
build `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/locations#option-1-build-a-seek-specific-location-input"
          }}>{`location suggest`}</a>{` then provide the selected location ID to our posting mutations.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If your software has its own location hierarchy which contains latitudes and longitudes,
build `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/locations#option-2-map-from-your-internal-location-hierarchy"
          }}>{`nearest locations`}</a>{` then provide the first location ID to our posting mutations.`}</p>
        <p parentName="li">{`SEEK will reliably infer a location based on the closest proximity to the supplied coordinates.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If your software has its own location hierarchy which lacks latitudes and longitudes,
build `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/locations#option-3-use-location-inference"
          }}>{`infer locations`}</a>{` then provide the location ID to our posting mutations.`}</p>
        <p parentName="li">{`SEEK will attempt to match the supplied details to a location in our hierarchy on a best-effort basis.
Location inference is less reliable than the other location options and should be used only as a last resort.`}</p>
      </li>
    </ol>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;