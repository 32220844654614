import { gql } from '@apollo/client';

export const GET_WEBHOOK_DETAILS = gql`
  query webhookDetails($id: String!) {
    webhookSubscription(id: $id) {
      id {
        value
      }
      eventTypeCode
      schemeId
      hirerId {
        value
      }
      hirer {
        id {
          value
        }
        name
      }
      url
      maxEventsPerAttempt
      signingAlgorithmCode
      createDateTime
      updateDateTime
    }
  }
`;
