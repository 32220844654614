import { Badge, Box, Stack, Text, Tiles } from 'braid-design-system';
import type { ComponentProps } from 'react';

import { Flowchart } from 'src/components/Flowchart';
import { FlowchartHeading } from 'src/components/FlowchartHeading';
import { FlowchartNode } from 'src/components/FlowchartNode';

import * as styles from './Flow.css';

type WrapperProps = Pick<ComponentProps<typeof Tiles>, 'children'>;

const Wrapper = ({ children }: WrapperProps) => (
  <Box className={styles.scrollable}>
    <Box className={styles.minContentWidth}>
      <Flowchart>
        <Tiles columns={3} space="medium">
          {children}
        </Tiles>
      </Flowchart>
    </Box>
  </Box>
);

export const Flow = () => (
  <Wrapper>
    <FlowchartHeading>Job posting method</FlowchartHeading>

    <FlowchartHeading>Application ingestion method</FlowchartHeading>

    <FlowchartHeading>Deliverables</FlowchartHeading>

    <FlowchartNode id="iPosting" target="iExport">
      <Text size="small">Via your software</Text>
    </FlowchartNode>

    <FlowchartNode
      id="iExport"
      target={['iDeliverable1', 'iDeliverable2', 'iDeliverable3']}
    >
      <Stack align="center" space="xsmall">
        <Text size="small">Optimised Apply</Text>
        <Badge tone="positive">New</Badge>
      </Stack>
    </FlowchartNode>

    <Stack space="small">
      <FlowchartNode id="iDeliverable1">
        <Text size="small">Candidate data</Text>
      </FlowchartNode>
      <FlowchartNode id="iDeliverable2">
        <Stack align="center" space="small">
          <Text size="small">Attachments</Text>
          <Text size="xsmall">resume, cover letter, selection criteria</Text>
        </Stack>
      </FlowchartNode>
      <FlowchartNode id="iDeliverable3">
        <Stack align="center" space="xsmall">
          <Text size="small">ATS screening question submissions</Text>
          <Badge tone="positive">New</Badge>
        </Stack>
      </FlowchartNode>
    </Stack>

    <FlowchartNode id="dPosting" target="dExport">
      <Text size="small">Direct on SEEK</Text>
    </FlowchartNode>

    <FlowchartNode
      id="dExport"
      target={['dDeliverable1', 'dDeliverable2', 'dDeliverable3']}
    >
      <Stack align="center" space="xsmall">
        <Text size="small">Optimised Apply</Text>
        <Badge tone="positive">New</Badge>
      </Stack>
    </FlowchartNode>

    <Stack space="small">
      <FlowchartNode id="dDeliverable1">
        <Text size="small">Candidate data</Text>
      </FlowchartNode>
      <FlowchartNode id="dDeliverable2">
        <Stack align="center" space="small">
          <Text size="small">Attachments</Text>
          <Text size="xsmall">resume, cover letter, selection criteria</Text>
        </Stack>
      </FlowchartNode>
      <FlowchartNode id="dDeliverable3">
        <Text size="small">SEEK screening question submissions</Text>
      </FlowchartNode>
    </Stack>
  </Wrapper>
);
