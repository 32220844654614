
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Actions, ButtonLink, IconArrow } from 'braid-design-system';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "web-components"
    }}>{`Web components`}</h1>
    <p>{`The SEEK API has web-based components that you can integrate with your software.
For example, you can embed SEEK-built panels on your website to enable `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection"
      }}>{`ad selection`}</a>{` and `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/questionnaires"
      }}>{`questionnaires`}</a>{`,
or include a link to a SEEK-hosted page for `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/previewing-a-job-ad"
      }}>{`previewing a job ad`}</a>{`.`}</p>
    <p>{`Our panels are bundled into a unified `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Software_development_kit"
      }}>{`software development kit`}</a>{`.
This makes it simple for your software to embed multiple panels,
while SEEK can enhance the panels over time with built-in support for beta testing and staged rollouts.`}</p>

    <Actions mdxType="Actions">
  <ButtonLink href="/introduction/panels" icon={<IconArrow direction="right" mdxType="IconArrow" />} variant="soft" mdxType="ButtonLink">
    Panels
  </ButtonLink>
    </Actions>
    <p>{`All of our web-based components follow a documented browser support policy.
Hirers should use an up-to-date web browser to ensure that they can access the components and use all of their features.`}</p>
    <Actions mdxType="Actions">
  <ButtonLink href="/introduction/browser-support" icon={<IconArrow direction="right" mdxType="IconArrow" />} variant="soft" mdxType="ButtonLink">
    Browser support
  </ButtonLink>
    </Actions>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;