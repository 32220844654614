import { List, Text } from 'braid-design-system';
import { Table, TableRow } from 'scoobie';

export const MinimumFieldList = () => (
  <Table header={['Category', 'Required Fields']}>
    <TableRow>
      <Text>SEEK Profile information</Text>
      <List>
        <Text>First name</Text>
        <Text>Last name</Text>
        <Text>Email address</Text>
        <Text>Phone number</Text>
      </List>
    </TableRow>
    <TableRow>
      <Text>Employment history</Text>
      <List>
        <Text>Job title</Text>
        <Text>Company</Text>
        <Text>Start date</Text>
        <Text>End date</Text>
        <Text>Description</Text>
      </List>
    </TableRow>
    <TableRow>
      <Text>Attachments</Text>
      <List>
        <Text>Resume file</Text>
      </List>
    </TableRow>
  </Table>
);
