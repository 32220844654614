import { Box, Column, Columns, Heading, IconTip } from 'braid-design-system';

import type { Page } from './UserGuide';
import { CloseUserGuideButton } from './UserGuideToggle';
import { getContent } from './content';

import {
  ClosedUserGuideSidebar,
  ContentInnerContainer,
  ContentOuterContainer,
  OpenUserGuideSidebar,
  UserGuideVerticalDivider,
} from './styles.css';

interface UserGuideContentProps {
  isWide: boolean;
  page?: Page;
  closeUserGuide: () => void;
}

const VerticalDivider = () => (
  <Box component="span" className={UserGuideVerticalDivider} />
);

export const UserGuideContent = ({
  isWide,
  page,
  closeUserGuide,
}: UserGuideContentProps) => {
  const { MainContent } = getContent(page);

  return (
    <Box className={page ? OpenUserGuideSidebar : ClosedUserGuideSidebar}>
      <VerticalDivider />
      <Box
        background="surface"
        display="flex"
        flexDirection="column"
        height="full"
      >
        <Box padding="large">
          <Columns space="small" alignY="center">
            <Column width="content">
              <CloseUserGuideButton closeUserGuide={() => closeUserGuide()} />
            </Column>
            <Column>
              <Heading
                align={{ mobile: 'center' }}
                icon={<IconTip alignY="uppercase" />}
                level="2"
              >
                Help
              </Heading>
            </Column>
          </Columns>
        </Box>

        <Box
          className={isWide ? ContentOuterContainer : undefined}
          flexGrow={1}
          height="full"
        >
          <Box position="relative" className={ContentInnerContainer}>
            <Box padding="large" paddingTop="medium">
              <MainContent />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
