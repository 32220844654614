import { useLazyQuery } from '@apollo/client';
import { Autosuggest } from 'braid-design-system';
import { useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import { useCriticalErrorsOnlyTrackingApolloClient } from 'src/hooks/apollo';
import {
  type EnvironmentConfig,
  useEnvironmentConfig,
} from 'src/hooks/environment';
import type {
  HiringOrganizationIdFieldsFragment,
  SuggestHiringOrganizationByAdvertiserIdQuery,
  SuggestHiringOrganizationByAdvertiserIdQueryVariables,
  SuggestHiringOrganizationByOidQuery,
  SuggestHiringOrganizationByOidQueryVariables,
  SuggestRelatedHirersQuery,
  SuggestRelatedHirersQueryVariables,
} from 'src/types/graphql';

import {
  SUGGEST_HIRING_ORGANIZATION_BY_ADVERTISER_ID,
  SUGGEST_HIRING_ORGANIZATION_ID_BY_OID,
  SUGGEST_RELATED_HIRERS,
} from './queries';

export const EMPTY_SUGGESTION = {
  text: '',
};

type SearchTermType = 'advertiserId' | 'oid' | 'name';

const inferSearchTermType = (
  value: string,
  seekAnzSchemeId: EnvironmentConfig['seekAnzSchemeId'],
): SearchTermType => {
  if (value && Number.isSafeInteger(Number(value))) {
    return 'advertiserId';
  }

  if (value.startsWith(`${seekAnzSchemeId}:`)) {
    return 'oid';
  }

  return 'name';
};

export interface HirerAutosuggestValue {
  text: string;
  description?: string;
  value?: HiringOrganizationIdFieldsFragment;
}

interface Props {
  id: string;
  onChange: (value: HirerAutosuggestValue) => void;
  onClear: () => void;
  value: HirerAutosuggestValue;
  label?: string;
}

export const HirerAutosuggest = ({
  id,
  onChange,
  onClear,
  value,
  label,
}: Props) => {
  const [debouncedSearchTerm] = useDebounce(value.text.trim(), 500);

  const [advertiserIdQuery, advertiserIdResult] = useLazyQuery<
    SuggestHiringOrganizationByAdvertiserIdQuery,
    SuggestHiringOrganizationByAdvertiserIdQueryVariables
  >(SUGGEST_HIRING_ORGANIZATION_BY_ADVERTISER_ID, {
    // Lookups by ID return FORBIDDEN if no relationship
    client: useCriticalErrorsOnlyTrackingApolloClient(),
  });

  const [oidQuery, oidResult] = useLazyQuery<
    SuggestHiringOrganizationByOidQuery,
    SuggestHiringOrganizationByOidQueryVariables
  >(SUGGEST_HIRING_ORGANIZATION_ID_BY_OID);

  const [nameQuery, nameResult] = useLazyQuery<
    SuggestRelatedHirersQuery,
    SuggestRelatedHirersQueryVariables
  >(SUGGEST_RELATED_HIRERS);

  const { seekAnzSchemeId } = useEnvironmentConfig();

  const searchTermType = inferSearchTermType(
    debouncedSearchTerm,
    seekAnzSchemeId,
  );

  useEffect(() => {
    if (!debouncedSearchTerm) {
      return;
    }

    switch (searchTermType) {
      case 'advertiserId':
        advertiserIdQuery({
          variables: {
            searchTerm: Number(debouncedSearchTerm),
          },
        });
        return;

      case 'name':
        nameQuery({
          variables: {
            searchTerm: debouncedSearchTerm,
            schemeId: seekAnzSchemeId,
          },
        });
        return;

      case 'oid':
        oidQuery({
          variables: {
            searchTerm: debouncedSearchTerm,
          },
        });
        return;
    }
  }, [
    advertiserIdQuery,
    debouncedSearchTerm,
    nameQuery,
    oidQuery,
    searchTermType,
    seekAnzSchemeId,
  ]);

  const data: HiringOrganizationIdFieldsFragment[] = {
    advertiserId: advertiserIdResult.data?.seekAnzAdvertiser
      ? [advertiserIdResult.data.seekAnzAdvertiser]
      : [],
    name:
      nameResult.data?.hiringOrganizations.edges.map((edge) => edge.node) ?? [],
    oid: oidResult.data?.hiringOrganization
      ? [oidResult.data.hiringOrganization]
      : [],
  }[searchTermType];

  const error = {
    advertiserId: advertiserIdResult,
    name: nameResult,
    oid: oidResult,
  }[searchTermType].error;

  const suggestions = data.map((hirer) => ({
    text: hirer.name,
    description: String(hirer.seekAnzAdvertiserId ?? hirer.id.value),
    value: hirer,
  }));

  return (
    <Autosuggest<HiringOrganizationIdFieldsFragment>
      aria-label="Hirer name"
      id={id}
      message={error ? error.message : undefined}
      onChange={onChange}
      onClear={onClear}
      placeholder="Acme Corp"
      suggestions={suggestions}
      tone={error ? 'critical' : undefined}
      value={value}
      label={label}
    />
  );
};
