
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';
import { SmartTextLink } from 'scoobie';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "completion-signal"
    }}>{`Completion Signal`}</h1>

    <Alert tone="info" mdxType="Alert">
  <Text mdxType="Text">
    If you are implementing Apply with SEEK, please refer to the{' '}
    <SmartTextLink href="/use-cases/apply-with-seek" mdxType="SmartTextLink">
      Apply with SEEK
    </SmartTextLink>{' '}
    documentation, as your implementation of Completion Signals will vary from
    the below.
  </Text>
    </Alert>
    <p>{`The Completion Signal is a message sent to SEEK to notify that an application has been completed within your recruitment software.
When a candidate chooses to apply to a `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/application-methods#linking-out-to-an-external-apply-form"
      }}>{`link-out`}</a>{` job advertised on SEEK, they will be redirected to your apply form along with a unique token.
This token should be passed back to the SEEK API once the candidate successfully submits their apply form.`}</p>
    <p>{`The Completion Signal is only required if you are posting job ads that `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/application-methods#linking-out-to-an-external-apply-form"
      }}>{`link out`}</a>{` to an external apply form hosted by your recruitment software.`}</p>
    <img alt="" data-scoobie-style="none" src={require('../../../../mermaid/.cfaf7bfc77b14cc73760f30b91e7e23dbf1459ca.mmd.svg')} title="Panel authentication" />
    <h2 {...{
      "id": "receiving-the-token"
    }}>{`Receiving the token`}</h2>
    <p>{`You will receive the token in a `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` parameter appended to the query string of the posted `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationMethod/field/applicationUri"
      }}><inlineCode parentName="a">{`applicationUri`}</inlineCode></a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`https://www.example.com/job/123445/?seek-token=93WyyF1h6eaeb58ccc384b0e8353daa3011f1ece
`}</code></pre>
    <p>{`The token is unique to that specific candidate, job ad and session, and should be stored for the duration of the application process.`}</p>
    <h3 {...{
      "id": "token-lifecycle"
    }}>{`Token lifecycle`}</h3>
    <p>{`Tokens remain valid for 30 days from the time of generation, and can be stored while a candidate drafts their application.`}</p>
    <p>{`Tokens do not need to be stored for more than 30 days.
Should an expired token be provided to the `}<inlineCode parentName="p">{`sendApplicationSignal`}</inlineCode>{` mutation, the SEEK API will internally handle the error and no error message will be returned.`}</p>
    <p>{`If a candidate returns to an apply form via the same SEEK link-out URL, the new token generated should be returned to SEEK in the Completion Signal, and any previous token discarded.`}</p>
    <h2 {...{
      "id": "sending-the-completion-signal"
    }}>{`Sending the Completion Signal`}</h2>
    <p>{`When the application is completed, the token can be passed back via the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal"
      }}><inlineCode parentName="a">{`sendApplicationSignal`}</inlineCode>{` mutation`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation ($input: SendApplicationSignalInput!) {\\n  sendApplicationSignal(input: $input)\\n}\",\"position\":{\"start\":{\"line\":62,\"column\":1,\"offset\":2598},\"end\":{\"line\":66,\"column\":4,\"offset\":2702},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"jsonc\",\"meta\":null,\"value\":\"{\\n  \\\"input\\\": {\\n    // Supported values are: `global` and `globalPublicTest`\\n    \\\"schemeId\\\": \\\"globalPublicTest\\\",\\n\\n    // Details of the type of signal being sent - currently only ApplicationComplete is supported\\n    \\\"typeCode\\\": \\\"ApplicationComplete\\\",\\n\\n    // The token received from SEEK as part of the link-out URL\\n    \\\"token\\\": \\\"eyJjYW5kaWRhdGVJZCI6ODAwMCwiam9iSWQiOjMwMDAwMDAwLCJzZXNzaW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MSIsImxpbmtvdXRBcHBsaWNhdGlvbkNvcnJlbGF0aW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MCJ9\\\"\\n  }\\n}\",\"position\":{\"start\":{\"line\":68,\"column\":1,\"offset\":2704},\"end\":{\"line\":81,\"column\":4,\"offset\":3257},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation": true,
        "($input:": true,
        "SendApplicationSignalInput!)": true,
        "{\\n": true,
        "": true,
        "sendApplicationSignal(input:": true,
        "$input)\\n}\",\"position\":{\"start\":{\"line\":62,\"column\":1,\"offset\":2598},\"end\":{\"line\":66,\"column\":4,\"offset\":2702},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"jsonc\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"input\\\":": true,
        "//": true,
        "Supported": true,
        "values": true,
        "are:": true,
        "`global`": true,
        "and": true,
        "`globalPublicTest`\\n": true,
        "\\\"schemeId\\\":": true,
        "\\\"globalPublicTest\\\",\\n\\n": true,
        "Details": true,
        "of": true,
        "the": true,
        "type": true,
        "signal": true,
        "being": true,
        "sent": true,
        "-": true,
        "currently": true,
        "only": true,
        "ApplicationComplete": true,
        "is": true,
        "supported\\n": true,
        "\\\"typeCode\\\":": true,
        "\\\"ApplicationComplete\\\",\\n\\n": true,
        "The": true,
        "token": true,
        "received": true,
        "from": true,
        "SEEK": true,
        "as": true,
        "part": true,
        "link-out": true,
        "URL\\n": true,
        "\\\"token\\\":": true,
        "\\\"eyJjYW5kaWRhdGVJZCI6ODAwMCwiam9iSWQiOjMwMDAwMDAwLCJzZXNzaW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MSIsImxpbmtvdXRBcHBsaWNhdGlvbkNvcnJlbGF0aW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MCJ9\\\"\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":68,\"column\":1,\"offset\":2704},\"end\":{\"line\":81,\"column\":4,\"offset\":3257},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`mutation ($input: SendApplicationSignalInput!) {
  sendApplicationSignal(input: $input)
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;