
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';
import { AdSelectionPanel } from './ad-selection/v2/AdSelectionPanel';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "ad-selection"
    }}>{`Ad selection`}</h1>
    <p>{`The SEEK API provides access to SEEK’s suite of ad products.`}</p>
    <p>{`By integrating ad selection into your posting flow, hirers will be able to:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Select an ad product based on their contractual arrangement with SEEK.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`View prices in real time for each ad product.`}</p>
        <p parentName="li">{`Prices are determined by the position title, job category & location;
refer to `}<a parentName="p" {...{
            "href": "https://help.seek.com.au/employer/s/article/Variable-Pricing-How-does-variable-pricing-work"
          }}>{`variable pricing`}</a>{` for further information.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Understand how they will be billed for each job ad posted.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`View additional fields relevant to the `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/SeekAnzAdProductFeatures"
          }}>{`ad product’s features`}</a>{`, such as `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/branding"
          }}>{`brands`}</a>{` and search bullets.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "using-ad-selection"
    }}>{`Using ad selection`}</h2>

    <Alert tone="promote" mdxType="Alert">
  <Text mdxType="Text">
    SEEK strongly recommends using the Ad Selection Panel for your integration.
    It will significantly reduce development time and ongoing maintenance, as
    well as provide an up-to-date user experience for SEEK hirers.
  </Text>
    </Alert>
    <h3 {...{
      "id": "option-1-ad-selection-panel"
    }}>{`Option 1: Ad Selection Panel`}</h3>
    <p>{`SEEK offers an Ad Selection Panel that you can embed into your posting flow:`}</p>

    <AdSelectionPanel mode="panel" mdxType="AdSelectionPanel" />
    <p>{`Jump to the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/v2/panel"
      }}>{`panel section`}</a>{` to get started.`}</p>
    <h3 {...{
      "id": "option-2-graphql"
    }}>{`Option 2: GraphQL`}</h3>
    <p>{`Available ad products can be programmatically retrieved by your software when `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad"
      }}>{`posting`}</a>{` or `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/updating-a-job-ad"
      }}>{`updating`}</a>{` a job ad.
This allows you to build your own custom user interface for ad selection.`}</p>
    <p>{`Jump to the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/v2/graphql"
      }}>{`GraphQL section`}</a>{` to get started.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;