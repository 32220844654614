import { useAuth0 } from '@auth0/auth0-react';
import React, {
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { extractTokenField } from 'src/utils/token';

import { useEnvironmentConfig } from './environment';

export const useLogin = () => {
  const auth = useAuth0();

  return useMemo(
    () => ({
      login: (returnTo: string) =>
        auth.loginWithRedirect({ appState: { returnTo } }),
    }),
    [auth],
  );
};

const useAuth0Permission = () => {
  const {
    auth: { internalTestPartnerUuid, namespace },
  } = useEnvironmentConfig();

  const [permissions, setPermissions] = useState<string[]>([]);
  const [allPermissions, setAllPermissions] = useState<string[]>([]);
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [isInternalTestPartner, setIsInternalTestPartner] = useState(false);
  const [integrationPartnerUuid, setIntegrationPartnerUuid] =
    useState<string>();

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!user || !isAuthenticated) {
      return;
    }
    getAccessTokenSilently().then((token) => {
      const impersonating = Boolean(extractTokenField(token, 'impersonating'));
      setIsImpersonating(impersonating);

      const auth0Permissions = (extractTokenField(token, 'permissions') ??
        []) as string[];

      const uuid = String(extractTokenField(token, 'integrationPartnerUuid'));
      setIntegrationPartnerUuid(uuid);
      setIsInternalTestPartner(uuid === internalTestPartnerUuid);

      setAllPermissions(auth0Permissions);

      setPermissions(
        auth0Permissions.filter((permission) =>
          // This defaults to non destructive permissions.
          // We allow mutate:users for impersonators to create users for partner onboarding.
          impersonating
            ? permission === 'mutate:users' || !permission.startsWith('mutate:')
            : true,
        ),
      );
    });
  }, [
    user,
    internalTestPartnerUuid,
    isAuthenticated,
    isInternalTestPartner,
    getAccessTokenSilently,
    namespace,
  ]);

  return {
    permissions,
    setPermissions,
    allPermissions,
    isImpersonating,
    isInternalTestPartner,
    integrationPartnerUuid,
  };
};

export const useUserId = (): string => {
  const { user } = useAuth0();

  if (!user?.sub) {
    throw new Error('No user details found in auth token');
  }

  return user.sub;
};

interface PermissionContext {
  permissions: string[];
  setPermissions: (value: React.SetStateAction<string[]>) => void;
  allPermissions: string[];
  isImpersonating: boolean;
  isInternalTestPartner: boolean;
  integrationPartnerUuid?: string;
}

const ctx = React.createContext<PermissionContext>({
  permissions: [],
  setPermissions: () => {},
  allPermissions: [],
  isImpersonating: false,
  isInternalTestPartner: false,
});

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const permissions = useAuth0Permission();

  return <ctx.Provider value={permissions}>{children}</ctx.Provider>;
};

export const usePermissions = () => useContext(ctx);
