
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "migrating-job-posting-api"
    }}>{`Migrating Job Posting API`}</h1>
    <p>{`The retired Job Posting API was a REST-based API that allowed integration partners to post & manage SEEK job ads on behalf of SEEK hirers.`}</p>
    <p>{`This section guides you through the process of migrating from the Job Posting API to the SEEK API.`}</p>
    <p>{`For more general information, see the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting"
      }}>{`Job Posting use case documentation`}</a>{`.`}</p>
    <h2 {...{
      "id": "seek-api-overview"
    }}>{`SEEK API overview`}</h2>
    <p>{`The SEEK API implements a new way to interface with and consume our job posting-related products.`}</p>
    <p>{`At a high level, the SEEK API uses:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://graphql.org/"
          }}>{`GraphQL`}</a>{` for exposing our business objects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Data structures for SEEK API resources based on `}<a parentName="p" {...{
            "href": "https://www.hropenstandards.org/"
          }}>{`HR Open Standards`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`New `}<a parentName="p" {...{
            "href": "/graphql/seek-api-conventions#object-identifiers"
          }}>{`object identifiers`}</a>{` for interfacing with our business objects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`New `}<a parentName="p" {...{
            "href": "/auth"
          }}>{`authentication`}</a>{` flows.`}</p>
      </li>
    </ul>
    <p>{`See `}<a parentName="p" {...{
        "href": "/graphql/in-practice"
      }}>{`GraphQL in practice`}</a>{` for more information.`}</p>
    <h2 {...{
      "id": "migration-development-phases"
    }}>{`Migration development phases`}</h2>
    <p>{`Migrating to the SEEK API is a five-phase process:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/job-posting-api/phase-1-auth"
          }}>{`Auth`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/job-posting-api/phase-2-object-identifiers"
          }}>{`Object identifiers`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/job-posting-api/phase-3-ad-selection"
          }}>{`Ad selection`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/job-posting-api/phase-4-managing-job-ads"
          }}>{`Managing job ads`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/job-posting-api/phase-5-event-delivery"
          }}>{`Event delivery`}</a></p>
      </li>
    </ol>
    <p>{`Each phase will have a set of exit criteria that will determine your readiness to move to the next phase.`}</p>
    <p>{`Depending on your current Job Posting API integration, these phases may be tweaked and changed to facilitate any differences.
It is important to work closely with SEEK’s support team to plan and execute the migration effectively.`}</p>
    <p>{`If you are ready to begin, start the first phase below.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;