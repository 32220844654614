import { Actions, Button, Dialog, Stack, Text } from 'braid-design-system';
import type { ComponentProps } from 'react';

interface DialogWithMessageProps {
  title: string;
  id: string;
  message: string | ComponentProps<typeof Stack>['children'];
  action: string;
  actionLoading?: boolean;
  showDialog: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}

export const DialogWithMessage = ({
  title,
  id,
  message,
  action,
  actionLoading,
  showDialog,
  onCancel,
  onConfirm,
}: DialogWithMessageProps) => (
  <Dialog id={id} onClose={onCancel} open={showDialog} title={title}>
    <Stack space="medium" dividers>
      {typeof message === 'string' ? <Text>{message}</Text> : message}

      <Actions>
        <Button loading={actionLoading} onClick={onConfirm} tone="critical">
          {action}
        </Button>
        <Button onClick={onCancel} variant="transparent">
          Cancel
        </Button>
      </Actions>
    </Stack>
  </Dialog>
);
