import lunr from 'lunr';
import { useEffect, useState } from 'react';

import { type PlainTextPage, searchIndex } from './searchIndex';

export type RawSearchResult = lunr.Index.Result & { page: PlainTextPage };

const getSearchResults = async (
  termText: string,
): Promise<RawSearchResult[]> => {
  if (termText.trim().length === 0) {
    // Lunr will show all pages in the order they appear in the index with an
    // empty string.
    return [];
  }

  const { lunrIndex, plainTextSite } = await searchIndex();

  const tokens = lunr.tokenizer(termText);

  const results = lunrIndex
    .query((query) => {
      query.term(tokens, { wildcard: lunr.Query.wildcard.TRAILING });
    })
    .filter((result) => result.score > 0.7)
    .map((result) => ({ ...result, page: plainTextSite[result.ref] }));

  results.sort((a, b) => {
    if (a.page.source === 'schema' && b.page.source === 'schema') {
      const aPrefixed = a.page.searchTitle.startsWith(termText);
      const bPrefixed = b.page.searchTitle.startsWith(termText);

      if (aPrefixed && !bPrefixed) {
        return -1;
      }

      if (!aPrefixed && bPrefixed) {
        return 1;
      }

      return a.page.searchTitle.length - b.page.searchTitle.length;
    }

    return a.score - b.score;
  });

  return results;
};

export const useSearchResults = (searchTerm: string) => {
  const [results, setResults] = useState<RawSearchResult[]>([]);

  useEffect(() => {
    let cancelled = false;

    getSearchResults(searchTerm)
      .then((result) => {
        if (cancelled) {
          return;
        }

        setResults(result);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error in search', error);
      });

    return () => {
      cancelled = true;
    };
  }, [searchTerm]);

  return [results, setResults] as const;
};
