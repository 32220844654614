import { Column, Columns, Heading, Stack, Text } from 'braid-design-system';
import { SmartTextLink } from 'scoobie';

import { NoPermissionAlert } from 'src/components/NoPermissionAlert/NoPermissionAlert';
import { PageGutter } from 'src/components/PageGutter';
import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';
import { AuthVerifier } from 'src/components/auth/AuthVerifier';
import { usePermissions } from 'src/hooks/auth';
import { useUserGuide } from 'src/pages/manage/userGuide/UserGuide';
import { UserGuideToggle } from 'src/pages/manage/userGuide/UserGuideToggle';

import { CredentialsProvider } from './CredentialsContext';
import { CredentialList } from './components/CredentialList/CredentialList';

export const CredentialsPage = () => {
  const { permissions } = usePermissions();
  const { toggleUserGuide } = useUserGuide();

  const hasReadAccess = permissions.includes('query:credentials');

  return (
    <AuthVerifier>
      <PageWrapper
        heading={
          <Columns space="gutter" alignY="center">
            <Column>
              <Heading level="2" weight="weak">
                Client credentials
              </Heading>
            </Column>

            <Column width="content">
              <UserGuideToggle
                toggleUserGuide={() => toggleUserGuide('credentials')}
              />
            </Column>
          </Columns>
        }
        explainer={
          <Stack space="medium">
            <Text tone="secondary">
              Manage your live and Playground client credentials for accessing
              the SEEK API.
            </Text>

            <Text tone="secondary">
              Read more about our{' '}
              <SmartTextLink href="https://developer.seek.com/auth#authentication">
                authentication flow
              </SmartTextLink>{' '}
              and{' '}
              <SmartTextLink href="https://developer.seek.com/graphql/playground">
                Playground environment
              </SmartTextLink>{' '}
              on the{' '}
              <SmartTextLink href="https://developer.seek.com">
                Developer Site
              </SmartTextLink>
              .
            </Text>
          </Stack>
        }
      >
        {hasReadAccess ? (
          <CredentialsProvider>
            <CredentialList />
          </CredentialsProvider>
        ) : (
          <PageGutter>
            <NoPermissionAlert subject="view credentials" />
          </PageGutter>
        )}
      </PageWrapper>
    </AuthVerifier>
  );
};
