
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "partner-tokens"
    }}>{`Partner tokens`}</h1>
    <p>{`Partner tokens authenticate your software’s backend to the SEEK API.`}</p>
    <p>{`A partner token grants access to the data of any SEEK hirer you have a `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}>{`relationship`}</a>{` with.
Partner tokens must never be sent to a third-party system such as a hirer’s browser.`}</p>
    <h2 {...{
      "id": "requesting-a-partner-token"
    }}>{`Requesting a partner token`}</h2>
    <p>{`Exchange your `}<a parentName="p" {...{
        "href": "/auth#client-credentials"
      }}>{`client credentials`}</a>{` for a partner token using `}<inlineCode parentName="p">{`auth.seek.com`}</inlineCode>{`.
This flow implements the `}<a parentName="p" {...{
        "href": "https://datatracker.ietf.org/doc/html/rfc6749#section-4.4"
      }}>{`OAuth 2.0 client credentials grant`}</a>{`.`}</p>
    <img alt="" data-scoobie-style="none" src={require('../../../mermaid/.c000d2ace799fbf3f78a76a75a29b89106980773.mmd.svg')} title="Partner token exchange" />
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Call the partner authentication endpoint with your client credentials.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-http",
            "metastring": "label=\"Request\"",
            "label": "\"Request\""
          }}>{`POST https://auth.seek.com/oauth/token HTTP/1.1
Content-Type: application/json
User-Agent: YourPartnerService/1.2.3

{
  "audience": "https://graphql.seek.com",
  "client_id": "CLIENT_ID_HERE",
  "client_secret": "CLIENT_SECRET_HERE",
  "grant_type": "client_credentials"
}
`}</code></pre>
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "/graphql/playground"
          }}>{`Playground`}</a>{` environment uses a separate `}<inlineCode parentName="p">{`https://test.graphql.seek.com`}</inlineCode>{` audience.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Receive the issued partner token.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-http",
            "metastring": "label=\"Response\"",
            "label": "\"Response\""
          }}>{`HTTP/1.1 200 OK
Content-Type: application/json

{
  "access_token": "PARTNER_TOKEN_HERE",
  "expires_in": 1800,
  "token_type": "Bearer"
}
`}</code></pre>
        <p parentName="li">{`Partner tokens must be cached for the number of seconds specified in the response’s `}<inlineCode parentName="p">{`expires_in`}</inlineCode>{`.
The cache expiry must be read from each response;
it cannot be hardcoded as the token lifetime is dynamic and may be updated without notice.
Caching reduces the load on SEEK’s systems and improves your software’s response time.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pass the partner token in the HTTP `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header when making requests to the GraphQL endpoint.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-http",
            "metastring": "label=\"Request\"",
            "label": "\"Request\""
          }}>{`POST https://graphql.seek.com/graphql HTTP/1.1
Accept-Language: en-AU
Authorization: Bearer PARTNER_TOKEN_HERE
User-Agent: YourPartnerService/1.2.3
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "token-expiration"
    }}>{`Token expiration`}</h2>
    <p>{`Re-initiate the client credentials flow in the above section to obtain a new partner token.
Note that this flow `}<a parentName="p" {...{
        "href": "https://datatracker.ietf.org/doc/html/rfc6749#section-4.4.3"
      }}>{`does not feature a refresh token`}</a>{`.`}</p>
    <p>{`Using a partner token right up to its expiry may lead to expiration occurring mid-flight due to clock drift or request latency.
Consider leaving a reasonable buffer of around a minute, or obtaining a new partner token and retrying the request on an `}<a parentName="p" {...{
        "href": "/graphql/error-responses#unauthenticated"
      }}><inlineCode parentName="a">{`UNAUTHENTICATED`}</inlineCode>{` error`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;