import 'braid-design-system/reset';

import { BraidProvider, ToastProvider } from 'braid-design-system';
import seekJobs from 'braid-design-system/themes/seekJobs';
import { Helmet } from 'react-helmet';
import { MdxProvider, ScoobieLink } from 'scoobie';

import { IncidentToaster } from 'src/components/IncidentToaster/IncidentToaster';
import { UserProvider } from 'src/hooks/auth';
import { StatuspageProvider } from 'src/hooks/statuspage';

import { Router } from './Router';

export const App = () => (
  <BraidProvider linkComponent={ScoobieLink} theme={seekJobs}>
    <MdxProvider graphqlPlayground="https://manage.developer.seek.com/graphql-explorer">
      <Helmet
        defaultTitle="SEEK Developer"
        titleTemplate="%s | SEEK Developer"
      />
      <UserProvider>
        <StatuspageProvider pageUrl="https://status.seek.com">
          <ToastProvider>
            <IncidentToaster />
            <Router />
          </ToastProvider>
        </StatuspageProvider>
      </UserProvider>
    </MdxProvider>
  </BraidProvider>
);
