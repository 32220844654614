import type { Private } from '@seek/indie-api-types';
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type User = Private.Auth0UserPayload;

import { useApi } from 'src/api';

interface ActionOptions {
  showLoading: boolean;
}

interface UsersContext {
  users: User[];
  loading: boolean;
  refresh: (opts: ActionOptions) => Promise<void>;
  error: string;
  setNewRole: (newDetails: Private.Auth0UserRoleChangeInput) => Promise<void>;
  deleteUser: (userId: string) => Promise<void>;
  resetMfa: (userId: string) => Promise<void>;
}

const UsersContext = createContext<UsersContext>({
  users: [],
  loading: false,
  refresh: async () => {},
  error: '',
  setNewRole: async () => {},
  deleteUser: async () => {},
  resetMfa: async () => {},
});

export const useUsers = () => {
  const context = useContext(UsersContext);

  if (!context) {
    throw new Error('useUsers must be used within a UsersProvider');
  }

  return context;
};

interface UsersProviderProps {
  children: ReactNode;
}

export const UsersProvider = ({ children }: UsersProviderProps) => {
  const api = useApi();
  const [users, setUsers] = useState<User[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const refresh = async ({ showLoading }: ActionOptions) => {
    if (showLoading) {
      setLoading(true);
    }

    try {
      const newUsers = await api.users.list();
      setUsers(newUsers);
      setErrorMessage('');
    } catch (err) {
      setErrorMessage(String(err));
    }

    if (showLoading) {
      setLoading(false);
    }
  };

  const setNewRole = async (input: Private.Auth0UserRoleChangeInput) => {
    await api.users.changeRole(input);

    const newUsers = users.map((user) =>
      user.id === input.id ? { ...user, role: input.role } : user,
    );

    setUsers(newUsers);
  };

  const deleteUser = async (userId: string) => {
    await api.users.delete(userId);

    const newUsers = users.filter((user) => user.id !== userId);

    setUsers(newUsers);
  };

  const resetMfa = async (userId: string) => {
    await api.users.resetMfa(userId);
  };

  const value = useMemo(
    () => ({
      users,
      loading,
      refresh,
      error: errorMessage,
      setNewRole,
      deleteUser,
      resetMfa,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users, loading, errorMessage],
  );

  // Load on initial render
  useEffect(() => {
    refresh({ showLoading: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};
