
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import QuestionnaireBuilder from './builder';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "ats-screening-questions"
    }}>{`ATS Screening Questions`}</h1>
    <p>{`This implementation option is recommended if your software already has a mechanism for creating and managing custom questions.
The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/createApplicationQuestionnaire"
      }}><inlineCode parentName="a">{`createApplicationQuestionnaire`}</inlineCode>{` mutation`}</a>{` lets you create questionnaires in the structure expected by the SEEK API and returns an ID that can be specified when posting a job ad.`}</p>
    <img alt="" data-scoobie-style="none" src={require('../../../../../mermaid/.9ed5877013aa21bcb1c63fec4f5fb6176641d57a.mmd.svg')} title="Questionnaire flow" />
    <h2 {...{
      "id": "operation"
    }}>{`Operation`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/createApplicationQuestionnaire"
      }}><inlineCode parentName="a">{`createApplicationQuestionnaire`}</inlineCode>{` mutation`}</a>{` creates a new questionnaire.`}</p>
    <p>{`The SEEK API provides 3 question types for custom questions:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`FreeText`}</inlineCode>{` presents the candidate with a free text input labelled with the question’s `}<inlineCode parentName="p">{`questionHtml`}</inlineCode>{`.
They must input at least one character before submitting their application.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`SingleSelect`}</inlineCode>{` presents the candidate with a set of mutually exclusive choices.
They must select a single choice before submitting their application.`}</p>
        <p parentName="li">{`SEEK renders these questions either as radio buttons or a dropdown list, depending on the number of choices.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`MultiSelect`}</inlineCode>{` presents the candidate with a set of non-exclusive choices.
They must select at least one choice before submitting their application.`}</p>
        <p parentName="li">{`SEEK renders these questions as a list of checkboxes.`}</p>
      </li>
    </ul>
    <p>{`If you manage individual questionnaire components in your software,
supply a unique reference `}<inlineCode parentName="p">{`value`}</inlineCode>{` for each `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestionnaireComponent"
      }}><inlineCode parentName="a">{`ApplicationQuestionnaireComponent`}</inlineCode></a>{` during questionnaire creation.
You can then retrieve `}<inlineCode parentName="p">{`responses.*.component.value`}</inlineCode>{` from the submission to correlate each response back to its corresponding component.`}</p>
    <p>{`If you manage questionnaires as a holistic collection in your software,
retrieve `}<inlineCode parentName="p">{`questionnaire.id`}</inlineCode>{` to correlate the submission back to the questionnaire ID.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation ($input: CreateApplicationQuestionnaireInput!) {\\n  createApplicationQuestionnaire(input: $input) {\\n    applicationQuestionnaire {\\n      id {\\n        value\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":44,\"column\":1,\"offset\":1914},\"end\":{\"line\":54,\"column\":4,\"offset\":2112},\"indent\":[1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"input\\\": {\\n    \\\"applicationQuestionnaire\\\": {\\n      \\\"hirerId\\\": \\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\",\\n      \\\"components\\\": [\\n        {\\n          \\\"componentTypeCode\\\": \\\"Question\\\",\\n          \\\"question\\\": {\\n            \\\"componentTypeCode\\\": \\\"Question\\\",\\n            \\\"value\\\": \\\"question1\\\",\\n            \\\"questionHtml\\\": \\\"Tell us about your relevant mock data experience\\\",\\n            \\\"responseTypeCode\\\": \\\"FreeText\\\"\\n          }\\n        },\\n        {\\n          \\\"componentTypeCode\\\": \\\"PrivacyConsent\\\",\\n          \\\"privacyConsent\\\": {\\n            \\\"componentTypeCode\\\": \\\"PrivacyConsent\\\",\\n            \\\"value\\\": \\\"consent1\\\",\\n            \\\"descriptionHtml\\\": \\\"Do you agree to our privacy policy?\\\",\\n            \\\"privacyPolicyUrl\\\": {\\n              \\\"url\\\": \\\"https://www.seek.com.au/privacy/\\\"\\n            }\\n          }\\n        }\\n      ]\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":56,\"column\":1,\"offset\":2114},\"end\":{\"line\":86,\"column\":4,\"offset\":2952},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation": true,
        "($input:": true,
        "CreateApplicationQuestionnaireInput!)": true,
        "{\\n": true,
        "": true,
        "createApplicationQuestionnaire(input:": true,
        "$input)": true,
        "applicationQuestionnaire": true,
        "id": true,
        "value\\n": true,
        "}\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":44,\"column\":1,\"offset\":1914},\"end\":{\"line\":54,\"column\":4,\"offset\":2112},\"indent\":[1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"input\\\":": true,
        "\\\"applicationQuestionnaire\\\":": true,
        "\\\"hirerId\\\":": true,
        "\\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\",\\n": true,
        "\\\"components\\\":": true,
        "[\\n": true,
        "\\\"componentTypeCode\\\":": true,
        "\\\"Question\\\",\\n": true,
        "\\\"question\\\":": true,
        "\\\"value\\\":": true,
        "\\\"question1\\\",\\n": true,
        "\\\"questionHtml\\\":": true,
        "\\\"Tell": true,
        "us": true,
        "about": true,
        "your": true,
        "relevant": true,
        "mock": true,
        "data": true,
        "experience\\\",\\n": true,
        "\\\"responseTypeCode\\\":": true,
        "\\\"FreeText\\\"\\n": true,
        "},\\n": true,
        "\\\"PrivacyConsent\\\",\\n": true,
        "\\\"privacyConsent\\\":": true,
        "\\\"consent1\\\",\\n": true,
        "\\\"descriptionHtml\\\":": true,
        "\\\"Do": true,
        "you": true,
        "agree": true,
        "to": true,
        "our": true,
        "privacy": true,
        "policy?\\\",\\n": true,
        "\\\"privacyPolicyUrl\\\":": true,
        "\\\"url\\\":": true,
        "\\\"https://www.seek.com.au/privacy/\\\"\\n": true,
        "]\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":56,\"column\":1,\"offset\":2114},\"end\":{\"line\":86,\"column\":4,\"offset\":2952},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`mutation ($input: CreateApplicationQuestionnaireInput!) {
  createApplicationQuestionnaire(input: $input) {
    applicationQuestionnaire {
      id {
        value
      }
    }
  }
}
`}</code></pre>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestionnaire"
      }}><inlineCode parentName="a">{`ApplicationQuestionnaire`}</inlineCode></a>{` object is immutable.
If your software supports mutable questionnaires,
you may snapshot its latest state by creating a new `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestionnaire"
      }}><inlineCode parentName="a">{`ApplicationQuestionnaire`}</inlineCode></a>{` at the time of posting.`}</p>
    <p>{`For detailed information on all available fields, refer to the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CreateApplicationQuestionnaireInput"
      }}><inlineCode parentName="a">{`CreateApplicationQuestionnaireInput`}</inlineCode></a>{` schema definition.`}</p>
    <p>{`You can use our interactive builder to explore the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestionnaire"
      }}><inlineCode parentName="a">{`ApplicationQuestionnaire`}</inlineCode></a>{` components supported by the SEEK API.`}</p>

    <QuestionnaireBuilder mdxType="QuestionnaireBuilder" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;