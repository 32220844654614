import {
  type OperationVariables,
  type QueryResult,
  type WatchQueryFetchPolicy,
  useQuery,
} from '@apollo/client';
import { Box, Loader, Notice, Text } from 'braid-design-system';
import type { DocumentNode } from 'graphql';
import type { ComponentProps } from 'react';

import { ErrorNotice } from '../ErrorNotice/ErrorNotice';

type QueryResultWithDefiniteData<
  Data,
  Variables extends OperationVariables,
> = Omit<QueryResult<Data, Variables>, 'data'> & { data: Data };

interface QueryProps<Data, Variables extends OperationVariables> {
  query: DocumentNode;
  errorMessage: string;
  variables: Variables;
  children: (
    result: QueryResultWithDefiniteData<Data, Variables>,
  ) => JSX.Element | null;
  wrapperComponent?: (props: { children: JSX.Element }) => JSX.Element;
  loaderSize?: ComponentProps<typeof Loader>['size'];
  noCache?: boolean;
}

const hasVariables = <Data, Variables extends OperationVariables>(
  input: unknown,
): input is QueryProps<Data, Variables> => Boolean((input as any).variables);

export const Query = <Data, Variables extends OperationVariables>(
  props: QueryProps<Data, Variables>,
) => {
  const fetchPolicy: WatchQueryFetchPolicy = props.noCache
    ? 'no-cache'
    : 'cache-first';

  const optionsObject = {
    fetchPolicy,
    variables: hasVariables<Data, Variables>(props)
      ? props.variables
      : undefined,
  };

  const { query, wrapperComponent, errorMessage, children } = props;
  const Wrapper = wrapperComponent;

  const queryResult = useQuery<Data, Variables>(query, optionsObject);

  const { called, loading, error, data } = queryResult;

  const wrap = (childrenToWrap: JSX.Element) =>
    !Wrapper ? childrenToWrap : <Wrapper>{childrenToWrap}</Wrapper>;

  if (!called) {
    return null;
  }

  if (loading) {
    return wrap(
      <Box height="full" width="full" display="flex" justifyContent="center">
        <Loader size={props.loaderSize ?? 'large'} />
      </Box>,
    );
  }

  if (error && !data) {
    return wrap(
      <ErrorNotice description={error.message} title={errorMessage} />,
    );
  }

  if (!data) {
    return wrap(
      <Notice tone="critical">
        <Text>No data was returned in the request.</Text>
      </Notice>,
    );
  }

  return children({ ...queryResult, data });
};
