
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "replay-events-prior-to-subscription"
    }}>{`Replay events prior to subscription`}</h4>
    <p>{`We’ve updated the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/replayWebhookSubscription"
      }}><inlineCode parentName="a">{`replayWebhookSubscription`}</inlineCode>{` mutation`}</a>{` to replay events that were emitted prior to a subscription or a hirer relationship being created.
This allows you to backfill events for a hirer without having to contact SEEK.`}</p>
    <p>{`To accommodate this change, the fields in `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ReplayWebhookSubscription_FilterInput"
      }}><inlineCode parentName="a">{`replayWebhookSubscription`}</inlineCode>{` filter input`}</a>{` are now mandatory.
In addition, a `}<inlineCode parentName="p">{`hirerId`}</inlineCode>{` must be provided for partner-scoped subscriptions when the field `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ReplayWebhookSubscription_FilterInput/field/replayDeliveredEventsIndicator"
      }}><inlineCode parentName="a">{`replayDeliveredEventsIndicator`}</inlineCode></a>{` is `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <p>{`This is also available in the `}<a parentName="p" {...{
        "href": "https://manage.developer.seek.com/webhooks"
      }}>{`Developer Dashboard’s webhooks page`}</a>{` and can be triggered by toggling the `}<inlineCode parentName="p">{`Include delivered events`}</inlineCode>{` checkbox and providing a hirer.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;