import gql from 'graphql-tag';

import { EVENT_SUBJECT_OID_FRAGMENT } from '../../fragments';

export const WEBHOOK_REQUEST_FRAGMENT = gql`
  fragment webhookRequestFields on WebhookRequest {
    attempts {
      id {
        value
      }
      event {
        id {
          value
        }
        ...eventSubjectOidFields
      }
    }
    requestId
    createDateTime
    descriptionCode
    statusCode
    latencyMs
  }
  ${EVENT_SUBJECT_OID_FRAGMENT}
`;
