
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { CurrentGuides, ArchivedGuides } from './guides';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "how-to-guides"
    }}>{`How-to guides`}</h1>
    <p>{`Recruitment software providers are expected to deliver the latest and most valuable experiences to our mutual hirers within a reasonable period from when they are made generally available.
The baseline requirements for a marketplace integration are revised on a scheduled basis,
rolling up changes to the SEEK API that have been `}<a parentName="p" {...{
        "href": "/introduction/release-notes"
      }}>{`incrementally released`}</a>{` since the last revision.`}</p>
    <p>{`Our how-to guides provide instructions and timelines for adopting new revisions and working with specific features,
walking you through each change in a self-contained manner.`}</p>
    <p>{`By contrast,
our use cases provide foundational technical knowledge about each product and its features,
and enable integration with your recruitment software from scratch.`}</p>
    <h2 {...{
      "id": "current-guides"
    }}>{`Current guides`}</h2>
    <CurrentGuides mdxType="CurrentGuides" />
    <h2 {...{
      "id": "archived-guides"
    }}>{`Archived guides`}</h2>
    <p>{`When a how-to guide is no longer in active use,
its pages are retained in an archive for troubleshooting purposes.`}</p>
    <ArchivedGuides mdxType="ArchivedGuides" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;