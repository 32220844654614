
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "application-methods"
    }}>{`Application methods`}</h1>
    <p>{`When posting a job ad you must specify if candidates apply via `}<a parentName="p" {...{
        "href": "#seeks-apply-form"
      }}>{`SEEK’s Apply Form`}</a>{`,
or the hirer’s apply form by `}<a parentName="p" {...{
        "href": "#linking-out-to-an-external-apply-form"
      }}>{`linking out`}</a>{`.
SEEK’s Apply Form is strongly recommended for the best candidate experience and conversion rates.`}</p>
    <p>{`We do not recommend changing the application method of a job ad while it is live on the SEEK platform.
Attempting to switch a live job ad between SEEK’s Apply Form and an external apply form may result in unexpected behaviour and complicate applicant tracking.
You may support closing and re-posting the job ad to SEEK with a different application method.`}</p>
    <h2 {...{
      "id": "seeks-apply-form"
    }}>{`SEEK’s Apply Form`}</h2>
    <p>{`Candidates apply via the SEEK website and app.
This allows a candidate to use documents and information already stored in their SEEK Profile.
The job ad may specify a `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/questionnaires"
      }}>{`questionnaire`}</a>{` for candidates to complete as part of their job application.
Questionnaires are rendered on SEEK’s candidate website and in our mobile apps.`}</p>
    <p>{`You should use `}<a parentName="p" {...{
        "href": "/use-cases/application-export"
      }}>{`Optimised Apply`}</a>{` to retrieve applications submitted on SEEK.
Optimised Apply is compatible with questionnaires.
While hirers can also view applicants on the `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au"
      }}>{`SEEK employer website`}</a>{`, that may not be part of their typical workflow.
You can validate that you have an `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}><inlineCode parentName="a">{`ApplicationExport`}</inlineCode>{` relationship`}</a>{` with the SEEK hirer before posting a job ad that uses SEEK’s Apply Form.`}</p>
    <p>{`SEEK’s Apply Form is used when no additional `}<inlineCode parentName="p">{`applicationMethods`}</inlineCode>{` are provided in `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PostingInstruction"
      }}><inlineCode parentName="a">{`PostingInstruction`}</inlineCode></a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsonc"
      }}>{`{
  // Default to SEEK’s Apply Form
  "applicationMethods": null

  // Other posting instruction fields
}
`}</code></pre>
    <h2 {...{
      "id": "linking-out-to-an-external-apply-form"
    }}>{`Linking out to an external apply form`}</h2>
    <p>{`Candidates apply via an external careers website that is hosted by your recruitment software.
No information from a candidate’s SEEK Profile is transferred during the link-out process.
However, `}<a parentName="p" {...{
        "href": "https://devportal.seek.com.au/apply-with-seek/overview"
      }}>{`Apply with SEEK`}</a>{` allows candidates to pre-fill your external apply form.`}</p>
    <p>{`SEEK hirers are not configured to link out by default.
If a hirer needs to link out,
they need to contact SEEK and comply with our link-out terms of use.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://manage.developer.seek.com/hirers"
      }}>{`Developer Dashboard’s hirers page`}</a>{` displays the SEEK hirers that you have a relationship with and lets you check whether they have the required `}<inlineCode parentName="p">{`ApplicationUri`}</inlineCode>{` capability.
You can also use the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/hiringOrganization"
      }}><inlineCode parentName="a">{`hiringOrganization`}</inlineCode>{` query`}</a>{` or `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/self"
      }}><inlineCode parentName="a">{`self`}</inlineCode>{` query`}</a>{` to achieve the same effect:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($id: String!) {\\n  hiringOrganization(id: $id) {\\n    seekApiCapabilities {\\n      applicationMethodCodes\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":47,\"column\":1,\"offset\":2414},\"end\":{\"line\":55,\"column\":4,\"offset\":2550},\"indent\":[1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"id\\\": \\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\"\\n}\",\"position\":{\"start\":{\"line\":57,\"column\":1,\"offset\":2552},\"end\":{\"line\":61,\"column\":4,\"offset\":2622},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"hiringOrganization\\\": {\\n    \\\"seekApiCapabilities\\\": {\\n      \\\"applicationMethodCodes\\\": [\\\"ApplicationUri\\\"]\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":63,\"column\":1,\"offset\":2624},\"end\":{\"line\":71,\"column\":4,\"offset\":2755},\"indent\":[1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($id:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "hiringOrganization(id:": true,
        "$id)": true,
        "seekApiCapabilities": true,
        "applicationMethodCodes\\n": true,
        "}\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":47,\"column\":1,\"offset\":2414},\"end\":{\"line\":55,\"column\":4,\"offset\":2550},\"indent\":[1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"id\\\":": true,
        "\\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\"\\n}\",\"position\":{\"start\":{\"line\":57,\"column\":1,\"offset\":2552},\"end\":{\"line\":61,\"column\":4,\"offset\":2622},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"hiringOrganization\\\":": true,
        "\\\"seekApiCapabilities\\\":": true,
        "\\\"applicationMethodCodes\\\":": true,
        "[\\\"ApplicationUri\\\"]\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":63,\"column\":1,\"offset\":2624},\"end\":{\"line\":71,\"column\":4,\"offset\":2755},\"indent\":[1,1,1,1,1,1,1,1]}}]": true
      }}>{`query ($id: String!) {
  hiringOrganization(id: $id) {
    seekApiCapabilities {
      applicationMethodCodes
    }
  }
}
`}</code></pre>
    <p>{`You can link out to the hirer’s apply form by providing an `}<inlineCode parentName="p">{`applicationUri`}</inlineCode>{` on the job ad’s `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationMethodInput"
      }}><inlineCode parentName="a">{`ApplicationMethodInput`}</inlineCode></a>{`.
This replaces SEEK’s Apply Form with an external apply form,
so `}<a parentName="p" {...{
        "href": "/use-cases/application-export"
      }}>{`Optimised Apply`}</a>{` and `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/questionnaires"
      }}>{`questionnaires`}</a>{` are not available.`}</p>
    <p>{`A link-out `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PostingInstruction"
      }}><inlineCode parentName="a">{`PostingInstruction`}</inlineCode></a>{` looks something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsonc"
      }}>{`{
  // Set explicitly to an external apply form
  "applicationMethods": [
    {
      "applicationUri": {
        "url": "https://www.example.com"
      }
    }
  ]

  // Other posting instruction fields
}
`}</code></pre>
    <p>{`If you try to provide an external apply form for a hirer without the required capability,
the posting mutation will fail with a permission error:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "errors": [
    {
      "message": "Invalid application URL: Application linkout not permitted",
      "extensions": {
        "code": "BAD_USER_INPUT",
        "invalidFields": {
          "/input/positionProfile/postingInstructions/0/applicationMethods/0/applicationUri/url": "Application linkout not permitted"
        }
      }
    }
  ],
  "data": null
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;