
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "closing-a-job-ad"
    }}>{`Closing a job ad`}</h1>
    <p>{`Job ads automatically close on the date specified in their `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PostingInstruction/field/end"
      }}><inlineCode parentName="a">{`postingInstructions.end`}</inlineCode>{` field`}</a>{`.
You can use `}<a parentName="p" {...{
        "href": "#closepostedpositionprofile"
      }}><inlineCode parentName="a">{`closePostedPositionProfile`}</inlineCode></a>{` to immediately close a job ad before its end date.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads#job-ad-lifecycle"
      }}>{`job ad lifecycle documentation`}</a>{` for more details.`}</p>
    <h2 {...{
      "id": "operations"
    }}>{`Operations`}</h2>
    <h3 {...{
      "id": "closepostedpositionprofile"
    }}>{`closePostedPositionProfile`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/closePostedPositionProfile"
      }}><inlineCode parentName="a">{`closePostedPositionProfile`}</inlineCode>{` mutation`}</a>{` closes an existing posted `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PositionProfile"
      }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{`.`}</p>
    <p>{`The job ad will be removed from the job board and no refund will be issued.
The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PositionProfile"
      }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{` and its associated candidate applications will be available for 180 days after its close date.`}</p>
    <p>{`This operation is `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Idempotence"
      }}>{`idempotent`}</a>{`.
It returns a success result even if the job ad has already been closed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation ($input: ClosePostedPositionProfileInput!) {\\n  closePostedPositionProfile(input: $input) {\\n    positionProfile {\\n      profileId {\\n        value\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":22,\"column\":1,\"offset\":830},\"end\":{\"line\":32,\"column\":4,\"offset\":1018},\"indent\":[1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"input\\\": {\\n    \\\"positionProfile\\\": {\\n      \\\"profileId\\\": \\\"seekAnzPublicTest:positionProfile:jobAd:2782PZfXV\\\"\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":34,\"column\":1,\"offset\":1020},\"end\":{\"line\":42,\"column\":4,\"offset\":1154},\"indent\":[1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"closePostedPositionProfile\\\": {\\n    \\\"positionProfile\\\": {\\n      \\\"profileId\\\": {\\n        \\\"value\\\": \\\"seekAnzPublicTest:positionProfile:jobAd:2782PZfXV\\\"\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":44,\"column\":1,\"offset\":1156},\"end\":{\"line\":54,\"column\":4,\"offset\":1338},\"indent\":[1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation": true,
        "($input:": true,
        "ClosePostedPositionProfileInput!)": true,
        "{\\n": true,
        "": true,
        "closePostedPositionProfile(input:": true,
        "$input)": true,
        "positionProfile": true,
        "profileId": true,
        "value\\n": true,
        "}\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":22,\"column\":1,\"offset\":830},\"end\":{\"line\":32,\"column\":4,\"offset\":1018},\"indent\":[1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"input\\\":": true,
        "\\\"positionProfile\\\":": true,
        "\\\"profileId\\\":": true,
        "\\\"seekAnzPublicTest:positionProfile:jobAd:2782PZfXV\\\"\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":34,\"column\":1,\"offset\":1020},\"end\":{\"line\":42,\"column\":4,\"offset\":1154},\"indent\":[1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"closePostedPositionProfile\\\":": true,
        "\\\"value\\\":": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":44,\"column\":1,\"offset\":1156},\"end\":{\"line\":54,\"column\":4,\"offset\":1338},\"indent\":[1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`mutation ($input: ClosePostedPositionProfileInput!) {
  closePostedPositionProfile(input: $input) {
    positionProfile {
      profileId {
        value
      }
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "events"
    }}>{`Events`}</h2>
    <h3 {...{
      "id": "positionprofileclosed"
    }}>{`PositionProfileClosed`}</h3>
    <p><a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PositionProfileClosedEvent"
      }}><inlineCode parentName="a">{`PositionProfileClosed`}</inlineCode>{` events`}</a>{` are emitted when job ads have been closed.
You will receive events for SEEK hirers you have a `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}><inlineCode parentName="a">{`JobPosting`}</inlineCode>{` relationship`}</a>{` with at the time the job ad closes.`}</p>
    <p>{`It’s recommended that you update your internal state based on `}<inlineCode parentName="p">{`PositionProfileClosed`}</inlineCode>{` events instead of scheduled end dates.
This ensures your software remains synchronised with SEEK, particularly when a hirer contacts our Customer Service team to close a job ad early.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`PositionProfileClosed`}</inlineCode>{` event is typically sent within a minute of a successful `}<a parentName="p" {...{
        "href": "#closepostedpositionprofile"
      }}><inlineCode parentName="a">{`closePostedPositionProfile`}</inlineCode></a>{` operation.
By contrast, scheduled closure of a job ad based on its end date is not instantaneous;
it may take up to 24 hours to take effect and for an event to be sent.`}</p>
    <p>{`This is an example of a `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhook`}</a>{` body containing a `}<inlineCode parentName="p">{`PositionProfileClosed`}</inlineCode>{` event:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsonc",
        "metastring": "sample-webhook-body",
        "sample-webhook-body": true
      }}>{`{
  "events": [
    {
      "id": "seekAnzPublicTest:event:events:5dEQGJWnR6mSyUwnL14VGA",
      "type": "PositionProfileClosed",
      // The date the job ad was closed
      "createDateTime": "2019-09-20T21:02:27.101Z",
      // This can be passed to the \`positionProfile\` query to retrieve the job ad's final state
      "positionProfileId": "seekAnzPublicTest:positionProfile:jobAd:26d9DVAzs",
      // This is only available for signed webhook subscriptions
      "hirerId": "seekAnzPublicTest:organization:seek:93WyyF1h"
    }
  ],
  "subscriptionId": "seekAnzPublicTest:webhookSubscription:events:9H6GD9v2o8hQw7aBBG2sAU",
  "url": "https://example.com/webhook"
}
`}</code></pre>
    <p>{`Once you receive an event, you can use its `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/webhooks/PositionProfileClosedEvent/property/positionProfileId"
      }}><inlineCode parentName="a">{`positionProfileId`}</inlineCode></a>{` to query the current state of the job ad from the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/querying-a-job-ad#positionprofile"
      }}><inlineCode parentName="a">{`positionProfile`}</inlineCode>{` query`}</a>{`.`}</p>
    <p>{`You should treat a closure as final to handle incidental and intentional `}<a parentName="p" {...{
        "href": "/events/webhooks#duplicates"
      }}>{`event duplication and redelivery`}</a>{`.
A `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad#positionprofileposted"
      }}><inlineCode parentName="a">{`PositionProfilePosted`}</inlineCode>{` event`}</a>{` that is delivered out of order must not re-open the job ad in your software.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;