import lunr from 'lunr';

/**
 * An inner Markdown heading in a plain text body
 *
 * This is used to segment the body into sections. The first level 1 heading is
 * extracted out to `TextPage.searchTtitle` and not included in this array.
 */
export interface HeadingOffset {
  /**
   * Offset into the plain text `body`
   *
   * Note that this not the offset into the original Markdown source.
   */
  offset: number;

  /**
   * Anchor for the heading if available
   */
  slug?: string;
}

export const searchSources = [
  { value: 'docs', icon: 'Guide', heading: 'Docs' },
  {
    value: 'dashboard',
    icon: 'DeveloperDashboard',
    heading: 'Developer Dashboard',
  },
  { value: 'schema', icon: 'Schema', heading: 'Schema' },
] as const;

export type SearchSource = 'docs' | 'dashboard' | 'schema';

/**
 * A site page that's been converted to plain text
 */
export interface PlainTextPage {
  /**
   * Breadcrumb title constructed out of the page labels
   */
  displayTitle: string;

  /**
   * Page's title, or `displayTitle` if not available
   */
  searchTitle: string;

  /**
   * List of Markdown headings and their offset in the page
   */
  headingOffsets?: HeadingOffset[];

  /**
   * Plain text body for Markdown pages
   *
   * This excludes the page's title and content that would be confusing to index
   * (e.g. example data).
   */
  body?: string;

  source: SearchSource;
}

/**
 * A map of site paths to a plain text page
 */
export type PlainTextSite = Record<string, PlainTextPage>;

/**
 * `search-index-v2.json` before being loaded by Lunr
 */
interface RawSearchIndex {
  plainTextSite: PlainTextSite;
  lunrIndex: unknown;
}

/**
 * `search-index-v2.json` after being loaded by Lunr
 */
export interface ParsedSearchIndex {
  plainTextSite: PlainTextSite;
  lunrIndex: lunr.Index;
}

/**
 * Occurrence of a term in a Lunr result
 *
 * This is a tuple of `[start, end]`
 */
export type Occurrence = [number, number];

/**
 * Lunr's builtin position metadata
 */
export interface PositionMetadata {
  position: Occurrence[];
}

/**
 * Names of our Lunr search fields
 */
export type Field = 'body' | 'searchTitle';

/**
 * Metadata attached to each match
 */
export type MatchMetadata = Partial<Record<Field, PositionMetadata>>;

let cachedSearchIndex: Promise<ParsedSearchIndex> | undefined;

export const searchIndex = (): Promise<ParsedSearchIndex> => {
  if (!cachedSearchIndex) {
    cachedSearchIndex = import(
      /* webpackChunkName: "search-index-v2" */ 'src/search-index-v2.json'
    ).then((raw: RawSearchIndex) => ({
      plainTextSite: raw.plainTextSite,
      lunrIndex: lunr.Index.load(raw.lunrIndex as Record<PropertyKey, unknown>),
    }));
  }

  return cachedSearchIndex;
};
