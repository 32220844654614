import { Text, TextDropdown } from 'braid-design-system';
import { useState } from 'react';

export type DeliveredIndicator = boolean | undefined;

export const useDeliveredIndicatorState = () => {
  const [deliveredIndicator, setDeliveredIndicator] =
    useState<DeliveredIndicator>(false);

  return {
    deliveredIndicator,
    setDeliveredIndicator,
  };
};

type Props = ReturnType<typeof useDeliveredIndicatorState>;

export const DeliveredIndicatorPicker = ({
  deliveredIndicator,
  setDeliveredIndicator,
}: Props) => (
  <Text>
    <TextDropdown
      id="eventDeliveredIndicator"
      label="Events"
      onChange={setDeliveredIndicator}
      options={[
        {
          text: 'All',
          value: undefined,
        },
        {
          text: 'Delivered',
          value: true,
        },
        {
          text: 'Undelivered',
          value: false,
        },
      ]}
      value={deliveredIndicator}
    />
  </Text>
);
