import {
  Column,
  Columns,
  Hidden,
  IconDate,
  Stack,
  Text,
} from 'braid-design-system';
import { Fragment } from 'react';

import { CopyableText } from 'src/components/CopyableText/CopyableText';
import { TwoColumnGrid } from 'src/components/TwoColumnGrid/TwoColumnGrid';
import type { PollEventsQuery } from 'src/types/graphql';

interface Props {
  event: NonNullable<PollEventsQuery['events']>['edges'][number]['node'];
}

export const Event = ({
  event: { __typename, createDateTime, id, schemeId, typeCode, ...rest },
}: Props) => (
  <Stack space="medium">
    <Columns space="small" collapseBelow="tablet">
      <Column>
        <CopyableText size="standard" weight="medium">
          {id.value}
        </CopyableText>
      </Column>

      <Column width="content">
        <Text size="small" tone="secondary">
          <Hidden above="mobile">
            <IconDate />{' '}
          </Hidden>
          {new Date(createDateTime).toLocaleString()}
        </Text>
      </Column>
    </Columns>

    <TwoColumnGrid space="small">
      {Object.entries({ schemeId, typeCode, ...rest }).map(
        ([fieldName, fieldValue]) => (
          <Fragment key={fieldName}>
            <Text size="small" weight="medium">
              {fieldName}
            </Text>

            <CopyableText inlineCode size="small">
              {fieldValue}
            </CopyableText>
          </Fragment>
        ),
      )}
    </TwoColumnGrid>
  </Stack>
);
