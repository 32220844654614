import {
  Badge,
  Box,
  CheckboxStandalone,
  Column,
  Columns,
  Heading,
  Stack,
  Text,
} from 'braid-design-system';
import { useState } from 'react';

import { BorderCard } from 'src/components/BorderCard';
import { TooltipField } from 'src/components/TooltipField';

import * as styles from './managing-job-ads/styles.css';

interface UserProps {
  id: string;
  name: string;
  jobAdCount: number;
}

const USERS: UserProps[] = [
  {
    id: '1',
    name: 'Gabriella Hettinger',
    jobAdCount: 10,
  },
  {
    id: '2',
    name: 'Joel Dixon',
    jobAdCount: 1,
  },
  {
    id: '3',
    name: 'Lucy Flatley',
    jobAdCount: 3,
  },
];

const User = ({ id, name, jobAdCount }: UserProps) => {
  const [checked, setChecked] = useState(false);

  return (
    <Box
      className={styles.hoverListItem}
      onClick={() => setChecked((value) => !value)}
      paddingX="medium"
      paddingY="small"
    >
      <Columns alignY="center" space="medium">
        <Column width="content">
          <CheckboxStandalone
            aria-label={`Select ${name}`}
            checked={checked}
            id={`seek-analytics-user-${id}`}
            onChange={() => setChecked((value) => !value)}
            size="small"
          />
        </Column>
        <Column>
          <Text size="small">
            <TooltipField name="name.formattedName">{name}</TooltipField>{' '}
          </Text>
        </Column>

        <Column width="content">
          <Badge bleedY tone="neutral">
            {jobAdCount.toLocaleString()}
          </Badge>
        </Column>
      </Columns>
    </Box>
  );
};

const UserFilter = () => (
  <Stack space="medium">
    <Text tone="secondary">User</Text>

    <Box
      background="neutralSoft"
      borderRadius="standard"
      boxShadow="borderNeutralLight"
      padding="medium"
    >
      <Text>
        <Box
          background="neutralSoft"
          component="input"
          disabled
          id="seekAnalyticsUserDropdown"
          userSelect="none"
          value="Search user..."
        />
      </Text>
    </Box>

    <BorderCard paddingX="none" paddingY="small">
      <Stack space="small">
        <Box paddingX="medium" paddingY="small">
          <Text>All users</Text>
        </Box>
        <Stack space="none">
          {USERS.map((user, index) => (
            <User key={index} {...user} />
          ))}
        </Stack>
      </Stack>
    </BorderCard>
  </Stack>
);

const ClassificationFilter = () => (
  <Stack space="medium">
    <Text tone="secondary">Classification</Text>

    <Box
      background="neutralSoft"
      borderRadius="standard"
      boxShadow="borderNeutralLight"
      padding="medium"
    >
      <Text>
        <Box
          background="neutralSoft"
          component="input"
          disabled
          id="seekAnalyticsClassificationDropdown"
          userSelect="none"
          value="All classifications"
        />
      </Text>
    </Box>
  </Stack>
);

const FilterCard = () => (
  <Box
    background="surface"
    className={styles.roundedLeft}
    paddingX="gutter"
    paddingY="large"
  >
    <Stack space="medium">
      <Text weight="strong">Filter ads posted by</Text>

      <Columns space="medium">
        <Column>
          <UserFilter />
        </Column>

        <Column>
          <ClassificationFilter />
        </Column>
      </Columns>
    </Stack>
  </Box>
);

export const SeekAnalytics = () => (
  <BorderCard
    background="brand"
    className={styles.seekAnalyticsBackground}
    paddingRight="none"
  >
    <Stack space="large">
      <Heading level="2">
        My{' '}
        <Box className={styles.pinkUnderline} component="span">
          ad usage
        </Box>
      </Heading>

      <FilterCard />
    </Stack>
  </BorderCard>
);
