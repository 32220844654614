import type { Private } from '@seek/indie-api-types';
import { Dialog, useToast } from 'braid-design-system';
import { useState } from 'react';

import { DialogWithMessage } from 'src/components/DialogWithMessage/DialogWithMessage';
import { usePermissions, useUserId } from 'src/hooks/auth';
import { useUsers } from 'src/pages/manage/users/UsersContext';
import type { UserType } from 'src/types/users';

import { EditRole } from './components/EditRole/EditRole';
import { UserDesktopRow } from './components/UserDesktopRow';
import { UserMobileRow } from './components/UserMobileRow';

interface UserRowProps {
  isMobile: boolean;
  type: UserType;
  user: Private.Auth0UserPayload;
}

const capitalise = (input: string) =>
  input.charAt(0).toUpperCase() + input.slice(1);

export const UserRow = ({ type, user, isMobile }: UserRowProps) => {
  const users = useUsers();
  const activeUserId = useUserId();

  const showToast = useToast();

  const { permissions } = usePermissions();
  const hasWriteAccess = permissions.includes('mutate:users');

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showResetMfaDialog, setShowResetMfaDialog] = useState(false);
  const [showEditRoleDialog, setShowEditRoleDialog] = useState(false);

  const { name, id } = user;

  const deleteUser = async () => {
    try {
      await users.deleteUser(user.id);
      setShowDeleteDialog(false);
    } catch (err) {
      showToast({
        tone: 'critical',
        message: `We couldn’t delete ${name}.`,
        description: String(err),
      });
    }
  };

  const isActingOnSelf = activeUserId === id;
  const possessivePronounOrName = isActingOnSelf ? 'your' : `${name}’s`;

  const resetMfa = async () => {
    try {
      await users.resetMfa(user.id);
      setShowResetMfaDialog(false);
      showToast({
        key: 'resetMfa',
        tone: 'positive',
        message: `${capitalise(possessivePronounOrName)} MFA has been reset.`,
        description: `We’ll prompt ${
          isActingOnSelf ? 'you' : 'them'
        } to register a new MFA device on ${
          isActingOnSelf ? 'your' : 'their'
        } next login.`,
      });
    } catch (err) {
      showToast({
        key: 'resetMfa',
        tone: 'critical',
        message: `We couldn’t reset ${possessivePronounOrName} MFA.`,
        description: String(err),
      });
    }
  };

  return (
    <>
      {isMobile ? (
        <UserMobileRow
          user={user}
          type={type}
          isActingOnSelf={isActingOnSelf}
          showOptionsMenu={hasWriteAccess}
          setShowDeleteDialog={setShowDeleteDialog}
          setShowResetMfaDialog={setShowResetMfaDialog}
          setShowEditRoleDialog={setShowEditRoleDialog}
        />
      ) : (
        <UserDesktopRow
          user={user}
          type={type}
          isActingOnSelf={isActingOnSelf}
          showActions={hasWriteAccess}
          setShowDeleteDialog={setShowDeleteDialog}
          setShowResetMfaDialog={setShowResetMfaDialog}
          setShowEditRoleDialog={setShowEditRoleDialog}
        />
      )}

      <DialogWithMessage
        title="Reset MFA"
        id={`resetMfa${id}Dialog`}
        message={`Are you sure you want to reset ${possessivePronounOrName} MFA?`}
        action="Reset MFA"
        showDialog={showResetMfaDialog}
        onCancel={() => setShowResetMfaDialog(false)}
        onConfirm={resetMfa}
      />

      <DialogWithMessage
        title={`Delete ${name}`}
        id={`deleteUser${id}Dialog`}
        message={`Are you sure you want to delete ${name}? This cannot be undone.`}
        action="Delete"
        showDialog={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={deleteUser}
      />

      <Dialog
        id={`editRole${id}Dialog`}
        onClose={() => setShowEditRoleDialog(false)}
        open={showEditRoleDialog}
        title="Edit role"
      >
        <EditRole user={user} onSave={() => setShowEditRoleDialog(false)} />
      </Dialog>
    </>
  );
};
