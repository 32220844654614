import { Actions, ButtonLink, IconDownload } from 'braid-design-system';

import file from './seek-job-posting-revision-test-cases.xlsx';

export const TestCasesFile = () => (
  <Actions>
    <ButtonLink
      download="seek-job-posting-revision-test-cases.xlsx"
      href={file}
      icon={<IconDownload />}
      variant="soft"
    >
      Test Steps Excel Workbook
    </ButtonLink>
  </Actions>
);
