
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';
import { IconClear, IconTick } from 'braid-design-system';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "phase-3-apply--talent-search"
    }}>{`Phase 3: Apply & Talent Search`}</h1>

    <Alert tone="info" mdxType="Alert">
  <Text mdxType="Text">
    This phase is only applicable to existing integrations with our legacy
    Jobstreet & Jobsdb APIs. You may skip this section if you are only
    integrated with the SEEK API.
  </Text>
    </Alert>
    <h2 {...{
      "id": "apply"
    }}>{`Apply`}</h2>
    <p>{`Your software must comply with our apply product strategy to release into new markets.
We currently support two products:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "#optimised-apply"
          }}>{`Optimised Apply`}</a></p>
        <p parentName="li">{`Candidates complete SEEK’s Apply Form without leaving our website or app.
The SEEK API notifies you when an application is ready for export,
and hirers can continue to manage applicants within your software.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "#apply-with-seek"
          }}>{`Apply with SEEK`}</a></p>
        <p parentName="li">{`Candidates link out to an external careers website to submit their application,
with an option to pre-fill fields using their SEEK Profile.`}</p>
      </li>
    </ol>
    <p>{`We have outlined the product options that you should offer going forward in the table below.
The strategy differs based on what you previously built for the Australia & New Zealand markets,
and in essence, you can release existing apply products across the Asia Pacific with minimal effort.`}</p>

    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p">{`Optimised Apply in ANZ?`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p">{`Apply with SEEK in ANZ?`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Product options for APAC`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><ol><li><p>{`Revisit `}<a parentName="td" {...{
                    "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/questionnaires"
                  }}>{`questionnaire options`}</a>{` and offer both products`}</p></li></ol></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="neutral" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><ol><li><p>{`Revisit `}<a parentName="td" {...{
                    "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/questionnaires"
                  }}>{`questionnaire options`}</a>{` and offer Optimised Apply only`}</p></li></ol></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="neutral" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><ol><li><p>{`Build Optimised Apply and offer both products`}</p><p><strong>{`or`}</strong></p></li><li><p>{`Offer Apply with SEEK only`}</p></li></ol></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><ol><li><p>{`Build and offer Optimised Apply only`}</p><p><strong>{`or`}</strong></p></li><li><p>{`Commit to build an apply product after migration`}</p></li></ol></td>
        </tr>
      </tbody>
    </table>
    <p>{`Some Jobstreet and Jobsdb hirers receive applications via email, which is no longer supported in the SEEK API.
SEEK instructed the affected hirers to use our employer website to screen applicants or link out to an external careers website,
and managed the change as a separate prerequisite to the `}<a parentName="p" {...{
        "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-4-transitioning-hirers"
      }}>{`hirer transition`}</a>{`.`}</p>
    <h3 {...{
      "id": "optimised-apply"
    }}>{`Optimised Apply`}</h3>
    <p><a parentName="p" {...{
        "href": "/use-cases/application-export"
      }}>{`Optimised Apply`}</a>{` works in new markets without significant changes.
If you have implemented this product in Australia & New Zealand,
we recommend revisiting the `}<a parentName="p" {...{
        "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/questionnaires"
      }}>{`questionnaire options`}</a>{`.
SEEK’s Screening Questions are a highly requested feature and we recently added a simpler integration option in the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/questionnaires/v2/panel"
      }}>{`Questionnaire Panel`}</a>{`.`}</p>
    <p>{`SEEK Profile information has been migrated from our Jobstreet & Jobsdb brands,
and depth may be limited until candidates review and update their profiles.
For example, structured `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CandidateProfile/field/education"
      }}>{`education history`}</a>{` fields were not migrated for Jobstreet candidate profiles.`}</p>
    <p>{`The SEEK API does not translate user input and serves SEEK Profile information as supplied by the candidate.
As a result, your software may need to handle multilingual candidate data.`}</p>
    <h3 {...{
      "id": "apply-with-seek"
    }}>{`Apply with SEEK`}</h3>
    <p>{`The legacy `}<a parentName="p" {...{
        "href": "https://devportal.seek.com.au/apply-with-seek/overview"
      }}>{`Apply with SEEK`}</a>{` product works in new markets without significant changes.`}</p>
    <p>{`The SEEK API does not translate user input and serves SEEK Profile information as supplied by the candidate.
As a result, your software may need to handle multilingual candidate data.`}</p>
    <h2 {...{
      "id": "talent-search"
    }}>{`Talent Search`}</h2>
    <p><a parentName="p" {...{
        "href": "/use-cases/proactive-sourcing"
      }}>{`Talent Search Connect`}</a>{` is not yet available for hirer accounts registered in markets outside of Australia & New Zealand.`}</p>
    <p>{`However, existing integrations benefit from a unified SEEK platform.
Hirers can `}<a parentName="p" {...{
        "href": "/use-cases/proactive-sourcing/profile-purchases"
      }}>{`purchase profiles`}</a>{` and synchronise `}<a parentName="p" {...{
        "href": "/use-cases/proactive-sourcing/sharing-a-candidate"
      }}>{`uploaded candidates`}</a>{` across an APAC candidate pool.`}</p>
    <p>{`The SEEK API does not translate user input and serves SEEK Profile information as supplied by the candidate.
As a result, your software may need to handle multilingual candidate data.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;