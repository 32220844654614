import { useMutation } from '@apollo/client';
import { Alert, Dialog, Stack, Text, useToast } from 'braid-design-system';

import type {
  UpdateWebhookDeliveryMutation,
  UpdateWebhookDeliveryMutationVariables,
  WebhookDetailsQuery,
} from 'src/types/graphql';

import { UPDATE_WEBHOOK_DELIVERY_CONFIGURATION } from '../../queries';

import {
  FormProvider,
  UpdateDeliveryForm,
  type UpdateDeliveryFormData,
} from './UpdateDeliveryForm';

interface UpdateDeliveryDialogProps {
  subscription: NonNullable<WebhookDetailsQuery['webhookSubscription']>;
  show: boolean;
  closeDialog: () => void;
}

export const UpdateDeliveryDialog = (props: UpdateDeliveryDialogProps) => {
  const { closeDialog, show, subscription } = props;

  const showToast = useToast();

  const [updateDelivery, updateDeliveryResult] = useMutation<
    UpdateWebhookDeliveryMutation,
    UpdateWebhookDeliveryMutationVariables
  >(UPDATE_WEBHOOK_DELIVERY_CONFIGURATION, {
    onCompleted: (data) => {
      switch (data.updateWebhookSubscriptionDeliveryConfiguration?.__typename) {
        case 'UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict':
          showToast({
            tone: 'critical',
            message: 'A subscription with these details already exists',
          });
          return;

        case 'UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success':
          closeDialog();

          showToast({
            tone: 'positive',
            message: 'Subscription updated successfully',
          });
          return;
        case undefined:
          showToast({
            tone: 'critical',
            message: 'Something went wrong. Please try again later.',
          });
          return;
      }
    },
  });

  const onSubmit = (values: UpdateDeliveryFormData) => {
    updateDelivery({
      variables: {
        subscriptionId: subscription.id.value,
        url: values.url,
        maxEventsPerAttempt: parseInt(values.maxEventsPerAttempt, 10),
      },
    });
  };

  return (
    <Dialog
      id="updateDeliveryDialog"
      title="Update subscription delivery"
      open={show}
      onClose={closeDialog}
    >
      <FormProvider
        initialValues={{
          url: subscription.url,
          maxEventsPerAttempt: String(subscription.maxEventsPerAttempt),
        }}
      >
        {({ handleSubmit }) => (
          <Stack space="medium">
            {updateDeliveryResult.error && (
              <Alert tone="critical">
                <Stack space="small">
                  <Text>We couldn’t update your subscription.</Text>
                  <Text size="small">{updateDeliveryResult.error.message}</Text>
                </Stack>
              </Alert>
            )}
            <UpdateDeliveryForm
              loading={updateDeliveryResult.loading}
              onSubmit={handleSubmit((values) => onSubmit(values))}
              closeDialog={closeDialog}
            />
          </Stack>
        )}
      </FormProvider>
    </Dialog>
  );
};
