import { gql } from '@apollo/client';

export const WEBHOOK_SUBSCRIPTIONS = gql`
  query webhookSubscriptions(
    $after: String
    $filter: WebhookSubscriptionsFilterInput
    $first: Int
    $requestsSince: DateTime!
    $schemeId: String!
  ) {
    webhookSubscriptions(
      after: $after
      filter: $filter
      first: $first
      schemeId: $schemeId
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          hirerId {
            value
          }
          hirer {
            # Uniquely identify a hirer object per our cache policy 🙃
            id {
              value
            }
            name
          }
          id {
            value
          }
          eventTypeCode
          url
          createDateTime

          # Retrieve deliverability in the same request
          webhookRequests(last: 15, filter: { afterDateTime: $requestsSince }) {
            edges {
              node {
                requestId
                descriptionCode
              }
            }
          }
        }
      }
    }
  }
`;
