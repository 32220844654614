
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "querying-a-job-ad"
    }}>{`Querying a job ad`}</h1>
    <h2 {...{
      "id": "operations"
    }}>{`Operations`}</h2>
    <h3 {...{
      "id": "positionprofile"
    }}>{`positionProfile`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/positionProfile"
      }}><inlineCode parentName="a">{`positionProfile`}</inlineCode>{` query`}</a>{` returns the current state of a job ad.
In addition to the fields you provided during posting, it returns the URL of a live job ad and the end date of the posting.`}</p>
    <p>{`Posting job ads to the SEEK job board is an asynchronous process.
Until the job ad has gone live the `}<inlineCode parentName="p">{`positionProfile`}</inlineCode>{` query will return `}<inlineCode parentName="p">{`null`}</inlineCode>{`.
The `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad#positionprofileposted"
      }}><inlineCode parentName="a">{`PositionProfilePosted`}</inlineCode>{` event`}</a>{` can be used to trigger a query of the position profile once it’s available.`}</p>
    <p>{`You can determine if a job ad is closed based on its `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PostingInstruction/field/end"
      }}><inlineCode parentName="a">{`postingInstructions.end`}</inlineCode>{` field`}</a>{`.
If the posting end date is in the past then the job ad was closed on the specified date.
The `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/closing-a-job-ad#positionprofileclosed"
      }}><inlineCode parentName="a">{`PositionProfileClosed`}</inlineCode>{` event`}</a>{` is emitted when a job ad has been closed.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PositionProfile"
      }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{` object will remain for 180 days past its close date, after which the `}<inlineCode parentName="p">{`positionProfile`}</inlineCode>{` query will return `}<inlineCode parentName="p">{`null`}</inlineCode>{`.
Your software must store any data it needs to access after the 180 day period.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($id: String!) {\\n  positionProfile(id: $id) {\\n    profileId {\\n      value\\n    }\\n    positionTitle\\n    positionUri\\n    postingInstructions {\\n      start\\n      end\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":27,\"column\":1,\"offset\":1487},\"end\":{\"line\":41,\"column\":4,\"offset\":1681},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"id\\\": \\\"seekAnzPublicTest:positionProfile:jobAd:27cuZeA47\\\"\\n}\",\"position\":{\"start\":{\"line\":43,\"column\":1,\"offset\":1683},\"end\":{\"line\":47,\"column\":4,\"offset\":1758},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"positionProfile\\\": {\\n    \\\"profileId\\\": {\\n      \\\"value\\\": \\\"seekAnzPublicTest:positionProfile:jobAd:27cuZeA47\\\"\\n    },\\n    \\\"positionTitle\\\": \\\"Customer Mobility Director\\\",\\n    \\\"positionUri\\\": \\\"https://example.seek.co.nz/job/30000001\\\",\\n    \\\"postingInstructions\\\": [\\n      {\\n        \\\"start\\\": \\\"2019-08-25T21:24:16.735Z\\\",\\n        \\\"end\\\": \\\"2038-01-01T00:00:00.000Z\\\"\\n      }\\n    ]\\n  }\\n}\",\"position\":{\"start\":{\"line\":49,\"column\":1,\"offset\":1760},\"end\":{\"line\":65,\"column\":4,\"offset\":2146},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($id:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "positionProfile(id:": true,
        "$id)": true,
        "profileId": true,
        "value\\n": true,
        "}\\n": true,
        "positionTitle\\n": true,
        "positionUri\\n": true,
        "postingInstructions": true,
        "start\\n": true,
        "end\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":27,\"column\":1,\"offset\":1487},\"end\":{\"line\":41,\"column\":4,\"offset\":1681},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"id\\\":": true,
        "\\\"seekAnzPublicTest:positionProfile:jobAd:27cuZeA47\\\"\\n}\",\"position\":{\"start\":{\"line\":43,\"column\":1,\"offset\":1683},\"end\":{\"line\":47,\"column\":4,\"offset\":1758},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"positionProfile\\\":": true,
        "\\\"profileId\\\":": true,
        "\\\"value\\\":": true,
        "\\\"seekAnzPublicTest:positionProfile:jobAd:27cuZeA47\\\"\\n": true,
        "},\\n": true,
        "\\\"positionTitle\\\":": true,
        "\\\"Customer": true,
        "Mobility": true,
        "Director\\\",\\n": true,
        "\\\"positionUri\\\":": true,
        "\\\"https://example.seek.co.nz/job/30000001\\\",\\n": true,
        "\\\"postingInstructions\\\":": true,
        "[\\n": true,
        "\\\"start\\\":": true,
        "\\\"2019-08-25T21:24:16.735Z\\\",\\n": true,
        "\\\"end\\\":": true,
        "\\\"2038-01-01T00:00:00.000Z\\\"\\n": true,
        "]\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":49,\"column\":1,\"offset\":1760},\"end\":{\"line\":65,\"column\":4,\"offset\":2146},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query ($id: String!) {
  positionProfile(id: $id) {
    profileId {
      value
    }
    positionTitle
    positionUri
    postingInstructions {
      start
      end
    }
  }
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;