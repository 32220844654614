import Home from 'src/pages/';
import AuthBrowserTokens from 'src/pages/auth/browser-tokens.mdx';
import AuthHirerRelationships from 'src/pages/auth/hirer-relationships.mdx';
import AuthPartnerTokens from 'src/pages/auth/partner-tokens.mdx';
import Auth from 'src/pages/auth.mdx';
import EventsPolling from 'src/pages/events/polling.mdx';
import EventsWebhooks from 'src/pages/events/webhooks.mdx';
import Events from 'src/pages/events.mdx';
import GraphqlErrorResponses from 'src/pages/graphql/error-responses.mdx';
import GraphqlInPractice from 'src/pages/graphql/in-practice.mdx';
import GraphqlPlaygroundMockObjects from 'src/pages/graphql/playground/mock-objects.mdx';
import GraphqlPlayground from 'src/pages/graphql/playground.mdx';
import GraphqlSeekApiConventions from 'src/pages/graphql/seek-api-conventions.mdx';
import Graphql from 'src/pages/graphql.mdx';
import GuidesMonitoring from 'src/pages/guides/monitoring.mdx';
import Guides from 'src/pages/guides.mdx';
import IntroductionBrowserSupport from 'src/pages/introduction/browser-support.mdx';
import IntroductionPanels from 'src/pages/introduction/panels.mdx';
import IntroductionReleaseNotes from 'src/pages/introduction/release-notes';
import IntroductionWebComponents from 'src/pages/introduction/web-components.mdx';
import Introduction from 'src/pages/introduction.mdx';
import MigrationGuidesArchived from 'src/pages/migration-guides/archived.mdx';
import MigrationGuidesJobPostingApiPhase1Auth from 'src/pages/migration-guides/job-posting-api/phase-1-auth.mdx';
import MigrationGuidesJobPostingApiPhase2ObjectIdentifiers from 'src/pages/migration-guides/job-posting-api/phase-2-object-identifiers.mdx';
import MigrationGuidesJobPostingApiPhase3AdSelection from 'src/pages/migration-guides/job-posting-api/phase-3-ad-selection.mdx';
import MigrationGuidesJobPostingApiPhase4ManagingJobAds from 'src/pages/migration-guides/job-posting-api/phase-4-managing-job-ads.mdx';
import MigrationGuidesJobPostingApiPhase5EventDelivery from 'src/pages/migration-guides/job-posting-api/phase-5-event-delivery.mdx';
import MigrationGuidesJobPostingApi from 'src/pages/migration-guides/job-posting-api.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase1DeveloperDashboard from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-1-developer-dashboard.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingAdSelectionGraphqlUserExperienceGuide from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/graphql/user-experience-guide.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingAdSelectionGraphql from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/graphql.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingAdSelectionPanel from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/panel.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingAdSelection from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingBranding from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/branding.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingContentLocalisation from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/content-localisation.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingJobAdPreview from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/job-ad-preview.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingJobCategories from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/job-categories.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingLocations from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/locations.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingQuestionnaires from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/questionnaires.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingSalary from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/salary.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPosting from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase3ApplyAndProactiveSourcing from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-3-apply-and-proactive-sourcing.mdx';
import MigrationGuidesJobstreetAndJobsdbUpliftPhase4TransitioningHirers from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift/phase-4-transitioning-hirers.mdx';
import MigrationGuidesJobstreetAndJobsdbUplift from 'src/pages/migration-guides/jobstreet-and-jobsdb-uplift.mdx';
import MigrationGuidesOverview from 'src/pages/migration-guides/overview.mdx';
import MigrationGuidesSae from 'src/pages/migration-guides/sae.mdx';
import MigrationGuidesSeekAdvertiserIds from 'src/pages/migration-guides/seek-advertiser-ids.mdx';
import UseCasesAdPerformanceCompletionSignal from 'src/pages/use-cases/ad-performance/completion-signal.mdx';
import UseCasesAdPerformancePanel from 'src/pages/use-cases/ad-performance/panel.mdx';
import UseCasesAdPerformance from 'src/pages/use-cases/ad-performance.mdx';
import UseCasesApplicationExportAttachments from 'src/pages/use-cases/application-export/attachments.mdx';
import UseCasesApplicationExportCandidateProfiles from 'src/pages/use-cases/application-export/candidate-profiles.mdx';
import UseCasesApplicationExportQuestionnaireSubmissions from 'src/pages/use-cases/application-export/questionnaire-submissions.mdx';
import UseCasesApplicationExportUserExperienceGuide from 'src/pages/use-cases/application-export/user-experience-guide.mdx';
import UseCasesApplicationExport from 'src/pages/use-cases/application-export.mdx';
import UseCasesApplyWithSeekDisplayButton from 'src/pages/use-cases/apply-with-seek/display-button.mdx';
import UseCasesApplyWithSeekHandleAuthorization from 'src/pages/use-cases/apply-with-seek/handle-authorization.mdx';
import UseCasesApplyWithSeekPopulateForm from 'src/pages/use-cases/apply-with-seek/populate-form.mdx';
import UseCasesApplyWithSeekRetrieveCandidate from 'src/pages/use-cases/apply-with-seek/retrieve-candidate.mdx';
import UseCasesApplyWithSeekRetrieveResume from 'src/pages/use-cases/apply-with-seek/retrieve-resume.mdx';
import UseCasesApplyWithSeekSendCompletionSignal from 'src/pages/use-cases/apply-with-seek/send-completion-signal.mdx';
import UseCasesApplyWithSeek from 'src/pages/use-cases/apply-with-seek.mdx';
import UseCasesJobPostingAdSelectionGraphql from 'src/pages/use-cases/job-posting/ad-selection/graphql.mdx';
import UseCasesJobPostingAdSelectionPanel from 'src/pages/use-cases/job-posting/ad-selection/panel.mdx';
import UseCasesJobPostingAdSelectionV2Features from 'src/pages/use-cases/job-posting/ad-selection/v2/features.mdx';
import UseCasesJobPostingAdSelectionV2Graphql from 'src/pages/use-cases/job-posting/ad-selection/v2/graphql.mdx';
import UseCasesJobPostingAdSelectionV2Panel from 'src/pages/use-cases/job-posting/ad-selection/v2/panel.mdx';
import UseCasesJobPostingAdSelectionV2Testing from 'src/pages/use-cases/job-posting/ad-selection/v2/testing.mdx';
import UseCasesJobPostingAdSelection from 'src/pages/use-cases/job-posting/ad-selection.mdx';
import UseCasesJobPostingApplicationMethods from 'src/pages/use-cases/job-posting/application-methods.mdx';
import UseCasesJobPostingArchivedAdSelection from 'src/pages/use-cases/job-posting/archived/ad-selection.mdx';
import UseCasesJobPostingArchivedQuestionnaires from 'src/pages/use-cases/job-posting/archived/questionnaires.mdx';
import UseCasesJobPostingArchived from 'src/pages/use-cases/job-posting/archived.mdx';
import UseCasesJobPostingBranding from 'src/pages/use-cases/job-posting/branding.mdx';
import UseCasesJobPostingContactPeople from 'src/pages/use-cases/job-posting/contact-people.mdx';
import UseCasesJobPostingFeatures from 'src/pages/use-cases/job-posting/features.mdx';
import UseCasesJobPostingIdentifiersAndReferences from 'src/pages/use-cases/job-posting/identifiers-and-references.mdx';
import UseCasesJobPostingJobCategories from 'src/pages/use-cases/job-posting/job-categories.mdx';
import UseCasesJobPostingLocations from 'src/pages/use-cases/job-posting/locations.mdx';
import UseCasesJobPostingManagingJobAdsClosingAJobAd from 'src/pages/use-cases/job-posting/managing-job-ads/closing-a-job-ad.mdx';
import UseCasesJobPostingManagingJobAdsPostingAJobAd from 'src/pages/use-cases/job-posting/managing-job-ads/posting-a-job-ad.mdx';
import UseCasesJobPostingManagingJobAdsPreviewingAJobAd from 'src/pages/use-cases/job-posting/managing-job-ads/previewing-a-job-ad.mdx';
import UseCasesJobPostingManagingJobAdsQueryingAJobAd from 'src/pages/use-cases/job-posting/managing-job-ads/querying-a-job-ad.mdx';
import UseCasesJobPostingManagingJobAdsUpdatingAJobAd from 'src/pages/use-cases/job-posting/managing-job-ads/updating-a-job-ad.mdx';
import UseCasesJobPostingManagingJobAds from 'src/pages/use-cases/job-posting/managing-job-ads.mdx';
import UseCasesJobPostingPositionOpenings from 'src/pages/use-cases/job-posting/position-openings.mdx';
import UseCasesJobPostingQuestionnairesAtsQuestions from 'src/pages/use-cases/job-posting/questionnaires/ats-questions.mdx';
import UseCasesJobPostingQuestionnairesCustomPanel from 'src/pages/use-cases/job-posting/questionnaires/custom-panel.mdx';
import UseCasesJobPostingQuestionnairesPanel from 'src/pages/use-cases/job-posting/questionnaires/panel.mdx';
import UseCasesJobPostingQuestionnairesV2Panel from 'src/pages/use-cases/job-posting/questionnaires/v2/panel.mdx';
import UseCasesJobPostingQuestionnaires from 'src/pages/use-cases/job-posting/questionnaires.mdx';
import UseCasesJobPostingReflectingJobAds from 'src/pages/use-cases/job-posting/reflecting-job-ads.mdx';
import UseCasesJobPostingSalary from 'src/pages/use-cases/job-posting/salary.mdx';
import UseCasesJobPostingUserExperienceGuide from 'src/pages/use-cases/job-posting/user-experience-guide.mdx';
import UseCasesJobPosting from 'src/pages/use-cases/job-posting.mdx';
import UseCasesProactiveSourcingInteractionHistory from 'src/pages/use-cases/proactive-sourcing/interaction-history.mdx';
import UseCasesProactiveSourcingProfilePurchases from 'src/pages/use-cases/proactive-sourcing/profile-purchases.mdx';
import UseCasesProactiveSourcingSharingACandidate from 'src/pages/use-cases/proactive-sourcing/sharing-a-candidate.mdx';
import UseCasesProactiveSourcingUnpostedPositions from 'src/pages/use-cases/proactive-sourcing/unposted-positions.mdx';
import UseCasesProactiveSourcingWidgets from 'src/pages/use-cases/proactive-sourcing/widgets.mdx';
import UseCasesProactiveSourcing from 'src/pages/use-cases/proactive-sourcing.mdx';
import UseCasesTestCases from 'src/pages/use-cases/test-cases.mdx';

import type { TemplateComponent } from './types';

export const mdxDocuments: Record<string, MDX.Document> = {
  '/auth/browser-tokens': AuthBrowserTokens,
  '/auth/hirer-relationships': AuthHirerRelationships,
  '/auth/partner-tokens': AuthPartnerTokens,
  '/auth': Auth,
  '/events/polling': EventsPolling,
  '/events/webhooks': EventsWebhooks,
  '/events': Events,
  '/graphql/error-responses': GraphqlErrorResponses,
  '/graphql/in-practice': GraphqlInPractice,
  '/graphql/playground/mock-objects': GraphqlPlaygroundMockObjects,
  '/graphql/playground': GraphqlPlayground,
  '/graphql/seek-api-conventions': GraphqlSeekApiConventions,
  '/graphql': Graphql,
  '/guides/monitoring': GuidesMonitoring,
  '/guides': Guides,
  '/introduction/browser-support': IntroductionBrowserSupport,
  '/introduction/panels': IntroductionPanels,
  '/introduction/web-components': IntroductionWebComponents,
  '/introduction': Introduction,
  '/migration-guides/archived': MigrationGuidesArchived,
  '/migration-guides/job-posting-api/phase-1-auth':
    MigrationGuidesJobPostingApiPhase1Auth,
  '/migration-guides/job-posting-api/phase-2-object-identifiers':
    MigrationGuidesJobPostingApiPhase2ObjectIdentifiers,
  '/migration-guides/job-posting-api/phase-3-ad-selection':
    MigrationGuidesJobPostingApiPhase3AdSelection,
  '/migration-guides/job-posting-api/phase-4-managing-job-ads':
    MigrationGuidesJobPostingApiPhase4ManagingJobAds,
  '/migration-guides/job-posting-api/phase-5-event-delivery':
    MigrationGuidesJobPostingApiPhase5EventDelivery,
  '/migration-guides/job-posting-api': MigrationGuidesJobPostingApi,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-1-developer-dashboard':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase1DeveloperDashboard,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/graphql/user-experience-guide':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingAdSelectionGraphqlUserExperienceGuide,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/graphql':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingAdSelectionGraphql,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/panel':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingAdSelectionPanel,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingAdSelection,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/branding':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingBranding,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/content-localisation':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingContentLocalisation,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/job-ad-preview':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingJobAdPreview,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/job-categories':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingJobCategories,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/locations':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingLocations,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/questionnaires':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingQuestionnaires,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/salary':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPostingSalary,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase2JobPosting,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-3-apply-and-proactive-sourcing':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase3ApplyAndProactiveSourcing,
  '/migration-guides/jobstreet-and-jobsdb-uplift/phase-4-transitioning-hirers':
    MigrationGuidesJobstreetAndJobsdbUpliftPhase4TransitioningHirers,
  '/migration-guides/jobstreet-and-jobsdb-uplift':
    MigrationGuidesJobstreetAndJobsdbUplift,
  '/migration-guides/overview': MigrationGuidesOverview,
  '/migration-guides/sae': MigrationGuidesSae,
  '/migration-guides/seek-advertiser-ids': MigrationGuidesSeekAdvertiserIds,
  '/use-cases/ad-performance/completion-signal':
    UseCasesAdPerformanceCompletionSignal,
  '/use-cases/ad-performance/panel': UseCasesAdPerformancePanel,
  '/use-cases/ad-performance': UseCasesAdPerformance,
  '/use-cases/application-export/attachments':
    UseCasesApplicationExportAttachments,
  '/use-cases/application-export/candidate-profiles':
    UseCasesApplicationExportCandidateProfiles,
  '/use-cases/application-export/questionnaire-submissions':
    UseCasesApplicationExportQuestionnaireSubmissions,
  '/use-cases/application-export/user-experience-guide':
    UseCasesApplicationExportUserExperienceGuide,
  '/use-cases/application-export': UseCasesApplicationExport,
  '/use-cases/apply-with-seek/display-button':
    UseCasesApplyWithSeekDisplayButton,
  '/use-cases/apply-with-seek/handle-authorization':
    UseCasesApplyWithSeekHandleAuthorization,
  '/use-cases/apply-with-seek/populate-form': UseCasesApplyWithSeekPopulateForm,
  '/use-cases/apply-with-seek/retrieve-candidate':
    UseCasesApplyWithSeekRetrieveCandidate,
  '/use-cases/apply-with-seek/retrieve-resume':
    UseCasesApplyWithSeekRetrieveResume,
  '/use-cases/apply-with-seek/send-completion-signal':
    UseCasesApplyWithSeekSendCompletionSignal,
  '/use-cases/apply-with-seek': UseCasesApplyWithSeek,
  '/use-cases/job-posting/ad-selection/graphql':
    UseCasesJobPostingAdSelectionGraphql,
  '/use-cases/job-posting/ad-selection/panel':
    UseCasesJobPostingAdSelectionPanel,
  '/use-cases/job-posting/ad-selection/v2/features':
    UseCasesJobPostingAdSelectionV2Features,
  '/use-cases/job-posting/ad-selection/v2/graphql':
    UseCasesJobPostingAdSelectionV2Graphql,
  '/use-cases/job-posting/ad-selection/v2/panel':
    UseCasesJobPostingAdSelectionV2Panel,
  '/use-cases/job-posting/ad-selection/v2/testing':
    UseCasesJobPostingAdSelectionV2Testing,
  '/use-cases/job-posting/ad-selection': UseCasesJobPostingAdSelection,
  '/use-cases/job-posting/application-methods':
    UseCasesJobPostingApplicationMethods,
  '/use-cases/job-posting/archived/ad-selection':
    UseCasesJobPostingArchivedAdSelection,
  '/use-cases/job-posting/archived/questionnaires':
    UseCasesJobPostingArchivedQuestionnaires,
  '/use-cases/job-posting/archived': UseCasesJobPostingArchived,
  '/use-cases/job-posting/branding': UseCasesJobPostingBranding,
  '/use-cases/job-posting/contact-people': UseCasesJobPostingContactPeople,
  '/use-cases/job-posting/features': UseCasesJobPostingFeatures,
  '/use-cases/job-posting/identifiers-and-references':
    UseCasesJobPostingIdentifiersAndReferences,
  '/use-cases/job-posting/job-categories': UseCasesJobPostingJobCategories,
  '/use-cases/job-posting/locations': UseCasesJobPostingLocations,
  '/use-cases/job-posting/managing-job-ads/closing-a-job-ad':
    UseCasesJobPostingManagingJobAdsClosingAJobAd,
  '/use-cases/job-posting/managing-job-ads/posting-a-job-ad':
    UseCasesJobPostingManagingJobAdsPostingAJobAd,
  '/use-cases/job-posting/managing-job-ads/previewing-a-job-ad':
    UseCasesJobPostingManagingJobAdsPreviewingAJobAd,
  '/use-cases/job-posting/managing-job-ads/querying-a-job-ad':
    UseCasesJobPostingManagingJobAdsQueryingAJobAd,
  '/use-cases/job-posting/managing-job-ads/updating-a-job-ad':
    UseCasesJobPostingManagingJobAdsUpdatingAJobAd,
  '/use-cases/job-posting/managing-job-ads': UseCasesJobPostingManagingJobAds,
  '/use-cases/job-posting/position-openings':
    UseCasesJobPostingPositionOpenings,
  '/use-cases/job-posting/questionnaires/ats-questions':
    UseCasesJobPostingQuestionnairesAtsQuestions,
  '/use-cases/job-posting/questionnaires/custom-panel':
    UseCasesJobPostingQuestionnairesCustomPanel,
  '/use-cases/job-posting/questionnaires/panel':
    UseCasesJobPostingQuestionnairesPanel,
  '/use-cases/job-posting/questionnaires/v2/panel':
    UseCasesJobPostingQuestionnairesV2Panel,
  '/use-cases/job-posting/questionnaires': UseCasesJobPostingQuestionnaires,
  '/use-cases/job-posting/reflecting-job-ads':
    UseCasesJobPostingReflectingJobAds,
  '/use-cases/job-posting/salary': UseCasesJobPostingSalary,
  '/use-cases/job-posting/user-experience-guide':
    UseCasesJobPostingUserExperienceGuide,
  '/use-cases/job-posting': UseCasesJobPosting,
  '/use-cases/proactive-sourcing/interaction-history':
    UseCasesProactiveSourcingInteractionHistory,
  '/use-cases/proactive-sourcing/profile-purchases':
    UseCasesProactiveSourcingProfilePurchases,
  '/use-cases/proactive-sourcing/sharing-a-candidate':
    UseCasesProactiveSourcingSharingACandidate,
  '/use-cases/proactive-sourcing/unposted-positions':
    UseCasesProactiveSourcingUnpostedPositions,
  '/use-cases/proactive-sourcing/widgets': UseCasesProactiveSourcingWidgets,
  '/use-cases/proactive-sourcing': UseCasesProactiveSourcing,
  '/use-cases/test-cases': UseCasesTestCases,
};

export const tsxComponents: Record<string, TemplateComponent> = {
  '/': Home,
  '/introduction/release-notes': IntroductionReleaseNotes,
};
