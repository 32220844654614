
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "job-posting-features"
    }}>{`Job Posting features`}</h4>
    <p>{`We’ve extended `}<a parentName="p" {...{
        "href": "/use-cases/job-posting"
      }}>{`Job Posting`}</a>{` with two new features:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Query the `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/locations#nearestlocations"
          }}>{`nearest locations`}</a>{` for a geolocation.`}</p>
        <p parentName="li">{`This enables you to suggest a SEEK location closest to an end user, or map to a SEEK location from an internal location hierarchy.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-scoobie-merged-code",
            "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($first: Int, $geoLocation: GeoLocationInput!, $schemeId: String!) {\\n  nearestLocations(\\n    first: $first\\n    geoLocation: $geoLocation\\n    schemeId: $schemeId\\n  ) {\\n    contextualName\\n  }\\n}\",\"position\":{\"start\":{\"line\":9,\"column\":3,\"offset\":268},\"end\":{\"line\":19,\"column\":6,\"offset\":500},\"indent\":[3,3,3,3,3,3,3,3,3,3]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"first\\\": 2,\\n  \\\"geoLocation\\\": {\\n    \\\"latitude\\\": -37.840935,\\n    \\\"longitude\\\": 144.946457\\n  },\\n  \\\"schemeId\\\": \\\"seekAnzPublicTest\\\"\\n}\",\"position\":{\"start\":{\"line\":21,\"column\":3,\"offset\":504},\"end\":{\"line\":30,\"column\":6,\"offset\":665},\"indent\":[3,3,3,3,3,3,3,3,3]}}]",
            "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
            "($first:": true,
            "Int,": true,
            "$geoLocation:": true,
            "GeoLocationInput!,": true,
            "$schemeId:": true,
            "String!)": true,
            "{\\n": true,
            "": true,
            "nearestLocations(\\n": true,
            "first:": true,
            "$first\\n": true,
            "geoLocation:": true,
            "$geoLocation\\n": true,
            "schemeId:": true,
            "$schemeId\\n": true,
            ")": true,
            "contextualName\\n": true,
            "}\\n}\",\"position\":{\"start\":{\"line\":9,\"column\":3,\"offset\":268},\"end\":{\"line\":19,\"column\":6,\"offset\":500},\"indent\":[3,3,3,3,3,3,3,3,3,3]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
            "\\\"first\\\":": true,
            "2,\\n": true,
            "\\\"geoLocation\\\":": true,
            "\\\"latitude\\\":": true,
            "-37.840935,\\n": true,
            "\\\"longitude\\\":": true,
            "144.946457\\n": true,
            "},\\n": true,
            "\\\"schemeId\\\":": true,
            "\\\"seekAnzPublicTest\\\"\\n}\",\"position\":{\"start\":{\"line\":21,\"column\":3,\"offset\":504},\"end\":{\"line\":30,\"column\":6,\"offset\":665},\"indent\":[3,3,3,3,3,3,3,3,3]}}]": true
          }}>{`query ($first: Int, $geoLocation: GeoLocationInput!, $schemeId: String!) {
  nearestLocations(
    first: $first
    geoLocation: $geoLocation
    schemeId: $schemeId
  ) {
    contextualName
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Query the `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/branding"
          }}>{`brands`}</a>{` available for StandOut and Premium ads.`}</p>
        <p parentName="li">{`This enables you to include a brand preview and picker in your posting flow.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-scoobie-merged-code",
            "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($after: String, $first: Int, $hirerId: String!) {\\n  advertisementBrandings(after: $after, first: $first, hirerId: $hirerId) {\\n    edges {\\n      node {\\n        name\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":36,\"column\":3,\"offset\":811},\"end\":{\"line\":46,\"column\":6,\"offset\":1036},\"indent\":[3,3,3,3,3,3,3,3,3,3]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"after\\\": null,\\n  \\\"first\\\": 2,\\n  \\\"hirerId\\\": \\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\"\\n}\",\"position\":{\"start\":{\"line\":48,\"column\":3,\"offset\":1040},\"end\":{\"line\":54,\"column\":6,\"offset\":1158},\"indent\":[3,3,3,3,3,3]}}]",
            "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
            "($after:": true,
            "String,": true,
            "$first:": true,
            "Int,": true,
            "$hirerId:": true,
            "String!)": true,
            "{\\n": true,
            "": true,
            "advertisementBrandings(after:": true,
            "$after,": true,
            "first:": true,
            "$first,": true,
            "hirerId:": true,
            "$hirerId)": true,
            "edges": true,
            "node": true,
            "name\\n": true,
            "}\\n": true,
            "}\\n}\",\"position\":{\"start\":{\"line\":36,\"column\":3,\"offset\":811},\"end\":{\"line\":46,\"column\":6,\"offset\":1036},\"indent\":[3,3,3,3,3,3,3,3,3,3]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
            "\\\"after\\\":": true,
            "null,\\n": true,
            "\\\"first\\\":": true,
            "2,\\n": true,
            "\\\"hirerId\\\":": true,
            "\\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\"\\n}\",\"position\":{\"start\":{\"line\":48,\"column\":3,\"offset\":1040},\"end\":{\"line\":54,\"column\":6,\"offset\":1158},\"indent\":[3,3,3,3,3,3]}}]": true
          }}>{`query ($after: String, $first: Int, $hirerId: String!) {
  advertisementBrandings(after: $after, first: $first, hirerId: $hirerId) {
    edges {
      node {
        name
      }
    }
  }
}
`}</code></pre>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;