
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "step-2---handle-authorization"
    }}>{`Step 2 - Handle authorization`}</h1>
    <p>{`When the user clicks the “Apply with SEEK” button, your software must redirect the candidate to the URL supplied by the `}<a parentName="p" {...{
        "href": "/use-cases/apply-with-seek/display-button"
      }}><inlineCode parentName="a">{`applyWithSeekButton`}</inlineCode>{` query`}</a>{` to complete authorization.`}</p>
    <p>{`The candidate will then see the Apply with SEEK Authorization page.
If the candidate is logged in, they will see the image below, otherwise they will see our login screen first.`}</p>
    <p><img alt="Apply with SEEK Authorization page" data-scoobie-style="default" src={require('./awsk-auth-page.png')} title="" /></p>
    <p>{`If the candidate clicks `}<inlineCode parentName="p">{`Allow Access`}</inlineCode>{` they will be redirected to your supplied `}<inlineCode parentName="p">{`redirectUri`}</inlineCode>{` with additional parameters in the query string.`}</p>
    <p>{`For example, if your `}<inlineCode parentName="p">{`redirectUri`}</inlineCode>{` was `}<inlineCode parentName="p">{`https://www.example.com/job/123445/`}</inlineCode>{`, then after authorization the candidate would be redirected to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`https://www.example.com/job/123445/apply?candidateid=seekAnzPublicTest:candidate:feed:5PGXAHysiXhtA9JUqhyM8hhzMuWMPA&seek-token=eyJqb2JJZCI6MCwiY2FuZGlkYXRlSWQiOjAsInNlc3Npb25JZCI6IiJ9
`}</code></pre>
    <p>{`The values returned in the redirect are:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Parameter`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`candidateid`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`A unique identifier that can be used to retrieve SEEK Profile information.`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`seek-token`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`A token that should be saved by your software to be used as part of the Completion Signal. This should override any `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` received from the query string when the candidate initially arrived from SEEK.`}</p></td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;