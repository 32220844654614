import {
  ApolloClient,
  ApolloProvider,
  type FieldFunctionOptions,
  type FieldPolicy,
  InMemoryCache,
  type NormalizedCacheObject,
  type Reference,
  type StoreObject,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import React, { type ReactNode, useMemo } from 'react';
import { apolloTypePolicies } from 'wingman-fe';

import { useTracingHeaders } from 'src/hooks/tracingHeaders';
import seekApiFragments from 'src/types/seek-api-fragments';
const { possibleTypes } = seekApiFragments;

import { useEnvironmentConfig } from './environment';
import { useStaticRender } from './staticRender';

export const readNestedStringField = (
  readField: FieldFunctionOptions['readField'],
  fieldPath: string[],
  from: unknown,
): string | undefined => {
  let field = from as Reference | StoreObject | undefined;

  for (const fieldName of fieldPath) {
    field = readField<Reference | StoreObject>(fieldName, field);
  }

  return typeof field === 'string' ? field : undefined;
};

const dashboardTypePolicies = () => {
  const conservativePaginationKeyArgs = ['after', 'before', 'first', 'last'];
  const typePolicies = apolloTypePolicies({
    paginationPolicy: 'infinite-scroll',
  });

  // Apply conservative type policy for BrandSelect pagination
  // TODO: consider upstreaming this to Wingman as a configuration/preset

  typePolicies.AdvertisementBrandingsConnection = {};

  const advertisementBrandingsQueryPolicy = typePolicies.Query?.fields
    ?.advertisementBrandings as FieldPolicy | undefined;

  if (!advertisementBrandingsQueryPolicy) {
    throw new Error(
      'Missing Query.fields.advertisementBrandings in apolloTypePolicies',
    );
  }

  advertisementBrandingsQueryPolicy.keyArgs = [
    'filter',
    'hirerId',
    ...conservativePaginationKeyArgs,
  ];

  return typePolicies;
};

interface ApolloEnvironmentProviderProps {
  children: ReactNode;
}

const criticalErrorsOnlyTrackingClientContext =
  React.createContext<ApolloClient<NormalizedCacheObject> | null>(null);

export const ApolloEnvironmentProvider = ({
  children,
}: ApolloEnvironmentProviderProps) => {
  const { baseUrls } = useEnvironmentConfig();
  const staticRender = useStaticRender();
  const auth = useAuth0();
  const tracingHeaders = useTracingHeaders();

  const fakeFetch = () =>
    Promise.resolve({
      status: 200,
      text: () => Promise.resolve(JSON.stringify({ data: null })),
    } as any);

  const clients = useMemo(() => {
    const clientLink = createHttpLink({
      fetch: global.fetch ?? fakeFetch,
      uri: `${baseUrls.graphql}/graphql`,
    });

    const authMiddleware = setContext(
      async (_, { headers: existingHeaders }) => {
        const token = await auth.getAccessTokenSilently();
        return {
          uri: `${baseUrls.graphql}/graphql`,
          headers: {
            ...tracingHeaders,
            ...existingHeaders,
            Authorization: `Bearer ${token}`,
          },
        };
      },
    );

    const base = {
      cache: new InMemoryCache({
        possibleTypes,
        typePolicies: dashboardTypePolicies(),
      }),
      ssrMode: staticRender,
      defaultOptions: {
        query: { errorPolicy: 'all' },
        watchQuery: { errorPolicy: 'all' },
        mutate: { errorPolicy: 'all' },
      },
    } as const;

    return {
      criticalErrorsOnlyTrackingClient: new ApolloClient({
        ...base,
        link: authMiddleware
          .concat(
            onError(({ graphQLErrors }) => {
              if (
                graphQLErrors?.length &&
                !graphQLErrors.every((error) =>
                  ['FORBIDDEN', 'BAD_USER_INPUT'].includes(
                    (error.extensions?.code ?? '') as string,
                  ),
                )
              ) {
                datadogRum.addError(new Error(graphQLErrors[0].message), {
                  graphQLErrors,
                });
              }
            }),
          )
          .concat(clientLink),
      }),
      errorTrackingClient: new ApolloClient({
        ...base,
        link: authMiddleware
          .concat(
            onError(({ graphQLErrors }) => {
              if (graphQLErrors?.length) {
                datadogRum.addError(new Error(graphQLErrors[0].message), {
                  graphQLErrors,
                });
              }
            }),
          )
          .concat(clientLink),
      }),
    };
  }, [auth, baseUrls.graphql, staticRender, tracingHeaders]);

  return (
    <criticalErrorsOnlyTrackingClientContext.Provider
      value={clients.criticalErrorsOnlyTrackingClient}
    >
      <ApolloProvider client={clients.errorTrackingClient}>
        {children}
      </ApolloProvider>
    </criticalErrorsOnlyTrackingClientContext.Provider>
  );
};

export const useCriticalErrorsOnlyTrackingApolloClient = () => {
  const client = React.useContext(criticalErrorsOnlyTrackingClientContext);

  if (!client) {
    throw new Error(
      'useCriticalErrorsOnlyTrackingApolloClient must be used within an ApolloEnvironmentProvider',
    );
  }

  return client;
};
