import { datadogRum } from '@datadog/browser-rum';
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import type * as Statuspage from 'src/types/statuspage';

const StatuspageContext = createContext<Statuspage.SummaryResponse | undefined>(
  undefined,
);

const loadSummary = async (
  pageUrl: string,
): Promise<Statuspage.SummaryResponse | undefined> => {
  const response = await fetch(`${pageUrl}/api/v2/summary.json`);
  if (!response.ok) {
    return undefined;
  }

  const summary = (await response.json()) as Statuspage.SummaryResponse;

  return {
    ...summary,

    // Assume that we don't need to surface fine-grained statuses within a group
    // for now. This means that we only show a top-level "Core platform" status
    // rather than the breakdown by "Partner auth", "Browser auth", etc.
    components: summary.components.filter((component) => !component.group_id),
  };
};

const loadAllIncidents = async (
  pageUrl: string,
): Promise<Statuspage.AllIncidentsResponse | undefined> => {
  const response = await fetch(`${pageUrl}/api/v2/incidents.json`);
  if (!response.ok) {
    return undefined;
  }

  return (await response.json()) as Statuspage.AllIncidentsResponse;
};

const loadAllScheduledMaintenances = async (
  pageUrl: string,
): Promise<Statuspage.AllScheduledMaintenancesResponse | undefined> => {
  const response = await fetch(`${pageUrl}/api/v2/scheduled-maintenances.json`);
  if (!response.ok) {
    return undefined;
  }

  return (await response.json()) as Statuspage.AllScheduledMaintenancesResponse;
};

interface Props {
  children: ReactNode;

  /**
   * Base URL of the Atlassian Statuspage instance to load from
   */
  pageUrl: string;

  /**
   * Determines if we should load all incidents or only unresolved incidents
   *
   * By default only unresolved instances are loaded. This can be set to `all`
   * to test incident rendering against historical incidents.
   */
  includeIncidents?: 'unresolved' | 'all';

  /**
   * Determines if we should load all scheduled maintenances
   *
   * By default only upcoming & in-progress maintenances are loaded. This can be
   * set to `all` to test maintenance rendering against completed maintenances.
   */
  includeScheduledMaintenances?: 'upcomingOrInProgress' | 'all';
}

/**
 * Loads the summary JSON from an instance of Atlassian Statuspage
 */
export const StatuspageProvider = ({
  children,
  pageUrl,
  includeIncidents,
  includeScheduledMaintenances,
}: Props) => {
  const [summary, setSummary] = useState<Statuspage.SummaryResponse>();

  const [allIncidents, setAllIncidents] =
    useState<Statuspage.AllIncidentsResponse>();

  const [allScheduledMaintenances, setAllScheduledMaintenances] =
    useState<Statuspage.AllScheduledMaintenancesResponse>();

  useEffect(() => {
    loadSummary(pageUrl)
      .then((loaded) => setSummary(loaded))
      .catch((err) =>
        datadogRum.addError(err, {
          err,
          msg: 'Failed to load Statuspage summary',
        }),
      );

    if (includeIncidents === 'all') {
      loadAllIncidents(pageUrl)
        .then((loaded) => setAllIncidents(loaded))
        .catch((err) =>
          datadogRum.addError(err, {
            err,
            msg: 'Failed to load all Statuspage incidents',
          }),
        );
    }

    if (includeScheduledMaintenances === 'all') {
      loadAllScheduledMaintenances(pageUrl)
        .then((loaded) => setAllScheduledMaintenances(loaded))
        .catch((err) =>
          datadogRum.addError(err, {
            err,
            msg: 'Failed to load all Statuspage scheduled maintenances',
          }),
        );
    }
  }, [includeIncidents, includeScheduledMaintenances, pageUrl]);

  // Merge our responses together in to the shape of a summary response
  // This is only used to patch data into the summary for local testing.
  const combinedResponse = summary && {
    ...summary,
    incidents: allIncidents?.incidents ?? summary.incidents,
    scheduled_maintenances:
      allScheduledMaintenances?.scheduled_maintenances ??
      summary.scheduled_maintenances,
  };

  return (
    <StatuspageContext.Provider value={combinedResponse}>
      {children}
    </StatuspageContext.Provider>
  );
};

/**
 * Uses the summary JSON from the Atlassian Statuspage API
 *
 * If we're outside a `StatuspageProvider`, still loading, or failed then
 * `undefined` will be returned.
 */
export const useStatuspage = () => useContext(StatuspageContext);
