import {
  Button,
  Column,
  Columns,
  IconCopy,
  IconDelete,
  IconEdit,
  IconTick,
  Inline,
  MenuItem,
  MenuItemDivider,
  Notice,
  OverflowMenu,
  Stack,
  Text,
  TextLinkButton,
  useToast,
} from 'braid-design-system';
import { useEffect, useState } from 'react';

import {
  type GetPartnerTokenPayload,
  type LiveClientPayload,
  type PublicTestClientPayload,
  useApi,
} from 'src/api';
import { usePermissions } from 'src/hooks/auth';

import { NameField } from '../NameField/NameField';
import { type FormData, FormProvider } from '../NameField/form';

import { CredentialSummary } from './CredentialSummary';

interface Props {
  credential: LiveClientPayload | PublicTestClientPayload;
  onDeleteRequest: () => unknown;
  onGeneratePartnerToken: (partnerToken: GetPartnerTokenPayload) => void;
  writeAccess: boolean;
}

export const Credential = ({
  credential,
  onDeleteRequest,
  onGeneratePartnerToken,
  writeAccess,
}: Props) => {
  const { permissions } = usePermissions();

  const api = useApi();

  const [isEditing, setIsEditing] = useState(false);

  const [hasClientGrant, setHasClientGrant] = useState(
    credential.hasClientGrant,
  );

  useEffect(
    () => setHasClientGrant(credential.hasClientGrant),
    [credential.hasClientGrant],
  );

  const showToast = useToast();

  const createClientGrant = async () => {
    try {
      await api.credentials.createClientGrant(credential.clientId);

      setHasClientGrant(true);

      showToast({
        key: 'createClientGrant',
        tone: 'positive',
        message: 'Credentials linked.',
      });
    } catch (err) {
      showToast({
        key: 'createClientGrant',
        tone: 'critical',
        message: 'We couldn’t link your credentials.',
        description: err instanceof Error ? err.message : String(err),
      });
    }
  };

  const updateClient = async ({ name }: FormData) => {
    try {
      await api.credentials.updateClient(credential.clientId, name.trim());

      setIsEditing(false);
    } catch (err) {
      showToast({
        key: 'updateClient',
        tone: 'critical',
        message: 'We couldn’t save your credential’s name.',
        description: err instanceof Error ? err.message : String(err),
      });
    }
  };

  const generatePartnerToken = async () => {
    try {
      const data = await api.credentials.getPartnerToken(credential.clientId);
      onGeneratePartnerToken(data);
    } catch (err) {
      showToast({
        key: 'generatedPartnerToken',
        tone: 'critical',
        message: 'We couldn’t generate a partner token.',
        description: err instanceof Error ? err.message : String(err),
      });
    }
  };

  const canGeneratePartnerToken =
    credential.type === 'publicTest' ||
    permissions.includes('query:live-credentials');
  const canDelete = writeAccess;

  return (
    <Columns alignY="center" collapseBelow="desktop" space="gutter">
      <Column>
        <Stack space="medium">
          <FormProvider initialValues={{ name: credential.name }}>
            {({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit((data) => {
                  updateClient(data);
                })}
              >
                <Inline space="xxsmall">
                  <NameField
                    disabled={!isEditing}
                    id={`update-credential-name-field-${credential.clientId}`}
                    showLabel={false}
                  />
                  {writeAccess ? (
                    <>
                      {isEditing ? (
                        <Button type="submit" variant="transparent">
                          <IconTick />
                        </Button>
                      ) : (
                        <Button
                          onClick={(event) => {
                            event.preventDefault();
                            setIsEditing(true);
                          }}
                          type="button"
                          variant="transparent"
                        >
                          <IconEdit />
                        </Button>
                      )}
                    </>
                  ) : null}
                </Inline>
              </form>
            )}
          </FormProvider>

          <CredentialSummary credential={credential} />

          {hasClientGrant ? null : (
            <Notice tone="critical">
              <Text>
                These credentials have not been linked to the SEEK API.
                {writeAccess ? (
                  <>
                    {' '}
                    <TextLinkButton onClick={createClientGrant}>
                      Click here
                    </TextLinkButton>{' '}
                    to resolve the issue.
                  </>
                ) : null}
              </Text>
            </Notice>
          )}
        </Stack>
      </Column>

      {credential.createdAt || credential.lastUsedAt ? (
        <Column width="content">
          <Stack space="medium">
            {credential.createdAt ? (
              <Text size="small" tone="secondary">
                Created on {new Date(credential.createdAt).toLocaleString()}
              </Text>
            ) : null}

            {credential.lastUsedAt ? (
              <Text size="small" tone="secondary">
                Last used on {new Date(credential.lastUsedAt).toLocaleString()}
              </Text>
            ) : null}
          </Stack>
        </Column>
      ) : null}

      {canGeneratePartnerToken || canDelete ? (
        <Column width="content">
          <OverflowMenu label="Manage credentials">
            {canGeneratePartnerToken ? (
              <MenuItem icon={<IconCopy />} onClick={generatePartnerToken}>
                Generate partner token
              </MenuItem>
            ) : null}
            {canGeneratePartnerToken && canDelete ? <MenuItemDivider /> : null}
            {canDelete ? (
              <MenuItem
                icon={<IconDelete />}
                onClick={onDeleteRequest}
                tone="critical"
              >
                Delete
              </MenuItem>
            ) : null}
          </OverflowMenu>
        </Column>
      ) : null}
    </Columns>
  );
};
