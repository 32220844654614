// TODO: Match Wingman and enforce `Record<SalaryCurrency, string>`
export const APAC_CURRENCIES = {
  AUD: { active: true, name: 'Australian dollar' },
  HKD: { active: true, name: 'Hong Kong dollar' },
  IDR: { active: true, name: 'Indonesian rupiah' },
  MYR: { active: true, name: 'Malaysian ringgit' },
  NZD: { active: true, name: 'New Zealand dollar' },
  PHP: { active: true, name: 'Philippine peso' },
  SGD: { active: true, name: 'Singapore dollar' },
  THB: { active: true, name: 'Thai baht' },
  USD: { active: true, name: 'United States dollar' },
  BDT: { active: false, name: 'Bangladeshi taka' },
  CNY: { active: false, name: 'Renminbi' },
  EUR: { active: false, name: 'Euro' },
  GBP: { active: false, name: 'Pound sterling' },
  INR: { active: false, name: 'Indian rupee' },
  JPY: { active: false, name: 'Japanese yen' },
  VND: { active: false, name: 'Vietnamese đồng' },
};

export const ACTIVE_CURRENCY_CODES = Object.entries(APAC_CURRENCIES).flatMap(
  ([key, { active }]) => (active ? key : []),
);

export const PAY_TYPE_MAPPING = {
  'Hourly rate': {
    basisCode: 'Hourly',
    intervalCode: 'Hour',
  },
  'Monthly salary': {
    basisCode: 'Salaried',
    intervalCode: 'Month',
  },
  'Annual salary': {
    basisCode: 'Salaried',
    intervalCode: 'Year',
  },
  'Annual plus commission': {
    basisCode: 'SalariedPlusCommission',
    intervalCode: 'Year',
  },
};

export const PAY_TYPES = Object.keys(PAY_TYPE_MAPPING) as Array<
  keyof typeof PAY_TYPE_MAPPING
>;

export type PayType = (typeof PAY_TYPES)[number];
