import { Heading, Loader, Stack, Text, TextField } from 'braid-design-system';
import { useEffect, useState } from 'react';

import { CardSection } from 'src/components/CardSection/CardSection';
import { ErrorNotice } from 'src/components/ErrorNotice/ErrorNotice';
import { useQueryParam } from 'src/hooks/queryParams';

import { Hirer } from '../Hirer/Hirer';

import { useHirerQuery } from './useHirerQuery';

interface HirerDetailsProps {
  searchTerm: string;
  setIdParam: (id: string) => void;
}

const HirerDetails = ({ searchTerm, setIdParam }: HirerDetailsProps) => {
  const { data, error, loading } = useHirerQuery(searchTerm);

  useEffect(() => {
    if (data?.id.value) {
      setIdParam(data.id.value);
    }
  }, [data?.id.value, setIdParam]);

  if (loading) {
    return <Loader />;
  }

  if (
    !data ||
    error?.graphQLErrors.every((err) => err.extensions?.code === 'FORBIDDEN')
  ) {
    return (
      <Text tone="secondary">
        We couldn’t find this hirer. Maybe you don’t have an API relationship
        with them?
      </Text>
    );
  }

  if (error) {
    return (
      <ErrorNotice
        description={error.message}
        title="We couldn’t look up this hirer."
      />
    );
  }

  return <Hirer hirer={data} />;
};

export const HirerLookup = () => {
  const [idParam, setIdParam] = useQueryParam('id');

  const [searchTerm, setSearchTerm] = useState(idParam ?? '');

  return (
    <CardSection
      gutter
      header={<Heading level="3">Look up a hirer by ID</Heading>}
    >
      <Stack space="large">
        <TextField
          aria-label="Hirer ID"
          id="hirerLookupSearchTerm"
          onChange={(event) => setSearchTerm(event.currentTarget.value)}
          placeholder='"seekAnz:organization:seek:abcdef" or "12345678"'
          value={searchTerm}
        />

        {searchTerm.trim() ? (
          <HirerDetails
            searchTerm={searchTerm.trim()}
            setIdParam={setIdParam}
          />
        ) : null}
      </Stack>
    </CardSection>
  );
};
