import {
  Badge,
  Box,
  Column,
  Columns,
  Stack,
  Strong,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProvider,
  Text,
} from 'braid-design-system';
import { useState } from 'react';

import { BorderCard } from 'src/components/BorderCard';

interface Interaction {
  date: string;
  description: string;
  name: string;
  party: string;
  source: 'Partner' | 'SEEK';
}

const INTERACTIONS: Interaction[] = [
  {
    date: '25 Dec 1999',
    description: 'sent an invite for Millennium Bug Squasher in Melbourne',
    name: 'Event invitation',
    party: 'Hayden R',
    source: 'SEEK',
  },
  {
    date: '26 Jul 1999',
    description:
      'added a note:\n“Candidate not currently looking for work, try again in six months. Keen to stay in Melbourne.”',
    name: 'Contact again later',
    party: 'Terry A',
    source: 'Partner',
  },
  {
    date: '10 Jul 1998',
    description:
      'sent a message:\n“Hi Jessie, just wondering if you’d be interested in a Product Manager role based in Brisbane.\nRobin R, Product Manager, SEEK Limited”',
    name: 'Recruitment message',
    party: 'Robin R',
    source: 'SEEK',
  },
  {
    date: '07 Jul 1998',
    description: 'downloaded a CV',
    name: 'Document download',
    party: 'Max C',
    source: 'SEEK',
  },
  {
    date: '07 Jul 1998',
    description: 'logged a call for Product Manager in Brisbane',
    name: 'Initial phone call',
    party: 'Jean W',
    source: 'Partner',
  },
];

const groupInteractionsByDate = (interactions: Interaction[]) =>
  Object.entries(
    interactions.reduce<Record<string, Interaction[]>>((acc, interaction) => {
      if (!acc[interaction.date]) {
        acc[interaction.date] = [];
      }

      acc[interaction.date].push(interaction);

      return acc;
    }, {}),
  );

const Interaction = ({ description, party, source }: Interaction) => (
  <Columns collapseBelow="tablet" space="gutter">
    <Column width="1/5">
      <Badge bleedY tone="info">
        {source}
      </Badge>
    </Column>

    <Column>
      <Text>
        <Strong>{party}</Strong>{' '}
        <Box component="span" style={{ whiteSpace: 'pre-wrap' }}>
          {description}
          {['.', '?', '’', '”'].includes(description[description.length - 1])
            ? ''
            : '.'}
        </Box>
      </Text>
    </Column>
  </Columns>
);

const TAB_ITEM_FOR_INDEX: Record<number, string> = {
  0: 'profile',
  1: 'cv',
  2: 'interactions',
};

export const InteractionHistory = () => {
  const [tabIndex, setTabIndex] = useState<number>(2);

  return (
    <BorderCard>
      <TabsProvider
        id="interactionHistoryTabs"
        onChange={setTabIndex}
        selectedItem={TAB_ITEM_FOR_INDEX[tabIndex]}
      >
        <Stack space="gutter">
          <Tabs label="Candidate">
            <Tab item="profile">Profile</Tab>
            <Tab item="cv">CV Preview</Tab>
            <Tab badge={<Badge tone="promote">New</Badge>} item="interactions">
              Interactions
            </Tab>
          </Tabs>

          <TabPanels>
            <TabPanel>
              <Text tone="secondary">
                This SEEK Profile is left to the reader’s imagination.
              </Text>
            </TabPanel>

            <TabPanel>
              <Text tone="secondary">
                This CV Preview is left to the reader’s imagination.
              </Text>
            </TabPanel>

            <TabPanel>
              <Stack dividers space="gutter">
                {groupInteractionsByDate(INTERACTIONS).map(
                  ([date, interactions]) => (
                    <Stack key={date} space="gutter">
                      <Text size="small" tone="secondary" weight="medium">
                        {date}
                      </Text>

                      {interactions.map((interaction, index) => (
                        <Interaction key={index} {...interaction} />
                      ))}
                    </Stack>
                  ),
                )}
              </Stack>
            </TabPanel>
          </TabPanels>
        </Stack>
      </TabsProvider>
    </BorderCard>
  );
};
