import { gql, useQuery } from '@apollo/client';

import type { SelfQuery, SelfQueryVariables } from 'src/types/graphql';

const SELF = gql`
  query Self {
    self {
      partner {
        credentialSelfServiceApprovedIndicator
        name
      }
    }
  }
`;

export const useSelf = () => {
  const { data, loading } = useQuery<SelfQuery, SelfQueryVariables>(SELF);

  return {
    self: data?.self,
    loading,
  };
};
