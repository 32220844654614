import { Box, Heading, Stack, Text, TextLink } from 'braid-design-system';
import { SmartTextLink } from 'scoobie';

import { CardSection } from 'src/components/CardSection/CardSection';

export const Subscriptions = () => (
  <CardSection
    header={
      <Heading level="4">
        <SmartTextLink href="https://developer.seek.com/events/webhooks#subscription-management">
          Webhook Subscriptions
        </SmartTextLink>
      </Heading>
    }
  >
    <Box padding="gutter">
      <Stack space="medium">
        <Text size="small">
          To receive production webhook events from SEEK, you will need to
          configure a subscription on the{' '}
          <TextLink href="/manage/webhooks">webhook subscriptions</TextLink>{' '}
          page.
        </Text>
        <Text size="small">
          Create a new webhook subscription{' '}
          <TextLink href="/manage/webhooks/new">here</TextLink>.
        </Text>
      </Stack>
    </Box>
  </CardSection>
);
