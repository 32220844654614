
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "stricter-end-date-validation-on-job-ad-edits"
    }}>{`Stricter end date validation on job ad edits`}</h4>
    <p>{`On 27 October 2023, the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/updatePostedPositionProfile"
      }}><inlineCode parentName="a">{`updatePostedPositionProfile`}</inlineCode>{` mutation`}</a>{` will be updated with stricter synchronous validation of the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/UpdatePostingInstructionInput/field/end"
      }}><inlineCode parentName="a">{`end`}</inlineCode>{` date`}</a>{`.
Your software will receive a `}<a parentName="p" {...{
        "href": "/graphql/error-responses#bad_user_input"
      }}><inlineCode parentName="a">{`BAD_USER_INPUT`}</inlineCode>{` error`}</a>{` if the supplied date exceeds the maximum duration of the job ad,
with a response that looks similar to the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "errors": [
    {
      "message": "Invalid posting end date",
      "extensions": {
        "code": "BAD_USER_INPUT",
        "invalidFields": {
          "/input/positionProfile/postingInstructions/0/end": "Must not be more than 30 days from 2020-01-01T00:00:00.000Z"
        }
      }
    }
  ]
}
`}</code></pre>
    <p>{`This validation rule has always been enforced on our other posting mutations and will not affect integrations that already limit the end date.
We have proactively reached out to partners that systematically provided invalid end dates to the SEEK API.
Other partners may review their job edit functionality to ensure that clients can view validation errors and correct the end date where necessary.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;