import { Dropdown, Stack } from 'braid-design-system';

import { type Role, roleDisplayNames } from 'src/types/users';

import {
  AdminDescription,
  ReadonlyUserDescription,
  UserDescription,
} from '../../userGuide/content/users';

const roleDescriptions: Record<Role, JSX.Element> = {
  'read-only': <ReadonlyUserDescription />,
  edit: <UserDescription />,
  admin: <AdminDescription />,
};

export const RoleSelector = ({
  value,
  onBlur,
  onChange,
}: {
  value: Role;
  onChange: (value: Role) => void;
  onBlur?: () => void;
}) => (
  <Stack space="medium">
    <Dropdown
      id="role"
      label="Role"
      value={value}
      onBlur={onBlur}
      onChange={(e) => onChange(e.currentTarget.value as Role)} // The dropdown only contains `Role` values
    >
      {Object.entries(roleDisplayNames).map(([val, roleName]) => (
        <option key={val} value={val}>
          {roleName}
        </option>
      ))}
    </Dropdown>
    {roleDescriptions[value]}
  </Stack>
);
