import { IconClear, IconHelp, IconTick, Text } from 'braid-design-system';

interface Props {
  indicator: boolean | null | undefined;
}

export const SeekCreatedBySelfIndicator = ({ indicator }: Props) => {
  if (typeof indicator !== 'boolean') {
    return (
      <Text icon={<IconHelp />} tone="neutral">
        Unknown
      </Text>
    );
  }

  return indicator ? (
    <Text icon={<IconTick />} tone="positive">
      Yes
    </Text>
  ) : (
    <Text icon={<IconClear />} tone="critical">
      No
    </Text>
  );
};
