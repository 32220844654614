import { createForm } from '@seek/forms-ui';

const FORM_NAME = 'webhookPlaygroundForm';

export interface FormData {
  eventTypeCode: string;
  eventVariation: string;
  url: string;
}

const { FormProvider, useField } = createForm<
  FormData,
  Record<string, never>,
  'en'
>(FORM_NAME);

export { FormProvider, useField };
