import { Actions, ButtonLink, IconDownload } from 'braid-design-system';

import pdf from './seek-ad-selection-panel-graphql.pdf';

export const PdfFile = () => (
  <Actions>
    <ButtonLink
      download="seek-ad-selection-panel-graphql-v3.pdf"
      href={pdf}
      icon={<IconDownload />}
      variant="soft"
    >
      PDF file
    </ButtonLink>
  </Actions>
);
