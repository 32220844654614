import { Dialog } from 'braid-design-system';
import type { ComponentProps } from 'react';

import type { LiveClientPayload, PublicTestClientPayload } from 'src/api';
import { displayNameForDataType } from 'src/utils/credentials';

import { DeleteCredentialDialogBody } from './DeleteCredentialDialogBody';

interface Props
  extends Pick<ComponentProps<typeof Dialog>, 'open' | 'onClose'> {
  client: LiveClientPayload | PublicTestClientPayload;
  liveClientCount: number;
  onDelete: () => void;
}

export const DeleteCredentialDialog = ({
  client,
  open,
  onClose,
  ...props
}: Props) => (
  <Dialog
    open={open}
    onClose={onClose}
    id="delete-credential-dialog"
    title={`Delete ${displayNameForDataType(client.type)} credentials`}
  >
    <DeleteCredentialDialogBody {...props} client={client} onClose={onClose} />
  </Dialog>
);
