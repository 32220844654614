
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { IconCaution, IconClear, IconTick, Text } from 'braid-design-system';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "identifiers--references"
    }}>{`Identifiers & references`}</h1>
    <p>{`The SEEK API provides a number of fields that can be used to identify a position.
They fall into two separate categories:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/PositionOpening/field/documentId"
          }}><inlineCode parentName="a">{`PositionOpening.documentId`}</inlineCode></a>{` and `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/PositionProfile/field/profileId"
          }}><inlineCode parentName="a">{`PositionProfile.profileId`}</inlineCode></a>{` fields are unique `}<a parentName="p" {...{
            "href": "/graphql/seek-api-conventions#object-identifiers"
          }}>{`object identifiers`}</a>{` assigned by the SEEK API.`}</p>
        <p parentName="li">{`While these are the primary way to identify positions in the SEEK API, they’re not available on the `}<a parentName="p" {...{
            "href": "https://talent.seek.com.au"
          }}>{`SEEK employer website`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Reference fields are optional strings that can be provided when `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/position-openings"
          }}>{`creating a position opening`}</a>{` or `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad"
          }}>{`posting a job ad`}</a>{`.
The SEEK API does not require that references are unique.
Your software may reuse a reference across multiple job ads.`}</p>
        <p parentName="li">{`Do not include any `}<a parentName="p" {...{
            "href": "https://www.oaic.gov.au/privacy/privacy-guidance-for-organisations-and-government-agencies/handling-personal-information/what-is-personal-information"
          }}>{`personal information`}</a>{` such as a legal name or email address.
Depending on the particular reference field, they can be used to correlate job ads posted via the SEEK API and the SEEK employer website.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "reference-fields"
    }}>{`Reference fields`}</h2>
    <h3 {...{
      "id": "seekhirerjobreference"
    }}>{`seekHirerJobReference`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`PositionProfile.seekHirerJobReference`}</inlineCode>{` field is a generic reference that can be set on a job ad and viewed across `}<a parentName="p" {...{
        "href": "#channel-support"
      }}>{`all channels`}</a>{`.
This makes it useful for supporting cross-channel workflows such as `}<a parentName="p" {...{
        "href": "/use-cases/application-export#job-ads-posted-outside-of-your-software"
      }}>{`exporting applications for jobs posted outside the SEEK API`}</a>{`.
SEEK hirers typically use this as their primary reference due to its broad support.`}</p>
    <h3 {...{
      "id": "seekbillingreference"
    }}>{`seekBillingReference`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`PositionProfile.seekBillingReference`}</inlineCode>{` field is intended to contain a billing reference or cost centre code.
This field can be set on a job ad via the SEEK API, but is typically unavailable on the SEEK employer website.
It appears on hirer invoices & contract reporting from SEEK so that costs incurred can be attributed to the relevant business unit or department.`}</p>
    <h3 {...{
      "id": "seekpartnermetadata"
    }}>{`seekPartnerMetadata`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`PositionOpening.seekPartnerMetadata`}</inlineCode>{` & `}<inlineCode parentName="p">{`PositionProfile.seekPartnerMetadata`}</inlineCode>{` fields are intended to contain opaque metadata that’s useful to your software.
They can only be populated or retrieved via the SEEK API; they’re not directly exposed to SEEK hirers.`}</p>
    <p>{`Note that SEEK hirers can have `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}>{`relationships`}</a>{` with multiple integration partners or move between integration partners over time.
The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PostedPositionProfile/field/seekCreatedBySelfIndicator"
      }}><inlineCode parentName="a">{`PositionProfile.seekCreatedBySelfIndicator`}</inlineCode>{` field`}</a>{` can identify job ads posted by other integration partners so their metadata can be ignored.`}</p>
    <h2 {...{
      "id": "channel-support"
    }}>{`Channel support`}</h2>

    <h3 {...{
      "id": "populated-by"
    }}>{`Populated by`}</h3>
    <p>{`The table below shows which identifiers & references can be populated in each channel.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Field`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p">{`SEEK API`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p">{`SEEK employer website`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionOpening.documentId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionOpening.seekPartnerMetadata`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionProfile.profileId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionProfile.seekHirerJobReference`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionProfile.seekBillingReference`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconCaution tone="caution" mdxType="IconCaution" /></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionProfile.seekPartnerMetadata`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
        </tr>
      </tbody>
    </table>
    <Text mdxType="Text">
  <IconCaution tone="caution" mdxType="IconCaution" />
  &nbsp;&nbsp;Referred to as “agent job reference”. Only available when posting
  as an agency on behalf of a hirer.
    </Text>
    <h3 {...{
      "id": "visible-in"
    }}>{`Visible in`}</h3>
    <p>{`The table below shows which identifiers & references can be viewed in each channel.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Field`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p">{`SEEK API`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p">{`SEEK employer website`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p">{`Hirer billing & reports`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionOpening.documentId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionOpening.seekPartnerMetadata`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionProfile.profileId`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionProfile.seekHirerJobReference`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p><IconTick tone="positive" mdxType="IconTick" /></p><p>{`Advertiser ad ID`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionProfile.seekBillingReference`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconCaution tone="caution" mdxType="IconCaution" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p><IconTick tone="positive" mdxType="IconTick" /></p><p>{`Billing reference`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td"><inlineCode parentName="p">{`PositionProfile.seekPartnerMetadata`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
        </tr>
      </tbody>
    </table>
    <Text mdxType="Text">
  <IconCaution tone="caution" mdxType="IconCaution" />
  &nbsp;&nbsp;Referred to as “agent job reference”. Only available when posting
  as an agency on behalf of a hirer.
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;