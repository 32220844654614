export interface PossibleTypesResultData {
  possibleTypes: Record<string, string[]>;
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ApplicationQuestionnaireComponent: [
      'ApplicationPrivacyConsent',
      'ApplicationQuestion',
    ],
    ApplicationQuestionnaireComponentResponse: [
      'ApplicationPrivacyConsentResponse',
      'ApplicationQuestionResponse',
    ],
    CreateCandidateProcessHistoryItemPayload: [
      'CreateCandidateProcessHistoryItemPayload_Conflict',
      'CreateCandidateProcessHistoryItemPayload_Success',
    ],
    CreateWebhookSubscriptionPayload: [
      'CreateWebhookSubscriptionPayload_Conflict',
      'CreateWebhookSubscriptionPayload_Success',
    ],
    Event: [
      'CandidateApplicationCreatedEvent',
      'CandidateProfilePurchasedEvent',
      'HirerRelationshipChangedEvent',
      'PositionProfileClosedEvent',
      'PositionProfilePostedEvent',
    ],
    PositionProfile: ['PostedPositionProfile', 'UnpostedPositionProfile'],
    PostPositionPayload: [
      'PostPositionPayload_Conflict',
      'PostPositionPayload_Success',
    ],
    PostPositionProfileForOpeningPayload: [
      'PostPositionProfileForOpeningPayload_Conflict',
      'PostPositionProfileForOpeningPayload_Success',
    ],
    UpdateUploadedCandidatePersonPayload: [
      'UpdateUploadedCandidatePersonPayload_Conflict',
      'UpdateUploadedCandidatePersonPayload_Success',
    ],
    UpdateWebhookSubscriptionDeliveryConfigurationPayload: [
      'UpdateWebhookSubscriptionDeliveryConfigurationPayload_Conflict',
      'UpdateWebhookSubscriptionDeliveryConfigurationPayload_Success',
    ],
    UploadCandidatePayload: [
      'UploadCandidatePayload_Conflict',
      'UploadCandidatePayload_Success',
    ],
    WebhookSubscriptionReplayRequest: [
      'WebhookSubscriptionReplayByIdRequest',
      'WebhookSubscriptionReplayByRangeRequest',
    ],
  },
};
export default result;
