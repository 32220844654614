import { gql } from '@apollo/client';

import { WEBHOOK_REQUEST_FRAGMENT } from './fragments';

export const GET_WEBHOOK_REQUESTS_FOR_SUBSCRIPTION = gql`
  query webhookRequestsForSubscription(
    $subscriptionId: String!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: WebhookRequestFilterInput
  ) {
    webhookRequestsForSubscription(
      subscriptionId: $subscriptionId
      after: $after
      before: $before
      first: $first
      last: $last
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...webhookRequestFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${WEBHOOK_REQUEST_FRAGMENT}
`;

export const GET_WEBHOOK_REQUEST_BY_ID = gql`
  query webhookRequestById($schemeId: String!, $requestId: String!) {
    webhookRequest(schemeId: $schemeId, requestId: $requestId) {
      webhookSubscription {
        id {
          value
        }
      }
      ...webhookRequestFields
    }
  }
  ${WEBHOOK_REQUEST_FRAGMENT}
`;
