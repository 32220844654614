
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "webhook-event-payload-hirerid-field"
    }}>{`Webhook event payload `}<inlineCode parentName="h4">{`hirerId`}</inlineCode>{` field`}</h4>
    <p>{`We’ve extended the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/webhooks"
      }}>{`webhook event payload`}</a>{` with a new `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/webhooks/CandidateApplicationCreatedEvent/property/hirerId"
      }}><inlineCode parentName="a">{`hirerId`}</inlineCode></a>{` field.`}</p>
    <p>{`This field is only available on signed webhook subscriptions.
This will allow your software to efficiently filter or forward events on a per-hirer basis for partner-scoped subscriptions instead of requiring a follow up query.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;