import { Box, FieldLabel, Stack, Text } from 'braid-design-system';
import { format } from 'date-fns';
import type React from 'react';

import * as styles from './styles.css';

interface DatePickerProps {
  name: string;
  type: 'date' | 'datetime-local';
  value?: Date;
  max?: Date;
  label?: string;
  onChange?: (name: string, isoDate?: Date) => void;
}

export const DatePicker = (props: DatePickerProps) => {
  const { name, label, value, max, type, onChange } = props;

  const dateFormat = type === 'date' ? 'yyyy-MM-dd' : "yyyy-MM-dd'T'HH:mm:ss";
  const formattedDate = value ? format(value, dateFormat) : '';
  const formattedMax = max ? format(max, dateFormat) : '';

  return (
    <Stack space="small">
      {label ? <FieldLabel htmlFor={name} label={label} /> : null}
      <Text>
        <Box
          component="input"
          type={type}
          id={name}
          name={name}
          value={formattedDate}
          max={formattedMax}
          className={styles.datePicker}
          background="surface"
          borderRadius="standard"
          boxShadow="borderField"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue =
              type === 'date' ? e.target.valueAsDate : new Date(e.target.value);

            if (newValue && !isNaN(newValue.getTime()) && onChange) {
              onChange(name, newValue);
            }
          }}
          paddingX="small"
          paddingY="xsmall"
          width="full"
        />
      </Text>
    </Stack>
  );
};
