
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "mutation-input-field-limits"
    }}>{`Mutation input field limits`}</h4>
    <p>{`A default maximum length for `}<inlineCode parentName="p">{`String`}</inlineCode>{` mutation input fields is now documented in the SEEK API’s `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#request-limits"
      }}>{`request limits`}</a>{`.
Exceptions to this default are explicitly documented in the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/"
      }}>{`GraphQL schema`}</a>{`.`}</p>
    <p>{`An operation containing an input field that exceeds its length limit will receive a `}<a parentName="p" {...{
        "href": "/graphql/error-responses#bad_user_input"
      }}><inlineCode parentName="a">{`BAD_USER_INPUT`}</inlineCode>{` error response`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;