import {
  Archive,
  Book,
  BookBookmark,
  FrameCorners,
  Graph,
  Key,
  ListMagnifyingGlass,
  Plugs,
  SignOut,
  TerminalWindow,
  UserGear,
  UserList,
  WebhooksLogo,
} from '@phosphor-icons/react';
import {
  IconCompose,
  IconHistory,
  IconHome,
  IconNotification,
  IconPeople,
  IconRenderer,
  IconResume,
  IconStatistics,
  IconTip,
  IconZoomIn,
  type Text,
} from 'braid-design-system';
import type { ComponentProps } from 'react';

import type { PageIconId } from 'src/page';

export const PageIcon = ({
  icon,
}: {
  icon: PageIconId;
}): ComponentProps<typeof Text>['icon'] => {
  switch (icon) {
    case 'AdPerformance':
      return <IconStatistics />;

    case 'ApplyWithSeek':
      return (
        <IconRenderer>
          {({ className }) => <UserList className={className} />}
        </IconRenderer>
      );

    case 'Auth':
      return (
        <IconRenderer>
          {({ className }) => <Key className={className} />}
        </IconRenderer>
      );

    case 'DeveloperDashboard':
      return (
        <IconRenderer>
          {({ className }) => <TerminalWindow className={className} />}
        </IconRenderer>
      );

    case 'Documentation':
      return (
        <IconRenderer>
          {({ className }) => <Book className={className} />}
        </IconRenderer>
      );

    case 'GraphQL':
      return (
        <IconRenderer>
          {({ className }) => <Graph className={className} />}
        </IconRenderer>
      );

    case 'Guide':
      return (
        <IconRenderer>
          {({ className }) => <Book className={className} />}
        </IconRenderer>
      );

    case 'GuideArchive':
      return (
        <IconRenderer>
          {({ className }) => <Archive className={className} />}
        </IconRenderer>
      );

    case 'GuideIndex':
      return (
        <IconRenderer>
          {({ className }) => <BookBookmark className={className} />}
        </IconRenderer>
      );

    case 'Home':
      return <IconHome />;

    case 'Introduction':
      return <IconTip />;

    case 'JobPosting':
      return <IconCompose />;

    case 'Logout':
      return (
        <IconRenderer>
          {({ className }) => <SignOut className={className} />}
        </IconRenderer>
      );

    case 'Monitoring':
      return <IconZoomIn />;

    case 'Notifications':
      return <IconNotification />;

    case 'OptimisedApply':
      return <IconResume />;

    case 'Panel':
      return (
        <IconRenderer>
          {({ className }) => <FrameCorners className={className} />}
        </IconRenderer>
      );

    case 'ReleaseNotes':
      return <IconHistory />;

    case 'Schema':
      return (
        <IconRenderer>
          {({ className }) => <ListMagnifyingGlass className={className} />}
        </IconRenderer>
      );

    case 'StatusPage':
      return (
        <IconRenderer>
          {({ className }) => <Plugs className={className} />}
        </IconRenderer>
      );

    case 'TalentSearchConnect':
      return <IconPeople />;

    case 'UserProfile':
      return (
        <IconRenderer>
          {({ className }) => <UserGear className={className} />}
        </IconRenderer>
      );

    case 'Users':
      return (
        <IconRenderer>
          {({ className }) => <UserList className={className} />}
        </IconRenderer>
      );

    case 'Webhook':
      return (
        <IconRenderer>
          {({ className }) => <WebhooksLogo className={className} />}
        </IconRenderer>
      );
  }
};
