
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';
import { InlineCode } from 'scoobie';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "phase-2-object-identifiers"
    }}>{`Phase 2: Object identifiers`}</h1>
    <p>{`The retired Job Posting API used a few different types of identifiers:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Hirers & agents were identified using numeric `}<a parentName="p" {...{
            "href": "/migration-guides/seek-advertiser-ids"
          }}>{`SEEK advertiser IDs`}</a>{`.
These were provided by SEEK’s support team when onboarding a new SEEK hirer.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Short, human-readable strings were used to specify locations & job categories.
These were distributed in an “expected values” spreadsheet.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`UUIDs were used to identify job ads after they’ve been posted.
The job ad’s UUID was returned in the HTTP response when initially creating the job ad.`}</p>
      </li>
    </ul>
    <p>{`By contrast, the SEEK API uses opaque `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#object-identifiers"
      }}>{`object identifiers`}</a>{` to uniquely identify each object in the SEEK API.
For example, this is an identifier for a job category in the `}<a parentName="p" {...{
        "href": "/graphql/playground"
      }}>{`Playground`}</a>{` environment: `}<inlineCode parentName="p">{`seekAnzPublicTest:jobCategory:seek:2PiXemmou`}</inlineCode></p>
    <p>{`Instead of distributing a spreadsheet of expected values, the SEEK API provides GraphQL queries to find input object identifiers.
This enables new hirer experiences such as interactively suggesting locations & job categories.`}</p>
    <p>{`To support your migration, the Job Posting API was updated to support object identifiers.
This allowed you to integrate with the new SEEK API objects listed below before migrating from the Job Posting API for managing jobs.`}</p>
    <h2 {...{
      "id": "hirer-ids"
    }}>{`Hirer IDs`}</h2>
    <p>{`The SEEK API uses opaque `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#object-identifiers"
      }}>{`object identifier`}</a>{` strings to identify SEEK hirers.
To ease migration to the SEEK API, the Job Posting API accepted hirer object identifiers in the existing `}<inlineCode parentName="p">{`thirdParties.advertiserId`}</inlineCode>{` field.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "label=\"Hirer ID\"",
        "label": "\"Hirer",
        "ID\"": true
      }}>{`{
  "thirdParties": {
    "advertiserId": "seekAnzPublicTest:organization:seek:93WyyF1h"
  }
}
`}</code></pre>
    <p>{`For more information, see the `}<a parentName="p" {...{
        "href": "/migration-guides/seek-advertiser-ids"
      }}>{`hirer identifier guide`}</a>{`.`}</p>
    <h2 {...{
      "id": "job-ad-input-objects"
    }}>{`Job ad input objects`}</h2>
    <p>{`Hirers select from a number of associated objects when posting a SEEK job ad:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#locations"
          }}>{`Locations`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#job-categories"
          }}>{`Job categories`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#branding"
          }}>{`Brands`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#questionnaires"
          }}>{`Questionnaires`}</a></p>
      </li>
    </ul>
    <p>{`The retired Job Posting API did not support programmatic retrieval of these objects.
For some objects, SEEK distributed a spreadsheet of “expected values” to be hardcoded in your software.
For others, the hirer would manually enter an identifier as part of your posting flow.`}</p>
    <p>{`The SEEK API supports queries to list or suggest these objects in real time.
This allows your hirers to use the most up-to-date SEEK datasets when they post their jobs.`}</p>
    <h3 {...{
      "id": "locations"
    }}>{`Locations`}</h3>
    <p>{`SEEK uses proprietary IDs to specify the location of positions.
Precisely locating positions helps candidates search for the most relevant jobs.
They are also used as an input into `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection"
      }}>{`SEEK’s ad pricing`}</a>{`.`}</p>
    <p>{`The Job Posting API used a `}<inlineCode parentName="p">{`location.id`}</inlineCode>{` field refined with an optional `}<inlineCode parentName="p">{`location.areaId`}</inlineCode>{`.
For example, the `}<inlineCode parentName="p">{`Melbourne`}</inlineCode>{` location included the `}<inlineCode parentName="p">{`MelbourneCBDInnerSuburbs`}</inlineCode>{` area.
SEEK’s ANZ location taxonomy had to be manually hardcoded into your software.`}</p>
    <p>{`The SEEK API uses a multi-level hierarchy from larger parent locations to smaller child locations.
Each node in the hierarchy is uniquely identified by a single `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#object-identifiers"
      }}>{`object identifier`}</a>{`.
Instead of hardcoding the location hierarchy, you’re encouraged to dynamically retrieve locations via GraphQL queries:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/locations#locationsuggestions"
          }}><inlineCode parentName="a">{`locationSuggestions`}</inlineCode></a>{` returns an array of likely locations for a provided text string.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/locations#nearestlocations"
          }}><inlineCode parentName="a">{`nearestLocations`}</inlineCode></a>{` returns an array of locations closest to the specified latitude & longitude, ordered by distance.`}</p>
      </li>
    </ul>
    <p>{`To ease migration to the SEEK API, the Job Posting API accepts location object identifiers in the existing `}<inlineCode parentName="p">{`location.id`}</inlineCode>{` field.
The optional `}<inlineCode parentName="p">{`location.areaId`}</inlineCode>{` field should be omitted when using object identifiers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "label=\"Location OID\"",
        "label": "\"Location",
        "OID\"": true
      }}>{`{
  "location": {
    "id": "seekAnzPublicTest:location:seek:2m81wybwV"
  }
}
`}</code></pre>
    <p>{`For more information on locations in the SEEK API, see the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/locations"
      }}>{`locations documentation`}</a>{`.`}</p>
    <h3 {...{
      "id": "job-categories"
    }}>{`Job categories`}</h3>
    <p>{`Job categories identify the occupational category of a position.
Accurately categorising positions helps candidates search for the most relevant jobs.
They are also used as an input into `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection"
      }}>{`SEEK’s ad pricing`}</a>{`.`}</p>
    <p>{`The Job Posting API used human-readable IDs such as `}<inlineCode parentName="p">{`DevelopersProgrammers`}</inlineCode>{`.
SEEK’s ANZ job category taxonomy had to be manually hardcoded into your software.`}</p>
    <p>{`The SEEK API uses `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#object-identifiers"
      }}>{`object identifiers`}</a>{` and supports programmatic retrieval via GraphQL queries:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/job-categories#jobcategories"
          }}><inlineCode parentName="a">{`jobCategories`}</inlineCode></a>{` returns an array of top-level job categories for a given scheme.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/job-categories#jobcategorysuggestions"
          }}><inlineCode parentName="a">{`jobCategorySuggestions`}</inlineCode></a>{` returns an array of suggested job categories based on a partial `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/PositionProfile"
          }}><inlineCode parentName="a">{`PositionProfile`}</inlineCode></a>{`.`}</p>
      </li>
    </ul>
    <p>{`To ease migration to the SEEK API, the Job Posting API accepts job category object identifiers in the existing `}<inlineCode parentName="p">{`subclassificationId`}</inlineCode>{` field.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "label=\"Job category OID\"",
        "label": "\"Job",
        "category": true,
        "OID\"": true
      }}>{`{
  "subclassificationId": "seekAnzPublicTest:jobCategory:seek:2EFstqFvP"
}
`}</code></pre>
    <p>{`For more information on job categories in the SEEK API, see the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/job-categories"
      }}>{`job categories documentation`}</a>{`.`}</p>
    <h3 {...{
      "id": "branding"
    }}>{`Branding`}</h3>
    <p>{`Branding allows hirers to promote their company brand on their job ads and apply forms.
They are manually managed by hirers in the `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au/account/branding"
      }}>{`Logos & Brands section`}</a>{` of the SEEK employer website.`}</p>
    <p>{`The Job Posting API had no mechanism to retrieve hirer brands;
hirers had to manually enter a logo ID in your posting flow.`}</p>
    <p>{`The SEEK API supports returning a paginated list of a hirer’s brands using the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/branding#advertisementbrandings"
      }}><inlineCode parentName="a">{`advertisementBrandings`}</inlineCode></a>{` query.`}</p>
    <p>{`If your software currently supports logos, it passes a numeric logo ID to the Job Posting API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "label=\"Numeric logo ID\"",
        "label": "\"Numeric",
        "logo": true,
        "ID\"": true
      }}>{`{
  "standout": { "logoId": 12345 }
}
`}</code></pre>
    <p>{`To ease migration to the SEEK API, a new string field named `}<inlineCode parentName="p">{`brandingId`}</inlineCode>{` was added to the Job Posting API that accepts a brand OID or a stringified logo ID:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label=\\\"Brand OID\\\"\",\"value\":\"{\\n  \\\"standout\\\": {\\n    \\\"brandingId\\\": \\\"globalPublicTest:advertisementBranding:hirerBranding:37EWfWxhwVEGTT5BcjfE2L\\\"\\n  }\\n}\",\"position\":{\"start\":{\"line\":145,\"column\":1,\"offset\":6430},\"end\":{\"line\":151,\"column\":4,\"offset\":6579},\"indent\":[1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label=\\\"Stringified logo ID\\\"\",\"value\":\"{\\n  \\\"standout\\\": { \\\"brandingId\\\": \\\"12345\\\" }\\n}\",\"position\":{\"start\":{\"line\":153,\"column\":1,\"offset\":6581},\"end\":{\"line\":157,\"column\":4,\"offset\":6664},\"indent\":[1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label": "\\\"Brand",
        "OID\\\"\",\"value\":\"{\\n": true,
        "": true,
        "\\\"standout\\\":": true,
        "{\\n": true,
        "\\\"brandingId\\\":": true,
        "\\\"globalPublicTest:advertisementBranding:hirerBranding:37EWfWxhwVEGTT5BcjfE2L\\\"\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":145,\"column\":1,\"offset\":6430},\"end\":{\"line\":151,\"column\":4,\"offset\":6579},\"indent\":[1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label": "\\\"Stringified",
        "logo": true,
        "ID\\\"\",\"value\":\"{\\n": true,
        "{": true,
        "\\\"12345\\\"": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":153,\"column\":1,\"offset\":6581},\"end\":{\"line\":157,\"column\":4,\"offset\":6664},\"indent\":[1,1,1,1]}}]": true
      }}>{`{
  "standout": {
    "brandingId": "globalPublicTest:advertisementBranding:hirerBranding:37EWfWxhwVEGTT5BcjfE2L"
  }
}
`}</code></pre>

    <Alert tone="caution" mdxType="Alert">
  <Text mdxType="Text">
    You must not use <InlineCode mdxType="InlineCode">logoId</InlineCode> and{' '}
    <InlineCode mdxType="InlineCode">brandingId</InlineCode> at the same time. You should replace
    your current usage of <InlineCode mdxType="InlineCode">logoId</InlineCode> with{' '}
    <InlineCode mdxType="InlineCode">brandingId</InlineCode> and convert the numeric logo IDs to
    strings.
  </Text>
    </Alert>
    <p>{`For more information on these queries, see the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/branding"
      }}>{`branding documentation`}</a>{`.`}</p>
    <h3 {...{
      "id": "questionnaires"
    }}>{`Questionnaires`}</h3>
    <p>{`A hirer can optionally include a questionnaire for candidates to answer in SEEK’s Apply Form.
Their responses are made available through the `}<a parentName="p" {...{
        "href": "/use-cases/application-export"
      }}>{`Optimised Apply`}</a>{` use case.
Questionnaires are rendered on SEEK’s candidate website and in our mobile apps.`}</p>
    <p>{`Some Job Posting API integrations made use of SEEK Screen,
a legacy feature that allowed hirers to manage questionnaires on the `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au"
      }}>{`SEEK employer website`}</a>{` and manually enter a numeric ID in your posting flow.`}</p>
    <p>{`The SEEK API does not support SEEK Screen for new integrations.
You can either replace the feature with SEEK API `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/questionnaires"
      }}>{`questionnaires`}</a>{`,
or remove it entirely if it sees low usage in your software.`}</p>
    <p>{`To ease migration to the SEEK API, a new string field named `}<inlineCode parentName="p">{`questionnaireId`}</inlineCode>{` has been added to the Job Posting API that accepts an application questionnaire OID:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "label=\"Questionnaire OID\"",
        "label": "\"Questionnaire",
        "OID\"": true
      }}>{`{
  "questionnaireId": "seekAnzPublicTest:applicationQuestionnaire:rrv2:SqFmkV8S2dMipyqbHjD9Mr"
}
`}</code></pre>
    <h2 {...{
      "id": "exit-criteria"
    }}>{`Exit criteria`}</h2>
    <p>{`In order to move to the next phase, you will need to have completed the following:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Criteria`}</strong></p></th>
          <th parentName="tr" {...{
            "align": null
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Locations supported`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Hirer can select from a list of locations`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Job categories supported`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Hirer can select from a list of job categories`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Branding supported`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Hirer can select from a list of brands`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Questionnaires supported`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Hirer can create a new questionnaire (if applicable)`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Job ad posted successfully`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Hirer can successfully post a job ad with objects retrieved or created via the SEEK API`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Job ad updated successfully`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}><p parentName="td">{`Hirer can successfully update a job ad with objects retrieved or created via the SEEK API`}</p></td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;