
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { UxFiles } from './assets/UxFiles';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "product-overview"
    }}>{`Product overview`}</h1>
    <p>{`The following resources provide a visual walkthrough of a Job Posting flow, incorporating SEEK’s recommended best practices and technical details.
These serve as a User Experience guide as well as a high-level technical reference to assist you in understanding how elements of the SEEK API work together during job ad creation.
Each field in the example flow is detailed with links to the corresponding sections of this documentation and API schema for further reference.`}</p>
    <p>{`The PDF file contains a pre-rendered version of the flow that doesn’t require any special software to view.`}</p>
    <p>{`The Figma file contains a componentised example based on SEEK’s `}<a parentName="p" {...{
        "href": "https://seek-oss.github.io/braid-design-system/"
      }}>{`Braid Design System`}</a>{` intended to assist design implementation.
The design has been informed by hirer research, experience with other integrations and SEEK’s own job posting flow.
It’s recommended to adapt the design of these components to align with your software’s specific needs.`}</p>

    <UxFiles mdxType="UxFiles" />
    <h2 {...{
      "id": "updating-a-job-ad"
    }}>{`Updating a job ad`}</h2>
    <p>{`The walkthrough concentrates primarily on the initial creation of a job ad.
However, the SEEK API also allows updating and closing existing job ads.`}</p>
    <p>{`When incorporating edit flows into your software, there are additional factors to consider, including:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Ad selection must be displayed again to retrieve an ad product relevant to the job ad being updated.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Questionnaires cannot be updated once a job ad has been posted.`}</p>
      </li>
    </ul>
    <p>{`For further detail on handling edits within your integration, refer to our `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/updating-a-job-ad"
      }}>{`Updating a job ad`}</a>{` section.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;