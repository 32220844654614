import { createForm } from '@seek/forms-ui';

import type { CreateNewWebhookMutationVariables } from 'src/types/graphql';

// Everything is strings, preserve optional keys
export type FormData = {
  [K in keyof CreateNewWebhookMutationVariables]: CreateNewWebhookMutationVariables[K] extends string
    ? CreateNewWebhookMutationVariables[K]
    : CreateNewWebhookMutationVariables[K] extends null
    ? string | null | undefined
    : string;
};

const { FormProvider, useField } = createForm<
  FormData,
  Record<string, never>,
  'en'
>();

export { FormProvider, useField };
