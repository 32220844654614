
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { SeekBrandSelect } from '../../branding';
import { SearchBulletPoints } from '../../assets/SearchBulletPoints';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "features-for-ad-products"
    }}>{`Features for ad products`}</h1>
    <p>{`Ad products can enable additional job ad `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/AdvertisementProduct/field/features"
      }}><inlineCode parentName="a">{`features`}</inlineCode></a>{`.
When an ad product is selected by the hirer,
your software should conditionally display form inputs that correspond to these features.`}</p>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <p>{`An ad product without any `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/AdvertisementProduct/field/features"
      }}><inlineCode parentName="a">{`features`}</inlineCode></a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "label=\"AdvertisementProductFeatures\"",
        "label": "\"AdvertisementProductFeatures\""
      }}>{`{
  "branding": null,
  "searchBulletPoints": null
}
`}</code></pre>
    <p>{`An ad product with `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/AdvertisementProduct/field/features"
      }}><inlineCode parentName="a">{`features`}</inlineCode></a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "label=\"AdvertisementProductFeatures\"",
        "label": "\"AdvertisementProductFeatures\""
      }}>{`{
  "branding": {
    "coverImageIndicator": false,
    "logoIndicator": true
  },
  "searchBulletPoints": {
    "limit": 2
  }
}
`}</code></pre>
    <h2 {...{
      "id": "branding"
    }}>{`Branding`}</h2>
    <p>{`If the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/AdvertisementProductFeatures/field/branding"
      }}><inlineCode parentName="a">{`branding`}</inlineCode></a>{` feature is enabled,
you should display a form input for the hirer to select a brand for their job ad.`}</p>

    <SeekBrandSelect variant="multiple-brands" mdxType="SeekBrandSelect" />
    <p>{`While some ad products will display the brand in its entirety on the job ad,
other ad products may be limited to select components of the brand.
For example, the second product above supports a logo but not a cover image.`}</p>
    <p>{`Your software should still use the same form input for brand selection,
but to provide your hirers with clarity on how the job ad will appear,
you may describe which brand components will apply to the job ad or include a `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/previewing-a-job-ad"
      }}>{`preview`}</a>{`.`}</p>
    <p>{`See our `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/branding"
      }}>{`branding documentation`}</a>{` for more information.`}</p>
    <h2 {...{
      "id": "search-bullet-points"
    }}>{`Search bullet points`}</h2>
    <p>{`If the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/AdvertisementProductFeatures/field/searchBulletPoints"
      }}><inlineCode parentName="a">{`searchBulletPoints`}</inlineCode></a>{` feature is enabled,
you should display form inputs for the hirer to enter key selling points for their job ad.`}</p>

    <SearchBulletPoints limit={2} mdxType="SearchBulletPoints" />
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/AdvertisementProductFeatures_SearchBulletPoints/field/limit"
      }}><inlineCode parentName="a">{`limit`}</inlineCode></a>{` describes the number of bullet points to request from the hirer.
These help the job ad to stand out in search results.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;