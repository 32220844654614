import {
  Alert,
  Box,
  Divider,
  Heading,
  PageBlock,
  Stack,
  Text,
} from 'braid-design-system';
import { Helmet } from 'react-helmet';
import { SmartTextLink, WrapperRenderer } from 'scoobie';

import { ContentHeader } from 'src/components/ContentHeader';
import { FlexPageWithFooter } from 'src/components/FlexPageWithFooter';
import { PageNavigationButtonLinks } from 'src/components/PageNavigationButtonLinks';
import { releaseNotes } from 'src/release-notes.map';
import type { PageNavigation } from 'src/site';

const Content = () => (
  <Stack space="xlarge">
    {Object.entries(releaseNotes)
      .sort(([a], [b]) => b.localeCompare(a))
      .map(([date, documents]) => (
        <Stack dividers space="large" key={date}>
          <Heading level="3">{date}</Heading>
          <Stack space="large">
            {documents.map((document, index) => (
              <WrapperRenderer document={document} key={`${date}-${index}`}>
                {({ children }) => <Stack space="large">{children}</Stack>}
              </WrapperRenderer>
            ))}
          </Stack>
        </Stack>
      ))}
  </Stack>
);

const ReleaseNotes = ({
  next,
  prev,
}: {
  next?: PageNavigation;
  prev?: PageNavigation;
}) => (
  <Box background="surface">
    <Helmet title="Release notes" />

    <PageBlock width="large">
      <ContentHeader>Release notes</ContentHeader>

      <Divider />

      <FlexPageWithFooter>
        <Stack space="xlarge">
          <Alert tone="promote">
            <Stack space="medium">
              <Text>
                Noteworthy additions and changes to the SEEK API are documented
                here.
              </Text>

              <Text>
                Subscribe to our{' '}
                <SmartTextLink href="https://developer.seek.com/feed/public.atom">
                  Atom
                </SmartTextLink>{' '}
                or{' '}
                <SmartTextLink href="https://developer.seek.com/feed/public.rss">
                  RSS
                </SmartTextLink>{' '}
                feed to be notified of updates.
              </Text>
            </Stack>
          </Alert>

          <Content />
        </Stack>

        <PageNavigationButtonLinks next={next} prev={prev} />
      </FlexPageWithFooter>
    </PageBlock>
  </Box>
);

export default ReleaseNotes;
