export const STATUS_CODES = [
  'All',
  'Running',
  'Completed',
  'Submitted',
  'Cancelled',
] as const;

export type StatusCode = (typeof STATUS_CODES)[number];

const statusCodeSet = new Set<string>(STATUS_CODES);

export const isValidStatusCode = (
  input: string,
): input is Exclude<StatusCode, 'All'> =>
  statusCodeSet.has(input) && input !== 'All';
