import {
  Box,
  IconCopy,
  IconTick,
  IconVisibility,
  Inline,
  Text,
} from 'braid-design-system';
import { useState } from 'react';
import { InlineCode } from 'scoobie';

export const Secret = ({
  secret,
  label,
}: {
  secret: string;
  label: string;
}) => {
  const [revealed, setRevealed] = useState(false);
  const [copied, setCopied] = useState(false);

  const copyText = async () => {
    if (copied) {
      return;
    }

    setCopied(true);

    await navigator.clipboard.writeText(secret);

    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <Text size="small" weight="medium">
        {label}
      </Text>
      <Inline space="xsmall" alignY="top">
        <Text size="small">
          <InlineCode>{revealed ? secret : '•'.repeat(50)}</InlineCode>
        </Text>
        <Box
          onClick={() => setRevealed((prev) => !prev)}
          cursor="pointer"
          title="Reveal"
        >
          <Text>
            <IconVisibility tone="link" />
          </Text>
        </Box>
        <Box
          component="span"
          cursor="pointer"
          onClick={copyText}
          title="Copy to Clipboard"
        >
          <Text>
            {copied ? <IconTick tone="positive" /> : <IconCopy tone="link" />}
          </Text>
        </Box>
      </Inline>
    </>
  );
};
