import { useState } from 'react';

import {
  EMPTY_SUGGESTION,
  HirerAutosuggest,
  type HirerAutosuggestValue,
} from 'src/components/HirerAutosuggest/HirerAutosuggest';
import type { HiringOrganizationIdFieldsFragment } from 'src/types/graphql';

export const useHirerState = () => {
  const [hirer, setHirer] = useState<HiringOrganizationIdFieldsFragment>();

  const [suggestion, setSuggestion] =
    useState<HirerAutosuggestValue>(EMPTY_SUGGESTION);

  return {
    hirer,
    setHirer,
    setSuggestion,
    suggestion,
  };
};

interface Props extends ReturnType<typeof useHirerState> {
  id: string;
}

export const HirerPicker = ({
  id,
  setHirer,
  setSuggestion,
  suggestion,
}: Props) => (
  <HirerAutosuggest
    id={id}
    label="Hirer"
    onChange={(selection) => {
      const selectedHirer = selection.value;

      if (selectedHirer) {
        setHirer(selectedHirer);

        setSuggestion(selection);
      } else {
        setSuggestion(selection);
      }
    }}
    onClear={() => {
      setHirer(undefined);
      setSuggestion(EMPTY_SUGGESTION);
    }}
    value={suggestion}
  />
);
