
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "application-questionnaire-input-limits"
    }}>{`Application questionnaire input limits`}</h4>
    <p>{`We’ve tightened the byte limit on `}<inlineCode parentName="p">{`String`}</inlineCode>{` input fields of the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/createApplicationQuestionnaire"
      }}><inlineCode parentName="a">{`createApplicationQuestionnaire`}</inlineCode>{` mutation`}</a>{` from 2,048 to 255 bytes in UTF-8 encoding.
This aligns with the `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#string-lengths"
      }}>{`default limit`}</a>{` for the SEEK API.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;