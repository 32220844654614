import { Box, Heading, Text } from 'braid-design-system';
import { SmartTextLink } from 'scoobie';

import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';
import { SectionCard } from 'src/components/SectionCard/SectionCard';
import { AuthVerifier } from 'src/components/auth/AuthVerifier';

import { JobAdLookupSection } from './components/JobAdLookupSection';

export const JobAdsPage = () => (
  <AuthVerifier>
    <PageWrapper
      heading={
        <Heading level="2" weight="weak">
          Job ads
        </Heading>
      }
      explainer={
        <Text>
          <SmartTextLink href="/use-cases/job-posting/managing-job-ads/querying-a-job-ad">
            Query
          </SmartTextLink>{' '}
          and{' '}
          <SmartTextLink href="/use-cases/job-posting/managing-job-ads/previewing-a-job-ad">
            preview
          </SmartTextLink>{' '}
          job ads posted by your hirers.
        </Text>
      }
    >
      <SectionCard>
        <Box paddingX="gutter" paddingY="large">
          <JobAdLookupSection key="JobAdLookupSection" />
        </Box>
      </SectionCard>
    </PageWrapper>
  </AuthVerifier>
);
