import { gql } from '@apollo/client';

export const APPLICATION_QUESTIONNAIRE = gql`
  query ApplicationQuestionnaire($id: String!) {
    applicationQuestionnaire(id: $id) {
      id {
        value
      }
      components {
        id {
          value
        }
        componentTypeCode
        ... on ApplicationQuestion {
          questionHtml
          responseTypeCode
          value
          responseChoice {
            id {
              value
            }
            text
            value
            preferredIndicator
          }
        }
        ... on ApplicationPrivacyConsent {
          descriptionHtml
          privacyPolicyUrl {
            url
          }
        }
      }
    }
  }
`;
