
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "panels"
    }}>{`Panels`}</h1>
    <p>{`The SEEK API includes panels that can be embedded in web-based software.
These JavaScript components provide an alternative to building features from scratch;
for example,
you can embed the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/v2/panel"
      }}>{`Ad Selection Panel`}</a>{` instead of implementing `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/v2/graphql"
      }}>{`ad selection via GraphQL`}</a>{`.`}</p>
    <p>{`Where the SEEK API provides a panel implementation,
we strongly recommend using it for your integration.
It will significantly reduce development time and ongoing maintenance, as
well as provide an up-to-date user experience for SEEK hirers.`}</p>
    <p>{`Our panels share a standardised entry point, interface and `}<a parentName="p" {...{
        "href": "/introduction/browser-support"
      }}>{`browser support`}</a>{` policy for ease of integration.
The common process of embedding a panel is outlined below,
and you can refer to the specific documentation of each panel for more information:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/ad-selection/v2/panel"
          }}>{`Ad Selection Panel`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/questionnaires/v2/panel"
          }}>{`Questionnaire Panel`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "include-the-panel"
    }}>{`Include the panel`}</h2>
    <p>{`Add the following script tag to the page where you want to insert a panel:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script
  type="text/javascript"
  src="https://integration.seek.com/panels/SeekApi.js"
></script>
`}</code></pre>
    <p>{`This will expose a `}<inlineCode parentName="p">{`SeekApi.render`}</inlineCode>{` function which renders an instance of a panel within a specified DOM element.`}</p>
    <h2 {...{
      "id": "render-the-panel"
    }}>{`Render the panel`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`render`}</inlineCode>{` function must be called on page load and whenever dynamic props change.
For example, if you have included the Ad Selection Panel and the hirer changes the position’s location, you must re-render the panel to reflect updated pricing.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`SeekApi.render(containerNode, panelName, props);
`}</code></pre>
    <h3 {...{
      "id": "handle-browser-token-requests"
    }}>{`Handle browser token requests`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function must be supplied via props:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`getAuthToken: async () => {
  // Do not implement caching in your \`getAuthToken\` implementation.
  // The panel will internally memoise the response.
  const token = await fetchAuthToken();

  return token;
},
`}</code></pre>
    <p>{`Your software should implement the following auth flow:`}</p>
    <img alt="" data-scoobie-style="none" src={require('../../../mermaid/.eae69ab3cbbd2143f1186f1d725436963b6405cd.mmd.svg')} title="Panel authentication" />
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The panel loads and invokes the `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function passed to it.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend requests a browser token from your backend.`}</p>
        <p parentName="li">{`The `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function should request a new token for the hirer ID in `}<inlineCode parentName="p">{`positionProfile.positionOrganizations`}</inlineCode>{`.
If a user switches to a different SEEK hirer account in your posting form,
your software should re-render the panel with the new hirer ID in `}<inlineCode parentName="p">{`positionProfile.positionOrganizations`}</inlineCode>{`,
and ensure that subsequent invocations of `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` will request a token for the new hirer ID.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend authenticates and authorises the user.`}</p>
        <p parentName="li">{`Your software is responsible for verifying that the user is authorised to access a given hirer ID.
A user must not be able to request a browser token for an arbitrary organization that they do not belong to.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend `}<a parentName="p" {...{
            "href": "/auth/browser-tokens"
          }}>{`requests a browser token from the SEEK API`}</a>{` for the appropriate hirer ID and scope.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend responds with the browser token.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend returns the browser token from the `}<inlineCode parentName="p">{`getAuthToken`}</inlineCode>{` function.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The panel can now make requests to the GraphQL endpoint.`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`POST https://graphql.seek.com/auth/token HTTP/1.1
Authorization: Bearer PARTNER_TOKEN_HERE
Content-Type: application/json
User-Agent: YourPartnerService/1.2.3

{
  "hirerId": "seekAnzPublicTest:organization:seek:93WyyF1h",
  "scope": "query:ad-products query:organizations",
  "userId": "317665"
}
`}</code></pre>
    <h2 {...{
      "id": "localise-the-panel"
    }}>{`Localise the panel`}</h2>
    <p>{`By default,
the panel will infer the preferred locale of the user from their web browser.`}</p>
    <p>{`The SEEK API supports a limited set of languages at this time and falls back to Australian English when preferred locales are not available.
The panel may serve mixed language content where translations are partially available.`}</p>
    <p>{`If your software manages the user locale in a different manner,
such as including it in a URL segment like `}<inlineCode parentName="p">{`/en-AU/content`}</inlineCode>{`,
you may use the `}<inlineCode parentName="p">{`locale`}</inlineCode>{` prop to force a specific preference:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`SeekApi.render(containerNode, panelName, {
  locale: 'id-ID'
});
`}</code></pre>
    <p>{`See our general `}<a parentName="p" {...{
        "href": "/graphql/in-practice#content-localisation"
      }}>{`content localisation`}</a>{` documentation for more information.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;