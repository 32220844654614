import { FileArchive } from '@phosphor-icons/react';
import { IconRenderer, Tiles } from 'braid-design-system';

import { TileLink } from 'src/components/TileLink';

const GuideIcon = () => (
  <IconRenderer>
    {({ className }) => <FileArchive className={className} />}
  </IconRenderer>
);

export const ArchivedGuides = () => (
  <Tiles columns={[1, 1, 1]} space="small">
    <TileLink
      description="Initial migration from legacy Job Posting & Application Export integrations in Australia & New Zealand to the SEEK API"
      href="/migration-guides/overview"
      icon={<GuideIcon />}
      title="2022 SEEK API migration"
    />
  </Tiles>
);
