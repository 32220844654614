import { Box } from 'braid-design-system';
import type { ReactNode } from 'react';

import { SectionCard } from 'src/components/SectionCard/SectionCard';

interface Props {
  children: ReactNode;
}

export const SectionWrapper = ({ children }: Props) => (
  <SectionCard>
    <Box paddingX="gutter" paddingY="large">
      {children}
    </Box>
  </SectionCard>
);
