
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "downloading-hirer-list-csvs"
    }}>{`Downloading hirer list CSVs`}</h4>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://manage.developer.seek.com/hirers"
      }}>{`Developer Dashboard’s hirers page`}</a>{` now supports generating a CSV of all the SEEK hirers you have an `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}>{`active relationship`}</a>{` with.
Previously, you would’ve had to contact SEEK for a hirer spreadsheet or use the Developer Dashboard’s web interface.`}</p>
    <p>{`The CSV can be generated by clicking the “Download CSV” button on the top right corner of the page’s “Search all hirers” section.
This will respect the current hirer search filter; ensure you clear the filters if you want a list of all hirers.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;