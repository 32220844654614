import type { ReactNode } from 'react-markdown';

import { LogoutWrapper } from './components/LogoutWrapper/LogoutWrapper';
import type { Page } from './page';
import { HomeMenuItemLabel } from './pages/manage/home/components/HomeMenuItemLabel/HomeMenuItemLabel';
import { UserProfileMenuItemLabel } from './pages/manage/userProfile/components/UserProfileMenuItemLabel';

export const site: Page[] = [
  {
    children: [
      {
        label: <HomeMenuItemLabel />,
        path: '/manage',
        type: 'standalone',
        icon: 'Home',
      },
      {
        label: 'Docs',
        path: '/',
        type: 'standalone',
        mobileOnly: true,
        icon: 'Documentation',
      },
      {
        label: 'Schema',
        path: 'https://developer.seek.com/schema',
        type: 'standalone',
        mobileOnly: true,
        icon: 'Schema',
      },
      {
        label: 'Status page',
        path: 'https://status.seek.com',
        type: 'standalone',
        mobileOnly: true,
        icon: 'StatusPage',
      },
    ],
  },
  {
    label: 'Getting started',
    children: [
      {
        label: 'Auth',
        type: 'standalone',
        icon: 'Auth',
        menuItemStartExpanded: true,
        children: [
          {
            label: 'Client credentials',
            path: '/manage/credentials',
            type: 'reviewed',
          },
          {
            label: 'Hirer relationships',
            path: '/manage/hirers',
            type: 'reviewed',
          },
        ],
      },
      {
        label: 'GraphQL Explorer',
        path: '/manage/graphql-explorer',
        type: 'standalone',
        icon: 'GraphQL',
      },
      {
        label: 'Events',
        type: 'standalone',
        icon: 'Webhook',
        menuItemStartExpanded: true,
        children: [
          {
            label: 'Webhook subscriptions',
            path: '/manage/webhooks',
            type: 'reviewed',
          },
          {
            label: 'Webhook playground',
            path: '/manage/webhook-playground',
            type: 'reviewed',
          },
          {
            label: 'Polling',
            path: '/manage/events',
            type: 'reviewed',
          },
        ],
      },
    ],
  },
  {
    label: 'Use Cases',
    children: [
      {
        label: 'Job Posting',
        type: 'standalone',
        icon: 'JobPosting',
        menuItemStartExpanded: true,
        children: [
          {
            label: 'Features',
            path: '/manage/job-posting',
            type: 'reviewed',
          },
          {
            label: 'Job Ads',
            path: '/manage/job-ads',
            type: 'reviewed',
          },
        ],
      },
    ],
  },
  {
    label: 'Settings',
    children: [
      {
        label: 'Notifications',
        type: 'standalone',
        icon: 'Notifications',
        path: '/manage/notifications',
      },
      {
        label: 'Users',
        type: 'standalone',
        icon: 'Users',
        path: '/manage/users',
      },
      {
        label: <UserProfileMenuItemLabel />,
        type: 'standalone',
        icon: 'UserProfile',
        path: '/manage/user-profile',
      },
      {
        label: 'Logout',
        type: 'standalone',
        icon: 'Logout',
        menuItemWrapper: (childNode: ReactNode) => (
          <LogoutWrapper>{childNode}</LogoutWrapper>
        ),
      },
    ],
  },
];
