import { ListChecks } from '@phosphor-icons/react';
import {
  Accordion,
  AccordionItem,
  Box,
  Heading,
  IconCreditCard,
  IconImage,
  IconLocation,
  IconRenderer,
  IconStatistics,
  IconSubCategory,
  Stack,
  Text,
} from 'braid-design-system';
import { SmartTextLink } from 'scoobie';

import {
  HirerPicker,
  useHirerState,
} from 'src/components/HirerPicker/HirerPicker';
import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';
import { AuthVerifier } from 'src/components/auth/AuthVerifier';
import { usePermissions } from 'src/hooks/auth';
import { useEnvironmentConfig } from 'src/hooks/environment';

import { AdPerformanceSection } from './components/AdPerformanceSection/AdPerformanceSection';
import type { AdPerformanceProps } from './components/AdPerformanceSection/components/types';
import { AdSelectionSection } from './components/AdSelectionSection/AdSelectionSection';
import type { AdSelectionProps } from './components/AdSelectionSection/components/types';
import { BrandingSection } from './components/BrandingSection';
import { JobCategoryLookupSection } from './components/JobCategoryLookupSection';
import { JobCategorySelectSection } from './components/JobCategorySelectSection';
import { JobCategorySuggestSection } from './components/JobCategorySuggestSection';
import { LocationLookupSection } from './components/LocationLookupSection';
import { LocationSelectMapSection } from './components/LocationSelectMapSection';
import { LocationSuggestSection } from './components/LocationSuggestSection';
import { QuestionnaireLookupSection } from './components/QuestionnaireLookupSection';
import { QuestionnairePanelSection } from './components/QuestionnairePanelSection/QuestionnairePanelSection';
import type { QuestionnairePanelProps } from './components/QuestionnairePanelSection/components/types';
import { SectionWrapper } from './components/SectionWrapper/SectionWrapper';

declare global {
  interface Window {
    SeekApi: {
      render: (
        element: HTMLElement,
        componentName: string,
        props: AdSelectionProps | QuestionnairePanelProps | AdPerformanceProps,
      ) => void;
    };
  }
}

export const JobPostingPage = () => {
  const hirerState = useHirerState();
  const { isImpersonating, isInternalTestPartner, integrationPartnerUuid } =
    usePermissions();
  const { adPerformanceAllowlist } = useEnvironmentConfig();
  const { hirer } = hirerState;

  const showAdPerformance =
    (integrationPartnerUuid &&
      adPerformanceAllowlist.has(integrationPartnerUuid)) ||
    isImpersonating ||
    isInternalTestPartner;

  return (
    <AuthVerifier>
      <PageWrapper
        heading={
          <Heading level="2" weight="weak">
            Job Posting features
          </Heading>
        }
        explainer={
          <Text>
            Interactively explore features of the{' '}
            <SmartTextLink href="https://developer.seek.com/use-cases/job-posting">
              Job Posting use case
            </SmartTextLink>
            .
          </Text>
        }
      >
        {[
          <Stack key="HirerPicker" space="large">
            <HirerPicker
              id="jobPostingHirerPickerAutosuggest"
              {...hirerState}
            />

            <Stack space="medium">
              <Text tone="secondary">
                Cross reference the below controls with your software’s
                implementation to ensure that you are correctly querying and
                displaying our Job Posting features.
              </Text>

              <Text tone="secondary">
                See the relevant sections on the{' '}
                <SmartTextLink href="https://developer.seek.com">
                  Developer Site
                </SmartTextLink>{' '}
                for more information.
              </Text>
            </Stack>
          </Stack>,
          ...(showAdPerformance
            ? [
                <Accordion key="AdPerformance" space="large" dividers={false}>
                  <AccordionItem
                    id="AdPerformanceSection"
                    label="Ad Performance"
                    icon={<IconStatistics />}
                  >
                    <SectionWrapper key="AdPerformanceWrapper">
                      <AdPerformanceSection key="AdPerformanceSection" />
                    </SectionWrapper>
                  </AccordionItem>
                </Accordion>,
              ]
            : []),
          <Accordion key="AdSelection" space="large" dividers={false}>
            <AccordionItem
              id="AdSelectionSection"
              label="Ad Selection"
              icon={<IconCreditCard />}
            >
              <SectionWrapper key="AdSelectionWrapper">
                <AdSelectionSection hirer={hirer} key="AdSelectionSection" />
              </SectionWrapper>
            </AccordionItem>
          </Accordion>,
          <Accordion key="Branding" space="large" dividers={false}>
            <AccordionItem
              id="BrandingSection"
              label="Branding"
              icon={<IconImage />}
            >
              <SectionWrapper key="BrandingWrapper">
                <BrandingSection hirer={hirer} key="BrandingSection" />
              </SectionWrapper>
            </AccordionItem>
          </Accordion>,

          <Accordion key="JobCategories" space="large" dividers={false}>
            <AccordionItem
              id="JobCategoriesFeatures"
              label="Job categories"
              icon={<IconSubCategory />}
            >
              <Stack space="large">
                {[
                  <JobCategorySelectSection key="JobCategorySelectSection" />,
                  <JobCategoryLookupSection key="JobCategoryLookupSection" />,
                  <JobCategorySuggestSection
                    hirer={hirer}
                    key="JobCategorySuggestSection"
                  />,
                ].map((section, index) => (
                  <SectionWrapper key={index}>{section}</SectionWrapper>
                ))}
              </Stack>
            </AccordionItem>
          </Accordion>,

          <Accordion key="LocationsFeatures" space="large" dividers={false}>
            <AccordionItem
              id="locations"
              label="Locations"
              icon={<IconLocation />}
            >
              <Stack space="large">
                {[
                  <LocationSuggestSection
                    hirer={hirer}
                    key="LocationSuggestSection"
                  />,
                  <LocationLookupSection key="LocationLookupSection" />,
                  <LocationSelectMapSection key="LocationSelectMapSection" />,
                ].map((section, index) => (
                  <SectionWrapper key={index}>{section}</SectionWrapper>
                ))}
              </Stack>
            </AccordionItem>
          </Accordion>,

          <Accordion key="Questionnaires" space="large" dividers={false}>
            <AccordionItem
              id="QuestionnairesFeatures"
              label="Questionnaires"
              icon={
                <IconRenderer>
                  {({ className }) => <ListChecks className={className} />}
                </IconRenderer>
              }
            >
              <Stack space="large">
                {[
                  <QuestionnaireLookupSection key="QuestionnaireLookupSection" />,
                  <QuestionnairePanelSection
                    hirer={hirer}
                    key="QuestionnairePanelSection"
                  />,
                ].map((section, index) => (
                  <SectionWrapper key={index}>{section}</SectionWrapper>
                ))}
              </Stack>
            </AccordionItem>
          </Accordion>,
        ].map((section, index) => (
          <SectionWrapper key={index}>{section}</SectionWrapper>
        ))}

        {/* TODO: temporary space below to display location suggestions. */}
        <Box paddingY="xxlarge" />
      </PageWrapper>
    </AuthVerifier>
  );
};
