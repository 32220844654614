
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Flow } from './application-export/Flow';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "optimised-apply"
    }}>{`Optimised Apply`}</h1>
    <p>{`This use case provides technical implementation details for the SEEK Optimised Apply product.
You can find more information and request an integration on its `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au/partners/integrations/seek-optimised-apply/"
      }}>{`product page`}</a>{`.`}</p>
    <p>{`Using SEEK’s Apply Form, candidates can apply for jobs without leaving our website or mobile app.
This allows candidates to use documents and information already stored in their SEEK Profiles.
SEEK Optimised Apply enables industry-leading conversion rates of candidates through the application funnel.`}</p>
    <p>{`When a candidate applies for a job advertised by one of your hirers, the SEEK API makes their application available for export.
You can export applications for job ads posted via your recruitment software, but also for those posted directly on SEEK.`}</p>
    <h2 {...{
      "id": "retrieving-candidate-applications"
    }}>{`Retrieving candidate applications`}</h2>
    <p><a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CandidateApplicationCreatedEvent"
      }}><inlineCode parentName="a">{`CandidateApplicationCreated`}</inlineCode>{` events`}</a>{` are emitted when candidates submit applications.
You will receive events for SEEK hirers you have an `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}><inlineCode parentName="a">{`ApplicationExport`}</inlineCode>{` relationship`}</a>{` with at the time of application submission.`}</p>
    <p>{`You will need to request candidate application details from the GraphQL endpoint in response to these events.
For more details, see:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/application-export/candidate-profiles"
          }}>{`Exporting candidate profiles`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/application-export/attachments"
          }}>{`Exporting attachments`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/application-export/questionnaire-submissions"
          }}>{`Exporting questionnaire submissions`}</a></p>
      </li>
    </ul>
    <p>{`The available deliverables for an application will differ based on the job posting method, configuration of the job ad, and what the candidate provides.
Ensure that you have accounted for all supported deliverables when you are validating your build.`}</p>
    <Flow mdxType="Flow" />
    <p>{`The SEEK API may occasionally send `}<a parentName="p" {...{
        "href": "/events/webhooks#duplicates"
      }}>{`duplicate events`}</a>{`.
This can occur incidentally, or when SEEK finds and fixes a data quality issue.
While it is generally safe to ignore duplicate events that are sent in quick succession,
we recommend reprocessing them to ensure that your software always has the latest view of each application.`}</p>
    <p>{`A duplicate `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CandidateApplicationCreatedEvent"
      }}><inlineCode parentName="a">{`CandidateApplicationCreated`}</inlineCode>{` event`}</a>{` does not indicate that a candidate modified their application.
A candidate may submit multiple applications for a given job ad on the SEEK job board,
but each application is modelled as its own object.`}</p>
    <p>{`A candidate application will remain for 180 days after the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads#job-ad-lifecycle"
      }}>{`close date`}</a>{` of its associated job ad.
Your software must store any data that it needs to access after the 180 day period.`}</p>
    <h2 {...{
      "id": "job-ads-posted-outside-of-your-software"
    }}>{`Job ads posted outside of your software`}</h2>
    <p>{`A significant number of SEEK hirers have workflows that require posting job ads directly on the SEEK employer website.
On the `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au"
      }}>{`SEEK employer website`}</a>{`, there is a `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/identifiers-and-references"
      }}>{`job reference`}</a>{` field that hirers can provide during posting if they intend for the applications to arrive in their ATS.`}</p>
    <p>{`In order to support the workflows of these hirers, SEEK strongly recommends that you appropriately handle `}<inlineCode parentName="p">{`CandidateApplicationCreated`}</inlineCode>{` events from job ads posted outside of your software.
Otherwise, hirers will need to use the SEEK employer website to manage applications for these job ads.`}</p>
    <p>{`To associate an application from a job ad with the appropriate position in your software, use the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PostedPositionProfile/field/seekHirerJobReference"
      }}><inlineCode parentName="a">{`PostedPositionProfile.seekHirerJobReference`}</inlineCode></a>{` field.
Your software will not have a record of position identifiers for job ads posted outside the SEEK API, so associating applications based on known position identifiers will fail.`}</p>
    <p>{`If the design of your software prohibits it from handling job ads posted from another source,
you can implement the following logic:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Use a `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/identifiers-and-references#reference-fields"
          }}>{`reference field`}</a>{` to correlate the job ad with an existing position in your software.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If correlation fails, check the `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/PostedPositionProfile/field/seekCreatedBySelfIndicator"
          }}><inlineCode parentName="a">{`PostedPositionProfile.seekCreatedBySelfIndicator`}</inlineCode></a>{` field for the job ad.`}</p>
        <p parentName="li"><inlineCode parentName="p">{`true`}</inlineCode>{` indicates that the job ad was posted by your software.
You should triage this job ad as your software may have omitted a reference field.`}</p>
        <p parentName="li"><inlineCode parentName="p">{`false`}</inlineCode>{` indicates that the job ad was posted outside of your software.
You may discard this job ad and its associated applications at the expense of workflow flexibility for your hirers.
If you received the application via `}<a parentName="p" {...{
            "href": "#option-1-webhook"
          }}>{`webhook`}</a>{`,
you should still respond with a `}<inlineCode parentName="p">{`2xx`}</inlineCode>{` status to allow the SEEK API to halt `}<a parentName="p" {...{
            "href": "/events/webhooks#retries--debugging"
          }}>{`retries`}</a>{` and mark the application as delivered.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "implementation-options"
    }}>{`Implementation options`}</h2>
    <h3 {...{
      "id": "option-1-webhook"
    }}>{`Option 1: Webhook`}</h3>
    <p>{`Receiving a candidate application via webhook with the SEEK API:`}</p>
    <img alt="" data-scoobie-style="none" src={require('../../../mermaid/.94d10a7fea0fae4680ef9268f889b3e2db03582b.mmd.svg')} title="Webhook interaction" />
    <p>{`This is an example of a `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhook`}</a>{` body containing two `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CandidateApplicationCreatedEvent"
      }}><inlineCode parentName="a">{`CandidateApplicationCreated`}</inlineCode>{` events`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsonc",
        "metastring": "sample-webhook-body",
        "sample-webhook-body": true
      }}>{`{
  "events": [
    {
      "id": "seekAnzPublicTest:event:events:5cKZnRzXas97fyUAhpiAV1",
      "type": "CandidateApplicationCreated",

      "createDateTime": "2019-09-13T22:16:10.593Z",

      "candidateApplicationProfileId": "seekAnzPublicTest:candidateProfile:apply:7DtW6Q68gk2R4okNGhvg1Y",
      "candidateId": "seekAnzPublicTest:candidate:feed:5PGXAHysiXhtA9JUqhyM8hhzMuWMPA",

      // This is only available for signed webhook subscriptions
      "hirerId": "seekAnzPublicTest:organization:seek:93WyyF1h"
    },
    {
      "id": "seekAnzPublicTest:event:events:WBmJMt4uEbj72ZVMhGi2hS",
      "type": "CandidateApplicationCreated",

      "createDateTime": "2019-09-08T20:32:12.479Z",

      "candidateApplicationProfileId": "seekAnzPublicTest:candidateProfile:apply:4QM5fWQbdekL9gPtPZrzex",
      "candidateId": "seekAnzPublicTest:candidate:feed:5PGXAHysjZdkQYwZghfL4bRCqvZ7ZM",

      // This is only available for signed webhook subscriptions
      "hirerId": "seekAnzPublicTest:organization:seek:93WyyF1h"
    }
  ],
  "subscriptionId": "seekAnzPublicTest:webhookSubscription:events:BoJiJ9ZWFVgejLXLJxUnvL",
  "url": "https://example.com/webhook"
}
`}</code></pre>
    <p>{`Events sent via the webhook method do not contain sensitive information.`}</p>
    <p>{`Once you receive an event you can use its `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/webhooks/CandidateApplicationCreatedEvent/property/candidateApplicationProfileId"
      }}><inlineCode parentName="a">{`candidateApplicationProfileId`}</inlineCode></a>{` to query the candidate’s application using the `}<a parentName="p" {...{
        "href": "/use-cases/application-export/candidate-profiles#candidateprofile"
      }}><inlineCode parentName="a">{`candidateProfile`}</inlineCode>{` query`}</a>{`.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhooks`}</a>{` section for more information.`}</p>
    <h3 {...{
      "id": "option-2-polling"
    }}>{`Option 2: Polling`}</h3>
    <p>{`Receiving candidate application events via `}<a parentName="p" {...{
        "href": "/events/polling"
      }}>{`polling`}</a>{` with the SEEK API:`}</p>
    <img alt="" data-scoobie-style="none" src={require('../../../mermaid/.9a7f315da4365d0c491ebb39460def11faaaf4e7.mmd.svg')} title="Polling interaction" />
    <p>{`This query example returns a page of `}<inlineCode parentName="p">{`CandidateApplicationCreated`}</inlineCode>{` events since the previous cursor:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($after: String!, $filter: EventsFilterInput!, $schemeId: String!) {\\n  events(after: $after, filter: $filter, schemeId: $schemeId) {\\n    edges {\\n      node {\\n        typeCode\\n        ... on CandidateApplicationCreatedEvent {\\n          candidate {\\n            person {\\n              name {\\n                formattedName\\n              }\\n            }\\n          }\\n          candidateApplicationProfile {\\n            associatedPositionProfile {\\n              positionUri\\n            }\\n          }\\n        }\\n      }\\n    }\\n    pageInfo {\\n      hasNextPage\\n      endCursor\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":167,\"column\":1,\"offset\":7810},\"end\":{\"line\":195,\"column\":4,\"offset\":8408},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"after\\\": \\\"seekAnzPublicTest:eventCursor:events:3zQw2pr4e9HHhsv8CJUhxMJPMA7mM4L13\\\",\\n  \\\"filter\\\": {\\n    \\\"eventTypeCodes\\\": \\\"CandidateApplicationCreated\\\"\\n  },\\n  \\\"schemeId\\\": \\\"seekAnzPublicTest\\\"\\n}\",\"position\":{\"start\":{\"line\":197,\"column\":1,\"offset\":8410},\"end\":{\"line\":205,\"column\":4,\"offset\":8615},\"indent\":[1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($after:": true,
        "String!,": true,
        "$filter:": true,
        "EventsFilterInput!,": true,
        "$schemeId:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "events(after:": true,
        "$after,": true,
        "filter:": true,
        "$filter,": true,
        "schemeId:": true,
        "$schemeId)": true,
        "edges": true,
        "node": true,
        "typeCode\\n": true,
        "...": true,
        "on": true,
        "CandidateApplicationCreatedEvent": true,
        "candidate": true,
        "person": true,
        "name": true,
        "formattedName\\n": true,
        "}\\n": true,
        "candidateApplicationProfile": true,
        "associatedPositionProfile": true,
        "positionUri\\n": true,
        "pageInfo": true,
        "hasNextPage\\n": true,
        "endCursor\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":167,\"column\":1,\"offset\":7810},\"end\":{\"line\":195,\"column\":4,\"offset\":8408},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"after\\\":": true,
        "\\\"seekAnzPublicTest:eventCursor:events:3zQw2pr4e9HHhsv8CJUhxMJPMA7mM4L13\\\",\\n": true,
        "\\\"filter\\\":": true,
        "\\\"eventTypeCodes\\\":": true,
        "\\\"CandidateApplicationCreated\\\"\\n": true,
        "},\\n": true,
        "\\\"schemeId\\\":": true,
        "\\\"seekAnzPublicTest\\\"\\n}\",\"position\":{\"start\":{\"line\":197,\"column\":1,\"offset\":8410},\"end\":{\"line\":205,\"column\":4,\"offset\":8615},\"indent\":[1,1,1,1,1,1,1,1]}}]": true
      }}>{`query ($after: String!, $filter: EventsFilterInput!, $schemeId: String!) {
  events(after: $after, filter: $filter, schemeId: $schemeId) {
    edges {
      node {
        typeCode
        ... on CandidateApplicationCreatedEvent {
          candidate {
            person {
              name {
                formattedName
              }
            }
          }
          candidateApplicationProfile {
            associatedPositionProfile {
              positionUri
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`}</code></pre>
    <p>{`You do not need to query for the candidate’s application separately as all information can be retrieved as part of the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/Event"
      }}><inlineCode parentName="a">{`Event`}</inlineCode></a>{`.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/events/polling"
      }}>{`polling`}</a>{` section for more information.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;