
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { SeekAnalytics } from './SeekAnalytics';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "contact-people"
    }}>{`Contact people`}</h1>
    <p>{`You need to provide a `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/SpecifiedPersonInput"
      }}>{`contact person`}</a>{` when `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/position-openings#createpositionopening"
      }}>{`creating a position opening`}</a>{` or using the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad#postposition"
      }}><inlineCode parentName="a">{`postPosition`}</inlineCode>{` mutation`}</a>{`.
SEEK may reach out to them if there is a problem with their job ad after it’s gone live.
Their name & contact details will not be shown on the job ad or exposed to candidates.
The following messaging may be presented to the user to explain SEEK’s usage of contact information:`}</p>
    <blockquote>
      <p parentName="blockquote">{`This information will only be visible to SEEK. It will not be made public or displayed on the ad.`}</p>
    </blockquote>
    <p>{`Multiple contact people can be provided in the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PostingRequesterInput/field/personContacts"
      }}><inlineCode parentName="a">{`personContacts`}</inlineCode>{` array`}</a>{`.
This can be useful if the person recruiting for the position is different from the position’s hiring manager.
The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/SpecifiedPersonInput/field/roleCode"
      }}>{`contact’s role`}</a>{` is used to differentiate those two cases.`}</p>
    <p>{`At a minimum the person’s name, email & role must be provided.
You can automatically map or pre-fill these fields based on the user logged in to your software.
Note that physical addresses and phone numbers may be omitted,
and `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CommunicationInput/field/seekDoNotContactIndicator"
      }}><inlineCode parentName="a">{`seekDoNotContactIndicator`}</inlineCode></a>{` should be omitted as it is not supported for contact people.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsonc",
        "metastring": "inputType=\"SpecifiedPersonInput\" label=\"SpecifiedPersonInput\"",
        "inputType": "\"SpecifiedPersonInput\"",
        "label": "\"SpecifiedPersonInput\""
      }}>{`// \`positionOpening.postingRequester.personContacts[]\`
{
  "name": {
    "formattedName": "Mary Manager"
  },
  "communication": {
    "email": [{ "address": "mary.manager@example.com" }],
    // This can be set to an empty array.
    "phone": [],
    // This can be omitted or set to an empty array.
    "address": [],
    // This should be omitted or set to null.
    "seekDoNotContactIndicator": null
  },
  "roleCode": "HiringManager"
}
`}</code></pre>
    <p>{`The job ad is also attributed to their name in `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au/analytics/"
      }}>{`SEEK Analytics`}</a>{`:`}</p>

    <SeekAnalytics mdxType="SeekAnalytics" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;