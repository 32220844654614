
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { SeekSalaryDetails } from './salary';
import { Monthly } from './assets/PayType';
import { SalaryCurrency } from './assets/SalaryCurrency';
import { SalaryRange } from './assets/SalaryRange';
import { JobAdSalary } from './assets/JobAdSalary';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "salary"
    }}>{`Salary`}</h1>
    <p>{`Hirers must specify a pay type, salary range and description of the remuneration package for their job ad.
This can be automatically mapped from your software’s internal representation, or presented as SEEK-specific input:`}</p>

    <SeekSalaryDetails mdxType="SeekSalaryDetails" />
    <p>{`Remuneration packages are specified in the `}<inlineCode parentName="p">{`offeredRemunerationPackage`}</inlineCode>{` field within all job posting mutations, and its schema is defined by the input type `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/RemunerationPackageInput"
      }}><inlineCode parentName="a">{`RemunerationPackageInput`}</inlineCode></a>{`.`}</p>
    <h2 {...{
      "id": "pay-type"
    }}>{`Pay type`}</h2>
    <p>{`A hirer must specify the pay type for the remuneration package.
This is expressed using a combination of two fields:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/RemunerationPackageInput/field/basisCode"
          }}><inlineCode parentName="a">{`basisCode`}</inlineCode></a>{` defines how the remuneration for the position is calculated.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/RemunerationRangeInput/field/intervalCode"
          }}><inlineCode parentName="a">{`intervalCode`}</inlineCode></a>{` defines the period of time the minimum & maximum amounts are calculated over.`}</p>
      </li>
    </ol>
    <p>{`There are currently four valid pay types:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Label`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p"><inlineCode parentName="strong">{`basisCode`}</inlineCode></strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p"><inlineCode parentName="strong">{`intervalCode`}</inlineCode></strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Description`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Hourly rate`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`Hourly`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`Hour`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Employment paid for the number of hours worked`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Monthly salary`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`Salaried`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`Month`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Employment paid on a monthly basis`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Annual salary`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`Salaried`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`Year`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Employment paid on an annual basis`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Annual plus commission`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`SalariedPlusCommission`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`Year`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Employment paid on an annual basis plus a results-based commission`}</p></td>
        </tr>
      </tbody>
    </table>

    <Monthly hideNew mdxType="Monthly" />
    <p>{`We recommend using the pay type labels documented above for consistency with SEEK.`}</p>
    <p>{`The SEEK API now supports dynamically querying valid pay type combinations with the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/payTypes"
      }}><inlineCode parentName="a">{`payTypes`}</inlineCode>{` query`}</a>{`.
These may be presented to a hirer for selection in a job posting flow.`}</p>
    <p>{`This can help prevent entering a nonsensical pay type combination.
For example, a job ad with an hourly rate of $70,000 will render a hirer’s job ad undiscoverable in recommendations and search`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($schemeId: String!) {\\n  payTypes(schemeId: $schemeId) {\\n    basisCode\\n    intervalCode\\n    label\\n  }\\n}\",\"position\":{\"start\":{\"line\":42,\"column\":1,\"offset\":2213},\"end\":{\"line\":50,\"column\":4,\"offset\":2337},\"indent\":[1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label=\\\"Variables\\\"\",\"value\":\"{\\n  \\\"schemeId\\\": \\\"seekAnzPublicTest\\\"\\n}\",\"position\":{\"start\":{\"line\":52,\"column\":1,\"offset\":2339},\"end\":{\"line\":56,\"column\":4,\"offset\":2406},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label=\\\"Result\\\"\",\"value\":\"{\\n  \\\"payTypes\\\": [\\n    {\\n      \\\"basisCode\\\": \\\"Hourly\\\",\\n      \\\"intervalCode\\\": \\\"Hour\\\",\\n      \\\"label\\\": \\\"Hourly rate\\\"\\n    },\\n    {\\n      \\\"basisCode\\\": \\\"Salaried\\\",\\n      \\\"intervalCode\\\": \\\"Month\\\",\\n      \\\"label\\\": \\\"Monthly salary\\\"\\n    },\\n    {\\n      \\\"basisCode\\\": \\\"Salaried\\\",\\n      \\\"intervalCode\\\": \\\"Year\\\",\\n      \\\"label\\\": \\\"Annual salary\\\"\\n    },\\n    {\\n      \\\"basisCode\\\": \\\"SalariedPlusCommission\\\",\\n      \\\"intervalCode\\\": \\\"Year\\\",\\n      \\\"label\\\": \\\"Annual plus commission\\\"\\n    }\\n  ]\\n}\",\"position\":{\"start\":{\"line\":58,\"column\":1,\"offset\":2408},\"end\":{\"line\":83,\"column\":4,\"offset\":2898},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($schemeId:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "payTypes(schemeId:": true,
        "$schemeId)": true,
        "basisCode\\n": true,
        "intervalCode\\n": true,
        "label\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":42,\"column\":1,\"offset\":2213},\"end\":{\"line\":50,\"column\":4,\"offset\":2337},\"indent\":[1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label": "\\\"Variables\\\"\",\"value\":\"{\\n",
        "\\\"schemeId\\\":": true,
        "\\\"seekAnzPublicTest\\\"\\n}\",\"position\":{\"start\":{\"line\":52,\"column\":1,\"offset\":2339},\"end\":{\"line\":56,\"column\":4,\"offset\":2406},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label": "\\\"Result\\\"\",\"value\":\"{\\n",
        "\\\"payTypes\\\":": true,
        "[\\n": true,
        "\\\"basisCode\\\":": true,
        "\\\"Hourly\\\",\\n": true,
        "\\\"intervalCode\\\":": true,
        "\\\"Hour\\\",\\n": true,
        "\\\"label\\\":": true,
        "\\\"Hourly": true,
        "rate\\\"\\n": true,
        "},\\n": true,
        "\\\"Salaried\\\",\\n": true,
        "\\\"Month\\\",\\n": true,
        "\\\"Monthly": true,
        "salary\\\"\\n": true,
        "\\\"Year\\\",\\n": true,
        "\\\"Annual": true,
        "\\\"SalariedPlusCommission\\\",\\n": true,
        "plus": true,
        "commission\\\"\\n": true,
        "}\\n": true,
        "]\\n}\",\"position\":{\"start\":{\"line\":58,\"column\":1,\"offset\":2408},\"end\":{\"line\":83,\"column\":4,\"offset\":2898},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query ($schemeId: String!) {
  payTypes(schemeId: $schemeId) {
    basisCode
    intervalCode
    label
  }
}
`}</code></pre>
    <p>{`SEEK may display the pay type alongside the `}<a parentName="p" {...{
        "href": "#salary-description"
      }}>{`salary description`}</a>{` for clarity.`}</p>
    <h2 {...{
      "id": "currency"
    }}>{`Currency`}</h2>
    <p>{`The SEEK API supports 16 currencies in total.
To assist hirers in selecting the right currency for their job ad, we recommend:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Displaying a shortlist of the 8 local currencies used across our Asia-Pacific markets in addition to the United States Dollar (USD)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pre-selecting the currency based on the `}<inlineCode parentName="p">{`currencies`}</inlineCode>{` value returned for the hirer’s selected location`}</p>
      </li>
    </ol>
    <h3 {...{
      "id": "1-display-a-shortlist-of-currencies"
    }}>{`1. Display a shortlist of currencies`}</h3>
    <p>{`The shortlist of currencies can be dynamically queried with the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/currencies"
      }}><inlineCode parentName="a">{`currencies`}</inlineCode>{` query`}</a>{`.
A query-based approach will allow your hirers to select new currencies if they are added in future.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($usageTypeCode: String!) {\\n  currencies(usageTypeCode: $usageTypeCode) {\\n    code\\n  }\\n}\",\"position\":{\"start\":{\"line\":105,\"column\":1,\"offset\":3894},\"end\":{\"line\":111,\"column\":4,\"offset\":4003},\"indent\":[1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"usageTypeCode\\\": \\\"SEEKMarket\\\"\\n}\",\"position\":{\"start\":{\"line\":113,\"column\":1,\"offset\":4005},\"end\":{\"line\":117,\"column\":4,\"offset\":4052},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"currencies\\\": [\\n    {\\n      \\\"code\\\": \\\"AUD\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"HKD\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"IDR\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"MYR\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"NZD\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"PHP\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"SGD\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"THB\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"USD\\\"\\n    }\\n  ]\\n}\",\"position\":{\"start\":{\"line\":119,\"column\":1,\"offset\":4054},\"end\":{\"line\":151,\"column\":4,\"offset\":4387},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($usageTypeCode:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "currencies(usageTypeCode:": true,
        "$usageTypeCode)": true,
        "code\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":105,\"column\":1,\"offset\":3894},\"end\":{\"line\":111,\"column\":4,\"offset\":4003},\"indent\":[1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"usageTypeCode\\\":": true,
        "\\\"SEEKMarket\\\"\\n}\",\"position\":{\"start\":{\"line\":113,\"column\":1,\"offset\":4005},\"end\":{\"line\":117,\"column\":4,\"offset\":4052},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"currencies\\\":": true,
        "[\\n": true,
        "\\\"code\\\":": true,
        "\\\"AUD\\\"\\n": true,
        "},\\n": true,
        "\\\"HKD\\\"\\n": true,
        "\\\"IDR\\\"\\n": true,
        "\\\"MYR\\\"\\n": true,
        "\\\"NZD\\\"\\n": true,
        "\\\"PHP\\\"\\n": true,
        "\\\"SGD\\\"\\n": true,
        "\\\"THB\\\"\\n": true,
        "\\\"USD\\\"\\n": true,
        "}\\n": true,
        "]\\n}\",\"position\":{\"start\":{\"line\":119,\"column\":1,\"offset\":4054},\"end\":{\"line\":151,\"column\":4,\"offset\":4387},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query ($usageTypeCode: String!) {
  currencies(usageTypeCode: $usageTypeCode) {
    code
  }
}
`}</code></pre>
    <p>{`Any supported currency can be used in any location.
SEEK will take care of currency conversions when candidates refine their job searches by `}<a parentName="p" {...{
        "href": "#salary-range"
      }}>{`salary range`}</a>{`.
SEEK will not perform conversions on the free-text `}<a parentName="p" {...{
        "href": "#salary-description"
      }}>{`salary description`}</a>{`;
we recommend hirers to provide a description in the dominant currency of the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/locations"
      }}>{`position’s location`}</a>{` with the currency clearly denoted.`}</p>
    <h3 {...{
      "id": "2-pre-selecting-currency-based-on-location"
    }}>{`2. Pre-selecting currency based on location`}</h3>
    <p>{`Our `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/locations#implementation-options"
      }}>{`location implementation options`}</a>{` provide the recommended `}<inlineCode parentName="p">{`currencies`}</inlineCode>{` for a given location.
The `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/ISO_4217"
      }}>{`ISO 4217`}</a>{` currency codes are listed in order of preference.
In most cases there will be a single currency returned,
and we recommend pre-selecting the first option for an optimal hirer experience:`}</p>

    <SalaryCurrency mdxType="SalaryCurrency" />
    <p>{`For currencies supported by the SEEK API,
your software must supply the same currency that the hirer sees when they are filling out the pay details of their position.
If your posting form includes currencies that are not supported by the SEEK API,
you may programmatically convert the salary range to `}<inlineCode parentName="p">{`USD`}</inlineCode>{`.`}</p>
    <p>{`SEEK may display the currency alongside the `}<a parentName="p" {...{
        "href": "#salary-description"
      }}>{`salary description`}</a>{` for clarity.`}</p>
    <h2 {...{
      "id": "salary-range"
    }}>{`Salary range`}</h2>
    <p>{`When posting a job ad, the hirer must specify a salary range with a minimum and maximum amount.
The amount is a floating point value in the major currency unit (e.g. dollars), calculated over the provided `}<a parentName="p" {...{
        "href": "#pay-type"
      }}>{`interval`}</a>{`.
You may specify a precision up to the minor unit of the currency.
For example, the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Australian_dollar"
      }}>{`AUD`}</a>{` currency accepts `}<inlineCode parentName="p">{`12.34`}</inlineCode>{` to represent the natural number `}<inlineCode parentName="p">{`1234`}</inlineCode>{` in cents.
On the other hand, it does not accept `}<inlineCode parentName="p">{`12.345`}</inlineCode>{` as such a value attempts to subdivide the minor unit.`}</p>
    <p>{`The salary range is used to provide more relevant job search results to candidates,
but the monetary values in `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/RemunerationRangeInput/field/minimumAmount"
      }}><inlineCode parentName="a">{`minimumAmount`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/RemunerationRangeInput/field/maximumAmount"
      }}><inlineCode parentName="a">{`maximumAmount`}</inlineCode></a>{` are not visible in search results nor on the job ad.
Hirers can provide a `}<a parentName="p" {...{
        "href": "#salary-description"
      }}>{`salary description`}</a>{` to communicate the remuneration package to the candidate.
SEEK may display the supplied `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/RemunerationAmountInput/field/currency"
      }}><inlineCode parentName="a">{`currency`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/RemunerationRangeInput/field/intervalCode"
      }}><inlineCode parentName="a">{`intervalCode`}</inlineCode></a>{` alongside this description for clarity.`}</p>
    <p>{`Incorporate the following recommendations into your user experience to ensure that your hirers get optimal performance from their SEEK job ads:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Display the salary currency alongside the range.`}</p>
        <p parentName="li">{`This dropdown should be implemented as documented in the section above.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Mark the maximum amount as a mandatory input.`}</p>
        <p parentName="li">{`We have observed that some hirers currently omit the maximum amount,
which defaults it to the minimum and affects the performance of their job ads.
The input field will be required in our schema in future.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Display the pay type alongside the range.`}</p>
        <p parentName="li">{`We have observed that some hirers currently enter hourly salary ranges against an annual pay type and vice versa,
rendering their job ads undiscoverable in recommendations and search results.`}</p>
      </li>
    </ol>

    <SalaryRange mdxType="SalaryRange" />
    <h2 {...{
      "id": "salary-description"
    }}>{`Salary description`}</h2>
    <p><a parentName="p" {...{
        "href": "#salary-range"
      }}>{`Salary ranges`}</a>{` are used to refine candidate job searches but the monetary values aren’t directly visible to candidates.
The hirer must provide a free text description if they want to communicate the position’s remuneration.
SEEK may display the supplied `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/RemunerationAmountInput/field/currency"
      }}><inlineCode parentName="a">{`currency`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/RemunerationRangeInput/field/intervalCode"
      }}><inlineCode parentName="a">{`intervalCode`}</inlineCode></a>{` alongside this description for clarity.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/RemunerationPackageInput/field/descriptions"
      }}><inlineCode parentName="a">{`RemunerationPackageInput.descriptions`}</inlineCode>{` field`}</a>{` displays a friendly remuneration package description to candidates.
Hirers can use this field to give a short summary of remuneration, including further benefits such as bonus options and superannuation.
Note that this field has a maximum length of 50 `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#string-lengths"
      }}>{`characters`}</a>{`, which roughly corresponds to a single English sentence.`}</p>
    <p>{`For example, if a hirer set the salary description field to `}<inlineCode parentName="p">{`$100k–$120k + super + bonus`}</inlineCode>{` it will appear on SEEK like so:`}</p>

    <JobAdSalary mdxType="JobAdSalary" />
    <h2 {...{
      "id": "validation"
    }}>{`Validation`}</h2>
    <p>{`There are two categories of salary validation rules:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The basic rules documented on this page and the corresponding schema documentation.
For example, the maximum amount must always be greater than or equal to the minimum amount.`}</p>
        <p parentName="li">{`You should enforce these rules in your software’s frontend to give the hirer feedback before they continue with the job posting flow.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Internal rules that the SEEK API only enforces when the hirer attempts to post the job ad.`}</p>
        <p parentName="li">{`SEEK will revise its internal salary rules in the future.
To support this, your software should display all validation error messages it receives from the SEEK API to the hirer.`}</p>
      </li>
    </ol>
    <p>{`When a job ad’s salary fails validation the posting mutation will fail with a `}<a parentName="p" {...{
        "href": "/graphql/error-responses#bad_user_input"
      }}><inlineCode parentName="a">{`BAD_USER_INPUT`}</inlineCode>{` error`}</a>{`.
The error will contain both a top-level summary `}<inlineCode parentName="p">{`message`}</inlineCode>{` and per-field `}<inlineCode parentName="p">{`invalidFields`}</inlineCode>{` messages.`}</p>
    <p>{`While the SEEK API will attempt to provide an actionable summary, this is not always possible when multiple fields have errors.
You’re encouraged to map the `}<inlineCode parentName="p">{`invalidFields`}</inlineCode>{` messages back to the relevant fields in your job posting form.
Otherwise, the hirer might not have enough information to correct the invalid fields.`}</p>
    <h3 {...{
      "id": "error-example"
    }}>{`Error example`}</h3>
    <p>{`When a hirer provides an overly-long description and a salary range where the minimum is greater than the maximum, the SEEK API will respond with a set of invalid fields like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "errors": [
    {
      "message": "Invalid remuneration",
      "extensions": {
        "code": "BAD_USER_INPUT",
        "invalidFields": {
          "/input/positionProfile/offeredRemunerationPackage/ranges/0/maximumAmount/value": "Must be greater than or equal to the minimum value",
          "/input/positionProfile/offeredRemunerationPackage/descriptions": "Cannot exceed 50 characters"
        }
      }
    }
  ],
  "data": null
}
`}</code></pre>
    <p>{`Note that the SEEK API was unable to generate a top-level `}<inlineCode parentName="p">{`message`}</inlineCode>{` that summarised both validation errors.
This demonstrates the importance of mapping the `}<inlineCode parentName="p">{`invalidFields`}</inlineCode>{` messages to the relevant fields on your job posting form.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;