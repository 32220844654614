
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text, Stack, TextLink } from 'braid-design-system';
import { PageIcon } from '../../../components/PageIcon';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "reflecting-job-ads"
    }}>{`Reflecting job ads`}</h1>

    <Alert tone="caution" mdxType="Alert">
  <Stack space="medium" mdxType="Stack">
    <Text mdxType="Text">
      Job ad reflection is an specialised scenario which is only required where
      your software needs to retrieve job ads posted to SEEK’s employment
      marketplace to reflect them on an internal job portal.
    </Text>
    <Text mdxType="Text">
      Please discuss your use case with SEEK’s support team via the{' '}
      <TextLink href="https://forms.office.com/pages/responsepage.aspx?id=Jg4UYrc30kKN-6TXzxvEJ0zmau3wnqRNvo4Y8qw-c8pUMkVISzZXR1hRQThDTVNYM09FVENNSkdHMS4u" mdxType="TextLink">
        Integration Service Desk
      </TextLink>{' '}
      before implementing this feature. This ensures that we can provide
      appropriate guidance and support for your integration.
    </Text>
  </Stack>
    </Alert>
    <h2 {...{
      "id": "authentication--authorisation"
    }}>{`Authentication & authorisation`}</h2>

    <p>{`Follow our regular `}<a parentName="p" {...{
        "href": "/introduction#integration-process"
      }}>{`integration process`}</a>{` to onboard and authenticate to the SEEK API.
You will need to supply a `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`partner token`}</a>{` when sending requests to our `}<a parentName="p" {...{
        "href": "/graphql/in-practice"
      }}>{`GraphQL endpoint`}</a>{`;
see our `}<a parentName="p" {...{
        "href": "/auth"
      }}><PageIcon icon="Auth" mdxType="PageIcon" />{` Auth`}</a>{` documentation for more details.`}</p>
    <p>{`SEEK will configure a `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}><inlineCode parentName="a">{`JobPosting`}</inlineCode>{` relationship`}</a>{` with each of your hirers to authorise this scenario.
You can view a list of your SEEK hirers and their relationships on the `}<a parentName="p" {...{
        "href": "https://manage.developer.seek.com/hirers"
      }}>{`Developer Dashboard’s hirers page`}</a>{`.`}</p>
    <h2 {...{
      "id": "job-posting-process"
    }}>{`Job Posting process`}</h2>
    <img alt="" data-scoobie-style="none" src={require('../../../../mermaid/.67ec82a47f7878c44a3364858f170fe1d39550d4.mmd.svg')} title="" />
    <h3 {...{
      "id": "1-post-job-ad"
    }}>{`1. Post job ad`}</h3>
    <p>{`Your hirers can post job ads directly on the `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au"
      }}>{`SEEK employer website`}</a>{` if your software has not implemented the rest of the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting"
      }}>{`Job Posting`}</a>{` use case.
When a job ad has been successfully posted,
it will be displayed on the SEEK candidate website,
and we will queue up a `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhook`}</a>{` to be sent to your software.`}</p>
    <p>{`You can test subsequent steps of this process by posting job ads with your `}<a parentName="p" {...{
        "href": "/introduction#integration-process"
      }}>{`test hirer`}</a>{`.`}</p>
    <h3 {...{
      "id": "2-send-positionprofileposted-event"
    }}>{`2. Send PositionProfilePosted event`}</h3>
    <p>{`Expose a public HTTPS endpoint in order to receive `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhooks`}</a>{` from the SEEK API.`}</p>
    <p>{`Then, `}<a parentName="p" {...{
        "href": "/events/webhooks#subscription-management"
      }}>{`configure a webhook subscription`}</a>{` through the `}<a parentName="p" {...{
        "href": "https://manage.developer.seek.com/webhooks"
      }}>{`Developer Dashboard’s webhooks page`}</a>{`.
A `}<a parentName="p" {...{
        "href": "/events/webhooks#security"
      }}>{`signed`}</a>{`, partner-scoped subscription is recommended in production for enhanced security and ease of maintenance.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Field`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Value`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/named-type/CreateWebhookSubscription_SubscriptionInput/field/eventTypeCode"
              }}><inlineCode parentName="a">{`eventTypeCode`}</inlineCode></a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`PositionProfilePosted`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/named-type/CreateWebhookSubscription_SubscriptionInput/field/schemeId"
              }}><inlineCode parentName="a">{`schemeId`}</inlineCode></a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`seekAnz`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/named-type/CreateWebhookSubscription_SubscriptionInput/field/signingAlgorithmCode"
              }}><inlineCode parentName="a">{`signingAlgorithmCode`}</inlineCode></a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`SeekHmacSha512`}</inlineCode></p></td>
        </tr>
      </tbody>
    </table>
    <p>{`Once the subscription is in place,
we will POST to your HTTPS endpoint when a job ad is successfully posted to SEEK.`}</p>
    <p>{`This is an example of a `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhook`}</a>{` body containing a `}<inlineCode parentName="p">{`PositionProfilePosted`}</inlineCode>{` event:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsonc",
        "metastring": "sample-webhook-body",
        "sample-webhook-body": true
      }}>{`{
  "events": [
    {
      "id": "seekAnzPublicTest:event:events:KNYEK91zoe8JoZU9iv6853",
      "type": "PositionProfilePosted",
      "createDateTime": "2019-08-20T21:02:24.611Z",
      // This can be passed to the \`positionProfile\` query
      "positionProfileId": "seekAnzPublicTest:positionProfile:jobAd:2782PZfXV",
      // This is only available for signed webhook subscriptions
      "hirerId": "seekAnzPublicTest:organization:seek:93WyyF1h"
    }
  ],
  "subscriptionId": "seekAnzPublicTest:webhookSubscription:events:Kyak8JfPKhHCAXM2s9b9wX",
  "url": "https://example.com/webhook"
}
`}</code></pre>
    <h3 {...{
      "id": "3-retrieve-job-ad"
    }}>{`3. Retrieve job ad`}</h3>
    <p>{`You can retrieve job ad details from the SEEK API by supplying the `}<inlineCode parentName="p">{`positionProfileId`}</inlineCode>{` from the prior step to the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/positionProfile"
      }}><inlineCode parentName="a">{`positionProfile`}</inlineCode>{` query`}</a>{`;
see `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/querying-a-job-ad"
      }}>{`querying a job ad`}</a>{` for more details.`}</p>
    <p>{`Review the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PostedPositionProfile"
      }}><inlineCode parentName="a">{`PostedPositionProfile`}</inlineCode></a>{` object and use the job ads posted with your test hirer to decide which fields you wish to retrieve.
This is an example query which additionally selects `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/branding"
      }}>{`branding images`}</a>{` associated with the job ad:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($id: String!) {\\n  positionProfile(id: $id) {\\n    postingInstructions {\\n      branding {\\n        images {\\n          typeCode\\n          url\\n        }\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":121,\"column\":1,\"offset\":5021},\"end\":{\"line\":134,\"column\":4,\"offset\":5210},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"id\\\": \\\"seekAnzPublicTest:positionProfile:jobAd:2782PZfXV\\\"\\n}\",\"position\":{\"start\":{\"line\":136,\"column\":1,\"offset\":5212},\"end\":{\"line\":140,\"column\":4,\"offset\":5287},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"positionProfile\\\": {\\n    \\\"postingInstructions\\\": [\\n      {\\n        \\\"branding\\\": {\\n          \\\"images\\\": [\\n            {\\n              \\\"typeCode\\\": \\\"OriginalLogo\\\",\\n              \\\"url\\\": \\\"https://image-service-cdn.seek.com.au/27ecca452d06d960b596aa40380f0d06c56b6ba8\\\"\\n            }\\n          ]\\n        }\\n      }\\n    ]\\n  }\\n}\",\"position\":{\"start\":{\"line\":142,\"column\":1,\"offset\":5289},\"end\":{\"line\":159,\"column\":4,\"offset\":5620},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($id:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "positionProfile(id:": true,
        "$id)": true,
        "postingInstructions": true,
        "branding": true,
        "images": true,
        "typeCode\\n": true,
        "url\\n": true,
        "}\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":121,\"column\":1,\"offset\":5021},\"end\":{\"line\":134,\"column\":4,\"offset\":5210},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"id\\\":": true,
        "\\\"seekAnzPublicTest:positionProfile:jobAd:2782PZfXV\\\"\\n}\",\"position\":{\"start\":{\"line\":136,\"column\":1,\"offset\":5212},\"end\":{\"line\":140,\"column\":4,\"offset\":5287},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"positionProfile\\\":": true,
        "\\\"postingInstructions\\\":": true,
        "[\\n": true,
        "\\\"branding\\\":": true,
        "\\\"images\\\":": true,
        "\\\"typeCode\\\":": true,
        "\\\"OriginalLogo\\\",\\n": true,
        "\\\"url\\\":": true,
        "\\\"https://image-service-cdn.seek.com.au/27ecca452d06d960b596aa40380f0d06c56b6ba8\\\"\\n": true,
        "]\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":142,\"column\":1,\"offset\":5289},\"end\":{\"line\":159,\"column\":4,\"offset\":5620},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query ($id: String!) {
  positionProfile(id: $id) {
    postingInstructions {
      branding {
        images {
          typeCode
          url
        }
      }
    }
  }
}
`}</code></pre>
    <p>{`Only include fields your software actually needs in the GraphQL query.
This allows us to evolve our GraphQL schema without requiring adjustments to your software for field changes and deprecations that do not materially affect your end user experience.`}</p>
    <h3 {...{
      "id": "4-add-job-ad-to-search-index"
    }}>{`4. Add job ad to search index`}</h3>
    <p>{`The SEEK API does not offer programmatic job search capabilities.
Your software will need to ingest job ads into a search index to serve your internal job portal.`}</p>
    <h2 {...{
      "id": "job-closure-process"
    }}>{`Job closure process`}</h2>
    <p>{`Our `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads#job-ad-lifecycle"
      }}>{`job ad lifecycle`}</a>{` specifies a maximum period of 30 days for a job ad.
Once a job ad reaches its scheduled end date,
SEEK will automatically close it and queue up a `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhook`}</a>{` to be sent to your software.`}</p>
    <img alt="" data-scoobie-style="none" src={require('../../../../mermaid/.6277a8be8aafd0e8fe05f9c9a8921f405db693e7.mmd.svg')} title="" />
    <p>{`While you can retrieve the scheduled end date as part of the job ad,
we recommend subscribing to the `}<inlineCode parentName="p">{`PositionProfileClosed`}</inlineCode>{` event.
You can `}<a parentName="p" {...{
        "href": "/events/webhooks#subscription-management"
      }}>{`configure a webhook subscription`}</a>{` through the `}<a parentName="p" {...{
        "href": "https://manage.developer.seek.com/webhooks"
      }}>{`Developer Dashboard’s webhooks page`}</a>{`.
This will cover the scenario of a hirer or SEEK support member manually closing the job ad ahead of schedule.`}</p>
    <img alt="" data-scoobie-style="none" src={require('../../../../mermaid/.7fcc8af38adf99a5006082789e36ec37a0505a0d.mmd.svg')} title="" />
    <p>{`An example of a `}<a parentName="p" {...{
        "href": "/events/webhooks"
      }}>{`webhook`}</a>{` body containing a `}<inlineCode parentName="p">{`PositionProfileClosed`}</inlineCode>{` event can be found in `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/closing-a-job-ad#positionprofileclosed"
      }}>{`closing a job ad`}</a>{`.
Use this event to remove the job ad from the search index that serves your internal job portal.`}</p>
    <h2 {...{
      "id": "application-process"
    }}>{`Application process`}</h2>
    <p><a parentName="p" {...{
        "href": "/use-cases/job-posting/application-methods#seeks-apply-form"
      }}>{`SEEK’s Apply Form`}</a>{` allows the application process to be hosted on SEEK.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The reflected listing on your internal job board should link back to the SEEK job details page where the candidate can apply for the job.`}</p>
        <p parentName="li">{`You can retrieve this URL from the `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/PostedPositionProfile/field/positionUri"
          }}><inlineCode parentName="a">{`PostedPositionProfile.positionUri`}</inlineCode>{` field`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the candidate can view the full details of the job ad on your internal job board listing,
you can skip SEEK’s job details page and link directly to SEEK’s Apply Form.`}</p>
        <p parentName="li">{`You can retrieve this URL from the `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/PostedPositionProfile/field/seekApplicationUri"
          }}><inlineCode parentName="a">{`PostedPositionProfile.seekApplicationUri`}</inlineCode>{` field`}</a>{`.`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($id: String!) {\\n  positionProfile(id: $id) {\\n    positionUri\\n\\n    ... on PostedPositionProfile {\\n      seekApplicationUri {\\n        url\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":228,\"column\":1,\"offset\":8479},\"end\":{\"line\":240,\"column\":4,\"offset\":8656},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"id\\\": \\\"seekAnzPublicTest:positionProfile:jobAd:2782PZfXV\\\"\\n}\",\"position\":{\"start\":{\"line\":242,\"column\":1,\"offset\":8658},\"end\":{\"line\":246,\"column\":4,\"offset\":8733},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"positionProfile\\\": {\\n    \\\"positionUri\\\": \\\"https://example.seek.com.au/job/30000000\\\",\\n    \\\"seekApplicationUri\\\": {\\n      \\\"url\\\": \\\"https://example.seek.com.au/job/30000000/apply\\\"\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":248,\"column\":1,\"offset\":8735},\"end\":{\"line\":257,\"column\":4,\"offset\":8936},\"indent\":[1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($id:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "positionProfile(id:": true,
        "$id)": true,
        "positionUri\\n\\n": true,
        "...": true,
        "on": true,
        "PostedPositionProfile": true,
        "seekApplicationUri": true,
        "url\\n": true,
        "}\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":228,\"column\":1,\"offset\":8479},\"end\":{\"line\":240,\"column\":4,\"offset\":8656},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"id\\\":": true,
        "\\\"seekAnzPublicTest:positionProfile:jobAd:2782PZfXV\\\"\\n}\",\"position\":{\"start\":{\"line\":242,\"column\":1,\"offset\":8658},\"end\":{\"line\":246,\"column\":4,\"offset\":8733},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"positionProfile\\\":": true,
        "\\\"positionUri\\\":": true,
        "\\\"https://example.seek.com.au/job/30000000\\\",\\n": true,
        "\\\"seekApplicationUri\\\":": true,
        "\\\"url\\\":": true,
        "\\\"https://example.seek.com.au/job/30000000/apply\\\"\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":248,\"column\":1,\"offset\":8735},\"end\":{\"line\":257,\"column\":4,\"offset\":8936},\"indent\":[1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query ($id: String!) {
  positionProfile(id: $id) {
    positionUri

    ... on PostedPositionProfile {
      seekApplicationUri {
        url
      }
    }
  }
}
`}</code></pre>
    <p>{`Your hirers will be able to manage candidates for a job ad on the `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au"
      }}>{`SEEK employer website`}</a>{`.
Implement the `}<a parentName="p" {...{
        "href": "/use-cases/application-export"
      }}>{`Optimised Apply`}</a>{` use case if you need to ingest applications into your applicant tracking system.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;