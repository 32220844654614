
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "ad-performance"
    }}>{`Ad Performance`}</h1>
    <p>{`This use case covers measurement and reporting of conversion rates for job ads posted through the SEEK API.`}</p>
    <p>{`The Ad Performance features of the SEEK API consist of two components:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/ad-performance/completion-signal"
          }}>{`Completion Signal`}</a>{`, which records application complete events for candidates applying within your recruitment software.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/ad-performance/panel"
          }}>{`Ad Performance Panel`}</a>{`, an embeddable widget allowing detailed performance data to be shown to hirers.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;