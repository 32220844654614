import { type NavigateOptions, useSearchParams } from 'react-router-dom';

export const useQueryParam = (name: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParam = searchParams.get(name);

  const setQueryParam = (
    value: string | null | undefined,
    navigateOptions: NavigateOptions = {
      preventScrollReset: true,
      replace: true,
    },
  ) => {
    const currentValue = searchParams.get(name);

    if (!currentValue && !value) {
      // Treat empty string, null and undefined as equivalent.
      return;
    }

    if (currentValue === value) {
      // Avoid an infinite History API loop.
      return;
    }

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    setSearchParams(searchParams, navigateOptions);
  };

  return [queryParam, setQueryParam] as const;
};
