import type { Location } from 'history';
import type lunr from 'lunr';

import type {
  Field,
  HeadingOffset,
  MatchMetadata,
  Occurrence,
  SearchSource,
} from './searchIndex';
import type { RawSearchResult } from './useSearchResults';

export interface SearchResultItem {
  title: string;
  description?: string;
  path: string;
  titleHighlights?: Array<[number, number]>;
  descriptionHighlights?: Array<[number, number]>;
  source: SearchSource;
}
const findOccurrencesInMetadata = (
  matchData: lunr.MatchData,
  field: Field,
): Occurrence[] => {
  const metadata = matchData.metadata as Record<string, MatchMetadata>;
  const occurrences = [];

  for (const matchMetadata of Object.values(metadata)) {
    const fieldMetadata = matchMetadata[field];

    if (fieldMetadata?.position) {
      occurrences.push(...fieldMetadata.position);
    }
  }

  return occurrences;
};
/**
 * Find an anchor to deep link a search result to
 *
 * This will only succeed if all the matches are within the same section.
 */
const anchorForResult = (
  result: RawSearchResult,
  headingOffsets: HeadingOffset[] | undefined,
): string | undefined => {
  if (!headingOffsets) {
    return;
  }

  const occurrences = findOccurrencesInMetadata(result.matchData, 'body');
  if (!occurrences.length) {
    return;
  }

  const slugs = new Set<string>();
  for (const [start] of occurrences) {
    // This assumes the heading offsets are in document order
    let slug: string | undefined;
    for (const headingOffset of headingOffsets) {
      if (start < headingOffset.offset) {
        // The heading is after us; use the current `slug`
        break;
      }

      // We're within this section until proven otherwise
      slug = headingOffset.slug;
    }

    if (!slug) {
      // At the start of the document or in an unaddressable section
      return;
    }

    slugs.add(slug);
    if (slugs.size > 1) {
      // Occurs in multiple sections
      return;
    }
  }

  return Array.from(slugs)[0];
};
const descriptionForResult = (
  result: RawSearchResult,
  body: string | undefined,
): string | undefined => {
  if (!body) {
    return;
  }

  const occurrences = findOccurrencesInMetadata(result.matchData, 'body');
  if (!occurrences.length) {
    return;
  }

  const [[start]] = occurrences;

  // `lastIndexOf` will return -1 on the first line. Round it up to `0`.
  const lineStart = Math.max(body.slice(0, start).lastIndexOf('\n'), 0);
  const lineEnd = body.slice(start).indexOf('\n') + start;

  return body.slice(lineStart, lineEnd);
};
/**
 * Creates autosuggest highlights based on the `displayTitle` and searched text
 */
const highlightsForTermText = (
  displayTitle: string,
  termText: string,
): Array<[number, number]> => {
  const searchTermIndex = displayTitle
    .toLocaleLowerCase()
    .indexOf(termText.toLocaleLowerCase());

  if (searchTermIndex === -1) {
    return [];
  }

  return [[searchTermIndex, searchTermIndex + termText.length]];
};
export const mapSearchResult = ({
  result,
  locationSearch,
  termText,
}: {
  result: RawSearchResult;
  locationSearch: Location['search'];
  termText: string;
}): SearchResultItem => {
  const { body, displayTitle, headingOffsets, source } = result.page;

  // Calculate the parts of the final path
  const basePath = result.ref;
  const anchor = anchorForResult(result, headingOffsets);

  const path = [
    basePath,
    ...(source === 'docs' ? [locationSearch] : []),
    ...(anchor ? [`#${anchor}`] : []),
  ].join('');

  const description = descriptionForResult(result, body);

  return {
    path,
    title: displayTitle,
    description,
    titleHighlights: highlightsForTermText(displayTitle, termText),
    descriptionHighlights: description
      ? highlightsForTermText(description, termText)
      : [],
    source,
  };
};
