
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { JobPostingFeatureList } from './job-posting-feature-list';
import { Alert, List, Stack, Text } from 'braid-design-system';
import { SmartTextLink } from 'scoobie';
import { JumpTo } from './JumpTo';
import { JobPostingAcceptanceCriteria } from './job-posting-acceptance-criteria';
import { TestCasesFile } from './assets/TestCasesFile';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "phase-2-job-posting"
    }}>{`Phase 2: Job Posting`}</h1>
    <p>{`With the consolidation of the SEEK, Jobstreet and Jobsdb platforms, we have changed the way job ads are surfaced across our markets.
Your job posting integration must be revised to ensure that it correctly interoperates with the SEEK API going forward.`}</p>
    <p>{`Job ads posted via the SEEK API are syndicated across our candidate websites,
including `}<a parentName="p" {...{
        "href": "https://www.seek.com.au/"
      }}>{`seek.com.au`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.seek.co.nz/"
      }}>{`seek.co.nz`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.jobstreet.com/"
      }}>{`jobstreet.com`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.jobsdb.com/"
      }}>{`jobsdb.com`}</a>{`.
Each website defaults to a market-specific location filter but candidates are able to manually search for international job ads.`}</p>
    <p>{`When your software retrieves the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PositionProfile/field/positionUri"
      }}><inlineCode parentName="a">{`positionUri`}</inlineCode>{` field`}</a>{` in response to a `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad#positionprofileposted"
      }}><inlineCode parentName="a">{`PositionProfilePosted`}</inlineCode>{` event`}</a>{`,
it will receive a localised link to the live job ad.
This is based on the hirer’s country as configured in SEEK;
a hirer based in Singapore is assigned a `}<a parentName="p" {...{
        "href": "https://sg.jobstreet.com/"
      }}>{`sg.jobstreet.com`}</a>{` link,
while a hirer based in Australia continues to be assigned a `}<a parentName="p" {...{
        "href": "https://www.seek.com.au/"
      }}>{`seek.com.au`}</a>{` link.`}</p>
    <h2 {...{
      "id": "step-1---understanding-your-scope"
    }}>{`Step 1 - Understanding your scope`}</h2>
    <p>{`We have extended the following job posting features to satisfy regional requirements across the Asia Pacific.
The revision is required even if you do not offer your recruitment software in these additional markets,
as it includes changes to marketplace features that affect Australia & New Zealand hirers.`}</p>

    <JobPostingFeatureList mdxType="JobPostingFeatureList" />
    <h2 {...{
      "id": "step-2---start-building"
    }}>{`Step 2 - Start building`}</h2>

    <Alert tone="info" mdxType="Alert">
  <Stack space="medium" mdxType="Stack">
    <Text mdxType="Text">Before you commence your integration, please ensure that:</Text>
    <List mdxType="List">
      <Text mdxType="Text">
        Your user experience is in line with our{' '}
        <SmartTextLink href="/use-cases/job-posting/user-experience-guide" mdxType="SmartTextLink">
          job posting user experience guide
        </SmartTextLink>
        , and if implementing ad selection via GraphQL, adheres to our{' '}
        <SmartTextLink href="/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/graphql/user-experience-guide" mdxType="SmartTextLink">
          ad selection user experience guide
        </SmartTextLink>
        .
      </Text>
      <Text mdxType="Text">
        You have access to the SEEK test hirer provided for development and
        testing purposes. Test hirers can post job ads free of charge, but their
        job ads don’t appear in SEEK’s search results.
      </Text>
    </List>
    <Text mdxType="Text">
      If you require any technical support during your integration please
      contact our{' '}
      <SmartTextLink href="https://forms.office.com/pages/responsepage.aspx?id=Jg4UYrc30kKN-6TXzxvEJ0zmau3wnqRNvo4Y8qw-c8pUMkVISzZXR1hRQThDTVNYM09FVENNSkdHMS4u" mdxType="SmartTextLink">
        Integration Service Desk
      </SmartTextLink>
      , available Monday - Friday 9:00AM to 5:00PM AEST.
    </Text>
  </Stack>
    </Alert>
    <p>{`The checklist below outlines best practices for integrating each job posting feature:`}</p>

    <h3 {...{
      "id": "locations"
    }}>{`Locations`}</h3>
    <p><em parentName="p">{`If you have already built a supported `}<a parentName="em" {...{
          "href": "/use-cases/job-posting/locations#implementation-options"
        }}>{`implementation option`}</a>{` in your software you may skip this step.`}</em></p>
    <p>{`If your software maintains a static mapping from its internal location hierarchy to SEEK location IDs:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Review available options within our `}<a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/locations"
          }}>{`Location documentation`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Implement API option most appropriate to your software`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Remove static mapping`}</p>
      </li>
    </ul>
    <JumpTo href="/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/locations" label="Locations" mdxType="JumpTo" />
    <h3 {...{
      "id": "job-categories"
    }}>{`Job categories`}</h3>
    <p><em parentName="p">{`This change ensures that hirers are only presented with relevant categories and that those categories are matched to the region the job is being posted in.`}</em></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Call the jobCategories query dynamically for each job ad, passing the current state of the ad in the `}<inlineCode parentName="p">{`positionProfile`}</inlineCode>{` field`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensure that all `}<inlineCode parentName="p">{`categories / sub-categories`}</inlineCode>{` returned are visible and sub-categories are selectable by the hirer`}</p>
      </li>
    </ul>
    <JumpTo href="/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/job-categories" label="Job categories" mdxType="JumpTo" />
    <h3 {...{
      "id": "salary"
    }}>{`Salary`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Remove deprecated `}<inlineCode parentName="p">{`CommissionOnly`}</inlineCode>{` pay type`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Remove any hard coded validation values (such as limiting max salary to a fixed value)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Implement error display for salary values as defined in our `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/salary#validation"
          }}>{`Salary validation documentation`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Implement `}<a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/salary#salary-range"
          }}>{`recommended UX updates for Salary Range`}</a></p>
      </li>
    </ul>
    <JumpTo href="/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/salary" label="Salary" mdxType="JumpTo" />
    <h3 {...{
      "id": "ad-selection"
    }}>{`Ad selection`}</h3>
    <p><em parentName="p">{`SEEK strongly recommends using the new Ad Selection Panel for your integration. It will significantly reduce development time and ongoing maintenance, as well as provide an up-to-date user experience for SEEK hirers.`}</em></p>
    <p>{`Ad Selection now requires the use of `}<inlineCode parentName="p">{`seekAdvertisementProductId`}</inlineCode>{`. Using the deprecated `}<inlineCode parentName="p">{`seekAnzAdvertisementType`}</inlineCode>{` will result in failed Job Posting create and update calls following the December 2024 retirement date.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Review available options within our Ad Selection documentation`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If migrating from the previous Ad Selection Panel:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Update script tag and render function with revised props`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Call render function on page load and when changes to `}<inlineCode parentName="p">{`positionProfile`}</inlineCode>{` input are made`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Replace any hard coded logic for brand selection / bullet point inputs with conditional logic based on features returned in `}<inlineCode parentName="p">{`onChange`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Replace references to `}<inlineCode parentName="p">{`seekAnzAdvertisementType`}</inlineCode>{` with `}<inlineCode parentName="p">{`seekAdvertisementProductId`}</inlineCode>{` field when previewing, posting or updating the job ad`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If using GraphQL integration:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Review the `}<a parentName="p" {...{
                "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/graphql/user-experience-guide"
              }}>{`user experience guide`}</a>{` and ensure that your implementation will meet our new requirements`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Rebuild calls to use the new `}<inlineCode parentName="p">{`advertisementProducts`}</inlineCode>{` query`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Call query on page load and whenever the positionProfile input changes`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Adjust UI to handle a variable number of products (we recommend accommodating 4) or provide a `}<inlineCode parentName="p">{`limit`}</inlineCode>{` param`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Ensure your UI pre-selects the ad product marked with `}<inlineCode parentName="p">{`selected`}</inlineCode>{` if one exists (typically returned when updating a job ad or when using `}<inlineCode parentName="p">{`selectedAdvertisementProductId`}</inlineCode>{` for rehydrating a draft)`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Remove any hardcoded logic based on specific names or identifiers`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Replace references to `}<inlineCode parentName="p">{`seekAnzAdvertisementType`}</inlineCode>{` with `}<inlineCode parentName="p">{`seekAdvertisementProductId`}</inlineCode>{` field when previewing, posting or updating the job ad`}</p>
          </li>
        </ul>
      </li>
    </ul>
    <JumpTo href="/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection" label="Ad selection" mdxType="JumpTo" />
    <h3 {...{
      "id": "branding"
    }}>{`Branding`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Display selectable branding elements to hirer retrieved from Brandings query`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Pass selected branding ID as part of `}<inlineCode parentName="p">{`positionProfile`}</inlineCode>{` in preview, create and update calls`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensure correct branding is displayed as selected during ad edit`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Display branding selection based on ad product features`}</p>
      </li>
    </ul>
    <JumpTo href="/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/branding" label="Branding" mdxType="JumpTo" />
    <h3 {...{
      "id": "questionnaires"
    }}>{`Questionnaires`}</h3>
    <p><em parentName="p">{`Note that the SEEK API does not allow Questionnaire’s to be modified once a job ad is posted. See the `}<a parentName="em" {...{
          "href": "/use-cases/job-posting/questionnaires#immutability"
        }}>{`questionnaire immutability`}</a>{` documentation for more information.`}</em></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Review available options within our Questionnaire documentation`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you have previously implemented the SEEK Questionnaire Panel:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Update script tag and render function call`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Remove any references to `}<inlineCode parentName="p">{`errorHandler`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Update calls from `}<inlineCode parentName="p">{`SEEKQuestionnairePanel.save() to dispatchEvent('seek:questionnaire:save');`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Update handling of errorCode / isError`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you’re implementing the SEEK Questionnaire Panel for the first time:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Add script tag to the page`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Call render function on page load and when changes to `}<inlineCode parentName="p">{`positionProfile`}</inlineCode>{` input are made`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Handle browser token request`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Call `}<inlineCode parentName="p">{`dispatchEvent('seek:questionnaire:save')`}</inlineCode>{` prior to previewing, posting or saving a draft of the job ad`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you have an existing question library and are implementing our ATS Screening Questions functionality:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Implement the `}<inlineCode parentName="p">{`createApplicationQuestionnaire`}</inlineCode>{` mutation`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Post job ad with returned questionnaire ID`}</p>
          </li>
        </ul>
      </li>
    </ul>
    <JumpTo href="/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/questionnaires" label="Questionnaires" mdxType="JumpTo" />
    <h3 {...{
      "id": "job-ad-preview"
    }}>{`Job ad preview`}</h3>
    <p><em parentName="p">{`If you have already built job ad preview then skip this step. However, you should review two other changes to the `}<inlineCode parentName="em">{`postedPositionProfilePreview`}</inlineCode>{` query for Ad Selection and Questionnaire.`}</em></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Display a button for hirers to click on to preview the job ad`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Execute the `}<inlineCode parentName="p">{`postedPositionProfilePreview`}</inlineCode>{` query to generate a job ad preview when the button is clicked`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensure the full state of the ad is passed to the positionProfile argument`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Open new tab or render iframe in the flow to the returned `}<inlineCode parentName="p">{`previewUri`}</inlineCode>{` field`}</p>
      </li>
    </ul>
    <JumpTo href="/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/job-ad-preview" label="Job ad preview" mdxType="JumpTo" />
    <h3 {...{
      "id": "access-to-asia-features"
    }}>{`Access to Asia features`}</h3>
    <p>{`If you have hirers in Asia or hirers in ANZ wishing to post ads in Asia, you can enable job posting in our six Asia markets by supporting localised salaries and content.`}</p>
    <h4 {...{
      "id": "salary-1"
    }}>{`Salary`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Implement the `}<inlineCode parentName="p">{`Month`}</inlineCode>{`ly pay interval`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Display a shortlist of currencies for hirers to select`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><em parentName="p">{`We recommend retrieving the shortlist of currencies from our currencies query to support further updates.`}</em></p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Pre-select the recommended currency based on job location`}</p>
      </li>
    </ul>
    <h4 {...{
      "id": "content-localisation"
    }}>{`Content localisation`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Send an appropriate `}<inlineCode parentName="p">{`Accept-Language`}</inlineCode>{` header in GraphQL requests`}</p>
      </li>
    </ul>
    <JumpTo href="/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/content-localisation" label="Content localisation" mdxType="JumpTo" />
    <h2 {...{
      "id": "step-3---test-your-integration"
    }}>{`Step 3 - Test your integration`}</h2>
    <p>{`Before you apply for SEEK Certification, thoroughly test your integration for the successful execution of the following acceptance criteria.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`All other features and capabilities previously supported are expected to continue to work as-is and will not be negatively impacted by the Job Posting revision changes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Hardcoding or storing of data for ad product features, locations or associated logic is no longer supported.
Dynamic SEEK API calls MUST be used for retrieving all data to ensure all information is current and mitigate the risk of breaking changes rolled out by SEEK in the future.`}</p>
      </li>
    </ul>

    <JobPostingAcceptanceCriteria mdxType="JobPostingAcceptanceCriteria" />
    <h2 {...{
      "id": "step-4---certification"
    }}>{`Step 4 - Certification`}</h2>
    <p>{`Before proceeding, please make sure you have:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Built the mandatory features detailed in `}<a parentName="p" {...{
            "href": "#step-1---understanding-your-scope"
          }}>{`Step 1`}</a>{` and `}<a parentName="p" {...{
            "href": "#step-2---start-building"
          }}>{`Step 2`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Tested that the features work correctly based on the acceptance criteria in `}<a parentName="p" {...{
            "href": "#step-3---test-your-integration"
          }}>{`Step 3`}</a></p>
      </li>
    </ol>
    <p>{`If anything is missing from your current implementation, please do not proceed further until you have implemented all mandatory features.`}</p>
    <h3 {...{
      "id": "applying-for-certification"
    }}>{`Applying for certification`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Plan to go through a step-by-step process of drafting, posting, updating and expiring a job ad.
If applicable for your software, also include job scheduling and automatic re-posting processes.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Record 2 to 3 videos that demonstrate the full user experience of the Job Posting flow indicated in the downloadable Test Steps Excel Workbook.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Submit the video recordings and links to test jobs with your certification request to the `}<a parentName="p" {...{
            "href": "https://forms.office.com/pages/responsepage.aspx?id=Jg4UYrc30kKN-6TXzxvEJ0zmau3wnqRNvo4Y8qw-c8pUMkVISzZXR1hRQThDTVNYM09FVENNSkdHMS4u"
          }}>{`Integration Service Desk`}</a>{`.`}</p>
      </li>
    </ol>

    <TestCasesFile mdxType="TestCasesFile" />
    <p>{`Note the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`During your testing, please enter the `}<strong parentName="p">{`exact test data`}</strong>{` indicated and fully demonstrate the feature exactly as described in each step`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Certification will only be granted by SEEK if we receive complete coverage of the test cases in your video submissions`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SEEK will notify you if it identifies any issues with your integration`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`All issues must be resolved in order to obtain successful certification and prior to live deployment`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For further review after making corrections, resubmit a new video to the `}<a parentName="p" {...{
            "href": "https://forms.office.com/pages/responsepage.aspx?id=Jg4UYrc30kKN-6TXzxvEJ0zmau3wnqRNvo4Y8qw-c8pUMkVISzZXR1hRQThDTVNYM09FVENNSkdHMS4u"
          }}>{`Integration Service Desk`}</a></p>
      </li>
    </ul>
    <h4 {...{
      "id": "certification-request"
    }}>{`Certification request`}</h4>
    <p>{`When submitting your certification request, include:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Your anticipated integration release date`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The date you began recording the test case videos`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Links to the test case videos`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`URL for the test job ad posted to `}<inlineCode parentName="p">{`www.seek.com.au`}</inlineCode>{` (this will appear as expired after completion of the test cases)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`URL for the test job ad posted to `}<inlineCode parentName="p">{`www.jobstreet.com.my`}</inlineCode>{` (this will appear as expired after completion of the test cases)`}</p>
      </li>
    </ul>
    <h4 {...{
      "id": "video-requirements"
    }}>{`Video requirements`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Each video should not exceed 10 minutes`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The video file must be downloadable`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The video file format should be MP4 video format`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The file name must follow this format: `}<inlineCode parentName="p">{`Partner Name_JP_CertGroup.mp4`}</inlineCode></p>
      </li>
    </ul>
    <h2 {...{
      "id": "step-5---release"
    }}>{`Step 5 - Release`}</h2>
    <p>{`Once your build has been certified, you are ready to release subject to completing the below steps.`}</p>
    <p>{`Please allow a 1 week lead time to allow for these steps to be completed.`}</p>
    <h3 {...{
      "id": "1-confirm-your-release-plan"
    }}>{`1. Confirm your release plan`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Advise SEEK of your planned release date.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Advise SEEK of your customer release communications approach including when they are sent to hirers.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "2-submit-release-communications-for-approval"
    }}>{`2. Submit release communications for approval`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`SEEK’s Release Pack includes release messaging and branding assets, which will be made available to you once your build has started.
Using the Release Pack you can easily develop content that showcases the new value you have created for our mutual hirers.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Before you share your content with hirers, please provide it to SEEK by emailing `}<a parentName="p" {...{
            "href": "mailto:APIImplementation@seek.com.au"
          }}>{`APIImplementation@seek.com.au`}</a>{` so it can be approved.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SEEK will review your messaging within 2 business days.
Once approved, you are ready to publish it!`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "3-confirm-your-release"
    }}>{`3. Confirm your release`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`On the day of release, please confirm with us that the release has been successfully completed.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;