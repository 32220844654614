
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "countrycode-required-for-location-inference"
    }}><inlineCode parentName="h4">{`countryCode`}</inlineCode>{` required for location inference`}</h4>
    <p>{`We have updated our schema for `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/locations#inferlocation"
      }}><inlineCode parentName="a">{`inferLocation`}</inlineCode>{` query`}</a>{`, now making the `}<inlineCode parentName="p">{`countryCode`}</inlineCode>{` value mandatory for all requests.`}</p>
    <p>{`This field accepts a two-letter `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2"
      }}>{`ISO 3166-1:2013 country code`}</a>{` of the location, in uppercase.`}</p>
    <p>{`Previously, `}<inlineCode parentName="p">{`countryCode`}</inlineCode>{` was optional, but subsequent testing has demonstrated that `}<inlineCode parentName="p">{`countryCode`}</inlineCode>{` significantly improves the quality of inference and avoids cases of incorrect locations being returned that impact hirer experience.`}</p>
    <p>{`No impact to existing implementations is expected.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;