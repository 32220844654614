import { Box, Text } from 'braid-design-system';
import type { ReactNode } from 'react';

import * as styles from './MiniHeading.css';

export const MiniHeading = ({ children }: { children: ReactNode }) => (
  <Text size="xsmall" weight="strong" tone="secondary">
    <Box className={styles.upper} component="span">
      {children}
    </Box>
  </Text>
);
