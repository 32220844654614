
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "mutation-conflicts"
    }}>{`Mutation conflicts`}</h4>
    <p>{`We’ve introduced a new result format for mutations that create objects.
This enables explicit handling of `}<a parentName="p" {...{
        "href": "/graphql/seek-api-conventions#mutation-conflicts"
      }}>{`mutation conflicts`}</a>{`.`}</p>
    <p>{`💥 The new format can be seen in the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/createWebhookSubscription"
      }}><inlineCode parentName="a">{`createWebhookSubscription`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/uploadCandidate"
      }}><inlineCode parentName="a">{`uploadCandidate`}</inlineCode></a>{` mutations.
If your software creates webhook subscriptions,
update the selection set to account for this change:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff",
        "metastring": "label=\"GraphQL\"",
        "label": "\"GraphQL\""
      }}>{`mutation($input: CreateWebhookSubscriptionInput!) {
  createWebhookSubscription(input: $input) {
-     webhookSubscription {
-       id {
-         value
-       }
-     }
+     __typename
+     ... on CreateWebhookSubscriptionPayload_Success {
+       webhookSubscription {
+         id {
+           value
+         }
+       }
+     }
+     ... on CreateWebhookSubscriptionPayload_Conflict {
+       conflictingWebhookSubscription {
+         id {
+           value
+         }
+       }
    }
  }
}
`}</code></pre>
    <p>{`Then, update your code to check for conflicts in one of two new ways:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff",
        "metastring": "label=\"JavaScript\"",
        "label": "\"JavaScript\""
      }}>{`if (
-   // Old: make assumptions based on semi-structured errors array
-   response.errors?.some(
-     (err) =>
-       err.extensions.code === 'BAD_USER_INPUT' &&
-       err.message === 'Subscription already exists',
-   )
+   // Option 1: test for existence of conflict field
+   response.data?.conflictingWebhookSubscription
+   // Option 2: test name of the response type
+   response.data?.__typename === 'CreateWebhookSubscriptionPayload_Conflict'
) {
  // Handle conflict here
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;