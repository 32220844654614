import type { Private } from '@seek/indie-api-types';
import {
  Box,
  IconFlag,
  IconVisibility,
  Strong,
  Text,
  TooltipRenderer,
} from 'braid-design-system';
import type React from 'react';

import { usePermissions } from 'src/hooks/auth';
import type { UserType } from 'src/types/users';

interface UserLabelProps {
  type: UserType;
  user: Private.Auth0UserPayload;
  labelProps?: React.ComponentProps<typeof Text>;
}

export const UserLabel = ({ type, user, labelProps = {} }: UserLabelProps) => {
  const { integrationPartnerUuid } = usePermissions();

  switch (type) {
    case 'impersonating':
      return (
        <TooltipRenderer
          id={`user-label-tooltip-${user.id}`}
          tooltip={
            <Text size="small">
              Impersonating{' '}
              {integrationPartnerUuid === user.partner.uuid ? (
                'this partner'
              ) : (
                <Strong>{user.partner.name}</Strong>
              )}
            </Text>
          }
        >
          {({ triggerProps }) => (
            <Text
              {...labelProps}
              icon={
                <Box
                  {...triggerProps}
                  aria-label="Impersonating"
                  component="span"
                >
                  <IconVisibility tone="promote" />
                </Box>
              }
            >
              {user.name}
            </Text>
          )}
        </TooltipRenderer>
      );

    case 'unverified':
      return (
        <Text {...labelProps} icon={<IconFlag tone="caution" />}>
          {user.name}
        </Text>
      );

    case 'verified':
      return <Text {...labelProps}>{user.name}</Text>;
  }
};
