import { ButtonLink, IconAdd, Stack } from 'braid-design-system';
import { endOfHour, sub } from 'date-fns';
import React from 'react';

import {
  EventTypePicker,
  useEventTypeState,
} from 'src/components/EventTypePicker/EventTypePicker';
import { GutterBox } from 'src/components/GutterBox/GutterBox';
import { InfiniteScrollingList } from 'src/components/InfiniteScrollingList/InfiniteScrollingList';
import { usePaginationDirectionState } from 'src/components/PaginationDirectionPicker/PaginationDirectionPicker';
import { Query } from 'src/components/Query/Query';
import { ScrollingXBox } from 'src/components/ScrollingBox/ScrollingBox';
import { usePermissions } from 'src/hooks/auth';
import { useEnvironmentConfig } from 'src/hooks/environment';
import type {
  WebhookSubscriptionsQuery,
  WebhookSubscriptionsQueryVariables,
} from 'src/types/graphql';

import {
  HirerPicker,
  useHirerState,
} from './components/HirerPicker/HirerPicker';
import {
  ScopePicker,
  useScopeState,
} from './components/ScopePicker/ScopePicker';
import { WebhookSubscriptionCard } from './components/WebhookSubscriptionCard/WebhookSubscriptionCard';
import { WEBHOOK_SUBSCRIPTIONS } from './queries';

export const SubscriptionListPanel = React.memo(() => {
  const { seekAnzSchemeId } = useEnvironmentConfig();

  const { permissions } = usePermissions();

  const eventTypeState = useEventTypeState();
  const scopeState = useScopeState();

  // We don't modify this state at the moment but we utilise its helpers.
  const { firstOrLast, processPageInfo } =
    usePaginationDirectionState('FORWARDS');

  const { eventTypeCodes } = eventTypeState;
  const { hirerScopedIndicator } = scopeState;

  const hirerState = useHirerState(hirerScopedIndicator);
  const { hirers } = hirerState;

  // We only show up to 30 days of requests so don't calculate deliverability
  // based on requests they can't see. Round up to the next hour to be nicer to
  // Apollo's cache
  const requestsSince = endOfHour(sub(new Date(), { days: 30 })).toISOString();

  return (
    <Stack dividers space="none">
      {permissions.includes('mutate:webhooks') ? (
        <GutterBox>
          <ButtonLink
            href="/manage/webhooks/new"
            icon={<IconAdd />}
            id="new-webhook-subscription-link"
            variant="transparent"
          >
            New subscription
          </ButtonLink>
        </GutterBox>
      ) : null}

      <GutterBox>
        <Stack space="gutter">
          <EventTypePicker {...eventTypeState} align="left" />

          <ScopePicker {...scopeState} />

          <HirerPicker idPrefix="webhookSubscription" {...hirerState} />
        </Stack>
      </GutterBox>

      <Query<WebhookSubscriptionsQuery, WebhookSubscriptionsQueryVariables>
        errorMessage="We couldn’t load your subscriptions."
        query={WEBHOOK_SUBSCRIPTIONS}
        variables={{
          filter: {
            eventTypeCodes,
            hirerIds: hirers?.map((hirer) => hirer.id.value),
            hirerScopedIndicator,
          },
          requestsSince,
          [firstOrLast]: 10,
          schemeId: seekAnzSchemeId,
        }}
        wrapperComponent={GutterBox}
      >
        {({ data, fetchMore }) => {
          const { edges, pageInfo } = data.webhookSubscriptions;

          const { shouldLoadMore, variables } = processPageInfo(pageInfo);

          const loadMore = () => fetchMore({ variables });

          return (
            <InfiniteScrollingList
              loadMore={loadMore}
              shouldLoadMore={shouldLoadMore}
              isEmpty={edges.length === 0}
              subject="webhook subscriptions"
            >
              {edges.map((edge) => (
                <ScrollingXBox key={edge.node.id.value}>
                  <WebhookSubscriptionCard subscription={edge.node} />
                </ScrollingXBox>
              ))}
            </InfiniteScrollingList>
          );
        }}
      </Query>
    </Stack>
  );
});
