
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "2022-seek-api-migration"
    }}>{`2022 SEEK API migration`}</h1>
    <p>{`The SEEK API replaces a number of legacy SEEK integrations, including the `}<a parentName="p" {...{
        "href": "/migration-guides/job-posting-api"
      }}>{`Job Posting API`}</a>{` and `}<a parentName="p" {...{
        "href": "/migration-guides/sae"
      }}>{`SEEK Application Export`}</a>{`.`}</p>
    <p>{`Migrating to the SEEK API provides a number of benefits to SEEK and its partners:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The SEEK API is a single point of access to SEEK’s hirer marketplace.
For example, the same `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/PositionProfile"
          }}>{`representation of a position`}</a>{` is used for `}<a parentName="p" {...{
            "href": "/use-cases/job-posting"
          }}>{`Job Posting`}</a>{`, `}<a parentName="p" {...{
            "href": "/use-cases/application-export"
          }}>{`Optimised Apply`}</a>{` and Talent Search Connect.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Using GraphQL makes it easier for SEEK to introduce new features without disrupting existing integrations.
You have the choice of when to broaden your software to take advantage of new SEEK API features.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A single set of OAuth2 credentials are used to `}<a parentName="p" {...{
            "href": "/auth"
          }}>{`authenticate your software to the SEEK API`}</a>{`.
This replaces multiple custom authentication mechanisms that have fallen behind industry best practices.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;