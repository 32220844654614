import {
  Box,
  IconClear,
  IconTick,
  Stack,
  Text,
  TextField,
} from 'braid-design-system';
import { type ComponentProps, useState } from 'react';
import { InlineCode } from 'scoobie';

import { BorderCard } from 'src/components/BorderCard';

import * as styles from './styles.css';

interface IdTextProps {
  id: string;
  tone: ComponentProps<typeof Text>['tone'];
}

const IdText = ({ id }: IdTextProps) => (
  <Text tone="secondary">
    <InlineCode weight="weak">
      {id ? (
        id.split('').map((c) =>
          /\s/.test(c) ? (
            <Box className={styles.criticalColor} key="space" component="span">
              ␣
            </Box>
          ) : (
            c
          ),
        )
      ) : (
        <Box className={styles.criticalColor} component="span">
          -
        </Box>
      )}
    </InlineCode>
  </Text>
);

const SplitHirerIdInput = () => {
  const [rawId, setId] = useState(' 93WyyF1h');

  return (
    <Stack space="large">
      <Text
        icon={<IconClear />}
        id="seek-hirer-onboarding-split-heading"
        tone="critical"
        weight="strong"
      >
        Hirer ID with hardcoded prefix (1)
      </Text>

      <TextField
        aria-labelledby="seek-hirer-onboarding-split-heading"
        id="seek-hirer-onboarding-split-id"
        onChange={(event) => setId(event.currentTarget.value)}
        onClear={() => setId('')}
        prefix="seekAnzPublicTest:organization:seek:"
        value={rawId}
      />

      <IdText
        id={`seekAnzPublicTest:organization:seek:${rawId}`}
        tone="critical"
      />
    </Stack>
  );
};

const RawHirerIdInput = () => {
  const [rawId, setId] = useState(
    'seekAnzPublicTest:organization:seek93WyyF1h ',
  );

  return (
    <Stack space="large">
      <Text
        icon={<IconClear />}
        id="seek-hirer-onboarding-raw-heading"
        tone="critical"
        weight="strong"
      >
        Hirer ID without trimming (2)
      </Text>

      <TextField
        aria-labelledby="seek-hirer-onboarding-raw-heading"
        id="seek-hirer-onboarding-raw-id"
        onChange={(event) => setId(event.currentTarget.value)}
        onClear={() => setId('')}
        value={rawId}
      />

      <IdText id={rawId} tone="critical" />
    </Stack>
  );
};

const TrimmedHirerIdInput = () => {
  const [rawId, setId] = useState(
    ' seekAnzPublicTest:organization:seek93WyyF1h ',
  );

  const id = rawId.trim();

  return (
    <Stack space="large">
      <Text
        icon={<IconTick />}
        id="seek-hirer-onboarding-raw-heading"
        tone="positive"
        weight="strong"
      >
        Hirer ID with trimming
      </Text>

      <TextField
        aria-labelledby="seek-hirer-onboarding-trimmed-heading"
        id="seek-hirer-onboarding-trimmed-id"
        onChange={(event) => setId(event.currentTarget.value)}
        onClear={() => setId('')}
        value={rawId}
      />

      <IdText id={id} tone="positive" />
    </Stack>
  );
};

export const HirerIdInput = () => (
  <BorderCard>
    <Stack dividers space="large">
      <SplitHirerIdInput />

      <RawHirerIdInput />

      <TrimmedHirerIdInput />
    </Stack>
  </BorderCard>
);
