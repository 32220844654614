import { Checkbox, Stack } from 'braid-design-system';
import { useState } from 'react';
import { CodeBlock } from 'scoobie';

import { BorderCard } from 'src/components/BorderCard';

export const PrivacyPolicy = () => {
  const [checked, setChecked] = useState(false);

  return (
    <BorderCard>
      <Stack dividers space="large">
        <Checkbox
          id="privacy-policy-input"
          label="Attach our company privacy policy"
          checked={checked}
          onChange={(event) => setChecked(event.currentTarget.checked)}
        />

        <CodeBlock label="Component" language="json">
          {JSON.stringify(
            checked
              ? [
                  {
                    componentTypeCode: 'PrivacyConsent',
                    privacyConsent: {
                      componentTypeCode: 'PrivacyConsent',
                      descriptionHtml: 'Do you agree to the privacy policy?',
                      privacyPolicyUrl: {
                        url: 'https://example.com',
                      },
                    },
                  },
                ]
              : [],
            null,
            2,
          )}
        </CodeBlock>
      </Stack>
    </BorderCard>
  );
};
