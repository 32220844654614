
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import {
  CommissionOnly,
  Monthly
} from '../../../use-cases/job-posting/assets/PayType';
import { SalaryCurrency } from '../../../use-cases/job-posting/assets/SalaryCurrency';
import { SalaryRange } from '../../../use-cases/job-posting/assets/SalaryRange';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "salary"
    }}>{`Salary`}</h1>
    <p>{`The SEEK API has added support for a monthly salary interval and additional currencies.`}</p>
    <p>{`This does not change our guidance around `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/salary#validation"
      }}>{`salary validation`}</a>{` nor our existing model for salary visibility.
The `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/salary#salary-range"
      }}>{`salary range`}</a>{` is used to provide more relevant job search results to candidates,
but the monetary values in `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/RemunerationRangeInput/field/minimumAmount"
      }}><inlineCode parentName="a">{`minimumAmount`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/RemunerationRangeInput/field/maximumAmount"
      }}><inlineCode parentName="a">{`maximumAmount`}</inlineCode></a>{` are not visible in search results nor on the job ad.
The hirer can provide an optional `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/salary#salary-description"
      }}>{`salary description`}</a>{` to describe the remuneration package,
and SEEK may display the `}<a parentName="p" {...{
        "href": "#pay-type"
      }}>{`pay type`}</a>{` and `}<a parentName="p" {...{
        "href": "#currency"
      }}>{`currency`}</a>{` alongside the description for clarity.`}</p>

    <h2 {...{
      "id": "pay-type"
    }}>{`Pay type`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`CommissionOnly`}</inlineCode>{` value is now deprecated in `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/RemunerationPackageInput/field/basisCode"
      }}><inlineCode parentName="a">{`RemunerationPackageInput.basisCode`}</inlineCode></a>{`.
You should remove the option from your software.`}</p>
    <CommissionOnly mdxType="CommissionOnly" />
    <p>{`It is common to express salaries with a `}<inlineCode parentName="p">{`Month`}</inlineCode>{`ly interval in certain markets.
This value is now supported in `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/RemunerationRangeInput/field/intervalCode"
      }}><inlineCode parentName="a">{`RemunerationRangeInput.intervalCode`}</inlineCode></a>{` and complements the existing `}<inlineCode parentName="p">{`Hour`}</inlineCode>{`ly and `}<inlineCode parentName="p">{`Year`}</inlineCode>{`ly intervals.`}</p>
    <Monthly mdxType="Monthly" />
    <p>{`We recommend using the pay type labels documented above for consistency with SEEK.
(The “Remove” and “New” badges displayed above only serve to clarify our documentation.
Your software should not display these badges on its job posting form.)`}</p>
    <p>{`The SEEK API now supports dynamically querying valid pay type combinations with the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/payTypes"
      }}><inlineCode parentName="a">{`payTypes`}</inlineCode>{` query`}</a>{`.
These may be presented to a hirer for selection in a job posting flow.`}</p>
    <p>{`This can help prevent entering a nonsensical pay type combination.
For example, a job ad with an hourly rate of $70,000 will render a hirer’s job ad undiscoverable in recommendations and search`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($schemeId: String!) {\\n  payTypes(schemeId: $schemeId) {\\n    basisCode\\n    intervalCode\\n    label\\n  }\\n}\",\"position\":{\"start\":{\"line\":48,\"column\":1,\"offset\":2395},\"end\":{\"line\":56,\"column\":4,\"offset\":2519},\"indent\":[1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label=\\\"Variables\\\"\",\"value\":\"{\\n  \\\"schemeId\\\": \\\"seekAnzPublicTest\\\"\\n}\",\"position\":{\"start\":{\"line\":58,\"column\":1,\"offset\":2521},\"end\":{\"line\":62,\"column\":4,\"offset\":2588},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label=\\\"Result\\\"\",\"value\":\"{\\n  \\\"payTypes\\\": [\\n    {\\n      \\\"basisCode\\\": \\\"Hourly\\\",\\n      \\\"intervalCode\\\": \\\"Hour\\\",\\n      \\\"label\\\": \\\"Hourly rate\\\"\\n    },\\n    {\\n      \\\"basisCode\\\": \\\"Salaried\\\",\\n      \\\"intervalCode\\\": \\\"Month\\\",\\n      \\\"label\\\": \\\"Monthly salary\\\"\\n    },\\n    {\\n      \\\"basisCode\\\": \\\"Salaried\\\",\\n      \\\"intervalCode\\\": \\\"Year\\\",\\n      \\\"label\\\": \\\"Annual salary\\\"\\n    },\\n    {\\n      \\\"basisCode\\\": \\\"SalariedPlusCommission\\\",\\n      \\\"intervalCode\\\": \\\"Year\\\",\\n      \\\"label\\\": \\\"Annual plus commission\\\"\\n    }\\n  ]\\n}\",\"position\":{\"start\":{\"line\":64,\"column\":1,\"offset\":2590},\"end\":{\"line\":89,\"column\":4,\"offset\":3080},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($schemeId:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "payTypes(schemeId:": true,
        "$schemeId)": true,
        "basisCode\\n": true,
        "intervalCode\\n": true,
        "label\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":48,\"column\":1,\"offset\":2395},\"end\":{\"line\":56,\"column\":4,\"offset\":2519},\"indent\":[1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label": "\\\"Variables\\\"\",\"value\":\"{\\n",
        "\\\"schemeId\\\":": true,
        "\\\"seekAnzPublicTest\\\"\\n}\",\"position\":{\"start\":{\"line\":58,\"column\":1,\"offset\":2521},\"end\":{\"line\":62,\"column\":4,\"offset\":2588},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label": "\\\"Result\\\"\",\"value\":\"{\\n",
        "\\\"payTypes\\\":": true,
        "[\\n": true,
        "\\\"basisCode\\\":": true,
        "\\\"Hourly\\\",\\n": true,
        "\\\"intervalCode\\\":": true,
        "\\\"Hour\\\",\\n": true,
        "\\\"label\\\":": true,
        "\\\"Hourly": true,
        "rate\\\"\\n": true,
        "},\\n": true,
        "\\\"Salaried\\\",\\n": true,
        "\\\"Month\\\",\\n": true,
        "\\\"Monthly": true,
        "salary\\\"\\n": true,
        "\\\"Year\\\",\\n": true,
        "\\\"Annual": true,
        "\\\"SalariedPlusCommission\\\",\\n": true,
        "plus": true,
        "commission\\\"\\n": true,
        "}\\n": true,
        "]\\n}\",\"position\":{\"start\":{\"line\":64,\"column\":1,\"offset\":2590},\"end\":{\"line\":89,\"column\":4,\"offset\":3080},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query ($schemeId: String!) {
  payTypes(schemeId: $schemeId) {
    basisCode
    intervalCode
    label
  }
}
`}</code></pre>
    <h2 {...{
      "id": "currency"
    }}>{`Currency`}</h2>
    <p>{`Previously, the SEEK API specified location-bound rules that covered 3 salary currencies:`}</p>
    <blockquote>
      <p parentName="blockquote">{`... the `}<a parentName="p" {...{
          "href": "/use-cases/job-posting/locations"
        }}>{`position’s location`}</a>{` determines the required currency:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li"><inlineCode parentName="p">{`NZD`}</inlineCode>{` is required for positions located in New Zealand.
These are locations that have a `}<a parentName="p" {...{
              "href": "https://developer.seek.com/schema/#/named-type/Location/field/countryCode"
            }}><inlineCode parentName="a">{`countryCode`}</inlineCode></a>{` of `}<inlineCode parentName="p">{`NZ`}</inlineCode>{`.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><inlineCode parentName="p">{`GBP`}</inlineCode>{` is required for positions located in the UK & Ireland.
These are locations that have a `}<a parentName="p" {...{
              "href": "https://developer.seek.com/schema/#/named-type/Location/field/countryCode"
            }}><inlineCode parentName="a">{`countryCode`}</inlineCode></a>{` of `}<inlineCode parentName="p">{`GB`}</inlineCode>{` or `}<inlineCode parentName="p">{`IE`}</inlineCode>{`.`}</p>
          <p parentName="li">{`Note that this requires positions in the Republic of Ireland to use salaries denominated in `}<inlineCode parentName="p">{`GBP`}</inlineCode>{`, even though their local currency is `}<inlineCode parentName="p">{`EUR`}</inlineCode>{`.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><inlineCode parentName="p">{`AUD`}</inlineCode>{` is required for positions in all other locations, regardless of their local currency.`}</p>
        </li>
      </ul>
      <p parentName="blockquote">{`While the currency can be derived from the location, it must be explicitly specified to ensure the appropriate currency was presented to the hirer in the job posting flow.
An explicit currency will also allow the SEEK API to accept additional currencies in a backward compatible way.`}</p>
    </blockquote>
    <p>{`The SEEK API now supports 16 currencies in total.
To assist hirers in selecting the right currency for their job ad, we recommend:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Displaying a shortlist of the 8 local currencies used across our Asia-Pacific markets in addition to the United States Dollar (USD)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pre-selecting the currency based on the `}<inlineCode parentName="p">{`currencies`}</inlineCode>{` value returned for the hirer’s selected location`}</p>
      </li>
    </ol>
    <h3 {...{
      "id": "1-display-a-shortlist-of-currencies"
    }}>{`1. Display a shortlist of currencies`}</h3>
    <p>{`The shortlist of currencies can be dynamically queried with the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/currencies"
      }}><inlineCode parentName="a">{`currencies`}</inlineCode>{` query`}</a>{`.
A query-based approach will allow your hirers to select new currencies if they are added in future.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($usageTypeCode: String!) {\\n  currencies(usageTypeCode: $usageTypeCode) {\\n    code\\n  }\\n}\",\"position\":{\"start\":{\"line\":122,\"column\":1,\"offset\":4720},\"end\":{\"line\":128,\"column\":4,\"offset\":4829},\"indent\":[1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"usageTypeCode\\\": \\\"SEEKMarket\\\"\\n}\",\"position\":{\"start\":{\"line\":130,\"column\":1,\"offset\":4831},\"end\":{\"line\":134,\"column\":4,\"offset\":4878},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"currencies\\\": [\\n    {\\n      \\\"code\\\": \\\"AUD\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"HKD\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"IDR\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"MYR\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"NZD\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"PHP\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"SGD\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"THB\\\"\\n    },\\n    {\\n      \\\"code\\\": \\\"USD\\\"\\n    }\\n  ]\\n}\",\"position\":{\"start\":{\"line\":136,\"column\":1,\"offset\":4880},\"end\":{\"line\":168,\"column\":4,\"offset\":5213},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($usageTypeCode:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "currencies(usageTypeCode:": true,
        "$usageTypeCode)": true,
        "code\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":122,\"column\":1,\"offset\":4720},\"end\":{\"line\":128,\"column\":4,\"offset\":4829},\"indent\":[1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"usageTypeCode\\\":": true,
        "\\\"SEEKMarket\\\"\\n}\",\"position\":{\"start\":{\"line\":130,\"column\":1,\"offset\":4831},\"end\":{\"line\":134,\"column\":4,\"offset\":4878},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"currencies\\\":": true,
        "[\\n": true,
        "\\\"code\\\":": true,
        "\\\"AUD\\\"\\n": true,
        "},\\n": true,
        "\\\"HKD\\\"\\n": true,
        "\\\"IDR\\\"\\n": true,
        "\\\"MYR\\\"\\n": true,
        "\\\"NZD\\\"\\n": true,
        "\\\"PHP\\\"\\n": true,
        "\\\"SGD\\\"\\n": true,
        "\\\"THB\\\"\\n": true,
        "\\\"USD\\\"\\n": true,
        "}\\n": true,
        "]\\n}\",\"position\":{\"start\":{\"line\":136,\"column\":1,\"offset\":4880},\"end\":{\"line\":168,\"column\":4,\"offset\":5213},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query ($usageTypeCode: String!) {
  currencies(usageTypeCode: $usageTypeCode) {
    code
  }
}
`}</code></pre>
    <p>{`Any supported currency can be used in any location.
SEEK will take care of currency conversions when candidates refine their job searches by `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/salary#salary-range"
      }}>{`salary range`}</a>{`.
SEEK will not perform conversions on the free-text `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/salary#salary-description"
      }}>{`salary description`}</a>{`;
we recommend hirers to provide a description in the dominant currency of the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/locations"
      }}>{`position’s location`}</a>{` with the currency clearly denoted.`}</p>
    <h3 {...{
      "id": "2-pre-selecting-currency-based-on-location"
    }}>{`2. Pre-selecting currency based on location`}</h3>
    <p>{`Our `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/locations#implementation-options"
      }}>{`location implementation options`}</a>{` now provide the recommended `}<inlineCode parentName="p">{`currencies`}</inlineCode>{` for a given location.
The `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/ISO_4217"
      }}>{`ISO 4217`}</a>{` currency codes are listed in order of preference.
In most cases there will be a single currency returned,
and we recommend pre-selecting the first option for an optimal hirer experience:`}</p>

    <SalaryCurrency mdxType="SalaryCurrency" />
    <p>{`For currencies supported by the SEEK API,
your software must supply the same currency that the hirer sees when they are filling out the pay details of their position.
If your posting form includes currencies that are not supported by the SEEK API,
you may programmatically convert the salary range to `}<inlineCode parentName="p">{`USD`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "behaviour-change-for-mismatched-inputs"
    }}>{`Behaviour change for mismatched inputs`}</h3>
    <p>{`Previously, the SEEK API would silently ignore mismatched currency inputs and replace them with the expected currency for the position location.
For example, if you sent `}<inlineCode parentName="p">{`NZD`}</inlineCode>{` for a job ad located in Australia, we would replace the currency with `}<inlineCode parentName="p">{`AUD`}</inlineCode>{`.
This behaviour was erroneous as it did not account for currency conversions,
and it has been removed now that the SEEK API accepts any supported currency in any location.
The currency that your software provides will be respected on the job ad,
and providing an unsupported currency will fail with a `}<a parentName="p" {...{
        "href": "/graphql/error-responses#bad_user_input"
      }}><inlineCode parentName="a">{`BAD_USER_INPUT`}</inlineCode></a>{` error.`}</p>
    <h2 {...{
      "id": "salary-range"
    }}>{`Salary range`}</h2>
    <p>{`We have revised our UX recommendations for salary ranges.
These changes are focused on supporting Asia-Pacific markets and ensuring hirers get optimal performance from their SEEK job ads.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Display the salary currency alongside the range.`}</p>
        <p parentName="li">{`This dropdown should be implemented as documented in the section above.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Mark the maximum amount as a mandatory input.`}</p>
        <p parentName="li">{`We have observed that some hirers currently omit the maximum amount,
which defaults it to the minimum and affects the performance of their job ads.
The input field will be required in our schema in future.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Display the pay type alongside the range.`}</p>
        <p parentName="li">{`We have observed that some hirers currently enter hourly salary ranges against an annual pay type and vice versa,
rendering their job ads undiscoverable in recommendations and search results.`}</p>
      </li>
    </ol>

    <SalaryRange mdxType="SalaryRange" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;