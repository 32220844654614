import { Column, Columns, IconChevron } from 'braid-design-system';

import type { PageNavigation } from 'src/site';

import { TileLink } from './TileLink';

export const PageNavigationButtonLinks = ({
  next,
  prev,
}: {
  next?: PageNavigation;
  prev?: PageNavigation;
}) => (
  <Columns space="gutter" collapseBelow="tablet">
    <Column>
      {prev?.label && (
        <TileLink
          align={['center', 'left']}
          href={prev.path}
          icon={<IconChevron direction="left" />}
          title={`${prev.parent ? `${prev.parent}: ` : ''}${prev.label}`}
        />
      )}
    </Column>
    <Column>
      {next?.label && (
        <TileLink
          align={['center', 'right']}
          href={next.path}
          icon={<IconChevron direction="right" />}
          reverse
          title={`${next.parent ? `${next.parent}: ` : ''}${next.label}`}
        />
      )}
    </Column>
  </Columns>
);
