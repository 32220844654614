
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Alert, Text } from 'braid-design-system';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "talent-search-connect"
    }}>{`Talent Search Connect`}</h1>

    <Alert tone="info" mdxType="Alert">
  <Text mdxType="Text">
    Talent Search Connect is coming soon. Check back regularly, or contact us
    for updates.
  </Text>
    </Alert>
    <p>{`This use case provides technical implementation details for the Talent Search Connect product.
You can find more information on its `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au/partners/integrations/talent-search-connect/"
      }}>{`product page`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://talent.seek.com.au/products/talentsearch"
      }}>{`SEEK Talent Search`}</a>{` enables hirers to proactively search for and connect with suitable candidates.
It’s powered by over 44 million SEEK Profiles across the Asia Pacific.`}</p>
    <p>{`Talent Search Connect integrates `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au/products/talentsearch"
      }}>{`SEEK Talent Search`}</a>{` with candidate management systems to address some of the efficiency bottlenecks hirers experience with segregated proactive sourcing and candidate management workflows.`}</p>
    <p>{`The SEEK API supports a number of features to enable a more seamless experience for hirers:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/proactive-sourcing/widgets"
          }}>{`Widgets`}</a></p>
        <p parentName="li">{`Widgets prompt hirers to consider proactive sourcing activities in the context of their candidate management workflow.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/proactive-sourcing/sharing-a-candidate"
          }}>{`Sharing candidates`}</a></p>
        <p parentName="li">{`Candidate details and their past `}<a parentName="p" {...{
            "href": "/use-cases/proactive-sourcing/interaction-history"
          }}>{`interaction history`}</a>{` with the hirer can be uploaded and kept in sync,
enabling hirers to determine at a glance if they have an existing relationship with the candidate.
Hirers are able to view their previous interactions with the candidate when viewing a profile in Talent Search,
reducing time spent cross-referencing candidate information with your software.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/proactive-sourcing/unposted-positions"
          }}>{`Unposted positions`}</a></p>
        <p parentName="li">{`Unposted positions allow your software to upload positions your hirers are actively recruiting for.
This position information is presented to the hirer when they are purchasing SEEK candidate profiles to allow them to select which position opening the purchase should be associated with.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/proactive-sourcing/profile-purchases"
          }}>{`Exporting purchased profiles`}</a></p>
        <p parentName="li">{`When a hirer purchases a candidate’s profile within Talent Search,
it is made available for export directly into a position in your software,
reducing the amount of manual handling of candidate information required by the hirer.`}</p>
      </li>
    </ul>
    <p>{`All SEEK API operations for the Talent Search Connect use case require use of a `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`partner token`}</a>{`.`}</p>
    <h2 {...{
      "id": "interaction-diagrams"
    }}>{`Interaction diagrams`}</h2>
    <h3 {...{
      "id": "sharing-candidates"
    }}>{`Sharing candidates`}</h3>
    <img alt="" data-scoobie-style="none" src={require('../../../mermaid/.70beb83fb92d5b2d2190bd696a757707de73ecbe.mmd.svg')} title="Sharing candidate information" />
    <h3 {...{
      "id": "sharing-unposted-positions"
    }}>{`Sharing unposted positions`}</h3>
    <img alt="" data-scoobie-style="none" src={require('../../../mermaid/.53dc29c1764aae805dbdcd7e5ee0508958eb5b26.mmd.svg')} title="Sharing open positions" />
    <h3 {...{
      "id": "exporting-purchased-profiles"
    }}>{`Exporting purchased profiles`}</h3>
    <img alt="" data-scoobie-style="none" src={require('../../../mermaid/.4fb1b2186d388f95a25e3530ef072dcebe323121.mmd.svg')} title="Export purchased profiles" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;