import { Text, TextDropdown } from 'braid-design-system';
import { useState } from 'react';

import { STATUS_CODES, type StatusCode } from './StatusCodes';

export const useStatusCodeState = () => {
  const [statusCode, setStatusCode] = useState<StatusCode>('All');

  return {
    statusCode,
    setStatusCode,
  };
};

type Props = ReturnType<typeof useStatusCodeState>;

export const StatusCodePicker = ({ setStatusCode, statusCode }: Props) => (
  <Text>
    <TextDropdown
      id="subscriptionReplayStatusCode"
      label="Status"
      onChange={setStatusCode}
      options={[...STATUS_CODES]}
      value={statusCode}
    />
  </Text>
);
