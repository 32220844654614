
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "partner-token-expiry-time-will-be-reduced"
    }}>{`Partner token expiry time will be reduced`}</h4>
    <p><strong parentName="p">{`What is the change?`}</strong></p>
    <p>{`On the 2nd of June at 9am AEST, the lifetime of SEEK API `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`partner tokens`}</a>{` will be reduced from 24 hours to 30 minutes.
Your hirers may be unable to post job ads and receive candidate applications if your SEEK integration does not comply with token expiry best practices after this date.`}</p>
    <p><strong parentName="p">{`Why is this happening?`}</strong></p>
    <p>{`This change is a routine security upgrade to safeguard both SEEK and our partners in the event of a compromised API credential.`}</p>
    <p><strong parentName="p">{`Action required from partners`}</strong></p>
    <p>{`No action is required if your software caches partner tokens based on the number of seconds specified in the `}<inlineCode parentName="p">{`expires_in`}</inlineCode>{` of the partner token response.`}</p>
    <p>{`If your software has hardcoded the token’s expiry or you are not currently caching partner tokens, you must update it to cache dynamically as described in our `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`partner token documentation`}</a>{`.`}</p>
    <p><strong parentName="p">{`When do partners need to make this change by?`}</strong></p>
    <p>{`In line with the `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au/partners/terms-of-use/#api-versioning"
      }}>{`SEEK API terms`}</a>{`, this change needs to be completed within 30 days (before the 2nd of June) to avoid hirer impact.
It is estimated to take less than 1 day of effort.
Please reach out if you have any concerns with the above change.
Subscribe to our `}<a parentName="p" {...{
        "href": "/introduction/release-notes"
      }}>{`release notes`}</a>{` and `}<a parentName="p" {...{
        "href": "https://status.seek.com"
      }}>{`status page`}</a>{` to be notified of future updates.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;