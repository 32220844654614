import {
  Box,
  Column,
  Columns,
  ContentBlock,
  Hidden,
  IconChevron,
  MenuItemCheckbox,
  MenuRenderer,
  Stack,
  Text,
  useResponsiveValue,
} from 'braid-design-system';
import { SmartTextLink } from 'scoobie';

import { usePermissions } from 'src/hooks/auth';
import { useEnvironmentConfig } from 'src/hooks/environment';
import { useSelf } from 'src/hooks/self';

import { GutterBox } from '../GutterBox/GutterBox';

export const ImpersonationBanner = () => {
  const { baseUrls } = useEnvironmentConfig();

  const { self } = useSelf();

  const { permissions, setPermissions, allPermissions, isImpersonating } =
    usePermissions();

  const responsiveValue = useResponsiveValue();

  return isImpersonating ? (
    <Box background="cautionLight">
      <ContentBlock width="large">
        <GutterBox>
          <Columns space="gutter" collapseBelow="tablet">
            <Column>
              <Stack space="medium">
                <Text>
                  You are currently impersonating {self?.partner.name}.
                  <Hidden below="desktop">
                    {' '}
                    Please refrain from editing their settings.
                  </Hidden>
                </Text>

                <Text>
                  <SmartTextLink
                    href={`${baseUrls.backoffice}/indirect-relationships?orgType=RSP`}
                  >
                    Change in the Back Office
                  </SmartTextLink>
                </Text>
              </Stack>
            </Column>

            <Column width="content">
              <MenuRenderer
                align={
                  responsiveValue({ mobile: 'left', tablet: 'right' }) ??
                  'right'
                }
                offsetSpace="small"
                trigger={(triggerProps, { open }) => (
                  <Box userSelect="none" cursor="pointer" {...triggerProps}>
                    <Text>
                      Permissions{' '}
                      <IconChevron
                        direction={open ? 'up' : 'down'}
                        alignY="lowercase"
                      />
                    </Text>
                  </Box>
                )}
              >
                {allPermissions.map((permission) => (
                  <MenuItemCheckbox
                    key={permission}
                    checked={permissions.includes(permission)}
                    onChange={(checked) =>
                      setPermissions((existingPermissions) =>
                        checked
                          ? [...existingPermissions, permission]
                          : existingPermissions.filter((e) => e !== permission),
                      )
                    }
                  >
                    {permission}
                  </MenuItemCheckbox>
                ))}
              </MenuRenderer>
            </Column>
          </Columns>
        </GutterBox>
      </ContentBlock>
    </Box>
  ) : null;
};
