import {
  Box,
  Card,
  Heading,
  Hidden,
  IconTime,
  Stack,
  Strong,
  Text,
} from 'braid-design-system';
import { SmartTextLink } from 'scoobie';

import type * as Statuspage from 'src/types/statuspage';

import { formatDateRange } from './formatDateRange';

import * as styles from './styles.css';

interface Props {
  page: Statuspage.Page;
  maintenance: Statuspage.ScheduledMaintenance;
}

export const UpcomingMaintenance = ({ page, maintenance }: Props) => {
  // Scheduled maintenances use `/incidents/{id}` URLs
  const statusPageHref = [
    page.url,
    'incidents',
    encodeURIComponent(maintenance.id),
  ].join('/');

  const currentIsoTime = new Date().toISOString();

  // Atlassian appears to push a second "maintenance will begin in 60 minutes"
  // update automatically. If we render this, we also need to render timestamps
  // for the message to be useful. This seems like a lot of clutter; have them
  // click in to Statuspage for the full details.
  const firstUpdate = [...maintenance.incident_updates]
    .reverse()
    .find(
      ({ status, display_at }) =>
        status === 'scheduled' && currentIsoTime >= display_at,
    );

  const { from, to } = formatDateRange(
    new Date(maintenance.scheduled_for),
    new Date(maintenance.scheduled_until),
  );

  return (
    <Card roundedAbove="tablet">
      <Stack space="large">
        <Stack space="medium">
          <Heading icon={<IconTime tone="caution" />} level="3">
            {maintenance.name}
          </Heading>
          <Text tone="secondary">
            <Hidden below="tablet">
              Scheduled from <Strong>{from}</Strong>
              {' to '}
              <Strong>{to}</Strong>
            </Hidden>

            <Hidden above="mobile">
              {from} – {to}
            </Hidden>
          </Text>
        </Stack>

        {firstUpdate && (
          <Text>
            <Box component="span" className={styles.updateBodyText}>
              {firstUpdate.body}
            </Box>
          </Text>
        )}

        <Text>
          <SmartTextLink href={statusPageHref}>
            View on the {page.name} status page
          </SmartTextLink>
        </Text>
      </Stack>
    </Card>
  );
};
