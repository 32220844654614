import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SearchField } from './SearchField';
import { type SearchResultItem, mapSearchResult } from './mapSearchResult';
import { type SearchSource, searchIndex, searchSources } from './searchIndex';
import { useSearchResults } from './useSearchResults';

export const SiteSearch = ({
  onBlur,
  autoFocus,
}: {
  autoFocus?: boolean;
  onBlur?: () => void;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Preload our search index
    searchIndex();
  }, []);

  const [rawResults, setResults] = useSearchResults(searchTerm);

  const results = useMemo(
    () =>
      Object.fromEntries(
        searchSources.map(({ value }) => [
          value,
          rawResults
            .filter((result) => result.page.source === value)
            .slice(0, 20)
            .map((result) =>
              mapSearchResult({
                result,
                locationSearch: location.search,
                termText: searchTerm,
              }),
            ),
        ]),
      ) as Record<SearchSource, SearchResultItem[]>,
    [rawResults, location.search, searchTerm],
  );

  return (
    <SearchField
      autoFocus={autoFocus}
      onBlur={onBlur}
      results={results}
      onSearchTermChange={setSearchTerm}
      searchTerm={searchTerm}
      onChooseResult={(result: SearchResultItem) => {
        const url = result.path;

        if (result.source === 'schema') {
          window.location.href = `https://developer.seek.com/schema#${url.slice(
            7,
          )}`;
        } else if (result.source === 'dashboard') {
          window.location.href = url;
        } else {
          navigate(url);
          onBlur?.();
        }

        setResults([]);
        setSearchTerm('');
      }}
    />
  );
};
