import {
  Actions,
  Button,
  ButtonLink,
  IconChevron,
  IconHome,
} from 'braid-design-system';
import { useNavigate } from 'react-router-dom';

export const NavActions = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Actions>
      <Button icon={<IconChevron direction="left" />} onClick={goBack}>
        Back
      </Button>
      <ButtonLink href="/" icon={<IconHome />} variant="soft">
        Home
      </ButtonLink>
    </Actions>
  );
};
