import { Box } from 'braid-design-system';
import React, { type ComponentProps, type ReactNode } from 'react';

import * as styles from './styles.css';

interface Props {
  children: ReactNode;
  onScroll?: ComponentProps<typeof Box>['onScroll'];
  paddingY?: ComponentProps<typeof Box>['paddingY'];
}

const InnerOverflowXBox = ({ children }: Pick<Props, 'children'>) => (
  <Box className={styles.fullMinWidth} display="inlineBlock">
    {children}
  </Box>
);

/**
 * Jump in.
 */
export const ScrollingXBox = ({ children, paddingY }: Props) => (
  <Box className={styles.overflowX} paddingY={paddingY}>
    <InnerOverflowXBox>{children}</InnerOverflowXBox>
  </Box>
);

export const ScrollingYBox = React.forwardRef(
  ({ children, onScroll }: Props, ref: React.ForwardedRef<HTMLElement>) => {
    const { maxHeight700, overflowY } = styles;

    return (
      <Box className={[maxHeight700, overflowY]} onScroll={onScroll} ref={ref}>
        {children}
      </Box>
    );
  },
);
