import type { Private } from '@seek/indie-api-types';
import {
  Badge,
  Box,
  ButtonIcon,
  Column,
  Columns,
  IconDelete,
  IconMobile,
  IconSecurity,
  Inline,
  MenuItem,
  OverflowMenu,
  Stack,
  Text,
} from 'braid-design-system';

import { type UserType, roleDisplayNames } from 'src/types/users';

import { DateRenderer } from './DateRenderer/DateRenderer';
import { UserLabel } from './UserLabel/UserLabel';

interface UserDesktopRowProps {
  type: UserType;
  user: Private.Auth0UserPayload;
  showActions: boolean;
  isActingOnSelf: boolean;
  setShowResetMfaDialog: (show: boolean) => void;
  setShowDeleteDialog: (show: boolean) => void;
  setShowEditRoleDialog: (show: boolean) => void;
}

export const UserDesktopRow = ({
  user,
  type,
  showActions,
  isActingOnSelf,
  setShowResetMfaDialog,
  setShowDeleteDialog,
  setShowEditRoleDialog,
}: UserDesktopRowProps) => {
  const { emailAddress, createdAt, updatedAt, role } = user;

  return (
    <Box paddingX="gutter" paddingY="medium">
      <Columns space="medium" alignY="center">
        <Column width="1/3">
          <Stack space="small">
            <UserLabel
              type={type}
              user={user}
              labelProps={{ weight: 'strong' }}
            />
            <Text>{emailAddress}</Text>
          </Stack>
        </Column>

        <Column width="1/4">
          <Badge tone="info">{roleDisplayNames[role]}</Badge>
        </Column>

        <Column width="1/4">
          <DateRenderer inputDate={createdAt} updatedDate={updatedAt} />
        </Column>

        {showActions ? (
          <>
            <Column>
              <Inline space="large" alignY="center" align="right">
                <ButtonIcon
                  id="reset-mfa-button-icon"
                  label="Reset MFA"
                  icon={<IconMobile />}
                  variant="transparent"
                  size="large"
                  onClick={() => setShowResetMfaDialog(true)}
                />

                {!isActingOnSelf ? (
                  <>
                    <ButtonIcon
                      id="edit-roles-button-icon"
                      label="Edit role"
                      icon={<IconSecurity />}
                      variant="transparent"
                      size="large"
                      onClick={() => setShowEditRoleDialog(true)}
                    />
                    <OverflowMenu label="Options">
                      <MenuItem
                        icon={<IconDelete />}
                        onClick={() => setShowDeleteDialog(true)}
                        tone="critical"
                      >
                        Delete
                      </MenuItem>
                    </OverflowMenu>
                  </>
                ) : null}
              </Inline>
            </Column>
          </>
        ) : null}
      </Columns>
    </Box>
  );
};
