import { Heading, Stack, TextField } from 'braid-design-system';
import { useState } from 'react';
import { SmartTextLink } from 'scoobie';

import { AdPerformancePanel } from './components/AdPerformancePanel';

export const AdPerformanceSection = () => {
  const [positionProfileId, setPositionProfileId] = useState<string>('');

  return (
    <Stack space="large" dividers>
      <Heading level="4">
        <SmartTextLink href="https://developer.seek.com/use-cases/ad-performance">
          Ad Performance Panel
        </SmartTextLink>
      </Heading>

      <Stack space="large" dividers>
        <TextField
          id="positionProfileId"
          label="Position Profile OID"
          message="e.g. seekAnz:positionProfile:jobAd:24FXNXVwM"
          onClear={() => setPositionProfileId('')}
          value={positionProfileId}
          onChange={(event) => setPositionProfileId(event.currentTarget.value)}
        />
        {positionProfileId ? (
          <AdPerformancePanel positionProfileId={positionProfileId} />
        ) : null}
      </Stack>
    </Stack>
  );
};
