import {
  Autosuggest,
  Column,
  Columns,
  Disclosure,
  Divider,
  Dropdown,
  IconSearch,
  Stack,
  Text,
} from 'braid-design-system';
import { useEffect } from 'react';
import { CodeBlock } from 'scoobie';

import { BorderCard } from 'src/components/BorderCard';
import { useSessionStorage } from 'src/hooks/storage';

import { ACTIVE_CURRENCY_CODES } from './constants';

const LOCATION_DATA = {
  'Bukit Batok West Region SG': {
    currency: 'SGD',
    id: 'seekAnz:location:seek:2dE6LBwKu',
  },
  'Cremorne VIC 3121 AU': {
    currency: 'AUD',
    id: 'seekAnzPublicTest:location:seek:46qaJ6Sj',
  },
  'George Town Penang MY': {
    currency: 'MYR',
    id: 'seekAnzPublicTest:location:seek:2FrBPbrNj',
  },
  'Milford Sound Southland NZ': {
    currency: 'NZD',
    id: 'seekAnzPublicTest:location:seek:j571RF3M',
  },
  'Perak MY': {
    currency: 'MYR',
    id: 'seekAnzPublicTest:location:seek:3989Xc56w',
  },
  'Phuket TH': {
    currency: 'THB',
    id: 'seekAnz:location:seek:2YGPNBe9V',
  },
} as const;

type Location = keyof typeof LOCATION_DATA;

const isLocation = (input: unknown): input is Location =>
  typeof input === 'string' && input in LOCATION_DATA;

const LOCATION_SUGGESTIONS = Object.keys(LOCATION_DATA).map((location) => ({
  text: location,
}));

export const SalaryCurrency = () => {
  const id = 'salary-currency-input';

  const [locationInput, setLocationInput] = useSessionStorage(
    `/migration-guides/jobstreet-and-jobsdb-uplift/${id}-location`,
    LOCATION_SUGGESTIONS[0],
  );

  const [currency, setCurrency] = useSessionStorage<string>(
    `/migration-guides/jobstreet-and-jobsdb-uplift/${id}-currency`,
    'MYR',
  );

  useEffect(() => {
    if (isLocation(locationInput.text)) {
      setCurrency(LOCATION_DATA[locationInput.text].currency);
    }
  }, [locationInput.text, setCurrency]);

  const suggestions = LOCATION_SUGGESTIONS.map((suggestion) => {
    const start = suggestion.text
      .toLocaleLowerCase()
      .indexOf(locationInput.text.toLocaleLowerCase());

    return {
      ...suggestion,
      highlights:
        start === -1 ? [] : [{ start, end: start + locationInput.text.length }],
    };
  }).sort((a, b) => {
    if (a.highlights.length && b.highlights.length) {
      return a.highlights[0].start > b.highlights[0].start ? 1 : -1;
    }

    if (!a.highlights.length && !b.highlights.length) {
      return 0;
    }

    return a.highlights.length ? -1 : 1;
  });

  return (
    <BorderCard>
      <Stack space="large">
        <Autosuggest
          automaticSelection
          icon={<IconSearch />}
          id={`${id}-location-suggest`}
          label="Location"
          onChange={setLocationInput}
          suggestions={suggestions}
          value={locationInput}
        />

        {isLocation(locationInput.text) && (
          <>
            <Disclosure
              collapseLabel="Hide response"
              expandLabel="Show response"
              id={`${id}-location-json-disclosure`}
            >
              <CodeBlock label="Location" language="json">
                {JSON.stringify(
                  {
                    contextualName: locationInput.text,
                    currencies: [
                      {
                        code: LOCATION_DATA[locationInput.text].currency,
                      },
                    ],
                    id: {
                      value: LOCATION_DATA[locationInput.text].id,
                    },
                  },
                  null,
                  2,
                )}
              </CodeBlock>
            </Disclosure>

            <Divider />

            <Stack space="small">
              <Text weight="strong">Pay range</Text>

              <Text size="small" tone="secondary">
                Enter a pay range to offer candidates (this will not show on
                your ad).
              </Text>

              <Columns alignY="center" collapseBelow="desktop" space="small">
                <Column width="content">
                  <Dropdown
                    aria-label="Currency"
                    id={`${id}-currency`}
                    onChange={(event) => setCurrency(event.currentTarget.value)}
                    value={currency}
                  >
                    {ACTIVE_CURRENCY_CODES.map((value) => (
                      <option key={value}>{value}</option>
                    ))}
                  </Dropdown>
                </Column>
              </Columns>
            </Stack>
          </>
        )}
      </Stack>
    </BorderCard>
  );
};
