
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "self-query"
    }}><inlineCode parentName="h4">{`self`}</inlineCode>{` query`}</h4>
    <p>{`We’ve added a `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/self"
      }}><inlineCode parentName="a">{`self`}</inlineCode>{` query`}</a>{` to return information about an access token’s organizations.`}</p>
    <p>{`This can be a convenient alternative to the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/hiringOrganization"
      }}><inlineCode parentName="a">{`hiringOrganization`}</inlineCode>{` query`}</a>{` when working with `}<a parentName="p" {...{
        "href": "/auth/browser-tokens"
      }}>{`browser tokens`}</a>{`.
Instead of needing to specify the hirer ID explicitly, it returns the SEEK hirer the browser token is scoped to.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":\"label=\\\"Query\\\" hirerId=\\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\" scope=\\\"query:organizations\\\"\",\"value\":\"query {\\n  self {\\n    hirer {\\n      id {\\n        value\\n      }\\n      name\\n      seekApiCapabilities {\\n        relationshipTypeCodes\\n        applicationMethodCodes\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":8,\"column\":1,\"offset\":333},\"end\":{\"line\":23,\"column\":4,\"offset\":626},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label=\\\"Result\\\"\",\"value\":\"{\\n  \\\"self\\\": {\\n    \\\"hirer\\\": {\\n      \\\"id\\\": {\\n        \\\"value\\\": \\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\"\\n      },\\n      \\\"name\\\": \\\"Acme Corp\\\",\\n      \\\"seekApiCapabilities\\\": {\\n        \\\"relationshipTypeCodes\\\": [\\n          \\\"ApplicationExport\\\",\\n          \\\"ApplicationPrefill\\\",\\n          \\\"JobPosting\\\",\\n          \\\"ProactiveSourcing\\\"\\n        ],\\n        \\\"applicationMethodCodes\\\": [\\\"ApplicationUri\\\"]\\n      }\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":25,\"column\":1,\"offset\":628},\"end\":{\"line\":45,\"column\":4,\"offset\":1064},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":\"label": "\\\"Query\\\"",
        "hirerId": "\\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\"",
        "scope": "\\\"query:organizations\\\"\",\"value\":\"query",
        "{\\n": true,
        "": true,
        "self": true,
        "hirer": true,
        "id": true,
        "value\\n": true,
        "}\\n": true,
        "name\\n": true,
        "seekApiCapabilities": true,
        "relationshipTypeCodes\\n": true,
        "applicationMethodCodes\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":8,\"column\":1,\"offset\":333},\"end\":{\"line\":23,\"column\":4,\"offset\":626},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label": "\\\"Result\\\"\",\"value\":\"{\\n",
        "\\\"self\\\":": true,
        "\\\"hirer\\\":": true,
        "\\\"id\\\":": true,
        "\\\"value\\\":": true,
        "\\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\"\\n": true,
        "},\\n": true,
        "\\\"name\\\":": true,
        "\\\"Acme": true,
        "Corp\\\",\\n": true,
        "\\\"seekApiCapabilities\\\":": true,
        "\\\"relationshipTypeCodes\\\":": true,
        "[\\n": true,
        "\\\"ApplicationExport\\\",\\n": true,
        "\\\"ApplicationPrefill\\\",\\n": true,
        "\\\"JobPosting\\\",\\n": true,
        "\\\"ProactiveSourcing\\\"\\n": true,
        "],\\n": true,
        "\\\"applicationMethodCodes\\\":": true,
        "[\\\"ApplicationUri\\\"]\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":25,\"column\":1,\"offset\":628},\"end\":{\"line\":45,\"column\":4,\"offset\":1064},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query {
  self {
    hirer {
      id {
        value
      }
      name
      seekApiCapabilities {
        relationshipTypeCodes
        applicationMethodCodes
      }
    }
  }
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;