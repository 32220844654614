/**
 * Vendored from react-router-dom@6.21.1 with customisation
 *
 * https://github.com/remix-run/react-router/blob/react-router-dom%406.21.1/packages/react-router-dom/index.tsx#L1288
 */

import { stripBasename } from '@remix-run/router';
import React from 'react';
import {
  type GetScrollRestorationKeyFunction,
  UNSAFE_DataRouterContext,
  UNSAFE_DataRouterStateContext,
  UNSAFE_NavigationContext,
  useLocation,
  useMatches,
  useNavigation,
} from 'react-router-dom';

const useDataRouterContext = () => {
  const ctx = React.useContext(UNSAFE_DataRouterContext);
  if (!ctx) {
    throw new Error(
      'useDataRouterContext must be used within a <DataRouter> component',
    );
  }
  return ctx;
};

const useDataRouterState = () => {
  const state = React.useContext(UNSAFE_DataRouterStateContext);
  if (!state) {
    throw new Error(
      'useDataRouterState must be used within a <DataRouter> component',
    );
  }
  return state;
};

const usePageHide = (
  callback: (event: PageTransitionEvent) => any,
  options?: { capture?: boolean },
): void => {
  const { capture } = options || {};
  React.useEffect(() => {
    const opts = capture != null ? { capture } : undefined;
    window.addEventListener('pagehide', callback, opts);
    return () => {
      window.removeEventListener('pagehide', callback, opts);
    };
  }, [callback, capture]);
};

const SCROLL_RESTORATION_STORAGE_KEY = 'react-router-scroll-positions';

let savedScrollPositions: Record<string, number> = {};

export const ScrollRestoration = ({
  getKey,
  storageKey,
}: {
  getKey?: GetScrollRestorationKeyFunction;
  storageKey?: string;
}) => {
  const { router } = useDataRouterContext();
  const { restoreScrollPosition, preventScrollReset } = useDataRouterState();

  const { basename } = React.useContext(UNSAFE_NavigationContext);
  const location = useLocation();
  const matches = useMatches();
  const navigation = useNavigation();

  // Trigger manual scroll restoration while we're active
  React.useEffect(() => {
    window.history.scrollRestoration = 'manual';
    return () => {
      window.history.scrollRestoration = 'auto';
    };
  }, []);

  // Save positions on pagehide
  usePageHide(
    React.useCallback(() => {
      if (navigation.state === 'idle') {
        const key = (getKey ? getKey(location, matches) : null) || location.key;
        savedScrollPositions[key] = window.scrollY;
      }
      try {
        sessionStorage.setItem(
          storageKey || SCROLL_RESTORATION_STORAGE_KEY,
          JSON.stringify(savedScrollPositions),
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(
          false,
          `Failed to save scroll positions in sessionStorage, <ScrollRestoration /> will not work properly (${error}).`,
        );
      }
      window.history.scrollRestoration = 'auto';
    }, [storageKey, getKey, navigation.state, location, matches]),
  );

  // Read in any saved scroll locations
  if (typeof document !== 'undefined') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useLayoutEffect(() => {
      try {
        const sessionPositions = sessionStorage.getItem(
          storageKey || SCROLL_RESTORATION_STORAGE_KEY,
        );
        if (sessionPositions) {
          savedScrollPositions = JSON.parse(sessionPositions);
        }
      } catch (e) {
        // no-op, use default empty object
      }
    }, [storageKey]);

    // Enable scroll restoration in the router
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useLayoutEffect(() => {
      const getKeyWithoutBasename: GetScrollRestorationKeyFunction | undefined =
        getKey && basename !== '/'
          ? (locationInner, matchesInner) =>
              getKey(
                // Strip the basename to match useLocation()
                {
                  ...locationInner,
                  pathname:
                    stripBasename(locationInner.pathname, basename) ||
                    locationInner.pathname,
                },
                matchesInner,
              )
          : getKey;
      const disableScrollRestoration = router?.enableScrollRestoration(
        savedScrollPositions,
        () => window.scrollY,
        getKeyWithoutBasename,
      );
      return () => disableScrollRestoration && disableScrollRestoration();
    }, [router, basename, getKey]);

    // Restore scrolling when state.restoreScrollPosition changes
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useLayoutEffect(() => {
      // Explicit false means don't do anything (used for submissions)
      if (restoreScrollPosition === false) {
        return;
      }

      // been here before, scroll to it
      if (typeof restoreScrollPosition === 'number') {
        window.scrollTo(0, restoreScrollPosition);
        return;
      }

      // try to scroll to the hash
      if (location.hash) {
        /**
         * Customisation from vendored code start
         *
         * Enables smooth scrolling to the hash target. This requires a hack of
         * deferring `.scrollIntoView()` to the next event cycle for reasons:
         *
         * https://github.com/facebook/react/issues/23396
         */

        const timeout = setTimeout(() => {
          const el = document.getElementById(
            decodeURIComponent(location.hash.slice(1)),
          );

          if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1);

        return () => clearTimeout(timeout);

        /**
         * Customisation from vendored code end
         */
      }

      // Don't reset if this navigation opted out
      if (preventScrollReset === true) {
        return;
      }

      // otherwise go to the top on new locations
      window.scrollTo(0, 0);
    }, [location, restoreScrollPosition, preventScrollReset]);
  }

  return null;
};
