import { useCallback, useState } from 'react';

type JsonSerialisable<V> =
  // eslint-disable-next-line @typescript-eslint/ban-types
  | {}
  | boolean
  | null
  | number
  | string
  | V[]
  | Record<string | number | symbol, V>;

/**
 * @see {@link https://usehooks.com/useLocalStorage/}
 */
const useStorage = <T extends JsonSerialisable<V>, V = unknown>(
  key: string,
  initialValue: T,
  storage?: Storage,
) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const rawItem = storage?.getItem(key) ?? null;

      if (rawItem !== null) {
        return JSON.parse(rawItem);
      }

      storage?.setItem(key, JSON.stringify(initialValue));

      return initialValue;
    } catch {
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((prevValue: T) => T)) => {
      setStoredValue((prevValue) => {
        const nextValue =
          typeof value === 'function' ? value(prevValue) : value;

        storage?.setItem(key, JSON.stringify(nextValue));

        return nextValue;
      });
    },
    [key, setStoredValue, storage],
  );

  return [storedValue, setValue] as const;
};

export const useLocalStorage = <T extends JsonSerialisable<V>, V = unknown>(
  key: string,
  initialValue: T,
) =>
  useStorage(
    key,
    initialValue,
    typeof window === 'undefined' ? undefined : window.localStorage,
  );

export const useSessionStorage = <T extends JsonSerialisable<V>, V = unknown>(
  key: string,
  initialValue: T,
) =>
  useStorage(
    key,
    initialValue,
    typeof window === 'undefined' ? undefined : window.sessionStorage,
  );
