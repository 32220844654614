import { Box } from 'braid-design-system';
import type { ReactNode } from 'react';

import type { Space } from 'src/types/vanilla-extract';

import * as styles from './styles.css';

interface Props {
  children: ReactNode;
  space: Space;
}

export const TwoColumnGrid = ({ children, space }: Props) => (
  <Box className={styles.twoColumnGrid[space]}>{children}</Box>
);
