import { gql } from '@apollo/client';

export const DELETE_WEBHOOK = gql`
  mutation deleteWebhook($subscriptionId: String!) {
    deleteWebhookSubscription(
      input: { webhookSubscription: { id: $subscriptionId } }
    ) {
      webhookSubscription {
        id {
          value
        }
      }
    }
  }
`;
