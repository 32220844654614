
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "profile-apply"
    }}>{`Profile Apply`}</h4>
    <p>{`When a candidate applies on SEEK through a supported channel,
they consent to share a snapshot of their SEEK Profile with the hirer.`}</p>
    <p>{`We’ve extended `}<a parentName="p" {...{
        "href": "/use-cases/application-export"
      }}>{`Application Export`}</a>{` with more structured information on the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CandidateProfile"
      }}><inlineCode parentName="a">{`CandidateProfile`}</inlineCode></a>{` object:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`employment`}</inlineCode>{` includes multiple roles from the candidate’s employment history,
along with a description of their achievements and responsibilities in each role.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`education`}</inlineCode>{` describes educational programs that the candidate has completed and is currently pursuing.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`qualifications`}</inlineCode>{` lists the self-asserted skills of the candidate.`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($id: String!) {\\n  candidateProfile(id: $id) {\\n    employment {\\n      positionHistories {\\n        descriptions\\n      }\\n    }\\n    education {\\n      educationDegrees {\\n        name\\n      }\\n    }\\n    qualifications {\\n      competencyName\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":13,\"column\":1,\"offset\":598},\"end\":{\"line\":31,\"column\":4,\"offset\":865},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"id\\\": \\\"seekAnzPublicTest:candidateProfile:apply:5G5NaFDmy7UigvGy6aT6VT\\\"\\n}\",\"position\":{\"start\":{\"line\":33,\"column\":1,\"offset\":867},\"end\":{\"line\":37,\"column\":4,\"offset\":956},\"indent\":[1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($id:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "candidateProfile(id:": true,
        "$id)": true,
        "employment": true,
        "positionHistories": true,
        "descriptions\\n": true,
        "}\\n": true,
        "education": true,
        "educationDegrees": true,
        "name\\n": true,
        "qualifications": true,
        "competencyName\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":13,\"column\":1,\"offset\":598},\"end\":{\"line\":31,\"column\":4,\"offset\":865},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"id\\\":": true,
        "\\\"seekAnzPublicTest:candidateProfile:apply:5G5NaFDmy7UigvGy6aT6VT\\\"\\n}\",\"position\":{\"start\":{\"line\":33,\"column\":1,\"offset\":867},\"end\":{\"line\":37,\"column\":4,\"offset\":956},\"indent\":[1,1,1,1]}}]": true
      }}>{`query ($id: String!) {
  candidateProfile(id: $id) {
    employment {
      positionHistories {
        descriptions
      }
    }
    education {
      educationDegrees {
        name
      }
    }
    qualifications {
      competencyName
    }
  }
}
`}</code></pre>
    <p>{`See our documentation on `}<a parentName="p" {...{
        "href": "/use-cases/application-export/candidate-profiles"
      }}>{`exporting candidate profiles`}</a>{` for more information.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;