import {
  Actions,
  Button,
  Dialog,
  Notice,
  Stack,
  Text,
} from 'braid-design-system';
import { type ComponentProps, useState } from 'react';

import { type CreateClientPayload, useApi } from 'src/api';
import { displayNameForDataType } from 'src/utils/credentials';

import { CredentialSummary } from '../Credential/CredentialSummary';
import { type FormData, FormProvider } from '../NameField/form';

import { NewCredentialForm } from './NewCredentialForm';

interface Props
  extends Pick<ComponentProps<typeof Dialog>, 'id' | 'open' | 'onClose'> {
  dataType: 'live' | 'publicTest';
  onCreate: () => void;
}

export const NewCredentialDialog = ({
  dataType,
  id,
  open,
  onClose,
  onCreate,
}: Props) => {
  const api = useApi();

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [newCredential, setNewCredential] = useState<CreateClientPayload>();

  const onExit = () => {
    setError(undefined);
    setLoading(false);
    setNewCredential(undefined);

    onClose(false);
  };

  const onSubmit = async (data: FormData) => {
    if (loading) {
      return;
    }

    setError(undefined);
    setLoading(true);

    try {
      const response = await api.credentials.create(data.name.trim(), dataType);

      setNewCredential(response);

      onCreate();
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onExit}
      id={id}
      title={`New ${displayNameForDataType(dataType)} credentials`}
    >
      {newCredential ? (
        <Stack space="large">
          <Notice tone="info">
            <Stack space="medium">
              <Text>
                Store these client credentials securely in a system such as AWS
                Secrets Manager or a password manager. Ensure you delete any
                temporary copies on unsecured devices.
              </Text>

              {dataType === 'live' && (
                <Text>
                  You won’t be able to view your client secret again after
                  closing this dialog.
                </Text>
              )}
            </Stack>
          </Notice>

          <CredentialSummary credential={newCredential} />

          <Actions>
            <Button onClick={onExit} variant="soft">
              Close
            </Button>
          </Actions>
        </Stack>
      ) : (
        <Stack space="large">
          {dataType === 'live' ? (
            <Text>
              Live credentials grant full access to the SEEK API, including the
              data of your authorised SEEK hirers and their candidates.
            </Text>
          ) : (
            <Text>
              Playground credentials grant access to the SEEK API with limited
              permissions to our read-only mock data. They are intended for
              early development and ongoing exploratory testing.
            </Text>
          )}

          <FormProvider initialValues={{ name: '' }}>
            {({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit((data) => {
                  onSubmit(data);
                })}
              >
                <NewCredentialForm
                  error={error}
                  loading={loading}
                  onExit={onExit}
                />
              </form>
            )}
          </FormProvider>
        </Stack>
      )}
    </Dialog>
  );
};
