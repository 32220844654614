import type { Private } from '@seek/indie-api-types';
import {
  Badge,
  Box,
  IconInfo,
  Inline,
  Stack,
  Text,
  TooltipRenderer,
} from 'braid-design-system';
import { InlineCode } from 'scoobie';

import { TwoColumnGrid } from 'src/components/TwoColumnGrid/TwoColumnGrid';

import { ResponseNotice } from './components/ResponseNotice';

interface ResponseDetailsProps {
  response: Private.TestCallOutput;
  request: Private.TestCallInput;
}

export const ResponseDetails = ({
  response,
  request,
}: ResponseDetailsProps) => (
  <Stack space="medium">
    <ResponseNotice
      result={response.result}
      status={response.status}
      url={request.url}
    />
    <TwoColumnGrid space="medium">
      <Text size="small" weight="strong" align="right">
        Status Code:
      </Text>
      <Inline space="small">
        <Badge
          bleedY
          tone={response.result === 'Success' ? 'positive' : 'critical'}
        >
          {response.status.toString()}
        </Badge>
      </Inline>

      <TooltipRenderer
        id="response-details-tooltip-renderer"
        tooltip={
          <Text>
            Webhook requests timeout after <i>10 seconds</i>. Ensure your
            services can respond in a timely manner for consistent success in
            webhook request delivery.{' '}
          </Text>
        }
      >
        {({ triggerProps }) => (
          <Box aria-label="Help" {...triggerProps}>
            <Text
              align="right"
              icon={<IconInfo />}
              size="small"
              weight="strong"
            >
              Latency:
            </Text>
          </Box>
        )}
      </TooltipRenderer>
      <Inline space="small">
        <Badge bleedY tone="info">{`${response.latencyMs.toString()}ms`}</Badge>
      </Inline>

      <Text size="small" weight="strong" align="right">
        Additional Details:
      </Text>
      <Text size="small">
        <InlineCode>{response.message ? response.message : 'None'}</InlineCode>
      </Text>
    </TwoColumnGrid>
  </Stack>
);
