import type { ReactNode } from 'react';

interface Props {
  condition: boolean;
  wrapper?: (childNode: ReactNode) => ReactNode;
  children: ReactNode;
}

export const ConditionalWrapper = ({ condition, wrapper, children }: Props) => (
  <>{condition && wrapper ? wrapper(children) : children}</>
);
