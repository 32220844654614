import { Box } from 'braid-design-system';

import * as styles from './styles.css';

interface Props {
  label: string;
}

export const MandatoryLabel = ({ label }: Props) => (
  <>
    {label}
    <Box className={styles.criticalColor} component="span">
      *
    </Box>
  </>
);
