import { Box, IconCopy, IconTick, Text } from 'braid-design-system';
import { type ComponentProps, useState } from 'react';

import { AutoLinkedInlineCode } from 'src/components/AutoLinkedInlineCode/AutoLinkedInlineCode';

import * as styles from './CopyableText.css';

interface CopyableTextProps
  extends Omit<ComponentProps<typeof Text>, 'children'> {
  inlineCode?: boolean;
  children: string | number;
  value?: string | number;
}

export const CopyableText = ({
  children,
  inlineCode,
  value,
  ...restProps
}: CopyableTextProps) => {
  const [copied, setCopied] = useState<boolean>(false);

  const copyText = async () => {
    if (copied) {
      return;
    }

    setCopied(true);

    const valueToCopy = String(value ?? children);

    await navigator.clipboard.writeText(valueToCopy);

    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Text {...restProps}>
      <Box
        className={styles.copyableText}
        component="span"
        paddingRight="xsmall"
      >
        {inlineCode ? (
          <AutoLinkedInlineCode>{children}</AutoLinkedInlineCode>
        ) : (
          children
        )}{' '}
        <Box
          className={!copied && styles.copyButton}
          component="span"
          cursor="pointer"
          onClick={copyText}
          title="Copy to Clipboard"
        >
          {copied ? <IconTick tone="positive" /> : <IconCopy tone="link" />}
        </Box>
      </Box>
    </Text>
  );
};
