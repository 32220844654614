
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { MinimumFieldList } from './minimum-field-list';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "step-5---populate-your-apply-form"
    }}>{`Step 5 - Populate your apply form`}</h1>
    <p>{`Populate your apply form with the supplied SEEK Profile information,
and attach the resume file where available.
Keep the following guidance in mind:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Some details may not be available or may be empty.`}</p>
        <p parentName="li">{`Your software should gracefully handle this scenario and should not display an error message to the candidate.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If a supplied value fails your form’s validation, avoid truncating or omitting the field.`}</p>
        <p parentName="li">{`Instead, show the supplied value along with suitable error messages, enabling the candidate to make necessary adjustments.`}</p>
      </li>
    </ul>
    <p>{`To reduce candidate effort when completing your apply form, you should endeavour to pre-fill as many matching fields as possible.
At a minimum,
the following fields must be pre-filled where available and relevant to the job’s application process:`}</p>

    <MinimumFieldList mdxType="MinimumFieldList" />
    <p>{`SEEK Profile information available through Apply with SEEK is only suitable for pre-populating apply forms.
The candidate must be able to review and edit pre-filled form fields before submitting the apply form.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;