
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "job-posting"
    }}>{`Job Posting`}</h1>
    <p>{`This use case provides technical implementation details for the SEEK Job Posting product.
You can find more information and request an integration on its `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au/partners/integrations/seek-job-posting/"
      }}>{`product page`}</a>{`.`}</p>
    <p>{`The SEEK API can be used to post job ads on the SEEK job board.
Job ads are explicitly created via GraphQL mutations;
SEEK does not support passively importing jobs from a JSON or XML feed.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;