
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "updates-for-graphql-over-http-compatibility"
    }}>{`Updates for GraphQL over HTTP compatibility`}</h4>
    <p>{`On the 23rd March 2024, we will be making changes to the SEEK API to ensure compliance with the `}<a parentName="p" {...{
        "href": "https://graphql.github.io/graphql-over-http/draft/"
      }}>{`GraphQL over HTTP specification`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Previously, response bodies contained an `}<inlineCode parentName="p">{`Content-Type: application/json`}</inlineCode>{` header.
This will be changed to `}<inlineCode parentName="p">{`Content-Type: application/json; charset=utf-8`}</inlineCode>{`, as `}<a parentName="p" {...{
            "href": "https://graphql.github.io/graphql-over-http/draft/#sec-Body"
          }}>{`recommended`}</a>{` by the specification.
The encoding of the body is not changing, and will continue to be UTF-8.
We advise your software not to take hard dependencies on the exact format of the `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` response header, as it may change in the future.
For example, `}<inlineCode parentName="p">{`application/graphql-response+json; charset=utf-8`}</inlineCode>{` may be used in the future.
We advise you to check that your software is resilient to changes in the `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` response header before the 23rd March 2024.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Previously, the SEEK API accepted non-standard request bodies containing `}<inlineCode parentName="p">{`variables`}</inlineCode>{` as a JSON-stringified object.
This may look like `}<inlineCode parentName="p">{`{"query": "...", "variables": "{...}"}`}</inlineCode>{`.
This support will be removed, as it is not supported by `}<a parentName="p" {...{
            "href": "https://graphql.github.io/graphql-over-http/draft/#sec-POST"
          }}>{`the specification`}</a>{`.
We require your software to use the standard request body format.
This may look like `}<inlineCode parentName="p">{`{"query": "...", "variables": {...}}`}</inlineCode>{`.
We advise you to check that your software is not using this non-standard request format before the 23rd March 2024.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;