import { intlFormat, isSameDay } from 'date-fns';

interface FormattedComponents {
  from: string;
  to: string;
}

// This is intended for writing locale-independent tests
interface FormatOptions {
  locale?: string;
  timeZone?: string;
}

/**
 * Formats the date range of scheduled maintenance
 *
 * This returns the formatted `from` & `to` separately to allow them to be
 * styled or incorporated in to different layouts.
 */
export const formatDateRange = (
  fromDate: Date,
  toDate: Date,
  { locale, timeZone }: FormatOptions = {},
): FormattedComponents => {
  // Format options for including the date
  const dateOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone,
  } as const;

  // Format options for including the time
  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
  } as const;

  // Format options for including the timezone
  const timeZoneOptions = {
    timeZoneName: 'short',
  } as const;

  const localeOptions = { locale: locale ?? [] };

  // Always include the date & time for the `from` date
  const from = intlFormat(
    fromDate,
    { ...dateOptions, ...timeOptions },
    localeOptions,
  );

  // Always include the time & timezone for the `to` date
  const to = intlFormat(
    toDate,
    // But only include the date if we span across days
    // Note this doesn't work properly if `timeZone` is set.
    isSameDay(fromDate, toDate)
      ? { ...timeOptions, ...timeZoneOptions }
      : { ...dateOptions, ...timeOptions, ...timeZoneOptions },
    localeOptions,
  );

  return { from, to };
};
