import {
  Box,
  Inline,
  Link,
  Stack,
  Text,
  TextLinkButton,
} from 'braid-design-system';
import type { ComponentProps, ReactNode } from 'react';

import * as styles from './TileLink.css';

type Align = 'center' | 'left' | 'right';

const Overlay = ({
  className,
}: Pick<ComponentProps<typeof Box>, 'className'>) => (
  <Box
    borderRadius="standard"
    className={className}
    component="span"
    inset={0}
    opacity={0}
    pointerEvents="none"
    position="absolute"
    transition="fast"
  />
);

export const TileLink = ({
  align = ['left', 'left'],
  description,
  href,
  icon,
  reverse,
  title,
  badge,
}: {
  align?: [Align, Align];
  description?: ReactNode;
  href?: string;
  icon: ComponentProps<typeof Text>['icon'];
  reverse?: boolean;
  title: string;
  badge?: ComponentProps<typeof Stack>['children'];
}) => {
  const active = Boolean(href);

  const inner = (
    <Box
      borderRadius="standard"
      boxShadow="borderNeutralLight"
      className={active ? styles.tile : undefined}
      height="full"
      paddingX="gutter"
      paddingY="medium"
      position="relative"
    >
      {active ? <Overlay className={styles.hoverOverlay} /> : null}
      {active ? <Overlay className={styles.activeOverlay} /> : null}

      <Box position="relative">
        <Stack align={align} space="medium">
          <Box
            className={styles.iconGap}
            display="flex"
            flexDirection={reverse ? 'rowReverse' : 'row'}
          >
            <Text>{icon}</Text>

            <Inline space="xxsmall">
              <Text
                weight={active ? 'strong' : 'regular'}
                tone={active ? 'neutral' : 'secondary'}
              >
                {active && description ? (
                  /* For underline styles - sorry! */
                  <TextLinkButton onClick={() => undefined}>
                    {title}
                  </TextLinkButton>
                ) : (
                  title
                )}
              </Text>
              {badge ?? null}
            </Inline>
          </Box>

          {description ? (
            <Text size="small" tone={active ? 'neutral' : 'secondary'}>
              {description}
            </Text>
          ) : null}
        </Stack>
      </Box>
    </Box>
  );

  return href ? (
    <Link className={styles.tileLink} href={href} role="button">
      {inner}
    </Link>
  ) : (
    inner
  );
};
