
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { UxFiles } from './assets/UxFiles';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "candidate-profile-user-experience-guide"
    }}>{`Candidate profile user experience guide`}</h1>
    <p>{`The files below contain an example rendering of a candidate’s profile based on SEEK’s recommended best practices.
Its design has been based on SEEK’s own profile page, which displays as an overlay drawer.`}</p>
    <p>{`The Figma file contains a componentised example based on SEEK’s `}<a parentName="p" {...{
        "href": "https://seek-oss.github.io/braid-design-system/"
      }}>{`Braid Design System`}</a>{`.
You should tailor the design of the components to better suit your software.`}</p>
    <p>{`The PDF file contains a pre-rendered version of the Figma file that doesn’t require any special software to view.`}</p>

    <UxFiles mdxType="UxFiles" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;