import {
  Actions,
  Button,
  Loader,
  Notice,
  Stack,
  Text,
  Toggle,
  useToast,
} from 'braid-design-system';
import { useState } from 'react';
import { CopyableText } from 'scoobie';

import { DialogWithMessage } from 'src/components/DialogWithMessage/DialogWithMessage';
import { TwoColumnGrid } from 'src/components/TwoColumnGrid/TwoColumnGrid';

import { useNotiConfig } from '../../../../ConfigContext';
import { ConfigCard } from '../ConfigCard/ConfigCard';

interface ContentProps {
  showFeedUrls: boolean;
}

const Content = ({ showFeedUrls }: ContentProps) => {
  const { notiConfig, update, loading, rotateFeedSecrets } = useNotiConfig();
  const [rotateLoading, setRotateLoading] = useState(false);
  const [showDisableDialog, setShowDisableDialog] = useState(false);
  const [showRegenerateDialog, setShowRegenerateDialog] = useState(false);
  const showToast = useToast();

  const disableFeedSecrets = async () => {
    const err = await update({
      ...notiConfig,
      enableSyndicationFeeds: false,
    });

    if (err) {
      return showToast({
        tone: 'critical',
        message: 'Unable to disable feeds',
        description: String(err),
      });
    }

    setShowDisableDialog(false);
  };

  const enableFeedSecrets = async () => {
    const err = await update({
      ...notiConfig,
      enableSyndicationFeeds: true,
    });

    if (err) {
      showToast({
        tone: 'critical',
        message: 'Unable to enable feeds',
        description: String(err),
      });
    }
  };

  const rotateSecret = async () => {
    setRotateLoading(true);
    await rotateFeedSecrets();
    setRotateLoading(false);

    setShowRegenerateDialog(false);
  };

  if (loading) {
    return <Loader />;
  }

  const [feedSecret] = notiConfig.feedUrlSecret;

  if (!feedSecret) {
    return (
      <Stack space="large">
        <Text>Syndication feeds are disabled.</Text>
        <Actions>
          <Button onClick={enableFeedSecrets} variant="soft">
            Enable feeds
          </Button>
        </Actions>
      </Stack>
    );
  }

  const feedUrl = `https://developer.seek.com/feed/${feedSecret}`;

  const feedLabel = showFeedUrls
    ? feedUrl
    : // Show just the first few characters of the secret to make it more
      // obvious when regeneration has been completed. This should be safe as
      // revealing 3 characters won't meaningfully detract from the entropy of
      // the secret overall (44 characters).
      `https://developer.seek.com/feed/${feedSecret.slice(0, 3)}${'*'.repeat(
        feedSecret.slice(3).length,
      )}`;

  return (
    <>
      <Stack space="large">
        <TwoColumnGrid space="medium">
          <Text weight="medium">RSS URL</Text>
          <CopyableText
            copiedLabel={`${feedLabel}.rss`}
            copyLabel={`${feedLabel}.rss`}
            size="small"
          >{`${feedUrl}.rss`}</CopyableText>

          <Text weight="medium">Atom URL</Text>
          <CopyableText
            copiedLabel={`${feedLabel}.atom`}
            copyLabel={`${feedLabel}.atom`}
            size="small"
          >{`${feedUrl}.atom`}</CopyableText>
        </TwoColumnGrid>

        <Actions>
          <Button onClick={() => setShowRegenerateDialog(true)} variant="soft">
            Regenerate feeds
          </Button>
          <Button
            onClick={() => setShowDisableDialog(true)}
            variant="transparent"
          >
            Disable feeds
          </Button>
        </Actions>
      </Stack>

      <DialogWithMessage
        title="Regenerate feeds"
        id="regenerate-feeds-dialog"
        message={
          <>
            <Text>
              Are you sure you want to regenerate your syndication feeds?
            </Text>

            <Text>
              This will invalidate your existing feed URLs, which may be useful
              if you have unintentionally disclosed them. You will have to
              update any feed integrations with the newly generated URLs.
            </Text>

            <Notice tone="info">
              <Text>It can take up to 5 minutes to regenerate your feed.</Text>
            </Notice>
          </>
        }
        action="Regenerate"
        actionLoading={rotateLoading}
        showDialog={showRegenerateDialog}
        onCancel={() => setShowRegenerateDialog(false)}
        onConfirm={rotateSecret}
      />

      <DialogWithMessage
        title="Disable feeds"
        id="disable-feeds-dialog"
        message={
          <>
            <Text>
              Are you sure you want to disable your syndication feeds?
            </Text>

            <Text>
              This will invalidate your existing feed URLs. You can re-enable
              your feeds at any point, but you will have to update any feed
              integrations with newly generated URLs.
            </Text>
          </>
        }
        action="Disable"
        showDialog={showDisableDialog}
        onCancel={() => setShowDisableDialog(false)}
        onConfirm={disableFeedSecrets}
      />
    </>
  );
};

export const FeedConfig = () => {
  const [showFeedUrls, setShowFeedUrls] = useState(false);

  return (
    <ConfigCard
      headerAside={
        <Toggle
          align="justify"
          id="show-feed-urls-toggle"
          label="Show feed URLs"
          size="small"
          on={showFeedUrls}
          onChange={() => setShowFeedUrls((reveal) => !reveal)}
        />
      }
      title="RSS/Atom"
    >
      <Content showFeedUrls={showFeedUrls} />
    </ConfigCard>
  );
};
