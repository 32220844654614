import { type Stack, useColor, useSpace } from 'braid-design-system';
import type { ComponentProps } from 'react';
import { ArcherContainer } from 'react-archer';

type Props = Pick<ComponentProps<typeof Stack>, 'children'>;

export const Flowchart = ({ children }: Props) => {
  const { foreground } = useColor();
  const { grid, space } = useSpace();

  return (
    <ArcherContainer
      endShape={{
        arrow: {
          arrowLength: grid * space.xxsmall,
          arrowThickness: grid * space.xxsmall,
        },
      }}
      strokeColor={foreground.secondary}
    >
      {children}
    </ArcherContainer>
  );
};
