
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "test-cases"
    }}>{`Test cases`}</h1>
    <p>{`The test cases listed below should give you an idea of our expectations of your software integration.`}</p>
    <p>{`Ideally your integration will have undergone rigorous internal testing prior to validation with SEEK.
You must be able to demonstrate the applicable test cases to show that your integration has been built correctly and in a manner that adds value to SEEK hirers.`}</p>
    <h2 {...{
      "id": "auth"
    }}>{`Auth`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/auth/hirer-relationships"
          }}>{`Hirer relationships`}</a></p>
        <p parentName="li">{`Demonstrate the ability to on-board a SEEK hirer to your software using a SEEK hirer ID.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "job-posting"
    }}>{`Job Posting`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/locations"
          }}>{`Locations`}</a></p>
        <p parentName="li">{`Demonstrate the ability to select a location from one of:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "/use-cases/job-posting/locations#option-1-build-a-seek-specific-location-input"
              }}>{`A SEEK-specific location autosuggest field`}</a></p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Your own location structure, `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/locations#option-2-map-from-your-internal-location-hierarchy"
              }}>{`automatically mapped to a SEEK location`}</a></p>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/job-categories"
          }}>{`Job categories`}</a></p>
        <p parentName="li">{`Demonstrate the ability to select a job category from one of:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`A list of `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/job-categories#jobcategorysuggestions"
              }}>{`suggested job categories`}</a>{` based on the position’s title, location & description.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`A two-level dropdown of `}<a parentName="p" {...{
                "href": "/use-cases/job-posting/job-categories#jobcategories"
              }}>{`all available job categories`}</a>{`.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/ad-selection"
          }}>{`Ad selection`}</a></p>
        <p parentName="li">{`Demonstrate the ability for a hirer to select from all SEEK ad types in your job posting flow (Classic, StandOut, Premium).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/branding"
          }}>{`Branding`}</a></p>
        <p parentName="li">{`Demonstrate the ability to select from all available brands for a hirer when posting a StandOut or Premium Ad.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad"
          }}>{`Posting a job ad`}</a></p>
        <p parentName="li">{`Demonstrate the ability to post a job ad with all optional and required fields.
The hirer should be able to advertise a job on SEEK for the maximum period of 30 days.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/updating-a-job-ad"
          }}>{`Updating a live job ad`}</a></p>
        <p parentName="li">{`Demonstrate the ability to update a live job ad (e.g. change the content of any optional or required fields).
The hirer should be prevented from changing the job ad’s `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/application-methods"
          }}>{`application method`}</a>{` or `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/questionnaires"
          }}>{`questionnaire`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/closing-a-job-ad"
          }}>{`Closing a live job ad`}</a></p>
        <p parentName="li">{`Demonstrate the ability to close a live job ad and have it successfully removed from the SEEK job board.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/questionnaires"
          }}>{`Questionnaires`}</a>{` (if applicable)`}</p>
        <p parentName="li">{`Demonstrate the ability to create screening questions with each supported question type and include them when posting a job.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirmation and error messages`}</p>
        <p parentName="li">{`Display a confirmation page when a job ad has been successfully posted or updated with a link to the live job ad on SEEK (`}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/PositionProfile/field/positionUri"
          }}><inlineCode parentName="a">{`positionUri`}</inlineCode></a>{`).`}</p>
        <p parentName="li">{`Display clear error messaging when a job ad has not been successfully posted, updated or closed.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "optimised-apply"
    }}>{`Optimised Apply`}</h2>
    <p>{`Demonstrate candidate applications listed against a position opening.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/application-export/candidate-profiles"
          }}>{`Candidate profiles`}</a></p>
        <p parentName="li">{`Display a candidate’s employment history, education, licences & certifications, and competencies alongside their application data.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/application-export/attachments"
          }}>{`Attachments`}</a></p>
        <p parentName="li">{`Demonstrate the ability to open a candidate’s resume, cover letter and selection criteria documents attached to an application.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/application-export/questionnaire-submissions"
          }}>{`Questionnaire submissions`}</a>{` (if applicable)`}</p>
        <p parentName="li">{`Display the candidate’s screening question submission alongside their application data.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "talent-search-connect"
    }}>{`Talent Search Connect`}</h2>
    <p>{`Demonstrate the ability to backfill a hirer’s existing candidate, positions & process history rate limited to a SEEK-provided value.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/proactive-sourcing/widgets"
          }}>{`Widgets`}</a></p>
        <p parentName="li">{`Demonstrate that all widgets are embedded in easily discoverable and appropriate locations in your software’s candidate management workflow.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/proactive-sourcing/sharing-a-candidate"
          }}>{`Sharing candidates`}</a></p>
        <p parentName="li">{`Demonstrate the ability to create, update or remove candidate profile information within 60 seconds of a change being made to a candidate in your software.`}</p>
        <p parentName="li">{`Demonstrate the ability to create, update or remove interaction history records within 60 seconds of a change being made to a candidate’s interactions in your software.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/proactive-sourcing/unposted-positions"
          }}>{`Unposted positions`}</a></p>
        <p parentName="li">{`Demonstrate the ability to create or update an unposted position within a position opening with all optional and required fields, within 60 seconds of a position being created or updated in your software.`}</p>
        <p parentName="li">{`Demonstrate the ability to delete an unposted position once a position in your software is no longer being recruited for.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/proactive-sourcing/profile-purchases"
          }}>{`Exporting purchased profiles`}</a></p>
        <p parentName="li">{`Demonstrate the ability to display the candidate profile and resume document to the hirer in the selected position, within 60 seconds of receiving a purchase event notification.`}</p>
      </li>
    </ol>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;