import { Actions, ButtonLink, IconDownload } from 'braid-design-system';

import figma from './job-posting-ui.fig';
import pdf from './job-posting-ui.pdf';

export const UxFiles = () => (
  <Actions>
    <ButtonLink
      download="job-posting-ui-v4.fig"
      href={figma}
      icon={<IconDownload />}
      variant="soft"
    >
      Figma file
    </ButtonLink>

    <ButtonLink
      download="job-posting-ui-v4.pdf"
      href={pdf}
      icon={<IconDownload />}
      variant="soft"
    >
      PDF file
    </ButtonLink>
  </Actions>
);
