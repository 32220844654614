import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  type LiveClientPayload,
  type PublicTestClientPayload,
  useApi,
} from 'src/api';

interface ActionOptions {
  showLoading: boolean;
}

interface CredentialsContext {
  liveCredentials: LiveClientPayload[];
  publicTestCredentials: PublicTestClientPayload[];
  loading: boolean;
  refresh: (opts: ActionOptions) => Promise<void>;
  error: string | undefined;
}

const CredentialsContext = createContext<CredentialsContext>({
  liveCredentials: [],
  publicTestCredentials: [],
  loading: true,
  error: undefined,
  refresh: async () => {},
});

export const useCredentials = () => {
  const context = useContext(CredentialsContext);

  if (!context) {
    throw new Error('useCredentials must be used within a CredentialsProvider');
  }

  return context;
};

export const CredentialsProvider = ({ children }: { children: ReactNode }) => {
  const api = useApi();
  const [liveCredentials, setLiveCredentials] = useState<LiveClientPayload[]>(
    [],
  );
  const [publicTestCredentials, setPublicTestCredentials] = useState<
    PublicTestClientPayload[]
  >([]);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);

  const refresh = async ({ showLoading }: ActionOptions) => {
    if (showLoading) {
      setLoading(true);
    }

    try {
      const clients = await api.credentials.list();
      const liveClients = clients.filter(
        (client): client is LiveClientPayload => client.type === 'live',
      );
      const publicTestClients = clients.filter(
        (client): client is PublicTestClientPayload =>
          client.type === 'publicTest',
      );
      setLiveCredentials(liveClients);
      setPublicTestCredentials(publicTestClients);
      setError(undefined);
    } catch (err) {
      setError(String(err));
    }

    if (showLoading) {
      setLoading(false);
    }
  };

  const value = useMemo(
    () => ({ liveCredentials, publicTestCredentials, error, refresh, loading }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [liveCredentials, publicTestCredentials, loading, error],
  );

  // Load on initial render
  useEffect(() => {
    refresh({ showLoading: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CredentialsContext.Provider value={value}>
      {children}
    </CredentialsContext.Provider>
  );
};
