import { useQuery } from '@apollo/client';
import {
  Heading,
  Loader,
  Notice,
  Stack,
  Text,
  TextField,
} from 'braid-design-system';
import { useState } from 'react';
import { SmartTextLink } from 'scoobie';
import { useDebounce } from 'use-debounce';
import { QuestionnaireForm } from 'wingman-fe';

import type {
  ApplicationQuestionnaireQuery,
  ApplicationQuestionnaireQueryVariables,
} from 'src/types/graphql';

import { APPLICATION_QUESTIONNAIRE } from './queries';

interface QuestionnaireLookupProps {
  id: string;
}

const QuestionnaireLookup = ({ id }: QuestionnaireLookupProps) => {
  const { data, error, loading } = useQuery<
    ApplicationQuestionnaireQuery,
    ApplicationQuestionnaireQueryVariables
  >(APPLICATION_QUESTIONNAIRE, {
    variables: {
      id,
    },
  });

  if (data?.applicationQuestionnaire) {
    return (
      <QuestionnaireForm
        components={data.applicationQuestionnaire.components}
        type="ApplicationQuestionnaire"
      />
    );
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Notice tone={error ? 'critical' : 'info'}>
      <Text>{error?.message ?? 'Hmm, we can’t find that questionnaire.'}</Text>
    </Notice>
  );
};

export const QuestionnaireLookupSection = () => {
  const [oid, setOid] = useState('');

  const [debouncedOid] = useDebounce(oid.trim(), 500);

  return (
    <Stack space="large">
      <Heading id="seek-questionnaire-lookup-heading" level="4">
        <SmartTextLink href="https://developer.seek.com/use-cases/job-posting/questionnaires">
          Questionnaire lookup
        </SmartTextLink>
      </Heading>

      <TextField
        aria-label="Questionnaire ID"
        id="seek-questionnaire-lookup-id-input"
        onChange={(event) => setOid(event.currentTarget.value)}
        value={oid}
      />

      {debouncedOid ? <QuestionnaireLookup id={debouncedOid} /> : null}
    </Stack>
  );
};
