import {
  Disclosure,
  IconClear,
  Stack,
  Tag,
  Text,
  TextLinkButton,
} from 'braid-design-system';
import { useState } from 'react';

import {
  EMPTY_SUGGESTION,
  HirerAutosuggest,
  type HirerAutosuggestValue,
} from 'src/components/HirerAutosuggest/HirerAutosuggest';
import type { HiringOrganizationIdFieldsFragment } from 'src/types/graphql';

export const useHirerState = (hirerScopedIndicator: boolean | null = null) => {
  const [hirers, setHirers] = useState<HiringOrganizationIdFieldsFragment[]>();

  const [suggestion, setSuggestion] =
    useState<HirerAutosuggestValue>(EMPTY_SUGGESTION);

  return {
    hirerScopedIndicator,
    hirers: hirerScopedIndicator === false ? undefined : hirers,
    setHirers,
    setSuggestion,
    suggestion,
  };
};

const hirersExpandLabel = (hirers: unknown[] | undefined) => {
  if (!hirers?.length) {
    return 'All hirers';
  }

  if (hirers.length === 1) {
    return '1 hirer';
  }

  return `${hirers.length} hirers`;
};

interface Props extends ReturnType<typeof useHirerState> {
  idPrefix: string;
}

export const HirerPicker = ({
  hirers,
  idPrefix,
  setHirers,
  setSuggestion,
  suggestion,
  hirerScopedIndicator,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  if (hirerScopedIndicator === false) {
    return <Text tone="secondary">All hirers</Text>;
  }

  return (
    <Disclosure
      id={`${idPrefix}HirerPickerDisclosure`}
      expandLabel={hirersExpandLabel(hirers)}
      expanded={expanded}
      onToggle={setExpanded}
      space="gutter"
    >
      <Stack space="gutter">
        {hirers?.length ? (
          <Stack space="small">
            {hirers.map((hirer) => (
              <Tag
                clearLabel="Remove"
                key={hirer.id.value}
                onClear={() =>
                  setHirers((existing) => {
                    const filtered = existing?.filter(
                      (e) => e.id.value !== hirer.id.value,
                    );

                    return filtered?.length ? filtered : undefined;
                  })
                }
              >
                {`${hirer.name} (${hirer.seekAnzAdvertiserId})`}
              </Tag>
            ))}

            <Text>
              <TextLinkButton
                icon={<IconClear />}
                onClick={() => setHirers(undefined)}
              >
                Clear all
              </TextLinkButton>
            </Text>
          </Stack>
        ) : null}

        <HirerAutosuggest
          id={`${idPrefix}HirerPickerAutosuggest`}
          onChange={(selection) => {
            const selectedHirer = selection.value;

            if (selectedHirer) {
              setHirers((existing) =>
                (existing ?? [])
                  .filter((hirer) => hirer.id.value !== selectedHirer.id.value)
                  .concat(selectedHirer),
              );

              setSuggestion(EMPTY_SUGGESTION);
            } else {
              setSuggestion(selection);
            }
          }}
          onClear={() => setSuggestion(EMPTY_SUGGESTION)}
          value={suggestion}
        />
      </Stack>
    </Disclosure>
  );
};
