import { useEffect, useRef } from 'react';
import { useScript } from 'usehooks-ts';

import { useEnvironmentConfig } from 'src/hooks/environment';

import type { QuestionnairePanelProps } from './types';

export const QuestionnairePanelLoader = ({
  props,
}: {
  props: QuestionnairePanelProps;
}) => {
  const {
    baseUrls: { panels },
  } = useEnvironmentConfig();
  const status = useScript(`${panels}/SeekApi.js`);
  const node = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (window.SeekApi && node?.current) {
      window.SeekApi.render(node.current, 'questionnaire', props);
    }
  }, [status, props]);

  if (
    status === 'error' ||
    (status === 'ready' && typeof window.SeekApi === 'undefined')
  ) {
    return null;
  }

  return <div ref={node} />;
};
