import type { Page } from './page';

export const site: Page[] = [
  {
    children: [
      {
        label: 'Home',
        path: '/',
        type: 'standalone',
        icon: 'Home',
      },
      {
        label: 'Dashboard',
        path: 'https://manage.developer.seek.com',
        icon: 'DeveloperDashboard',
        mobileOnly: true,
      },
      {
        label: 'Schema',
        path: 'https://developer.seek.com/schema',
        icon: 'Schema',
        mobileOnly: true,
      },
      {
        label: 'Status page',
        path: 'https://status.seek.com',
        icon: 'StatusPage',
        mobileOnly: true,
      },
    ],
  },
  {
    label: 'Getting started',
    children: [
      {
        label: 'Introduction',
        path: '/introduction',
        type: 'reviewed',
        icon: 'Introduction',
      },
      {
        label: 'Auth',
        path: '/auth',
        type: 'reviewed',
        icon: 'Auth',
        children: [
          {
            label: 'Partner tokens',
            path: '/auth/partner-tokens',
            type: 'reviewed',
          },
          {
            label: 'Browser tokens',
            path: '/auth/browser-tokens',
            type: 'reviewed',
          },
          {
            label: 'Hirer relationships',
            path: '/auth/hirer-relationships',
            type: 'reviewed',
          },
        ],
      },
      {
        label: 'GraphQL',
        path: '/graphql',
        type: 'reviewed',
        icon: 'GraphQL',
        children: [
          {
            label: 'Playground',
            path: '/graphql/playground',
            type: 'reviewed',
            hiddenKeywords: ['sandbox'],
          },
          {
            label: 'Mock objects',
            path: '/graphql/playground/mock-objects',
            type: 'reviewed',
          },
          {
            label: 'In practice',
            path: '/graphql/in-practice',
            type: 'reviewed',
          },
          {
            label: 'SEEK API conventions',
            path: '/graphql/seek-api-conventions',
            type: 'reviewed',
          },
          {
            label: 'Error responses',
            path: '/graphql/error-responses',
            type: 'reviewed',
          },
        ],
      },
      {
        label: 'Web components',
        path: '/introduction/web-components',
        type: 'reviewed',
        icon: 'Panel',
        children: [
          {
            label: 'Panels',
            path: '/introduction/panels',
            type: 'reviewed',
          },
          {
            label: 'Browser support',
            path: '/introduction/browser-support',
            type: 'reviewed',
          },
        ],
      },
      {
        label: 'Events',
        path: '/events',
        type: 'reviewed',
        icon: 'Webhook',
        children: [
          {
            label: 'Webhooks',
            path: '/events/webhooks',
            type: 'reviewed',
          },
          {
            label: 'Polling',
            path: '/events/polling',
            type: 'reviewed',
          },
        ],
      },
      {
        label: 'Release notes',
        path: '/introduction/release-notes',
        type: 'standalone',
        icon: 'ReleaseNotes',
      },
    ],
  },
  {
    label: 'Use cases',
    children: [
      {
        label: 'Job Posting',
        path: '/use-cases/job-posting',
        type: 'reviewed',
        icon: 'JobPosting',
        children: [
          {
            label: 'Product overview',
            path: '/use-cases/job-posting/user-experience-guide',
            type: 'reviewed',
            hiddenKeywords: ['user experience guide', 'UX guide'],
          },
          {
            label: 'Job posting features',
            path: '/use-cases/job-posting/features',
            type: 'reviewed',
            children: [
              {
                label: 'Position openings',
                path: '/use-cases/job-posting/position-openings',
                type: 'reviewed',
              },
              {
                label: 'Contact people',
                path: '/use-cases/job-posting/contact-people',
                type: 'reviewed',
              },
              {
                label: 'Identifiers & references',
                path: '/use-cases/job-posting/identifiers-and-references',
                type: 'reviewed',
              },
              {
                label: 'Locations',
                path: '/use-cases/job-posting/locations',
                type: 'reviewed',
              },
              {
                label: 'Job categories',
                path: '/use-cases/job-posting/job-categories',
                type: 'reviewed',
                hiddenKeywords: ['classification', 'subclassification'],
              },
              {
                label: 'Salary',
                path: '/use-cases/job-posting/salary',
                type: 'reviewed',
              },
              {
                label: 'Ad selection',
                path: '/use-cases/job-posting/ad-selection',
                type: 'reviewed',
                children: [
                  {
                    label: 'Option 1: Panel',
                    path: '/use-cases/job-posting/ad-selection/v2/panel',
                    type: 'reviewed',
                  },
                  {
                    label: 'Option 2: GraphQL',
                    path: '/use-cases/job-posting/ad-selection/v2/graphql',
                    type: 'reviewed',
                  },
                  {
                    label: 'Features',
                    path: '/use-cases/job-posting/ad-selection/v2/features',
                    type: 'reviewed',
                  },
                  {
                    label: 'Testing',
                    path: '/use-cases/job-posting/ad-selection/v2/testing',
                    type: 'reviewed',
                  },
                ],
              },
              {
                label: 'Branding',
                path: '/use-cases/job-posting/branding',
                type: 'reviewed',
              },
              {
                label: 'Application methods',
                path: '/use-cases/job-posting/application-methods',
                type: 'reviewed',
                hiddenKeywords: ['linkout'],
              },
              {
                label: 'Questionnaires',
                path: '/use-cases/job-posting/questionnaires',
                type: 'reviewed',
                hiddenKeywords: ['Role Requirements', 'screen'],
                children: [
                  {
                    label: 'Panel',
                    path: '/use-cases/job-posting/questionnaires/v2/panel',
                    type: 'reviewed',
                  },
                  {
                    path: '/use-cases/job-posting/questionnaires/custom-panel',
                    type: 'unstable',
                  },
                  {
                    label: 'ATS Screening Questions',
                    path: '/use-cases/job-posting/questionnaires/ats-questions',
                    type: 'reviewed',
                  },
                ],
              },
            ],
          },
          {
            label: 'Managing job ads',
            path: '/use-cases/job-posting/managing-job-ads',
            type: 'reviewed',
            children: [
              {
                label: 'Posting a job ad',
                path: '/use-cases/job-posting/managing-job-ads/posting-a-job-ad',
                type: 'reviewed',
              },
              {
                label: 'Previewing a job ad',
                path: '/use-cases/job-posting/managing-job-ads/previewing-a-job-ad',
                type: 'reviewed',
              },
              {
                label: 'Querying a job ad',
                path: '/use-cases/job-posting/managing-job-ads/querying-a-job-ad',
                type: 'reviewed',
              },
              {
                label: 'Updating a job ad',
                path: '/use-cases/job-posting/managing-job-ads/updating-a-job-ad',
                type: 'reviewed',
              },
              {
                label: 'Closing a job ad',
                path: '/use-cases/job-posting/managing-job-ads/closing-a-job-ad',
                type: 'reviewed',
                hiddenKeywords: ['expire', 'expiry'],
              },
            ],
          },
          {
            label: 'Reflecting job ads',
            path: '/use-cases/job-posting/reflecting-job-ads',
            type: 'reviewed',
            hiddenKeywords: ['mirror', 'mirroring'],
          },
          {
            label: 'Archived',
            path: '/use-cases/job-posting/archived',
            type: 'reviewed',
            children: [
              {
                label: 'Ad selection (V1)',
                path: '/use-cases/job-posting/archived/ad-selection',
                replacement: '/use-cases/job-posting/ad-selection',
                type: 'archived',
                children: [
                  {
                    label: 'Panel',
                    path: '/use-cases/job-posting/ad-selection/panel',
                    replacement: '/use-cases/job-posting/ad-selection/v2/panel',
                    type: 'archived',
                  },
                  {
                    label: 'GraphQL',
                    path: '/use-cases/job-posting/ad-selection/graphql',
                    replacement:
                      '/use-cases/job-posting/ad-selection/v2/graphql',
                    type: 'archived',
                  },
                ],
              },
              {
                label: 'Questionnaires (V1)',
                path: '/use-cases/job-posting/archived/questionnaires',
                replacement: '/use-cases/job-posting/questionnaires',
                type: 'archived',
                children: [
                  {
                    label: 'Panel',
                    path: '/use-cases/job-posting/questionnaires/panel',
                    replacement:
                      '/use-cases/job-posting/questionnaires/v2/panel',
                    type: 'archived',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: 'Optimised Apply',
        path: '/use-cases/application-export',
        type: 'reviewed',
        icon: 'OptimisedApply',
        children: [
          {
            label: 'Candidate profiles',
            path: '/use-cases/application-export/candidate-profiles',
            type: 'reviewed',
          },
          {
            label: 'Attachments',
            path: '/use-cases/application-export/attachments',
            type: 'reviewed',
          },
          {
            label: 'Questionnaire submissions',
            path: '/use-cases/application-export/questionnaire-submissions',
            type: 'reviewed',
          },
          {
            label: 'User experience guide',
            path: '/use-cases/application-export/user-experience-guide',
            type: 'reviewed',
            hiddenKeywords: ['UX guide'],
          },
        ],
      },
      {
        label: 'Talent Search Connect',
        path: '/use-cases/proactive-sourcing',
        type: 'reviewed',
        icon: 'TalentSearchConnect',
        children: [
          {
            label: 'Widgets',
            path: '/use-cases/proactive-sourcing/widgets',
            type: 'reviewed',
          },
          {
            label: 'Sharing a candidate',
            path: '/use-cases/proactive-sourcing/sharing-a-candidate',
            type: 'reviewed',
          },
          {
            label: 'Interaction history',
            path: '/use-cases/proactive-sourcing/interaction-history',
            type: 'reviewed',
          },
          {
            label: 'Unposted positions',
            path: '/use-cases/proactive-sourcing/unposted-positions',
            type: 'reviewed',
          },
          {
            label: 'Profile purchases',
            path: '/use-cases/proactive-sourcing/profile-purchases',
            type: 'reviewed',
          },
        ],
      },
      {
        label: 'Ad Performance',
        path: '/use-cases/ad-performance',
        icon: 'AdPerformance',
        type: 'unstable',
        children: [
          {
            label: 'Panel',
            path: '/use-cases/ad-performance/panel',
            type: 'unstable',
          },
          {
            label: 'Completion Signal',
            path: '/use-cases/ad-performance/completion-signal',
            type: 'unstable',
          },
        ],
      },
      {
        path: '/use-cases/apply-with-seek',
        icon: 'ApplyWithSeek',
        type: 'unstable',
        children: [
          {
            label: '1. Display button',
            path: '/use-cases/apply-with-seek/display-button',
            type: 'unstable',
          },
          {
            label: '2. Handle authorization',
            path: '/use-cases/apply-with-seek/handle-authorization',
            type: 'unstable',
          },
          {
            label: '3. Retrieve candidate',
            path: '/use-cases/apply-with-seek/retrieve-candidate',
            type: 'unstable',
          },
          {
            label: '4. Retrieve resume',
            path: '/use-cases/apply-with-seek/retrieve-resume',
            type: 'unstable',
          },
          {
            label: '5. Populate form',
            path: '/use-cases/apply-with-seek/populate-form',
            type: 'unstable',
          },
          {
            label: '6. Completion signal',
            path: '/use-cases/apply-with-seek/send-completion-signal',
            type: 'unstable',
          },
        ],
      },
      {
        path: '/use-cases/test-cases',
        type: 'archived',
      },
    ],
  },
  {
    label: 'How-to guides',
    children: [
      {
        label: 'Overview',
        path: '/guides',
        type: 'reviewed',
        icon: 'GuideIndex',
      },
      {
        label: '2023 Job Posting revision',
        path: '/migration-guides/jobstreet-and-jobsdb-uplift',
        type: 'reviewed',
        hiddenKeywords: ['Asia', 'Jobstreet', 'Jobsdb', 'Unification'],
        icon: 'Guide',
        children: [
          {
            label: '1. Developer Dashboard',
            path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-1-developer-dashboard',
            type: 'reviewed',
          },
          {
            label: '2. Job Posting',
            path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting',
            type: 'reviewed',
            children: [
              {
                label: 'Locations',
                path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/locations',
                type: 'reviewed',
              },
              {
                label: 'Job categories',
                path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/job-categories',
                type: 'reviewed',
              },
              {
                label: 'Salary',
                path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/salary',
                type: 'reviewed',
              },
              {
                label: 'Ad selection',
                path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection',
                type: 'reviewed',
                children: [
                  {
                    label: 'Option 1: Panel',
                    path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/panel',
                    type: 'reviewed',
                  },
                  {
                    label: 'Option 2: GraphQL',
                    path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/graphql',
                    type: 'reviewed',
                    children: [
                      {
                        label: 'User experience guide',
                        path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection/graphql/user-experience-guide',
                        type: 'reviewed',
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Branding',
                path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/branding',
                type: 'reviewed',
              },
              {
                label: 'Questionnaires',
                path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/questionnaires',
                type: 'reviewed',
              },
              {
                label: 'Job ad preview',
                path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/job-ad-preview',
                type: 'reviewed',
              },
              {
                label: 'Content localisation',
                path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/content-localisation',
                type: 'reviewed',
              },
            ],
          },
          {
            label: '3. Apply & Talent Search',
            path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-3-apply-and-proactive-sourcing',
            type: 'reviewed',
          },
          {
            label: '4. Transitioning hirers',
            path: '/migration-guides/jobstreet-and-jobsdb-uplift/phase-4-transitioning-hirers',
            type: 'reviewed',
          },
        ],
      },
      {
        label: 'Monitoring',
        path: '/guides/monitoring',
        type: 'reviewed',
        icon: 'Monitoring',
      },
      {
        label: 'Hirer identifiers',
        path: '/migration-guides/seek-advertiser-ids',
        type: 'reviewed',
        icon: 'Guide',
      },
      {
        label: 'Archived',
        path: '/migration-guides/archived',
        type: 'reviewed',
        icon: 'GuideArchive',
        children: [
          {
            label: '2022 SEEK API migration',
            path: '/migration-guides/overview',
            type: 'archived',
            children: [
              {
                label: 'Job Posting API',
                path: '/migration-guides/job-posting-api',
                type: 'archived',
                hiddenKeywords: ['FLAPI'],
                children: [
                  {
                    label: '1. Auth',
                    path: '/migration-guides/job-posting-api/phase-1-auth',
                    type: 'archived',
                  },
                  {
                    label: '2. Object identifiers',
                    path: '/migration-guides/job-posting-api/phase-2-object-identifiers',
                    type: 'archived',
                  },
                  {
                    label: '3. Ad selection',
                    path: '/migration-guides/job-posting-api/phase-3-ad-selection',
                    type: 'archived',
                  },
                  {
                    label: '4. Managing job ads',
                    path: '/migration-guides/job-posting-api/phase-4-managing-job-ads',
                    type: 'archived',
                  },
                  {
                    label: '5. Event delivery',
                    path: '/migration-guides/job-posting-api/phase-5-event-delivery',
                    type: 'archived',
                  },
                ],
              },
              {
                label: 'SEEK Application Export',
                path: '/migration-guides/sae',
                type: 'archived',
              },
            ],
          },
        ],
      },
    ],
  },
];
