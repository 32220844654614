import {
  Box,
  Inline,
  Secondary,
  Text,
  TooltipRenderer,
} from 'braid-design-system';
import { format } from 'date-fns';

type DateFormat = 'dateOnly' | 'fullDate';

interface DateRendererProps {
  inputDate: string;
  updatedDate?: string;
  dateFormat?: DateFormat;
}

export const DateRenderer = ({
  inputDate,
  updatedDate,
  dateFormat = 'dateOnly',
}: DateRendererProps) => {
  const date = new Date(inputDate);

  const fullDateString = format(date, "EEEE do 'of' LLLL, yyyy");
  const dateOnlyString = format(date, 'dd-MMM-yyyy');
  const visibleDate =
    dateFormat === 'dateOnly' ? dateOnlyString : fullDateString;

  const message = updatedDate ? (
    <Text size="small">
      Last updated {format(new Date(updatedDate), "EEEE do 'of' LLLL, yyyy")}
    </Text>
  ) : (
    <Text size="small">{visibleDate}</Text>
  );

  return (
    <TooltipRenderer id={`dateTooltip-${dateOnlyString}`} tooltip={message}>
      {({ triggerProps }) => (
        <Inline space="none">
          <Box {...triggerProps}>
            <Text>
              <Secondary>{visibleDate}</Secondary>
            </Text>
          </Box>
        </Inline>
      )}
    </TooltipRenderer>
  );
};
