
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "content-discoverability-improvements"
    }}>{`Content discoverability improvements`}</h4>
    <p>{`We’ve made a handful of improvements to the discoverability of content on the Developer Site:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Site search is now available on every page`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Search results can now deep link to the relevant page section`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Content is now indexable by external search engines like Google`}</p>
      </li>
    </ol>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;