
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "ad-selection-panel-now-returns-paymentdueexcludingtax"
    }}>{`Ad Selection Panel now returns `}<inlineCode parentName="h4">{`paymentDueExcludingTax`}</inlineCode></h4>
    <p>{`We’ve introduced a `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CurrencyMinorUnit"
      }}><inlineCode parentName="a">{`paymentDueExcludingTax`}</inlineCode></a>{` property in the `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` callback for the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/panel"
      }}>{`Ad Selection Panel`}</a>{` which will give your software the pricing information needed to update invoicing systems if necessary.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`SEEKAdPostingWidget.render(document.getElementById('seekWidget1ContainerDiv'), {
  mode: 'Create',
  onChange: function (event) {
    const { advertisement } = event;
    const { paymentDueExcludingTax } = advertisement;

    console.log(\`$\${paymentDueExcludingTax.value} \${paymentDueExcludingTax.currency} will be invoiced to the hirer\`);
  },
  getAuthToken: function () {
    return Promise.resolve('browser token');
  },
  draftAdvertisement: {...},
});
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;