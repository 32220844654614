import {
  Actions,
  Button,
  type Dialog,
  IconDelete,
  IconTime,
  List,
  Notice,
  Stack,
  Strong,
  Text,
} from 'braid-design-system';
import { type ComponentProps, useEffect, useState } from 'react';

import {
  type LiveClientPayload,
  type PublicTestClientPayload,
  useApi,
} from 'src/api';

interface Props extends Pick<ComponentProps<typeof Dialog>, 'onClose'> {
  client: LiveClientPayload | PublicTestClientPayload;
  liveClientCount: number;
  onDelete: () => void;
}

export const DeleteCredentialDialogBody = ({
  client,
  liveClientCount,
  onClose,
  onDelete,
}: Props) => {
  const api = useApi();

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [isDeleted, setIsDeleted] = useState(false);

  const [cooldownSeconds, setCooldownSeconds] = useState(15);

  useEffect(() => {
    const id = setInterval(
      () =>
        setCooldownSeconds((prev) => {
          const next = prev - 1;

          if (!next) {
            clearInterval(id);
          }

          return next;
        }),
      1000,
    );

    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    if (loading) {
      return;
    }

    setError(undefined);
    setLoading(true);

    try {
      await api.credentials.delete(client.clientId);

      setIsDeleted(true);

      onDelete();
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
    } finally {
      setLoading(false);
    }
  };

  if (isDeleted) {
    return (
      <Stack space="large">
        <Text>
          Your <Strong>{client.name}</Strong> credentials have been deleted.
        </Text>

        <Actions>
          <Button onClick={() => onClose(false)} variant="soft">
            Close
          </Button>
        </Actions>
      </Stack>
    );
  }

  // This needs to come after `isDeleted`, else we will confusingly show this
  // word salad after successful deletion of the second set of credentials.
  if (client.type === 'live' && liveClientCount === 1) {
    return (
      <Stack space="large">
        <Stack space="medium">
          <Text>
            As a safety measure, you can’t delete your last set of live
            credentials. This would leave your software without a method to
            authenticate to the SEEK API and result in downtime.
          </Text>

          <Text>
            If you need to rotate your credentials, you can stage a seamless
            cutover without disrupting service as follows:
          </Text>

          <List type="number">
            <Text>Create new credentials</Text>
            <Text>Configure your software with new credentials</Text>
            <Text>Delete old credentials</Text>
          </List>
        </Stack>

        <Actions>
          <Button onClick={() => onClose(false)} variant="soft">
            Close
          </Button>
        </Actions>
      </Stack>
    );
  }

  return (
    <Stack space="large">
      <Stack space="medium">
        <Text>
          This will permanently delete your <Strong>{client.name}</Strong>{' '}
          credentials. Once deleted, you will be unable to exchange them for new
          partner tokens to access the SEEK API.
        </Text>

        {client.type === 'live' && (
          <Text tone="critical">
            Make sure that these live credentials are not in active use in your
            software before proceeding.
          </Text>
        )}
      </Stack>

      <Actions>
        <Button
          icon={cooldownSeconds ? <IconTime /> : <IconDelete />}
          loading={loading}
          onClick={cooldownSeconds ? undefined : onSubmit}
          tone="critical"
          variant={cooldownSeconds ? 'soft' : undefined}
        >
          {loading ? 'Deleting' : 'Delete'}
          {cooldownSeconds ? ` (${cooldownSeconds})` : ''}
        </Button>

        <Button onClick={() => onClose(false)} variant="transparent">
          Cancel
        </Button>
      </Actions>

      {error ? (
        <Notice tone="critical">
          <Stack space="medium">
            <Text>We couldn’t delete your credentials.</Text>

            <Text>{error}</Text>
          </Stack>
        </Notice>
      ) : null}
    </Stack>
  );
};
