
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "applicationquestioninputquestionhtml-limit-increased"
    }}><inlineCode parentName="h4">{`ApplicationQuestionInput.questionHtml`}</inlineCode>{` limit increased`}</h4>
    <p>{`The maximum size of the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationQuestionInput/field/questionHtml"
      }}><inlineCode parentName="a">{`ApplicationQuestionInput.questionHtml`}</inlineCode></a>{` field has been increased to 1,000 bytes in UTF-8 encoding.`}</p>
    <p>{`We’ve modified this limit to support compliance questions that are non-trivial to reword due to legal implications;
we still discourage long questions in the general case as they negatively impact the candidate experience.
Note that the increase does not apply to the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationPrivacyConsentInput/field/descriptionHtml"
      }}><inlineCode parentName="a">{`ApplicationPrivacyConsentInput.descriptionHtml`}</inlineCode></a>{` field.`}</p>
    <p>{`See our `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/questionnaires#limits"
      }}>{`questionnaire limits`}</a>{` for more information.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;