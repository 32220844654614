import { Box } from 'braid-design-system';
import type { ReactNode } from 'react';
import { ArcherElement } from 'react-archer';
import type { RelationType } from 'react-archer/lib/types';

import * as styles from './FlowchartNode.css';

interface Props {
  children: ReactNode;
  id: string;
  target?: string | string[];
}

export const FlowchartNode = ({ children, id, target = [] }: Props) => {
  const targetIds = Array.isArray(target) ? target : [target];

  const relations = targetIds.map<RelationType>((targetId) => ({
    sourceAnchor: 'right',
    targetAnchor: 'left',
    targetId,
  }));

  return (
    <Box alignItems="center" display="flex" height="full">
      <Box
        background="body"
        className={styles.node}
        borderRadius="large"
        width="full"
      >
        <ArcherElement id={id} relations={relations}>
          <Box padding="small" textAlign="center">
            {children}
          </Box>
        </ArcherElement>
      </Box>
    </Box>
  );
};
