
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "email-parsing-retirement"
    }}>{`Email parsing retirement`}</h4>
    <p>{`We have retired the SEEK API’s legacy compatibility with email parsing integrations.
SEEK will no longer send plain-text job application emails to an email address nominated on the job ad or hirer account.
Note that this does not affect the rich HTML emails that can be configured on the `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au"
      }}>{`SEEK employer website`}</a>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`ApplicationMethodInput.applicationEmail`}</inlineCode>{` field is ignored when `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad"
      }}>{`posting`}</a>{` or `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/updating-a-job-ad"
      }}>{`updating`}</a>{` a job ad.
Remove this field from your requests, even if you are sending a `}<inlineCode parentName="p">{`null`}</inlineCode>{` value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`{
  "postingInstructions": [
-   {
-     "applicationEmail": null
-   }
  ]
}
`}</code></pre>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/ApplicationMethod/field/applicationEmail"
      }}><inlineCode parentName="a">{`ApplicationMethod.applicationEmail`}</inlineCode></a>{` field is `}{`\``}{`null`}{`\``}{`ed out when `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/querying-a-job-ad"
      }}>{`querying`}</a>{` a job ad.
Remove this field from your requests:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`query {
  positionProfile {
    postingInstructions {
      applicationMethods {
-       applicationEmail {
-         address
-       }
        applicationUri
      }
    }
  }
}
`}</code></pre>
    <p>{`We are actively working with partners who are still sending non-compliant requests.
These fields will be formally removed from our GraphQL schema once remediation is complete.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;