import { Bleed, Box, Heading, Stack, Text } from 'braid-design-system';

import { BorderCard } from 'src/components/BorderCard';

export const JobAdSalary = () => (
  <BorderCard>
    <Stack space="large">
      <Stack space="medium">
        <Heading level="2" component="span">
          Senior Software Engineer
        </Heading>

        <Text weight="medium" size="large">
          Synthetic Testing
        </Text>

        <Box display="flex" flexDirection="column">
          <Stack space="medium">
            <Text>Melbourne VIC</Text>

            <Text>
              Information & Communication Technology (Engineering - Software)
            </Text>

            <Text>Full time</Text>

            <Bleed space="small">
              <Box
                background="formAccentSoft"
                borderRadius="small"
                boxShadow="borderFormAccentLight"
                padding="small"
              >
                <Text>$100k–$120k + super + bonus</Text>
              </Box>
            </Bleed>
          </Stack>
        </Box>
      </Stack>

      <Text tone="secondary">Posted just now</Text>
    </Stack>
  </BorderCard>
);
