
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "apply-with-seek"
    }}>{`Apply with SEEK`}</h1>
    <p>{`This use case provides technical implementation details for the Apply with SEEK product.
More information can be found on the `}<a parentName="p" {...{
        "href": "https://talent.seek.com.au/partners/whats-possible/apply-with-seek/"
      }}>{`Apply with SEEK product page`}</a>{`.`}</p>
    <p>{`Apply with SEEK allows candidates to pre-fill an external apply form hosted by your recruitment software using information from their SEEK Profile.
Candidates can also attach their CV already stored on SEEK, making it easier to apply on desktop or mobile.`}</p>
    <h2 {...{
      "id": "candidate-experience"
    }}>{`Candidate experience`}</h2>
    <p>{`Apply with SEEK can be used where a candidate arrives on your hosted apply form via a SEEK job advertisement using `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/application-methods#linking-out-to-an-external-apply-form"
      }}>{`link-out`}</a>{`,
or where the candidate accesses the apply form in your software through another source.
In either case, the candidate can click the Apply with SEEK button to authorize access to their SEEK Profile,
and have their details pre-filled into the apply form.`}</p>
    <p>{`The diagram below outlines the Apply with SEEK experience for a candidate starting on SEEK:`}</p>
    <img alt="" data-scoobie-style="none" src={require('../../../mermaid/.81baf7d245352a6ad4211bf5e7a86bfd5c175487.mmd.svg')} title="Candidate Overview" />
    <h2 {...{
      "id": "before-you-begin"
    }}>{`Before you begin`}</h2>
    <p>{`Before you start accessing the GraphQL queries for Apply with SEEK,
you will need to `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`request a partner token`}</a>{`.`}</p>
    <h2 {...{
      "id": "integrating-apply-with-seek"
    }}>{`Integrating Apply with SEEK`}</h2>
    <p>{`To integrate the Apply with SEEK flow into your apply form, the following steps are required:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/display-button"
          }}>{`Display the Apply with SEEK button`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/handle-authorization"
          }}>{`Handle SEEK candidate authorization`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/retrieve-candidate"
          }}>{`Retrieve SEEK Profile information`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/retrieve-resume"
          }}>{`Download resume if available`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/populate-form"
          }}>{`Populate your apply form`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/send-completion-signal"
          }}>{`Send Completion Signal`}</a></p>
      </li>
    </ol>
    <p>{`The diagram below shows an expanded sequence of a candidate applying via Apply with SEEK.
It details the interactions between your software and SEEK to pre-fill an apply form,
grouped by each step in the integration process.`}</p>
    <img alt="" data-scoobie-style="none" src={require('../../../mermaid/.3ab1729370995bb1a75b1218d4bae12589343ada.mmd.svg')} title="Full sequence" />
    <h2 {...{
      "id": "migrating-from-apply-with-seek-v1"
    }}>{`Migrating from Apply with SEEK v1`}</h2>
    <p>{`The updated Apply with SEEK offers a user experience similar to `}<a parentName="p" {...{
        "href": "https://devportal.seek.com.au/apply-with-seek/overview"
      }}>{`its predecessor`}</a>{`, while introducing several enhancements for both candidates and integration partners:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`It adopts a consistent approach with other SEEK API components, simplifying access and allowing you to apply the same `}<a parentName="p" {...{
            "href": "/auth/partner-tokens"
          }}>{`API authorization`}</a>{` and GraphQL methodologies used in other SEEK API integrations.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The candidate’s authorization link is available via a GraphQL query, avoiding the need to hard-code auth link elements directly in your software.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SEEK Profile information is retrieved using the same GraphQL `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/query/candidateProfile"
          }}><inlineCode parentName="a">{`candidateProfile`}</inlineCode>{` query`}</a>{` as our `}<a parentName="p" {...{
            "href": "/use-cases/application-export"
          }}>{`SEEK Optimised Apply`}</a>{` service.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Introduces additional candidate fields that can be pre-populated into your apply form.`}</p>
      </li>
    </ul>
    <p>{`If you currently integrate with the `}<a parentName="p" {...{
        "href": "https://devportal.seek.com.au/apply-with-seek/overview"
      }}>{`previous version of Apply with SEEK`}</a>{`, you will need to follow the same steps above to update your integration.`}</p>
    <p>{`The table below outlines the key differences between the previous and current versions of Apply with SEEK:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Feature`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Prior version (v1)`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`This version (v2)`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "/auth/partner-tokens"
              }}>{`API authorization`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Legacy Client Credentials`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`SEEK API `}<a parentName="p" {...{
                "href": "/auth/partner-tokens"
              }}>{`partner access tokens`}</a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/display-button#candidate-arrives-from-seek"
              }}>{`Link-out tracking`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`No support`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`New `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` passed in during link-out redirect`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/display-button"
              }}>{`Apply with SEEK button`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Link generated by partner`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`GraphQL `}<a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/query/_unstable_applyWithSeekButton"
              }}><inlineCode parentName="a">{`applyWithSeekButton`}</inlineCode>{` query`}</a>{` returns link, branded image, and text localised for SEEK, Jobsdb and Jobstreet`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/handle-authorization"
              }}>{`Candidate authorization`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`OAuth2.0 with authorization code exchanged for access token`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Revised authorization flow returning `}<inlineCode parentName="p">{`candidateid`}</inlineCode>{` for retrieving profile and `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` for completion tracking`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/retrieve-candidate"
              }}>{`Candidate data retrieval`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`POST endpoint`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`GraphQL `}<a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/query/candidateProfile"
              }}><inlineCode parentName="a">{`candidateProfile`}</inlineCode>{` query`}</a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/retrieve-resume"
              }}>{`Candidate resume retrieval`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`GET based on returned `}<inlineCode parentName="p">{`url`}</inlineCode></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`GET based on returned `}<inlineCode parentName="p">{`url`}</inlineCode></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/populate-form"
              }}>{`Populating your form`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Partner pre-fills fields based on returned data`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Partner pre-fills fields based on returned data`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/send-completion-signal"
              }}>{`Completion Signal`}</a></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`POST endpoint`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`GraphQL `}<a parentName="p" {...{
                "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal"
              }}><inlineCode parentName="a">{`sendApplicationSignal`}</inlineCode>{` mutation`}</a></p></td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;