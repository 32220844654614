import { Alert, Stack, Text } from 'braid-design-system';
import { SmartTextLink } from 'scoobie';

import { MarkdownPage } from 'src/templates/MarkdownPage';

import { site } from './documentation.config';
import type { Label, Page } from './page';
import { mdxDocuments, tsxComponents } from './site.map';

export interface PageNavigation {
  path: string;
  label?: Label;
  parent?: Label;
}

export type FlatPage = Omit<Page, 'children'> & {
  parent?: {
    path?: string;
    label?: Label;
  };
  prev?: PageNavigation;
  next?: PageNavigation;
};

export interface ListablePage extends Page {
  label: Label;
  path?: string;
}

export interface RenderablePage extends Page {
  path: string;
  type: NonNullable<Page['type']>;
}

const flattenPages = (pages: Page[]): FlatPage[] =>
  pages.reduce<FlatPage[]>((acc, { children, ...page }) => {
    acc.push(page);

    if (children !== undefined) {
      const newChildren = children.map((child) => ({
        ...child,
        parent: { path: page.path, label: page.label },
      }));
      acc.push(...flattenPages(newChildren));
    }

    return acc;
  }, []);

export const userCanViewPage = (
  page: FlatPage | Page,
  permissions: string[],
) => {
  if (page.permissions === undefined) {
    return true;
  }
  return page.permissions.every((permission) =>
    permissions.includes(permission),
  );
};

export const isListablePage = (page: FlatPage | Page): page is ListablePage =>
  page.label !== undefined;

const isRenderablePage = (page: FlatPage): page is RenderablePage & FlatPage =>
  page.path !== undefined &&
  page.path.startsWith('/') &&
  page.type !== undefined;

export const RENDERABLE_PAGES = flattenPages(site)
  .filter(isRenderablePage)
  .map((page, index, arr) => {
    const next = arr.slice(index + 1).find(isListablePage);

    const prev = arr.slice(0, index).reverse().find(isListablePage);

    return {
      ...page,
      prev: prev
        ? {
            path: prev.path,
            label: prev.label,
            parent: prev.parent?.label,
          }
        : undefined,
      next: next
        ? {
            path: next.path,
            label: next.label,
            parent: next.parent?.label,
          }
        : undefined,
    };
  });

const Banner = (page: {
  replacement: RenderablePage['replacement'];
  supersedes?: string;
  type: Exclude<RenderablePage['type'], 'reviewed' | 'standalone'>;
}): JSX.Element => {
  switch (page.type) {
    case 'archived':
      return (
        <Alert tone="caution">
          <Text>
            This content has been archived.{' '}
            {page.replacement ? (
              <>
                You may be looking for its{' '}
                <SmartTextLink href={page.replacement}>
                  latest revision
                </SmartTextLink>
                .
              </>
            ) : (
              'It may contain guidance that is no longer relevant.'
            )}
          </Text>
        </Alert>
      );

    case 'draft':
      return (
        <Alert tone="info">
          <Text>This content is still in draft.</Text>
        </Alert>
      );

    case 'unstable':
      return (
        <Alert tone="caution">
          <Stack space="medium">
            <Text>
              This content provides an early look at an upcoming extension to
              the SEEK API. You may use it to scope integration effort, but the
              features described are not fully implemented and may be adjusted
              ahead of a production release.
            </Text>

            <Text>
              Please check with your SEEK contact before starting any build work
              described here
              {page.supersedes ? (
                <>
                  , or refer to our{' '}
                  <SmartTextLink href={page.supersedes}>
                    prior documentation
                  </SmartTextLink>{' '}
                  for in-flight or existing integrations
                </>
              ) : null}
              .
            </Text>
          </Stack>
        </Alert>
      );
  }
};

export const renderPage = (page: RenderablePage) => {
  const currentPage = RENDERABLE_PAGES.find((rp) => rp.path === page.path);
  const next = currentPage?.next;
  const prev = currentPage?.prev;
  switch (page.type) {
    case 'archived':
    case 'draft':
    case 'reviewed':
    case 'unstable':
      const document = mdxDocuments[page.path];

      if (document === undefined) {
        throw Error(`${page.path} does not have an associated component`);
      }

      const supersededPage = RENDERABLE_PAGES.find(
        (x) => x.replacement === page.path,
      )?.path;

      const banner =
        page.type === 'reviewed' ? null : (
          <Banner
            replacement={page.replacement}
            supersedes={supersededPage}
            type={page.type}
          />
        );

      return (
        <MarkdownPage
          banner={banner}
          document={document}
          label={page.label}
          next={next}
          prev={prev}
        />
      );

    case 'standalone':
      const Component = tsxComponents[page.path];

      if (Component === undefined) {
        throw Error(`${page.path} does not have an associated component`);
      }

      return <Component next={next} prev={prev} />;
  }
};
