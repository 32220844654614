import { gql } from '@apollo/client';

export const CREATE_WEBHOOK = gql`
  mutation createNewWebhook(
    $eventTypeCode: String!
    $schemeId: String!
    $hirerId: String
    $url: String!
    $maxEventsPerAttempt: Int
    $signingAlgorithmCode: String!
    $secret: String
  ) {
    createWebhookSubscription(
      input: {
        webhookSubscription: {
          eventTypeCode: $eventTypeCode
          schemeId: $schemeId
          hirerId: $hirerId
          url: $url
          maxEventsPerAttempt: $maxEventsPerAttempt
          signingAlgorithmCode: $signingAlgorithmCode
          secret: $secret
        }
      }
    ) {
      ... on CreateWebhookSubscriptionPayload_Success {
        webhookSubscription {
          id {
            value
          }
          eventTypeCode
          url
        }
      }

      ... on CreateWebhookSubscriptionPayload_Conflict {
        conflictingWebhookSubscription {
          id {
            value
          }
        }
      }
    }
  }
`;

export const ADD_NEW_WEBHOOK_SUBSCRIPTION_EDGE_FRAGMENT = gql`
  fragment NewWebhookSubscriptionEdge on WebhookSubscriptionEdge {
    node {
      id {
        value
      }
    }
    __typename
  }
`;
