import { List, Text, TextLink } from 'braid-design-system';
import { Table, TableRow } from 'scoobie';

export const JobPostingFeatureList = () => (
  <Table header={['Market', 'Features', 'Detail']}>
    <TableRow>
      <Text>ANZ</Text>
      <Text>Ad Selection Revision</Text>
      <List>
        <Text>
          <TextLink href="#locations">Dynamic Locations</TextLink>
        </Text>
        <Text>
          <TextLink href="#job-categories">Dynamic Job Categories</TextLink>
        </Text>
        <Text>
          <TextLink href="#ad-selection">Ad Selection - Panel</TextLink>
        </Text>
        <Text>
          <TextLink href="#ad-selection">Ad Selection - GraphQL</TextLink>
        </Text>
        <Text>
          <TextLink href="#questionnaires">Questionnaires</TextLink>
        </Text>
      </List>
    </TableRow>
    <TableRow>
      <Text>ANZ</Text>
      <Text>Job Ad Preview / Branding</Text>
      <List>
        <Text>
          <TextLink href="#job-ad-preview">Job Ad Preview</TextLink>
        </Text>
        <Text>
          <TextLink href="#branding">Branding</TextLink> (Optional)
        </Text>
      </List>
    </TableRow>
    <TableRow>
      <Text>Asia</Text>
      <Text>Access to Asia features (Optional)</Text>
      <List>
        <Text>
          <TextLink href="#salary">Salary Currencies</TextLink>
        </Text>
        <Text>
          <TextLink href="#salary">Monthly Salary</TextLink>
        </Text>
        <Text>
          <TextLink href="#content-localisation">Content Localisation</TextLink>{' '}
          (Language)
        </Text>
      </List>
    </TableRow>
  </Table>
);
