
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "ad-selection-panel-contract-bar-and-info-icons"
    }}>{`Ad Selection Panel contract bar and info icons`}</h4>
    <p>{`We have removed contract bar and info icons from the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/panel"
      }}>{`Ad Selection Panel`}</a>{` to keep it clean and simple.
The contract bar UI component was shown when a hirer is on a contract.
Info icons were used to show more information about the ad products.`}</p>
    <p>{`Hirers will still be informed when they have a contract with SEEK through the checkout summary displayed upon selecting an ad product.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;