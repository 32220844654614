
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "candidateprofileassociatedpositionprofile-field"
    }}><inlineCode parentName="h4">{`CandidateProfile.associatedPositionProfile`}</inlineCode>{` field`}</h4>
    <p>{`When retrieving a candidate profile from the SEEK API,
you typically need to select additional fields to attribute it to a position and hirer.
Previously, this required indirection through the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CandidateProfile/field/associatedPositionOpenings"
      }}><inlineCode parentName="a">{`CandidateProfile.associatedPositionOpenings`}</inlineCode></a>{` field,
which was generally unintuitive and inefficiently retrieved all position profiles for the opening.`}</p>
    <p>{`The new `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CandidateProfile/field/associatedPositionProfile"
      }}><inlineCode parentName="a">{`CandidateProfile.associatedPositionProfile`}</inlineCode></a>{` field allows you to directly select the position associated with a candidate application or profile purchase:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`    query ($id: String!) {
      candidateProfile(id: $id) {
+       associatedPositionProfile {
-       associatedPositionOpenings {
-         positionOpening {
-           positionProfiles {
              seekHirerJobReference
              positionOrganizations {
                id {
                  value
                }
              }
-             positionUri
-           }
-         }
-         positionUri
        }
      }
    }
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;