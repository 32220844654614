
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "documentation-updates"
    }}>{`Documentation updates`}</h4>
    <p>{`We’ve extended our documentation with new guidance:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`GraphQL usage`}</p>
        <p parentName="li">{`We’ve documented some introductory concepts `}<a parentName="p" {...{
            "href": "/graphql/seek-api-conventions"
          }}>{`specific to the SEEK API`}</a>{`,
along with general guidance for using GraphQL `}<a parentName="p" {...{
            "href": "/graphql/in-practice"
          }}>{`in your software`}</a>{`.
This includes strong advice around using GraphQL variables to prevent code injection.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/events/webhooks#duplicates"
          }}>{`Reprocessing webhooks`}</a></p>
        <p parentName="li">{`We’ve included advice on reprocessing “duplicate” webhooks.
In the rare event of a data quality issue,
this allows SEEK to perform remediation and prompt your software to automatically re-retrieve the affected objects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SEEK Application Export migration`}</p>
        <p parentName="li">{`We’ve described a `}<a parentName="p" {...{
            "href": "/migration-guides/sae#method-1-deduplicating-applications"
          }}>{`new transition approach`}</a>{` that allows you to run both integrations simultaneously and deduplicate applications rather than candidates.
We’ve also noted some `}<a parentName="p" {...{
            "href": "/migration-guides/sae#potential-gotchas"
          }}>{`potential gotchas`}</a>{`.`}</p>
      </li>
    </ul>
    <p>{`We’ve refreshed our `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/"
      }}>{`schema documentation`}</a>{` site!
It should be easier to navigate and drill down into nested objects.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;