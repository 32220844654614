import { Auth0Provider, type Auth0ProviderOptions } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { useEnvironmentConfig } from 'src/hooks/environment.tsx';

interface AuthProviderProps {
  children: JSX.Element;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();

  const redirectCallback: Auth0ProviderOptions['onRedirectCallback'] = (
    appState,
  ) => {
    if (appState?.returnTo) {
      const parsed = new URL(appState.returnTo, window.location.origin);

      if (parsed.search) {
        const params = new URLSearchParams(parsed.search);

        if (params.has('v')) {
          // Get static-site-router to recognise the new version, which is done server-side
          window.location.href = appState.returnTo;
          return;
        }
      }

      navigate(appState.returnTo);
    }
  };

  const { auth } = useEnvironmentConfig();

  return (
    <Auth0Provider
      domain={auth.domain}
      clientId={auth.clientId}
      onRedirectCallback={redirectCallback}
      useRefreshTokens
      useRefreshTokensFallback
      cacheLocation="localstorage"
      authorizationParams={{
        audience: 'https://dashboard.graphql.seek.com',
        redirect_uri: auth.redirectUri,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
