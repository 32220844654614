
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "browser-support"
    }}>{`Browser support`}</h1>
    <p>{`Our web-based components support versions released over the last year for the following web browsers:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.apple.com/safari/"
          }}>{`Apple Safari`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.google.com/intl/en/chrome/"
          }}>{`Google Chrome`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.microsoft.com/en-gb/edge?form=MA13FJ"
          }}>{`Microsoft Edge`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.mozilla.org/en-GB/firefox/new/"
          }}>{`Mozilla Firefox`}</a></p>
      </li>
    </ul>
    <p>{`Hirers should use an up-to-date web browser;
unsupported browsers may not be able to access our web-based components or use all of their features.
See the documentation page of each component for more information:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/ad-selection/v2/panel#browser-support"
          }}>{`Ad Selection Panel`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/managing-job-ads/previewing-a-job-ad#browser-support"
          }}>{`Job Ad Preview`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/questionnaires/v2/panel#browser-support"
          }}>{`Questionnaire Panel`}</a></p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;