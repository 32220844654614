import { QuestionnaireForm } from 'wingman-fe';

import { BorderCard } from 'src/components/BorderCard';

export const Questionnaire = () => (
  <BorderCard>
    <QuestionnaireForm
      components={[
        {
          componentTypeCode: 'Question',
          questionHtml:
            'Which of the following statements best describes your right to work in Australia?',
          responseTypeCode: 'SingleSelect',
          value: 'Q1',
          responseChoice: [
            {
              value: 'A1',
              text: "I'm an Australian citizen",
              preferredIndicator: false,
            },
            {
              value: 'A2',
              text: "I'm a permanent resident and/or NZ citizen",
              preferredIndicator: false,
            },
            {
              value: 'A3',
              text: 'I have a family/partner visa with no restrictions',
              preferredIndicator: false,
            },
            {
              value: 'A4',
              text: 'I have a graduate temporary work visa',
              preferredIndicator: false,
            },
            {
              value: 'A5',
              text: 'I have a holiday temporary work visa',
              preferredIndicator: false,
            },
            {
              value: 'A6',
              text: 'I have a temporary visa with restrictions on work location (e.g. skilled regional visa 489)',
              preferredIndicator: false,
            },
            {
              value: 'A7',
              text: 'I have a temporary protection or safe haven enterprise work visa',
              preferredIndicator: false,
            },
            {
              value: 'A8',
              text: 'I have a temporary visa with no restrictions (e.g. doctoral student)',
              preferredIndicator: false,
            },
            {
              value: 'A9',
              text: 'I have a temporary visa with restrictions on work hours (e.g. student visa, retirement visa)',
              preferredIndicator: false,
            },
            {
              value: 'A10',
              text: 'I have a temporary visa with restrictions on industry (e.g. temporary activity visa 408)',
              preferredIndicator: false,
            },
            {
              value: 'A11',
              text: 'I require sponsorship to work for a new employer (e.g. 482, 457)',
              preferredIndicator: false,
            },
          ],
        },
        {
          componentTypeCode: 'Question',
          questionHtml: 'Which of the following forklift licences do you have?',
          responseTypeCode: 'MultiSelect',
          value: 'Q2',
          responseChoice: [
            {
              value: 'A1',
              text: 'Forklift truck (LF)',
              preferredIndicator: false,
            },
            {
              value: 'A2',
              text: 'Order picking forklift truck (LO)',
              preferredIndicator: false,
            },
            {
              value: 'A3',
              text: 'None of these',
              preferredIndicator: false,
            },
          ],
        },
        {
          componentTypeCode: 'Question',
          questionHtml: 'Do you even lift?',
          responseTypeCode: 'FreeText',
          value: 'Q3',
        },
      ]}
      type="Form"
    />
  </BorderCard>
);
