import { Notice, Stack, Text } from 'braid-design-system';

interface Props {
  description: string;
  title: string;
}

export const ErrorNotice = ({ description, title }: Props) => (
  <Notice tone="critical">
    <Stack space="small">
      <Text>{title}</Text>

      <Text size="small">{description}</Text>
    </Stack>
  </Notice>
);
