
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "ad-selection-panel-theme"
    }}>{`Ad Selection Panel theme`}</h4>
    <p>{`We’ve extended the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/panel"
      }}>{`Ad Selection Panel`}</a>{` to support custom styling via `}<inlineCode parentName="p">{`theme`}</inlineCode>{` properties provided to the render method.
All the theme properties are optional and have default values.`}</p>
    <p>{`Here is an example of how to specify the theme properties:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`SEEKAdPostingWidget.render(document.getElementById('seekWidget1ContainerDiv'), {
  mode: 'Create',
  onChange: function (event) {},
  getAuthToken: function () {
    return Promise.resolve('browser token');
  },
  draftAdvertisement: {
    positionTitle: 'Developer',
    jobCategoryId: 'seekAnzPublicTest:jobCategory:seek:2EFstqFvP',
    positionLocationId: 'seekAnzPublicTest:location:seek:2TCFhBtw9',
    hirerJobReference: 'Premium521',
  },
  theme: {
    selectionColor: '#e82b85',
    linkColor: '#e82b85',
    contractBarPrimaryBackgroundColor: '#e82b85',
    contractBarSecondaryBackgroundColor: '#f6d2e0',
    errorColor: '#ff0000',
  },
});
`}</code></pre>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/panel"
      }}>{`Ad Selection Panel`}</a>{` page for more information on each theme property.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;