import { gql } from '@apollo/client';

export const REPLAY_WEBHOOK = gql`
  mutation replayWebhookSubscriptionById(
    $webhookSubscription: ReplayWebhookSubscription_SubscriptionInput!
    $eventIds: [String!]
  ) {
    replayWebhookSubscription(
      input: { webhookSubscription: $webhookSubscription, eventIds: $eventIds }
    ) {
      webhookSubscription {
        id {
          value
        }
      }
    }
  }
`;
