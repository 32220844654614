
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "ad-selection-panel-maximum-width"
    }}>{`Ad Selection Panel maximum width`}</h4>
    <p>{`We removed the maximum width of the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/panel"
      }}>{`Ad Selection Panel`}</a>{` to give your software control over the panel’s width.
Now the panel will inherit the width of the `}<inlineCode parentName="p">{`containerNode`}</inlineCode>{`’s parent.
If you don’t have any preference for the panel’s width, we recommend that you add a `}<inlineCode parentName="p">{`max-width: 822px`}</inlineCode>{` style to the parent of the `}<inlineCode parentName="p">{`containerNode`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div style="max-width: 822px;">
  <div id="seekWidget1ContainerDiv"></div>
</div>
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;