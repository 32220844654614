import { Column, Columns, Heading, Hidden } from 'braid-design-system';
import { useParams } from 'react-router-dom';

import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';
import { SectionCard } from 'src/components/SectionCard/SectionCard';
import { AuthVerifier } from 'src/components/auth/AuthVerifier';

import { useUserGuide } from '../userGuide/UserGuide';
import { UserGuideToggle } from '../userGuide/UserGuideToggle';

import { NewSubscriptionPanel } from './components/NewSubscriptionPanel/NewSubscriptionPanel';
import { SubscriptionListPanel } from './components/SubscriptionListPanel/SubscriptionListPanel';
import { ViewSubscriptionPanel } from './components/ViewSubscriptionPanel/ViewSubscriptionPanel';

const DetailsColumn = ({ subscriptionId }: { subscriptionId: string }) => {
  if (subscriptionId === 'new') {
    return <NewSubscriptionPanel />;
  }

  return <ViewSubscriptionPanel subscriptionId={subscriptionId} />;
};

export const WebhooksPage = () => {
  const { subscriptionId: encodedSubscriptionId } = useParams<{
    subscriptionId?: string;
  }>();
  const subscriptionId = decodeURIComponent(encodedSubscriptionId ?? '');
  const { toggleUserGuide } = useUserGuide();

  return (
    <AuthVerifier>
      <PageWrapper
        heading={
          <Columns space="gutter">
            <Column>
              <Heading level="2" weight="weak">
                Webhook subscriptions
              </Heading>
            </Column>

            <Column width="content">
              <UserGuideToggle
                toggleUserGuide={() => toggleUserGuide('webhooks')}
              />
            </Column>
          </Columns>
        }
      >
        <Columns
          space={['none', 'none', 'none', 'gutter']}
          collapseBelow="wide"
        >
          <Column width="1/3">
            {/* Simulate a multi-page layout unless we're `wide`. We push a
              history item when selecting a subscription so they can navigate
              back to show the subscription list again */}
            <Hidden below={subscriptionId ? 'wide' : undefined}>
              <SectionCard>
                <SubscriptionListPanel />
              </SectionCard>
            </Hidden>
          </Column>

          {subscriptionId ? (
            <Column width="2/3">
              <DetailsColumn subscriptionId={subscriptionId} />
            </Column>
          ) : null}
        </Columns>
      </PageWrapper>
    </AuthVerifier>
  );
};
