
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { IconTick, IconClear } from 'braid-design-system';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "step-6---send-completion-signal"
    }}>{`Step 6 - Send Completion Signal`}</h1>
    <p>{`Once the candidate has completed and submitted your apply form,
your software must send a Completion Signal to SEEK to indicate that the application process is complete.
The Completion Signal requires a `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` token your software will have received during the Apply with SEEK flow.`}</p>
    <h2 {...{
      "id": "handling-multiple-seek-tokens"
    }}>{`Handling multiple `}<inlineCode parentName="h2">{`seek-token`}</inlineCode>{`s`}</h2>
    <p>{`A `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` is supplied on a query string to your software in two ways:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`When the candidate initially arrives at your site from SEEK (see `}<a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/display-button"
          }}>{`Step 1. Display button`}</a>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After the candidate has authorized access to their profile (see `}<a parentName="p" {...{
            "href": "/use-cases/apply-with-seek/handle-authorization"
          }}>{`Step 2. Handle authorization`}</a>{`)`}</p>
      </li>
    </ol>
    <p>{`If your software has received both, it should retain only the second `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` received during the candidate authorizes access.`}</p>

    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Candidate Source`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Using Apply with SEEK`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Token handling`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`SEEK`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`seek-token`}</inlineCode>{` returned during the `}<a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/handle-authorization"
              }}>{`Authorization flow`}</a>{` step should be used for the Completion Signal`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`SEEK`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`As no authorization occurred, the original `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` received when the candidate arrived from SEEK should be used for the Completion Signal`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Other source`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><IconTick tone="positive" mdxType="IconTick" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><inlineCode parentName="p">{`seek-token`}</inlineCode>{` returned during the `}<a parentName="p" {...{
                "href": "/use-cases/apply-with-seek/handle-authorization"
              }}>{`Authorization flow`}</a>{` step should be used for the Completion Signal`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Other source`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td"><IconClear tone="critical" mdxType="IconClear" /></p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`No `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` will have been received by your software, do not submit a Completion Signal`}</p></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "sending-the-completion-signal"
    }}>{`Sending the Completion Signal`}</h2>
    <p>{`To send the Completion Signal, use the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal"
      }}><inlineCode parentName="a">{`sendApplicationSignal`}</inlineCode>{` mutation`}</a>{`, passing the `}<inlineCode parentName="p">{`seek-token`}</inlineCode>{` as the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/mutation/sendApplicationSignal/argument/token"
      }}><inlineCode parentName="a">{`token`}</inlineCode>{` argument`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation ($input: SendApplicationSignalInput!) {\\n  sendApplicationSignal(input: $input)\\n}\",\"position\":{\"start\":{\"line\":29,\"column\":1,\"offset\":2173},\"end\":{\"line\":33,\"column\":4,\"offset\":2277},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"jsonc\",\"meta\":null,\"value\":\"{\\n  \\\"input\\\": {\\n    // Supported values are: `global` and `globalPublicTest`\\n    \\\"schemeId\\\": \\\"globalPublicTest\\\",\\n\\n    // Details of the type of signal being sent - currently only ApplicationComplete is supported\\n    \\\"typeCode\\\": \\\"ApplicationComplete\\\",\\n\\n    // The seek-token value received from SEEK during the authorization flow\\n    // (or initial arrival on site if no Apply with SEEK authorization occurred)\\n    \\\"token\\\": \\\"eyJjYW5kaWRhdGVJZCI6ODAwMCwiam9iSWQiOjMwMDAwMDAwLCJzZXNzaW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MSIsImxpbmtvdXRBcHBsaWNhdGlvbkNvcnJlbGF0aW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MCJ9\\\"\\n  }\\n}\",\"position\":{\"start\":{\"line\":35,\"column\":1,\"offset\":2279},\"end\":{\"line\":49,\"column\":4,\"offset\":2926},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"mutation": true,
        "($input:": true,
        "SendApplicationSignalInput!)": true,
        "{\\n": true,
        "": true,
        "sendApplicationSignal(input:": true,
        "$input)\\n}\",\"position\":{\"start\":{\"line\":29,\"column\":1,\"offset\":2173},\"end\":{\"line\":33,\"column\":4,\"offset\":2277},\"indent\":[1,1,1,1]}},{\"type\":\"code\",\"lang\":\"jsonc\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"input\\\":": true,
        "//": true,
        "Supported": true,
        "values": true,
        "are:": true,
        "`global`": true,
        "and": true,
        "`globalPublicTest`\\n": true,
        "\\\"schemeId\\\":": true,
        "\\\"globalPublicTest\\\",\\n\\n": true,
        "Details": true,
        "of": true,
        "the": true,
        "type": true,
        "signal": true,
        "being": true,
        "sent": true,
        "-": true,
        "currently": true,
        "only": true,
        "ApplicationComplete": true,
        "is": true,
        "supported\\n": true,
        "\\\"typeCode\\\":": true,
        "\\\"ApplicationComplete\\\",\\n\\n": true,
        "The": true,
        "seek-token": true,
        "value": true,
        "received": true,
        "from": true,
        "SEEK": true,
        "during": true,
        "authorization": true,
        "flow\\n": true,
        "(or": true,
        "initial": true,
        "arrival": true,
        "on": true,
        "site": true,
        "if": true,
        "no": true,
        "Apply": true,
        "with": true,
        "occurred)\\n": true,
        "\\\"token\\\":": true,
        "\\\"eyJjYW5kaWRhdGVJZCI6ODAwMCwiam9iSWQiOjMwMDAwMDAwLCJzZXNzaW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MSIsImxpbmtvdXRBcHBsaWNhdGlvbkNvcnJlbGF0aW9uSWQiOiJjMmU1YzJkN2E0YjY0MWVlOGRmMzliOTlmYTgyYzU5MCJ9\\\"\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":35,\"column\":1,\"offset\":2279},\"end\":{\"line\":49,\"column\":4,\"offset\":2926},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`mutation ($input: SendApplicationSignalInput!) {
  sendApplicationSignal(input: $input)
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;