import { useApolloClient } from '@apollo/client';
import { SeekApiPayload } from '@seek/indie-api-types';
import {
  Box,
  Button,
  Column,
  Columns,
  Heading,
  IconChevron,
  IconDownload,
  MenuItemCheckbox,
  MenuRenderer,
  Stack,
  Text,
  TextField,
} from 'braid-design-system';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

import { CardSection } from 'src/components/CardSection/CardSection';
import { GutterBox } from 'src/components/GutterBox/GutterBox';
import { InfiniteScrollingList } from 'src/components/InfiniteScrollingList/InfiniteScrollingList';
import { usePaginationDirectionState } from 'src/components/PaginationDirectionPicker/PaginationDirectionPicker';
import { Query } from 'src/components/Query/Query';
import { ScrollingXBox } from 'src/components/ScrollingBox/ScrollingBox';
import { SectionCard } from 'src/components/SectionCard/SectionCard';
import { useEnvironmentConfig } from 'src/hooks/environment';
import type {
  RelatedHirersQuery,
  RelatedHirersQueryVariables,
} from 'src/types/graphql';

import { DISPLAY_LABEL_FOR_API_RELATIONSHIP, Hirer } from '../Hirer/Hirer';

import { downloadCsv } from './downloadCsv';
import { RELATED_HIRERS } from './queries';

const RELATIONSHIP_TYPES = SeekApiPayload.RelationshipTypeCode.alternatives.map(
  ({ value }) => value,
);

const relationshipTypesLabel = (
  types: SeekApiPayload.RelationshipTypeCode[],
) => {
  const displayTypes = types.map(
    (type) => DISPLAY_LABEL_FOR_API_RELATIONSHIP[type],
  );

  if (displayTypes.length === 1) {
    return displayTypes[0];
  }

  if (!displayTypes.length) {
    return 'No relationship types';
  }

  if (displayTypes.length === RELATIONSHIP_TYPES.length) {
    return 'All relationship types';
  }

  return `${displayTypes.length} relationship types`;
};

export const HirerList = () => {
  // We don't modify this state at the moment but we utilise its helpers.
  const { firstOrLast, processPageInfo } =
    usePaginationDirectionState('FORWARDS');

  const [nameSearch, setNameSearch] = useState('');
  const [debouncedNameSearch] = useDebounce(nameSearch, 500);
  const trimmedNameSearch = debouncedNameSearch.trim();

  const [relationshipTypeCodes, setEventTypeCodes] =
    useState<SeekApiPayload.RelationshipTypeCode[]>(RELATIONSHIP_TYPES);

  const filter = {
    nameSearch: trimmedNameSearch || null,
    relationshipTypeCodes,
  };

  const apolloClient = useApolloClient();
  const [downloadingCsv, setDownloadingCsv] = useState(false);

  const { seekAnzSchemeId } = useEnvironmentConfig();

  return (
    <CardSection
      gutter
      header={
        <Columns alignY="center" space="small">
          <Column>
            <Heading level="3">Search all hirers</Heading>
          </Column>

          <Column width="content">
            <Button
              bleed
              icon={<IconDownload />}
              loading={downloadingCsv}
              onClick={() => {
                setDownloadingCsv(true);
                downloadCsv(apolloClient, filter, seekAnzSchemeId).finally(() =>
                  setDownloadingCsv(false),
                );
              }}
              size="small"
              variant="soft"
            >
              {downloadingCsv ? 'Generating' : 'Download CSV'}
            </Button>
          </Column>
        </Columns>
      }
    >
      <Stack space="large">
        <Columns space="gutter" collapseBelow="desktop" alignY="center">
          <Column>
            <TextField
              aria-label="Hirer name"
              id="hirerListNameSearch"
              onChange={(event) => setNameSearch(event.currentTarget.value)}
              placeholder="Acme Corp"
              value={nameSearch}
            />
          </Column>
          <Column width="content">
            <MenuRenderer
              align="left"
              offsetSpace="small"
              trigger={(triggerProps, { open }) => (
                <Box userSelect="none" cursor="pointer" {...triggerProps}>
                  <Text weight="medium">
                    {relationshipTypesLabel(relationshipTypeCodes)}{' '}
                    <IconChevron
                      direction={open ? 'up' : 'down'}
                      alignY="lowercase"
                    />
                  </Text>
                </Box>
              )}
            >
              {RELATIONSHIP_TYPES.map((type) => (
                <MenuItemCheckbox
                  checked={relationshipTypeCodes.includes(type)}
                  key={type}
                  onChange={() =>
                    setEventTypeCodes((existing) =>
                      existing.includes(type)
                        ? existing.filter((item) => item !== type)
                        : [...existing, type],
                    )
                  }
                >
                  {DISPLAY_LABEL_FOR_API_RELATIONSHIP[type]}
                </MenuItemCheckbox>
              ))}
            </MenuRenderer>
          </Column>
        </Columns>

        <Query<RelatedHirersQuery, RelatedHirersQueryVariables>
          errorMessage="We couldn’t load your hirers."
          query={RELATED_HIRERS}
          variables={{
            // `hiringOrganizations` has high per-query overhead so request as
            // many as possible at once
            [firstOrLast]: 100,
            filter,
            schemeId: seekAnzSchemeId,
          }}
          wrapperComponent={GutterBox}
        >
          {({ data, fetchMore }) => {
            const { edges, pageInfo } = data.hiringOrganizations;

            const { shouldLoadMore, variables } = processPageInfo(pageInfo);

            const loadMore = () => fetchMore({ variables });

            return (
              <SectionCard>
                <InfiniteScrollingList
                  loadMore={loadMore}
                  shouldLoadMore={shouldLoadMore}
                  isEmpty={edges.length === 0}
                  subject="hirers"
                >
                  {edges.map(({ node }) => (
                    <ScrollingXBox key={node.id.value}>
                      <GutterBox>
                        <Hirer hirer={node} />
                      </GutterBox>
                    </ScrollingXBox>
                  ))}
                </InfiniteScrollingList>
              </SectionCard>
            );
          }}
        </Query>
      </Stack>
    </CardSection>
  );
};
