import type { UseField } from '@seek/forms-ui/lib/Field/useField';
import { required } from '@seek/validators-js';
import {
  type Column,
  Dropdown,
  Notice,
  Stack,
  Text,
  TextField,
} from 'braid-design-system';
import { type ComponentProps, useState } from 'react';

import { FieldRow } from 'src/components/FieldRow/FieldRow';
import { MandatoryLabel } from 'src/components/MandatoryLabel/MandatoryLabel';

const possibleNumberOfMaxEvents = new Array(10)
  .fill(1)
  .map((val, i) => val + i);

export interface DeliveryFormData {
  url?: string;
  maxEventsPerAttempt?: string;
}

interface DeliveryFormProps<T extends DeliveryFormData> {
  disabled: boolean;
  mode: 'create' | 'update';
  rowWidths?: ComponentProps<typeof Column>['width'];
  useField: UseField<T>;
}

export const DeliveryForm = <T extends DeliveryFormData>({
  disabled,
  mode,
  rowWidths = '2/5',
  useField,
}: DeliveryFormProps<T>) => {
  const url = useField({ id: 'url', validators: [required] });
  const maxEventsPerAttempt = useField({
    id: 'maxEventsPerAttempt',
    validators: [required],
  });

  const [disruptiveChange, setDisruptiveChange] = useState<boolean>(false);

  return (
    <Stack space="large">
      <FieldRow
        label={<MandatoryLabel label="URL" />}
        htmlFor="webhookSubscriptionUrl"
        description="The HTTPS URL we can send events to."
        width={rowWidths}
      >
        <TextField
          aria-labelledby="webhookSubscriptionUrlLabel"
          id="webhookSubscriptionUrl"
          disabled={disabled}
          value={url.value ?? ''}
          onChange={(changeEvent) => {
            setDisruptiveChange(true);
            url.onChange(changeEvent);
          }}
          onBlur={url.onBlur}
          tone={url.valid ? 'neutral' : 'critical'}
          message={url.valid ? undefined : 'Required field'}
        />
      </FieldRow>
      <FieldRow
        label={<MandatoryLabel label="Max events per request" />}
        htmlFor="webhookSubscriptionMaxEventsPerAttempt"
        description="The maximum number of events we’ll include in a single request. You can think of this as the batch size."
        width={rowWidths}
      >
        <Dropdown
          {...maxEventsPerAttempt}
          aria-labelledby="webhookSubscriptionMaxEventsPerAttemptLabel"
          id="webhookSubscriptionMaxEventsPerAttempt"
          placeholder="Select size..."
          tone={maxEventsPerAttempt.valid ? 'neutral' : 'critical'}
          message={maxEventsPerAttempt.valid ? undefined : 'Required field'}
          disabled={disabled}
          value={maxEventsPerAttempt.value ?? ''}
        >
          {possibleNumberOfMaxEvents.map((code) => (
            <option key={code} value={code}>
              {code}
            </option>
          ))}
        </Dropdown>
      </FieldRow>

      {mode === 'update' && disruptiveChange ? (
        <Notice>
          <Text>
            Make sure that your software is ready to receive incoming requests
            with this delivery configuration to avoid disruption.
          </Text>
        </Notice>
      ) : null}
    </Stack>
  );
};
