import { type OAuthError, useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { type ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useLogin } from 'src/hooks/auth';
import { extractTokenField } from 'src/utils/token';

import { useDatadog } from '../Datadog';

export const AuthVerifier = ({ children }: { children: ReactNode }) => {
  const { pathname, search } = useLocation();
  const { setIntegrationPartner, setUser } = useDatadog();

  const auth = useAuth0();
  const { login } = useLogin();

  const checkAuth = async (returnTo: string) => {
    if (auth.isLoading || auth.isAuthenticated || pathname === '/authorize') {
      return;
    }
    return login(returnTo);
  };

  useEffect(() => {
    const returnTo = `${pathname}${search}`;
    checkAuth(returnTo);

    if (auth.isAuthenticated) {
      auth
        .getAccessTokenSilently()
        .then((token) => {
          const field = extractTokenField(token, 'integrationPartnerUuid');
          const userId = auth.user?.sub;

          if (field) {
            datadogRum.setUser({ id: userId, integrationPartnerUuid: field });
            setIntegrationPartner(field);
          }

          if (userId) {
            setUser(userId);
          }
        })
        .catch((e: OAuthError) => {
          // Re-prompt for login if error is `login_required`
          if (e.error === 'login_required') {
            return login(returnTo);
          }
          // eslint-disable-next-line no-console
          console.error(e);
          throw e;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, auth.isLoading]);

  return auth.isAuthenticated ? <>{children}</> : null;
};
