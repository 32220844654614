import type { SeekApiPayload } from '@seek/indie-api-types';
import { required } from '@seek/validators-js';
import {
  Actions,
  Box,
  Button,
  Dropdown,
  IconInfo,
  Inline,
  RadioGroup,
  RadioItem,
  Stack,
  Strong,
  Text,
  TextField,
  TooltipRenderer,
} from 'braid-design-system';
import type React from 'react';

import { type FormData, FormProvider, useField } from './form';
import { validVariation } from './validators';

const eventTypeCodes: SeekApiPayload.EventType[] = [
  'CandidateApplicationCreated',
  'CandidateProfilePurchased',
  'HirerRelationshipChanged',
  'PositionProfileClosed',
  'PositionProfilePosted',
];

interface FormProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  resetForm: () => void;
  loading: boolean;
}

const Form = ({ onSubmit, loading, resetForm }: FormProps) => {
  const eventTypeCode = useField({
    id: 'eventTypeCode',
    validators: [required],
    validatorProps: {
      requiredLabel: 'an event type code.',
    },
  });

  const eventVariation = useField({
    id: 'eventVariation',
    validators: [validVariation],
    validatorProps: {
      eventTypeCode: eventTypeCode.value,
    },
  });

  const url = useField({
    id: 'url',
    validators: [required],
    validatorProps: { requiredLabel: 'a URL.' },
  });

  const VariationTooltip = () => (
    <TooltipRenderer
      id="variation-tool-tip"
      tooltip={
        <Text size="small" weight="strong">
          Each event variation corresponds to a different underlying object that
          will contain or omit the specified qualities. This will help you to
          test your software and the different permutations for a given object.
        </Text>
      }
    >
      {({ triggerProps }) => (
        <Box aria-label="Help" {...triggerProps}>
          <IconInfo />
        </Box>
      )}
    </TooltipRenderer>
  );

  return (
    <form onSubmit={onSubmit}>
      <Stack space="gutter">
        <Dropdown
          id="webhooks-playground-event-type-code"
          label="Event Type Code"
          placeholder="Select event type..."
          disabled={loading}
          {...eventTypeCode}
          onChange={(value) => {
            eventTypeCode.onChange(value);
          }}
          tone={eventTypeCode.valid ? 'neutral' : 'critical'}
          message={eventTypeCode.errorMessage}
          description="The event type code of the webhook event to send"
        >
          {eventTypeCodes.map((code) => (
            <option key={code}>{code}</option>
          ))}
        </Dropdown>

        {eventTypeCode.value === 'CandidateApplicationCreated' && (
          <RadioGroup
            id="webhooks-playground-event-variation"
            label={
              <Inline space="xsmall" alignY="center">
                <Strong>CandidateApplicationCreated Event Variation</Strong>
                <VariationTooltip />
              </Inline>
            }
            description="Select one of the following qualities to guarantee for the candidate in the test event."
            message={eventVariation.errorMessage}
            tone={eventVariation.valid ? 'neutral' : 'critical'}
            disabled={loading}
            {...eventVariation}
          >
            <RadioItem
              label="Has attachments (e.g., Résumé)"
              value="HasAttachments"
            />
            <RadioItem label="Has questionnaire" value="HasQuestionnaire" />
            <RadioItem
              label="Is currently employed"
              value="HasCurrentEmployment"
            />
            <RadioItem
              label="Has previous employment"
              value="HasPastEmployment"
            />
            <RadioItem label="New to workforce" value="HasNeverWorked" />
          </RadioGroup>
        )}

        {eventTypeCode.value === 'HirerRelationshipChanged' && (
          <RadioGroup
            id="webhooks-playground-event-variation"
            label={
              <Inline space="xsmall" alignY="center">
                <Strong>HirerRelationshipChanged Event Variation</Strong>
                <VariationTooltip />
              </Inline>
            }
            description="Select the relationship between the playground partner and the hirer in the test event."
            message={eventVariation.errorMessage}
            tone={eventVariation.valid ? 'neutral' : 'critical'}
            disabled={loading}
            {...eventVariation}
          >
            <RadioItem label="All Relationships" value="AllRelationships" />
            <RadioItem
              label="ApplicationExport only"
              value="ApplicationExportOnly"
            />
          </RadioGroup>
        )}

        <TextField
          id="webhooks-playground-url-text-field"
          type="url"
          label="URL"
          placeholder="Enter a URL..."
          tone={url.valid ? 'neutral' : 'critical'}
          message={url.errorMessage}
          description="The URL to POST the webhook event to"
          disabled={loading}
          {...url}
        />
        <Actions>
          <Button type="submit" loading={loading}>
            {loading ? 'Sending' : 'Send'}
          </Button>
          <Button variant="ghost" onClick={resetForm}>
            Reset
          </Button>
        </Actions>
      </Stack>
    </form>
  );
};

interface TestWebhookFormProps {
  onSubmit: (details: FormData) => void;
  loading: boolean;
}

const initialValues: FormData = {
  eventTypeCode: '',
  eventVariation: '',
  url: '',
};

export const TestWebhookForm = ({
  onSubmit,
  loading,
}: TestWebhookFormProps) => (
  <FormProvider initialValues={{ ...initialValues }}>
    {({ handleSubmit, resetForm }) => (
      <Form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
        resetForm={resetForm}
        loading={loading}
      />
    )}
  </FormProvider>
);
