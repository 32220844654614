import {
  Column,
  Columns,
  IconDelete,
  Loader,
  Text,
  TextLinkButton,
} from 'braid-design-system';
import { useState } from 'react';

interface ItemRowProps {
  item: string;
  removeItem: (itemToRemove: string) => Promise<void>;
}

export const ItemRow = ({ item, removeItem }: ItemRowProps) => {
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    await removeItem(item);
    setLoading(false);
  };

  return (
    <Columns space="large" alignY="bottom">
      <Column>
        <Text>{item}</Text>
      </Column>

      <Column width="content">
        <Text tone="critical">
          <TextLinkButton onClick={onClick}>
            {loading ? <Loader size="xsmall" /> : <IconDelete />}
          </TextLinkButton>
        </Text>
      </Column>
    </Columns>
  );
};
