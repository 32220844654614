import { useToast } from 'braid-design-system';
import { useEffect } from 'react';

import { useStatuspage } from 'src/hooks/statuspage';
import { useLocalStorage } from 'src/hooks/storage';

/** Maximum number of viewed incident IDs to save in `localStorage` */
const MAX_VIEWED_IDS = 16;

/**
 * Creates Braid toasts for Atlassian Statuspage incidents & maintenances
 *
 * This can be tested using:
 *  `<StatuspageProvider includeIncidents="all" includeScheduledMaintenances="all">`
 */
export const IncidentToaster = () => {
  const summary = useStatuspage();
  const showToast = useToast();

  // Persist the IDs the user has viewed on Statuspage
  //
  // Braid doesn't tell us when a user dismisses toast; we'll just keep toasting
  // those on page load until the incident is resolved.
  //
  // The `/reset-session` page will reset `localStorage` for testing.
  const [viewedIds, setViewedIds] = useLocalStorage<string[]>(
    'viewed-statuspage-incident-ids',
    [],
  );

  // We can't `useToast` during render according to React Strict Mode
  useEffect(() => {
    if (!summary) {
      return;
    }

    // Toast action to set incident as viewed and redirect to its Statuspage URL
    const viewIncident = (id: string) => ({
      label: 'View on status page',
      onClick: () => {
        setViewedIds([...viewedIds, id].slice(-MAX_VIEWED_IDS));

        // Both incidents and maintenances use `/incidents/{id}` URLs
        window.location.href = [
          summary.page.url,
          'incidents',
          encodeURIComponent(id),
        ].join('/');
      },
    });

    for (const { id, impact, name } of summary.incidents) {
      if (viewedIds.includes(id) || impact === 'none') {
        continue;
      }

      showToast({
        key: 'statuspage-incident',
        tone: 'critical',
        message: `${summary.page.name} incident`,
        description: name,
        action: viewIncident(id),
      });

      return;
    }

    for (const { id, impact, name, status } of summary.scheduled_maintenances) {
      if (
        viewedIds.includes(id) ||
        impact === 'none' ||
        // This sneakily doesn't cover `completed` so we can test locally using
        // `includeScheduledMaintenances="all"`. Completed maintenances aren't
        // returned by the Statuspage summary API normally.
        status === 'scheduled'
      ) {
        continue;
      }

      showToast({
        key: 'statuspage-maintenance',
        tone: 'critical',
        message: `${summary.page.name} maintenance in progress`,
        description: name,
        action: viewIncident(id),
      });

      return;
    }
  }, [summary, showToast, viewedIds, setViewedIds]);

  return null;
};
