import {
  Accordion,
  AccordionItem,
  Divider,
  Heading,
  List,
  Stack,
  Strong,
  Text,
  TextLink,
} from 'braid-design-system';
import { useState } from 'react';

export const ReadonlyUserDescription = () => (
  <Stack space="medium">
    <Text>
      Grants <Strong>read only</Strong> permissions for resources in the
      Developer Dashboard. Users will <Strong>not</Strong> have access to the
      Credentials, GraphQL Explorer and Users pages.
    </Text>

    <Text>Users can expect to do some of the following:</Text>

    <List>
      <Text tone="info">View posted job ad information</Text>
      <Text tone="info">View webhook events</Text>
      <Text tone="info">
        Search for SEEK hirers that you have an active relationship with
      </Text>
    </List>
  </Stack>
);

export const UserDescription = () => (
  <Stack space="medium">
    <Text>
      Grants <Strong>write</Strong> permissions for the{' '}
      <Strong>Webhooks</Strong> and <Strong>Notifications</Strong> pages in the
      Developer Dashboard as well as any permissions associated with the read
      only role. Users will <Strong>not</Strong> have access to the Credentials,
      GraphQL Explorer and Users pages.
    </Text>

    <Text>Users can expect to do some of the following:</Text>

    <List>
      <Text tone="info">
        Create, update, delete and replay webhook subscriptions
      </Text>
      <Text tone="info">Create and update notifications</Text>
    </List>
  </Stack>
);

export const AdminDescription = () => (
  <Stack space="medium">
    <Text>
      Grants <Strong>privileged</Strong> permissions for resources in the
      Developer Dashboard as well as any permissions associated with the
      previous roles.
    </Text>

    <Text>This will allow users to:</Text>

    <List>
      <Text tone="info">View, create and rotate client credentials</Text>
      <Text tone="info">
        Create, update and delete existing Developer Dashboard users
      </Text>
      <Text tone="info">
        Validate queries and mutations via the GraphQL Explorer with your
        playground credentials
      </Text>
    </List>
  </Stack>
);

export const MainContent = () => {
  const [isRoleExpanded, toggleRoleExplanation] = useState(true);

  return (
    <Stack space="large">
      <Text>
        This page manages user access to the Developer Dashboard. It displays a
        user’s personal details and their current role. The following
        capabilities are available:
      </Text>

      <List>
        <Text>Create a new user</Text>
        <Text>Update a user’s role</Text>
        <Text>Delete a user</Text>
        <Text>Reset a user’s MFA</Text>
      </List>

      <Divider />

      <Accordion dividers={false}>
        <AccordionItem
          label="Roles available for assignment"
          id="available_roles"
          expanded={isRoleExpanded}
          onToggle={() => toggleRoleExplanation(!isRoleExpanded)}
        >
          <List space="large" type="number">
            <Stack space="small">
              <Text weight="strong">Read only user</Text>
              <ReadonlyUserDescription />
            </Stack>
            <Stack space="small">
              <Text weight="strong">User</Text>
              <UserDescription />
            </Stack>
            <Stack space="small">
              <Text weight="strong">Administrator</Text>
              <AdminDescription />
            </Stack>
          </List>
        </AccordionItem>
      </Accordion>

      <Divider />

      <Heading level="3">Troubleshooting</Heading>
      <Accordion dividers={false}>
        <AccordionItem
          label="I haven’t received a welcome email"
          id="no_welcome_email"
        >
          <Text>
            Ensure that your colleague has checked their spam or junk mail
            folder. If no email has been received after an hour, you may resend
            the welcome email by deleting and re-creating their user account.
          </Text>
        </AccordionItem>
        <AccordionItem label="I want to reset my password" id="reset_password">
          <Text>
            You can reset your password on the{' '}
            <TextLink href="/user-profile">profile</TextLink> page.
          </Text>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};
