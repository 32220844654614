
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "self-service-credential-management"
    }}>{`Self-service credential management`}</h4>
    <p>{`We’ve added the ability to provision and rotate SEEK API client credentials to the Developer Dashboard.
Previously, you’d have to contact SEEK to manage your credentials.`}</p>
    <p>{`See our revised `}<a parentName="p" {...{
        "href": "/introduction#integration-process"
      }}>{`integration process`}</a>{` to learn more,
and jump to the `}<a parentName="p" {...{
        "href": "https://manage.developer.seek.com/credentials"
      }}>{`Developer Dashboard’s credentials page`}</a>{` to get started.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;