import {
  Box,
  Column,
  Columns,
  IconArrow,
  IconClear,
  Inline,
  Text,
  TextLinkButton,
} from 'braid-design-system';
import { useEffect, useState } from 'react';

const ONE_DAY_MS = 1000 * 60 * 60 * 24;

export const useShowPreview = () => {
  const [isPreview, setIsPreview] = useState(false);
  const [dismissedRecently, setDismissedRecently] = useState(false);

  // useEffect to run client-side only
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const version = searchParams.get('v');

    if (version) {
      setIsPreview(true);
    }

    const dismissedDate = window.localStorage.getItem(
      `seekDeveloperDismissed.v_${version}`,
    );

    if (dismissedDate) {
      const elapsed = new Date().getTime() - new Date(dismissedDate).getTime();
      if (elapsed < ONE_DAY_MS) {
        setDismissedRecently(true);
      }
    }
  }, [setIsPreview]);

  const dismiss = () => {
    setDismissedRecently(true);

    const searchParams = new URLSearchParams(window.location.search);
    const version = searchParams.get('v');

    window.localStorage.setItem(
      `seekDeveloperDismissed.v_${version}`,
      new Date().toISOString(),
    );
  };

  return [isPreview && !dismissedRecently, dismiss] as const;
};

export const PreviewDeploymentBanner = ({
  dismiss,
}: {
  dismiss: () => void;
}) => (
  <Box background="caution" padding="medium">
    <Columns alignY="center" collapseBelow="tablet" space="medium">
      <Column>
        <Text>
          This is a preview version of the SEEK Developer Site. Please avoid
          sharing links to this version with third parties.
        </Text>
      </Column>
      <Column width="content">
        <Inline space="small">
          <Text>
            <TextLinkButton
              icon={<IconArrow direction="right" />}
              onClick={() => {
                const url = new URL(window.location.href);
                url.searchParams.delete('v');
                window.location.assign(url);
              }}
            >
              Live site
            </TextLinkButton>
          </Text>

          <Text>
            <TextLinkButton icon={<IconClear />} onClick={dismiss}>
              Dismiss
            </TextLinkButton>
          </Text>
        </Inline>
      </Column>
    </Columns>
  </Box>
);
