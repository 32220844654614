
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "removal-of-commissiononly-pay-type"
    }}>{`Removal of `}<inlineCode parentName="h4">{`CommissionOnly`}</inlineCode>{` pay type`}</h4>
    <p>{`On 21st December 2023, the `}<inlineCode parentName="p">{`CommissionOnly`}</inlineCode>{` pay type will be removed from the SEEK API.
This pay type sees near-zero usage and can lead to compliance issues with local labour laws and our terms of use.`}</p>
    <p>{`From this date, if a hirer selects the ‘Commission only’ pay option in your software, the SEEK API will fail their subsequent request to `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad"
      }}>{`post a job ad`}</a>{`.
We highly recommend that you remove or disable this option from your job posting workflow.
See our `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/salary#pay-type"
      }}>{`pay type documentation`}</a>{` for more information on valid pay types.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;