import "src/components/MenuItem.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/MenuItem.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA72U21LCMBCG73mKvaRikfQgWh7GSZP0MKTdThoQdHx3x3hqAtTIRe86k/3+/292s8snUsq0wGQFrzOAjnJet2UoRaEzYFSy+Z6qeRhWybFqDnEAC5iTZSQaWMDwJAqCYDMQUHVZ6cwp2czeZstvQ2IMGUpUdh1RbuHPZ3SRSS8ysWFyVFyor/8aki8B9ChrPiqXGA1e952kxwxyiWxrF2QV7oX6tKJsWyrctTw8kzTqHOmsQLbrryBTwzRUlXV7qWEkgBtY2dy9L0dsbu3LxTb34Ms5l/7oy61tjlqTnKPW2Ng3GVujqrE7PT7T21+DWzhpn+P+v07mhqF9J5gOFdU1ZkDgDsh4jnwsR+6TA+0czGcHRB87YNUd/nruzvRwb+1r94tjKKY2LKY2LKc2HGzVypo9ZzArE6zAVofP4lQ1MQ/2HWhLILZ6BgAA\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var activeIndicator = '_1gl5fo43';
export var activeLink = '_1gl5fo41';
export var expander = '_1gl5fo4b';
export var hierarchy = {'1':'_1gl5fo45','2':'_1gl5fo46','3':'_1gl5fo47','4':'_1gl5fo48','5':'_1gl5fo49'};
export var horizontalPadding = {'1':'_1gl5fo4c','2':'_1gl5fo4d','3':'_1gl5fo4e','4':'_1gl5fo4f','5':'_1gl5fo4g'};
export var iconSpace = 'var(--h4yhmx2)';
export var link = '_1gl5fo44';
export var linkInner = '_1gl5fo4a';
export var menuSpace = '_1gl5fo40';
export var pageIcon = '_1gl5fo42';
export var textFocus = '_1gl5fo4h';