import { Text, TextDropdown } from 'braid-design-system';
import { useState } from 'react';

export const useScopeState = () => {
  const [hirerScopedIndicator, setHirerScopedIndicator] = useState<
    boolean | null
  >(null);

  return {
    hirerScopedIndicator,
    setHirerScopedIndicator,
  };
};

type Props = ReturnType<typeof useScopeState>;

export const ScopePicker = ({
  hirerScopedIndicator,
  setHirerScopedIndicator,
}: Props) => (
  <Text>
    <TextDropdown
      id="ScopePickerDropdown"
      label="Scope"
      onChange={setHirerScopedIndicator}
      options={[
        { text: 'All subscriptions', value: null },
        { text: 'Hirer subscriptions', value: true },
        { text: 'Partner subscriptions', value: false },
      ]}
      value={hirerScopedIndicator}
    />
  </Text>
);
