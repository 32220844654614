
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "profile-apply-extensions"
    }}>{`Profile Apply extensions`}</h4>
    <p>{`We’ve extended `}<a parentName="p" {...{
        "href": "/introduction/release-notes#profile-apply"
      }}>{`Profile Apply`}</a>{` with additional structured information on the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/CandidateProfile"
      }}><inlineCode parentName="a">{`CandidateProfile`}</inlineCode></a>{` object:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`certifications`}</inlineCode>{` lists certifications and licences the candidate holds.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`education.descriptions`}</inlineCode>{` adds free-text descriptions to the candidate’s education history.`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query ($id: String!) {\\n  candidateProfile(id: $id) {\\n    education {\\n      descriptions\\n    }\\n    certifications {\\n      name\\n      issuingAuthority {\\n        name\\n      }\\n      issued\\n      effectiveTimePeriod {\\n        validTo\\n      }\\n      descriptions\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":8,\"column\":1,\"offset\":349},\"end\":{\"line\":27,\"column\":4,\"offset\":631},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n  \\\"id\\\": \\\"seekAnzPublicTest:candidateProfile:apply:HmvRHVfJ7FBJMz6jPKuCkQ\\\"\\n}\",\"position\":{\"start\":{\"line\":29,\"column\":1,\"offset\":633},\"end\":{\"line\":33,\"column\":4,\"offset\":722},\"indent\":[1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":null,\"value\":\"query": true,
        "($id:": true,
        "String!)": true,
        "{\\n": true,
        "": true,
        "candidateProfile(id:": true,
        "$id)": true,
        "education": true,
        "descriptions\\n": true,
        "}\\n": true,
        "certifications": true,
        "name\\n": true,
        "issuingAuthority": true,
        "issued\\n": true,
        "effectiveTimePeriod": true,
        "validTo\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":8,\"column\":1,\"offset\":349},\"end\":{\"line\":27,\"column\":4,\"offset\":631},\"indent\":[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":null,\"value\":\"{\\n": true,
        "\\\"id\\\":": true,
        "\\\"seekAnzPublicTest:candidateProfile:apply:HmvRHVfJ7FBJMz6jPKuCkQ\\\"\\n}\",\"position\":{\"start\":{\"line\":29,\"column\":1,\"offset\":633},\"end\":{\"line\":33,\"column\":4,\"offset\":722},\"indent\":[1,1,1,1]}}]": true
      }}>{`query ($id: String!) {
  candidateProfile(id: $id) {
    education {
      descriptions
    }
    certifications {
      name
      issuingAuthority {
        name
      }
      issued
      effectiveTimePeriod {
        validTo
      }
      descriptions
    }
  }
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;