import {
  Column,
  Columns,
  Hidden,
  Stack,
  Strong,
  Text,
} from 'braid-design-system';
import { sub } from 'date-fns';
import { InlineCode } from 'scoobie';

import { NoWrapText } from 'src/components/NoWrapText/NoWrapText';
import { TwoColumnGrid } from 'src/components/TwoColumnGrid/TwoColumnGrid';
import type { WebhookRequestsForSubscriptionQuery } from 'src/types/graphql';

import { DateTimeColumn } from '../DateTimeColumn/DateTimeColumn';

import { RequestStatusBadge } from './RequestStatusBadge';

type WebhookRequest =
  NonNullable<WebhookRequestsForSubscriptionQuery>['webhookRequestsForSubscription']['edges'][0]['node'];

const EVENT_DEHYDRATION_DAYS = 90;

const BodyCell = ({ request }: { request: WebhookRequest }) => {
  const { createDateTime, attempts } = request;

  const eventDehydrationDateTime = sub(new Date(), {
    days: EVENT_DEHYDRATION_DAYS,
  }).toISOString();

  if (attempts.length === 0 && createDateTime < eventDehydrationDateTime) {
    // Our events dehydrate after 90 days while requests live for 120 days
    return (
      <NoWrapText size="small" tone="secondary">
        Data unavailable after {EVENT_DEHYDRATION_DAYS} days
      </NoWrapText>
    );
  }

  return (
    <>
      {/* Above mobile, show a list of subject OIDs in the body */}
      <Hidden below="tablet">
        <Stack space="small">
          {attempts.map((attempt) => (
            <NoWrapText size="small" key={attempt.event.oid}>
              <InlineCode>{attempt.event.oid}</InlineCode>
            </NoWrapText>
          ))}
        </Stack>
      </Hidden>

      {/* On mobile just show a summary; it's too cramped otherwise. */}
      <Hidden above="mobile">
        <NoWrapText size="small">
          {attempts.length === 1 ? '1 event' : `${attempts.length} events`}
        </NoWrapText>
      </Hidden>
    </>
  );
};

interface WebhookRequestProps {
  request: WebhookRequest;
}

export const WebhookRequest = ({ request }: WebhookRequestProps) => {
  const { createDateTime, requestId, descriptionCode, statusCode, latencyMs } =
    request;

  return (
    <Columns space="gutter">
      <DateTimeColumn>{new Date(createDateTime)}</DateTimeColumn>

      <Column>
        <TwoColumnGrid space="small">
          <Text align="right" size="small" weight="strong">
            ID
          </Text>
          <NoWrapText size="small">
            <InlineCode>{requestId}</InlineCode>
          </NoWrapText>

          <Text align="right" size="small" weight="strong">
            Body
          </Text>
          <BodyCell request={request} />
        </TwoColumnGrid>
      </Column>

      <Column width="content">
        <Stack space="small" align="right">
          <RequestStatusBadge description={descriptionCode} code={statusCode} />

          {typeof latencyMs === 'number' && (
            <NoWrapText size="small" tone="secondary">
              in <Strong>{latencyMs.toLocaleString()}ms</Strong>
            </NoWrapText>
          )}
        </Stack>
      </Column>
    </Columns>
  );
};
