import { Box } from 'braid-design-system';
import type { ComponentProps } from 'react';

type Props = Omit<ComponentProps<typeof Box>, 'borderRadius' | 'boxShadow'>;

export const BorderCard = (props: Props) => (
  <Box borderRadius="large" boxShadow="small">
    <Box
      background="surface"
      paddingX="gutter"
      paddingY="large"
      {...props}
      borderRadius="large"
      boxShadow="borderNeutralLight"
    />
  </Box>
);
