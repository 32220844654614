
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "hirer-relationship-changed-events"
    }}>{`Hirer Relationship Changed Events`}</h4>
    <p>{`To use the SEEK API on behalf of a given hirer, you need to have the appropriate `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships"
      }}>{`hirer relationships`}</a>{` configured.
Keeping these relationships up to date was difficult, as changes made after your initial integration had to be manually communicated by SEEK.`}</p>
    <p>{`You might have addressed this problem by asking SEEK’s customer support team for spreadsheets of the current state of relationships, or maybe by periodically querying the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/hiringOrganizations"
      }}><inlineCode parentName="a">{`hiringOrganizations`}</inlineCode>{` query`}</a>{` and calculating a diff.`}</p>
    <p>{`To make this easier, we’ve introduced a new `}<inlineCode parentName="p">{`HirerRelationshipChanged`}</inlineCode>{` event type to the SEEK API.
Like existing event types, you can `}<a parentName="p" {...{
        "href": "/events/webhooks#creating-a-subscription"
      }}>{`create a webhook subscription`}</a>{` that will listen for new `}<inlineCode parentName="p">{`HirerRelationshipChanged`}</inlineCode>{` events and use them to automatically update your software.`}</p>
    <p>{`A sample event might look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "sample-webhook-body",
        "sample-webhook-body": true
      }}>{`{
  "events": [
    {
      "id": "seekAnzPublicTest:event:events:RJrWs6Kw13TvACTTXG6qZg",
      "type": "HirerRelationshipChanged",

      "createDateTime": "2020-10-20T23:13:58.804Z",

      "hirerId": "seekAnzPublicTest:organization:seek:93WyyF1h"
    }
  ],
  "subscriptionId": "seekAnzPublicTest:webhookSubscription:events:RNzsabxEX56cuRepCD9A8j",
  "url": "https://example.com/webhook"
}
`}</code></pre>
    <p>{`You can then use the `}<inlineCode parentName="p">{`hirerId`}</inlineCode>{` contained in the event to get the current state of the relationship from the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/hiringOrganization"
      }}><inlineCode parentName="a">{`hiringOrganization`}</inlineCode>{` query`}</a>{`.`}</p>
    <p>{`For more information, see the `}<a parentName="p" {...{
        "href": "/auth/hirer-relationships#events"
      }}>{`events section`}</a>{` of the hirer relationships documentation.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;