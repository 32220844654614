
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "features"
    }}>{`Features`}</h1>
    <p>{`SEEK job ads require a number of SEEK-specific inputs:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/contact-people"
          }}>{`Contact people`}</a>{` specify who to contact if there’s a problem with the job ad.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/locations"
          }}>{`Locations`}</a>{` specify the location of the position.
These can either be suggested interactively or mapped from a partner’s location ontology.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/job-categories"
          }}>{`Job categories`}</a>{` specify the job’s occupational category.
These can either be suggested interactively or mapped from a partner’s job category ontology.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/use-cases/job-posting/ad-selection"
          }}>{`Ad selection`}</a>{` enables the hirer to interactively select a SEEK `}<a parentName="p" {...{
            "href": "https://talent.seek.com.au/products/jobads"
          }}>{`ad product`}</a>{`.
It returns the ad product’s exact price based on the hirer, location, role title & job category.`}</p>
      </li>
    </ul>
    <p>{`The following inputs are also optionally available:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Attach a `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/questionnaires"
          }}>{`questionnaire`}</a>{` to be filled out by the candidate.
The candidate’s responses are available via `}<a parentName="p" {...{
            "href": "/use-cases/application-export/questionnaire-submissions"
          }}>{`Optimised Apply`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Associate a `}<a parentName="p" {...{
            "href": "/use-cases/job-posting/branding"
          }}>{`brand`}</a>{` to promote the hirer’s brand when the appropriate `}<a parentName="p" {...{
            "href": "https://talent.seek.com.au/products/jobads"
          }}>{`ad product`}</a>{` is selected.`}</p>
      </li>
    </ul>
    <p>{`Once these inputs have been collected you can create a job ad using the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/posting-a-job-ad"
      }}>{`job posting`}</a>{` mutations.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;