import { useAuth0 } from '@auth0/auth0-react';
import { Actions, Heading, Loader, Stack, Text } from 'braid-design-system';

import { GutterBox } from 'src/components/GutterBox/GutterBox';
import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';
import { SectionCard } from 'src/components/SectionCard/SectionCard';
import { AuthVerifier } from 'src/components/auth/AuthVerifier';

import { ChangePassword } from './components/ChangePassword';
import { Name } from './components/Name';
import { ResetSelfMfa } from './components/ResetSelfMfa';

export const UserProfilePage = () => {
  const { user, isLoading } = useAuth0();

  return (
    <AuthVerifier>
      <PageWrapper
        heading={
          <Heading level="2" weight="weak">
            User profile
          </Heading>
        }
      >
        <SectionCard>
          <GutterBox>
            {isLoading ? (
              <Loader />
            ) : (
              <Stack space="medium">
                <Name initialUsername={user?.name} />
                <Text>{user?.email}</Text>
                <Actions>
                  <ResetSelfMfa />
                  <ChangePassword />
                </Actions>
              </Stack>
            )}
          </GutterBox>
        </SectionCard>
      </PageWrapper>
    </AuthVerifier>
  );
};
