import { Heading } from 'braid-design-system';

import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';
import { AuthVerifier } from 'src/components/auth/AuthVerifier';

import { ConfigSection } from './components/ConfigSection/ConfigSection';
import { HistorySection } from './components/HistorySection/HistorySection';

export const NotificationsPage = () => (
  <AuthVerifier>
    <PageWrapper
      heading={
        <Heading level="2" weight="weak">
          Notifications
        </Heading>
      }
    >
      <ConfigSection />
      <HistorySection />
    </PageWrapper>
  </AuthVerifier>
);
