
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "extended-location-hierarchy-for-asia"
    }}>{`Extended location hierarchy for Asia`}</h4>
    <p>{`We’ve extended our location hierarchy and `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/locations#option-1-build-a-seek-specific-location-input"
      }}>{`location suggest`}</a>{` with increased granularity in the following regions where the SEEK Group operates:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}><p parentName="th"><strong parentName="p">{`Code`}</strong></p></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><p parentName="th"><strong parentName="p">{`Name`}</strong></p></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`HK`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Hong Kong`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`ID`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Indonesia`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`MY`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Malaysia`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`PH`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`The Philippines`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`SG`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Singapore`}</p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><p parentName="td">{`TH`}</p></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><p parentName="td">{`Thailand`}</p></td>
        </tr>
      </tbody>
    </table>
    <p>{`The hierarchy only extends to the country level (e.g. Fiji) outside of our core Asia-Pacific locations.`}</p>
    <p>{`This enhances efficiency and accuracy when posting and searching for international jobs on the SEEK Australia & New Zealand marketplace,
and lays the groundwork for the SEEK API to support Jobstreet & Jobsdb markets in future.`}</p>
    <p>{`For the time being, job ads posted to deprecated locations will be mapped to the corresponding new locations and will continue to function as expected across the SEEK API.
If you’re using the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/locationSuggestions"
      }}><inlineCode parentName="a">{`locationSuggestions`}</inlineCode>{` query`}</a>{`, you will automatically receive our new locations and will not have to modify your integration.
If you’re caching locations or using a static mapping to `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/locations#option-2-map-from-your-internal-location-hierarchy"
      }}>{`map from your internal location hierarchy`}</a>{`,
we will contact you at a future date to refresh your data before we remove support for deprecated locations.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;