import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useDebounce } from 'use-debounce';

import { useCriticalErrorsOnlyTrackingApolloClient } from 'src/hooks/apollo';
import type {
  HiringOrganizationByAdvertiserIdQuery,
  HiringOrganizationByAdvertiserIdQueryVariables,
  HiringOrganizationByOidQuery,
  HiringOrganizationByOidQueryVariables,
} from 'src/types/graphql';

import {
  HIRING_ORGANIZATION_BY_ADVERTISER_ID,
  HIRING_ORGANIZATION_BY_OID,
} from './queries';

type SearchTermType = 'advertiserId' | 'oid';

const inferSearchTermType = (value: string): SearchTermType => {
  if (value && Number.isSafeInteger(Number(value))) {
    return 'advertiserId';
  }

  return 'oid';
};

export const useHirerQuery = (searchTerm: string) => {
  const [debouncedSearchTerm] = useDebounce(searchTerm.trim(), 500);

  const searchTermType = inferSearchTermType(debouncedSearchTerm);

  const [advertiserIdQuery, advertiserIdResult] = useLazyQuery<
    HiringOrganizationByAdvertiserIdQuery,
    HiringOrganizationByAdvertiserIdQueryVariables
  >(HIRING_ORGANIZATION_BY_ADVERTISER_ID, {
    // Lookups by ID return FORBIDDEN if no relationship
    client: useCriticalErrorsOnlyTrackingApolloClient(),
  });

  const [oidQuery, oidResult] = useLazyQuery<
    HiringOrganizationByOidQuery,
    HiringOrganizationByOidQueryVariables
  >(HIRING_ORGANIZATION_BY_OID, {
    client: useCriticalErrorsOnlyTrackingApolloClient(),
  });

  useEffect(() => {
    switch (searchTermType) {
      case 'advertiserId':
        advertiserIdQuery({
          variables: {
            id: Number(debouncedSearchTerm),
          },
        });
        return;

      case 'oid':
        oidQuery({
          variables: {
            id: debouncedSearchTerm,
          },
        });
        return;
    }
  }, [advertiserIdQuery, debouncedSearchTerm, searchTermType, oidQuery]);

  return useMemo(() => {
    switch (searchTermType) {
      case 'advertiserId':
        return {
          ...advertiserIdResult,
          data: advertiserIdResult.data?.seekAnzAdvertiser,
        };

      case 'oid':
        return {
          ...oidResult,
          data: oidResult.data?.hiringOrganization,
        };
    }
  }, [advertiserIdResult, oidResult, searchTermType]);
};
