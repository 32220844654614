import { format } from '@seek/currency';

import type { JobAdPreviewQueryVariables } from 'src/types/graphql';

import type { PositionProfile, RemunerationRange } from './types';

export const formatRemunerationRange = (range: RemunerationRange) => {
  const options = {
    currency: range.minimumAmount.currency,
    locale: 'en-AU',
    display: 'code',
  } as const;
  const minimum = format(range.minimumAmount.value, options);

  const interval = range.intervalCode.toLocaleLowerCase();

  if (!range.maximumAmount) {
    return `${minimum} per ${interval}`;
  }

  const maximum = format(range.maximumAmount.value, options);
  return `${minimum} - ${maximum} per ${interval}`;
};

export const mapPositionProfileToPreviewInput = (
  positionProfile: PositionProfile,
  seekAnzWorkTypeCode: NonNullable<PositionProfile['seekAnzWorkTypeCode']>,
): JobAdPreviewQueryVariables => ({
  positionProfile: {
    offeredRemunerationPackage: {
      descriptions: positionProfile.offeredRemunerationPackage.descriptions,
    },
    positionOrganizations: positionProfile.positionOrganizations.map(
      (organization) => organization.id.value,
    ),
    positionTitle: positionProfile.positionTitle,
    postingInstructions: positionProfile.postingInstructions.map(
      (instruction) => {
        const applicationMethods = instruction.applicationMethods.flatMap(
          (method) =>
            method.applicationUri
              ? {
                  applicationUri: {
                    url: method.applicationUri.url,
                  },
                }
              : [],
        );

        return {
          applicationMethods: applicationMethods.length
            ? applicationMethods
            : undefined,
          brandingId: instruction.brandingId,
          seekAnzAdvertisementType: instruction.seekAnzAdvertisementType,
        };
      },
    ),
    seekAnzWorkTypeCode,
    jobCategories: positionProfile.jobCategories.map(
      (category) => category.id.value,
    ),
    positionLocation: positionProfile.positionLocation.map(
      (location) => location.id.value,
    ),
    positionFormattedDescriptions:
      positionProfile.positionFormattedDescriptions.map(
        ({ content, descriptionId }) => ({
          content,
          descriptionId: descriptionId.value,
        }),
      ),
    profileId: positionProfile.profileId.value,
    seekVideo: positionProfile.seekVideo
      ? {
          seekAnzPositionCode: positionProfile.seekVideo.seekAnzPositionCode,
          url: positionProfile.seekVideo.url,
        }
      : undefined,
  },
});
