import { createForm } from '@seek/forms-ui';
import { Actions, Button, Stack } from 'braid-design-system';
import type React from 'react';

import { DeliveryForm } from 'src/pages/manage/webhooks/components/SubscriptionForm/DeliveryForm';

export interface UpdateDeliveryFormData {
  url: string;
  maxEventsPerAttempt: string;
}

const { FormProvider, useField } = createForm<
  UpdateDeliveryFormData,
  Record<string, never>,
  'en'
>();
export { FormProvider };

interface UpdateDeliveryFormProps {
  loading: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  closeDialog: () => void;
}

export const UpdateDeliveryForm = ({
  loading,
  onSubmit,
  closeDialog,
}: UpdateDeliveryFormProps) => (
  <form onSubmit={onSubmit}>
    <Stack space="large">
      <DeliveryForm
        disabled={loading}
        mode="update"
        rowWidths="1/2"
        useField={useField}
      />
      <Actions>
        <Button type="submit">{loading ? 'Updating' : 'Update'}</Button>
        <Button variant="transparent" onClick={() => closeDialog()}>
          Cancel
        </Button>
      </Actions>
    </Stack>
  </form>
);
