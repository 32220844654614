import { Alert, Column, Columns, Heading, Text } from 'braid-design-system';
import type { ComponentProps } from 'react';
import { SmartTextLink } from 'scoobie';

import type * as Statuspage from 'src/types/statuspage';

/**
 * Maps a rolled up status indicator to an `Alert` tone
 *
 * <https://support.atlassian.com/statuspage/docs/top-level-status-and-incident-impact-calculations/>
 */
const TOP_LEVEL_STATUS_INDICATOR_TONE: Record<
  Statuspage.TopLevelStatusIndicator,
  ComponentProps<typeof Alert>['tone']
> = {
  none: 'positive',
  minor: 'caution',
  major: 'critical',

  // It's unclear in what situation qualifies as critical. Possibly global
  // thermonuclear war?
  critical: 'critical',
};

/**
 * Renders Atlassian Statuspage's rolled up status of the entire SEEK API
 */
export const TopLevelStatus = ({
  status,
  pageName,
  statusPageUrl,
}: {
  status: Statuspage.TopLevelStatus;
  pageName: string;
  statusPageUrl: string;
}) => (
  <Alert tone={TOP_LEVEL_STATUS_INDICATOR_TONE[status.indicator]}>
    <Columns space="gutter" collapseBelow="tablet">
      <Column>
        <Heading level="4">{pageName} status</Heading>
      </Column>

      <Column width="content">
        <Text>
          <SmartTextLink href={statusPageUrl}>
            {status.description}
          </SmartTextLink>
        </Text>
      </Column>
    </Columns>
  </Alert>
);
