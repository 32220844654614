
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "browser-tokens"
    }}>{`Browser tokens`}</h1>
    <p>{`Browser tokens allow you to query the SEEK API directly from a hirer’s browser or mobile app.
This can reduce the complexity and overhead of mediating SEEK API access through your software’s backend.`}</p>
    <p>{`A browser token is scoped to a SEEK hirer and a set of actions that can be performed on the hirer’s behalf.
Their restricted scope makes them safe to send to authenticated users of your frontend.`}</p>
    <p>{`Our GraphQL schema supports browser tokens for features that can be used interactively by hirers.
A query or mutation’s `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/"
      }}>{`schema documentation`}</a>{` will indicate which scope it accepts.
If browser tokens aren’t mentioned the operation will only accept `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`partner tokens`}</a>{`.`}</p>
    <h2 {...{
      "id": "request-parameters"
    }}>{`Request parameters`}</h2>
    <p>{`When requesting a browser token you provide three parameters along with your `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`partner token`}</a>{`:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><inlineCode parentName="p">{`hirerId`}</inlineCode>{` is the SEEK hirer `}<a parentName="p" {...{
            "href": "/graphql/seek-api-conventions#object-identifiers"
          }}>{`object identifier`}</a>{` associated with your frontend’s user.
The returned browser token will only be able to access data related to the specified hirer.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><inlineCode parentName="p">{`scope`}</inlineCode>{` is a space-separated list of permitted scopes, e.g. `}<inlineCode parentName="p">{`query:ontologies`}</inlineCode>{` or `}<inlineCode parentName="p">{`query:ad-products query:organizations`}</inlineCode>{`.
Each scope represents an action your frontend can perform with the returned browser token.`}</p>
        <p parentName="li">{`Queries or mutations accepting browser tokens will indicate the required scope in their `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/"
          }}>{`schema documentation`}</a>{`.
You can combine multiple scopes together to allow a browser token to be reused across different operations.
However, including unnecessary scopes increases the security impact of a lost or compromised token.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><inlineCode parentName="p">{`userId`}</inlineCode>{` is a partner-specified identifier for the end user of your software.`}</p>
        <p parentName="li">{`For effective tracking and debugging this should uniquely identify an end user.
Do not include any `}<a parentName="p" {...{
            "href": "https://www.oaic.gov.au/privacy/privacy-guidance-for-organisations-and-government-agencies/handling-personal-information/what-is-personal-information"
          }}>{`personal information`}</a>{` such as a legal name or email address.
Instead, you can use an anonymous identifier such as a numeric ID or UUID assigned by your software.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "requesting-a-browser-token"
    }}>{`Requesting a browser token`}</h2>
    <p>{`Exchange a `}<a parentName="p" {...{
        "href": "/auth/partner-tokens"
      }}>{`partner token`}</a>{` for a browser token using `}<inlineCode parentName="p">{`graphql.seek.com`}</inlineCode>{`.`}</p>
    <img alt="" data-scoobie-style="none" src={require('../../../mermaid/.ee81f12355e80b57ac106da87b3a07f9cccdfb49.mmd.svg')} title="Browser token exchange" />
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Your frontend requests a browser token from your backend.`}</p>
        <p parentName="li">{`If a user switches to a different SEEK hirer account in your software,
your software should request a token for the new hirer ID.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend authenticates and authorises the user.`}</p>
        <p parentName="li">{`Your software is responsible for verifying that the user is authorised to access a given hirer ID.
A user must not be able to request a browser token for an arbitrary organization that they do not belong to.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend requests a `}<a parentName="p" {...{
            "href": "/auth/partner-tokens"
          }}>{`partner token`}</a>{` if one isn’t in cache.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><inlineCode parentName="p">{`auth.seek.com`}</inlineCode>{` issues your backend a partner token.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend calls the browser authentication endpoint with the partner token and the `}<a parentName="p" {...{
            "href": "#request-parameters"
          }}>{`request parameters`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-http",
            "metastring": "label=\"Request\"",
            "label": "\"Request\""
          }}>{`POST https://graphql.seek.com/auth/token HTTP/1.1
Authorization: Bearer PARTNER_TOKEN_HERE
Content-Type: application/json
User-Agent: YourPartnerService/1.2.3

{
  "hirerId": "seekAnzPublicTest:organization:seek:93WyyF1h",
  "scope": "query:ad-products query:ontologies query:organizations",
  "userId": "317665"
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li"><inlineCode parentName="p">{`graphql.seek.com`}</inlineCode>{` validates your relationship with the SEEK hirer and issues your backend a browser token.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-http",
            "metastring": "label=\"Response\"",
            "label": "\"Response\""
          }}>{`HTTP/1.1 200 OK
Content-Type: application/json

{
  "access_token": "BROWSER_TOKEN_HERE",
  "expires_in": 3600,
  "token_type": "Bearer"
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your backend returns the browser token to your frontend.`}</p>
        <p parentName="li">{`Your frontend must cache the token for the number of seconds specified in the response’s `}<inlineCode parentName="p">{`expires_in`}</inlineCode>{`.
The cache expiry must be read from each response;
it cannot be hardcoded as the token lifetime is dynamic and may be updated without notice.
Alternatively, you can use an `}<a parentName="p" {...{
            "href": "/graphql/error-responses#unauthenticated"
          }}><inlineCode parentName="a">{`UNAUTHENTICATED`}</inlineCode>{` error`}</a>{` from the GraphQL endpoint to trigger a new token request.
Caching browser tokens is important for frontend performance as requesting a new token can require multiple steps.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your frontend passes the browser token in the HTTP `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header when making requests to the GraphQL endpoint.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-http",
            "metastring": "label=\"Request\"",
            "label": "\"Request\""
          }}>{`POST https://graphql.seek.com/graphql HTTP/1.1
Authorization: Bearer BROWSER_TOKEN_HERE
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "token-expiration"
    }}>{`Token expiration`}</h2>
    <p>{`Re-initiate the token exchange flow in the above section to obtain a new browser token.
Note that this flow does not feature a refresh token.`}</p>
    <p>{`Using a browser token right up to its expiry may lead to expiration occurring mid-flight due to clock drift or request latency.
Consider leaving a reasonable buffer of around a minute, or obtaining a new browser token and retrying the request on an `}<a parentName="p" {...{
        "href": "/graphql/error-responses#unauthenticated"
      }}><inlineCode parentName="a">{`UNAUTHENTICATED`}</inlineCode>{` error`}</a>{`.`}</p>
    <h2 {...{
      "id": "introspecting-browser-tokens"
    }}>{`Introspecting browser tokens`}</h2>
    <p>{`You can use the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/self"
      }}><inlineCode parentName="a">{`self`}</inlineCode>{` query`}</a>{` to return the associated SEEK hirer for a browser token.
This requires that the token includes the `}<inlineCode parentName="p">{`query:organizations`}</inlineCode>{` scope.
If the token has expired the query will fail with an `}<a parentName="p" {...{
        "href": "/graphql/error-responses#unauthenticated"
      }}><inlineCode parentName="a">{`UNAUTHENTICATED`}</inlineCode>{` error`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scoobie-merged-code",
        "metastring": "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":\"label=\\\"Query\\\" hirerId=\\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\" scope=\\\"query:organizations\\\"\",\"value\":\"query {\\n  self {\\n    hirer {\\n      id {\\n        value\\n      }\\n      name\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":131,\"column\":1,\"offset\":5603},\"end\":{\"line\":142,\"column\":4,\"offset\":5799},\"indent\":[1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label=\\\"Result\\\"\",\"value\":\"{\\n  \\\"self\\\": {\\n    \\\"hirer\\\": {\\n      \\\"id\\\": {\\n        \\\"value\\\": \\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\"\\n      },\\n      \\\"name\\\": \\\"Acme Corp\\\"\\n    }\\n  }\\n}\",\"position\":{\"start\":{\"line\":144,\"column\":1,\"offset\":5801},\"end\":{\"line\":155,\"column\":4,\"offset\":5981},\"indent\":[1,1,1,1,1,1,1,1,1,1,1]}}]",
        "[{\"type\":\"code\",\"lang\":\"graphql\",\"meta\":\"label": "\\\"Query\\\"",
        "hirerId": "\\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\"",
        "scope": "\\\"query:organizations\\\"\",\"value\":\"query",
        "{\\n": true,
        "": true,
        "self": true,
        "hirer": true,
        "id": true,
        "value\\n": true,
        "}\\n": true,
        "name\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":131,\"column\":1,\"offset\":5603},\"end\":{\"line\":142,\"column\":4,\"offset\":5799},\"indent\":[1,1,1,1,1,1,1,1,1,1,1]}},{\"type\":\"code\",\"lang\":\"json\",\"meta\":\"label": "\\\"Result\\\"\",\"value\":\"{\\n",
        "\\\"self\\\":": true,
        "\\\"hirer\\\":": true,
        "\\\"id\\\":": true,
        "\\\"value\\\":": true,
        "\\\"seekAnzPublicTest:organization:seek:93WyyF1h\\\"\\n": true,
        "},\\n": true,
        "\\\"name\\\":": true,
        "\\\"Acme": true,
        "Corp\\\"\\n": true,
        "}\\n}\",\"position\":{\"start\":{\"line\":144,\"column\":1,\"offset\":5801},\"end\":{\"line\":155,\"column\":4,\"offset\":5981},\"indent\":[1,1,1,1,1,1,1,1,1,1,1]}}]": true
      }}>{`query {
  self {
    hirer {
      id {
        value
      }
      name
    }
  }
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;