import { jwtDecode } from 'jwt-decode';

export const extractTokenField = (
  token: string,
  field: string,
): unknown | undefined => {
  const decodedToken = jwtDecode<Record<string, unknown>>(token, {
    header: false,
  });

  return Object.entries(decodedToken).find(([key, _]) =>
    key.includes(field),
  )?.[1];
};
