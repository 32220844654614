
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Actions, ButtonLink, IconNewWindow } from 'braid-design-system';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "job-ad-preview"
    }}>{`Job ad preview`}</h1>
    <p>{`Your software can provide a preview of how a job ad will appear on SEEK.
This helps a hirer to spot issues prior to posting and provides them with confidence that their job ad will appropriately represent their position and organization.`}</p>

    <Actions mdxType="Actions">
  <ButtonLink href="https://job-ad-preview.seek.com/anzPublicTest?id=seekAnzPublicTest:postedPositionProfilePreview:seekApi:37EWfWxhwVEGTT5BcjfE2L" icon={<IconNewWindow mdxType="IconNewWindow" />} size="small" target="_blank" tone="brandAccent" mdxType="ButtonLink">
    Try it out
  </ButtonLink>
    </Actions>
    <p>{`If you haven’t yet built this feature into your software,
check out the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/managing-job-ads/previewing-a-job-ad"
      }}>{`previewing a job ad`}</a>{` documentation.`}</p>
    <p>{`If previews are already available in your software,
we have seamlessly extended them to support our new `}<a parentName="p" {...{
        "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/locations"
      }}>{`location`}</a>{` and `}<a parentName="p" {...{
        "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/salary"
      }}>{`salary`}</a>{` options.
However, you should review two other changes to the `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/query/postedPositionProfilePreview"
      }}><inlineCode parentName="a">{`postedPositionProfilePreview`}</inlineCode></a>{` query:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/ad-selection"
          }}>{`Ad selection`}</a></p>
        <p parentName="li">{`Once you have integrated our new panel or GraphQL query,
omit the deprecated `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/PostedPositionProfilePreview_PostingInstructionInput/field/seekAnzAdvertisementType"
          }}><inlineCode parentName="a">{`seekAnzAdvertisementType`}</inlineCode></a>{` field in favour of a `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/PostedPositionProfilePreview_PostingInstructionInput/field/seekAdvertisementProductId"
          }}><inlineCode parentName="a">{`seekAdvertisementProductId`}</inlineCode></a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/migration-guides/jobstreet-and-jobsdb-uplift/phase-2-job-posting/questionnaires"
          }}>{`Questionnaires`}</a></p>
        <p parentName="li">{`If your software integrates with SEEK questionnaires,
the job ad page will display a summary of the questions posed by the hirer.
Supply a `}<a parentName="p" {...{
            "href": "https://developer.seek.com/schema/#/named-type/PostedPositionProfilePreview_PositionProfileInput/field/seekApplicationQuestionnaireId"
          }}><inlineCode parentName="a">{`seekApplicationQuestionnaireId`}</inlineCode></a>{` for an accurate preview of the job ad.`}</p>
      </li>
    </ol>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;