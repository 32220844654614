
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "updated-messaging-for-ad-selection-with-graphql"
    }}>{`Updated messaging for ad selection with GraphQL`}</h4>
    <p>{`We’ve updated the guidance for custom Ad Selection Panels to help hirers understand how prices are set by SEEK:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`We’ve provided instructions on how to display a scheduled pricing caveat and trigger a tooltip explaining how SEEK sets ad prices.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`We’ve updated the sample images to show how the caveat and tooltip could be shown to the users.`}</p>
      </li>
    </ul>
    <p>{`This reflects the changes recently made to the `}<a parentName="p" {...{
        "href": "/introduction/release-notes#ad-selection-panel-tooltip"
      }}>{`SEEK-built Ad Selection Panel’s tooltip`}</a>{`.
If you’ve implemented a custom Ad Selection Panel you should incorporate the updated messaging into your software, especially if your software supports scheduling job ads to post on SEEK on a future date.`}</p>
    <p>{`See the “Scheduling pricing caveat” and “How are prices set” sections of the `}<a parentName="p" {...{
        "href": "/use-cases/job-posting/ad-selection/graphql#field-mapping"
      }}>{`field mapping`}</a>{` documentation for further guidance.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;