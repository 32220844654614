
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "postedpositionprofileseekcreatedbyselfindicator-field"
    }}><inlineCode parentName="h4">{`PostedPositionProfile.seekCreatedBySelfIndicator`}</inlineCode>{` field`}</h4>
    <p>{`The SEEK API provides access to the job ads and candidate applications of your hirers.
This may include job ads posted directly by a hirer on the SEEK employer website.`}</p>
    <p>{`While we recommend handling job ads and candidate applications posted outside of your software,
we understand that this isn’t always possible depending on the design of your software.
The new `}<a parentName="p" {...{
        "href": "https://developer.seek.com/schema/#/named-type/PostedPositionProfile/field/seekCreatedBySelfIndicator"
      }}><inlineCode parentName="a">{`PostedPositionProfile.seekCreatedBySelfIndicator`}</inlineCode></a>{` field allows you to identify whether your software posted a given job ad.`}</p>
    <p>{`See the updated `}<a parentName="p" {...{
        "href": "/use-cases/application-export#job-ads-posted-outside-of-your-software"
      }}>{`Job ads posted outside of your software`}</a>{` documentation for guidance on using this field.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;