import { gql } from '@apollo/client';

export const EVENT_SUBJECT_OID_FRAGMENT = gql`
  fragment eventSubjectOidFields on Event {
    ... on CandidateApplicationCreatedEvent {
      oid: candidateApplicationProfileId
    }
    ... on CandidateProfilePurchasedEvent {
      oid: candidateProfileId
    }
    ... on HirerRelationshipChangedEvent {
      oid: hirerId
    }
    ... on PositionProfileClosedEvent {
      oid: positionProfileId
    }
    ... on PositionProfilePostedEvent {
      oid: positionProfileId
    }
  }
`;
